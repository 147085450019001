import React from "react";
import MyAssignedAssetsContainer from "./MyAssignedAssetsContainer";

const AssignedAssets = (props) => {

  return (
      <MyAssignedAssetsContainer props={props} location={props.location} history={props.history} />
  );

};

export default AssignedAssets;
