import React from "react";
import EditContainer from "./EditContainer";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const EditBeneficiary = (props) => {

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <EditContainer props={props} location={props.location} history={props.history} />
    </MuiPickersUtilsProvider>
  );

};

export default EditBeneficiary;
