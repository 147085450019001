import React from "react";
import MyAssetsContainer from "./MyAssetsContainer";

const MyAssets = (props) => {

  return (
      <MyAssetsContainer props={props} location={props.location} history={props.history} />
  );

};

export default MyAssets;
