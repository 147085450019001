import React from "react";
import CountdownResponseStatusContainer from "./CountdownResponseStatusContainer";

const CountdownResponseStatus = (props) => {
  //const { token } = props;
  //alert(token);
  return (
      <CountdownResponseStatusContainer {...props} location={props.location} history={props.history} />
  );

};

export default CountdownResponseStatus;
