import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLORS } from '../../helpers/constants';

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    border-radius: 6px;
    svg{
        color: ${COLORS.lightgold};
    }
 `  

const OverlayWaiting = () => {
    return (
        <Wrapper>
            <CircularProgress size={45} />
        </Wrapper>
    )
}

export default OverlayWaiting;