import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';
import { breakpoints } from '../../helpers/breakpoints';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: ${props => props.css.width ? "100%" : "50%"}!important;
    width: ${props => props.css.width ? props.css.width + 'vw' : "15vw"};
    height: ${props => props.css.height ? props.css.height + 'vw' : "15vw"};
    border: ${props => props.css.border.withBorder ? '2px solid '+ props.css.border.color : '2px solid transparent' };

    img{
        height: 100%;
    }
    .text_avatar{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: ${COLORS.lightgold};
        p{
            font-size: 5vw;
            line-height: 5vw;
            font-weight: 600;
            text-transform: uppercase;
            color: ${COLORS.whiteTwo}
        }
    }

    @media ${breakpoints.xs} {
        border-radius: ${props => props.css.width ? "100%" : "50%"}!important;
        width: ${props => props.css.width ? props.css.width + 'px' : "100px"};
        height: ${props => props.css.height ? props.css.height + 'px' : "100px"};
        .text_avatar{
            p{
                font-size: 30px;
                line-height: 30px;
            }
        }

    }
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`




const Thumb = ({ children, css, onClick, className }) => {
    console.log(css);
    return (
        <Wrapper css={css} onClick={onClick} className={className}>
            { children }
        </Wrapper>
    )
}


export default Thumb
