import { useEffect, useState, useRef, useContext } from "react"
import storage from 'local-storage-fallback';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';
import { RiDeleteBin6Line, RiCheckLine } from "react-icons/ri";
import { truncateString } from '../../helpers/functions';
import { ContentContext } from '../../context';

import FileIcon from '../Elements/Upload/FileIcon';

import axios, { CancelToken, isCancel } from "axios"

import { styled as muiStyled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

const BootstrapTooltip = muiStyled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} enterTouchDelay={0} />
))(({ theme }) => ({
[`& .${tooltipClasses.arrow}`]: {
    color: COLORS.bluegrey,
},
[`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.bluegrey,
}})
);

const FileElement = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    //border: 1px solid ${COLORS.cloudyblue};
    //background-color: #f5f5f5;
    border-radius: 5px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px 15px 15px 15px;
    box-sizing: border-box;

    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.20);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.20);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.20);

    .uploader_inner{
        display: flex;
        flex-direction: row;
        align-items: center;
        .left{
            width: 23px;
            margin-right: 7px;
            .file_type{
                width: 23px;
                height: 26px;
                overflow: hidden;
                .ico{
                    width: 23px;
                    fill: ${COLORS.cloudyblue}!important;
                }
            }
        }
        .right{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: calc(100% - 30px);
            .loader{
                position: absolute;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                right: 7px;
                top: 7px;
                .MuiCircularProgress-root{
                    height: 20px!important;
                    width: 20px!important;
                    svg{
                        color: ${COLORS.bluegrey};
                    }
                }
            }
            .file_uploaded_ok{
                position: absolute;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 15px;
                height: 15px;
                background-color: ${COLORS.green};
                border-radius: 8px;
                left: -7px;
                top: -7px;
                svg{
                    color: #FFFFFF;
                }
            }
            .bt_delete_file{
                //position: absolute;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 25px;
                height: 25px;
                background-color: ${COLORS.red};
                border-radius: 3px;
                right: 7px;
                top: 7px;
                cursor: pointer;
                svg{
                    width: 15px;
                    height: 15px;
                    fill: #FFFFFF;
                }
                :hover{
                    background-color: ${COLORS.darkgrey};
                }
            }
            .file_name{
                font-size: 13px;
                font-weight: 600;
                color: ${COLORS.darkgrey};
            }
            
        }
        
    }
    .progress{
        height: 15px;
        background-color: ${COLORS.lightgrey};
        margin-top: 5px;
        .progress_bar{
            height: 15px;
            background-color: ${COLORS.bluegrey};
        }
    }
`

export function SingleFileUploadWithProgress(props){

    const { file,
            files,
            setFiles,
            assetId,
            folder,
            onUpload,
            setFileToUpload } = props;
    
    const { deleteFile } = useContext(ContentContext);

    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [fileResponse, setFileResponse] = useState({})
        
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const cancelFileUpload = useRef(null);

    useEffect(() => {
        
        uploadFile(file, assetId, folder, onUpload);
    
    }, [])


    const uploadFile = (file, assetId, folder, onUpload) => {

        setIsLoading(true);
        const url = `${process.env.REACT_APP_API_SERVER}/utils/upload-files`;

        const data = new FormData();
        data.append('file', file);
        data.append('asset_id', assetId);
        data.append('folder', folder);
    
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100)/total);
                if(percent < 100){
                    setUploadPercentage(percent);
                }
            },
            headers: { Authorization: `Bearer ${storage.getItem('ie_access_token')}` },
            cancelToken: new CancelToken(
                cancel => (cancelFileUpload.current = cancel)
            )
        }
    
        axios.post(
            url,
            data,
            options
        )
        .then(res => {

            setUploadPercentage(100);
            onUpload(res.data);

            setFileResponse(res.data.data);
            setIsLoading(false);

            setFileToUpload(null);

        })
        .catch(err => {
            console.log(err);
    
            if (isCancel(err)) {
            }
            setUploadPercentage(0);
        });
    }
    
    const cancelUpload = () => {

        if (cancelFileUpload.current){

            const response = files.filter(f => f.tempId !== file.tempId);
            const files_filtered = files.map(f => {
                if(f.tempId !== file.tempId){
                    return f;
                }
            })
            cancelFileUpload.current("User has canceled the file upload: " + file.tempId);

        }
    };

    const onDeleteFile = (file) => {
        setIsLoading(true);
        const payload = {asset_file_id: file.id,
                         asset_id: assetId,
                         image_folder: 'assets'}
                         
        const result = deleteFile(payload, file, 'assets');
        result.then((res) => {

            const response = files.filter(f => f.tempId !== file.tempId);
            const files_filtered = files.map(f => {
                if(f.tempId !== file.tempId){
                    return f;
                }
            })
            setIsLoading(false);
            setFiles(response);

        })
        
    }

    useEffect(() => {
      return () => {
        cancelUpload();
      }
    }, [])
    
    
    
    return (
        <FileElement key={""}>
            <div className="uploader_inner">
                <div className="left">
                    <div className="file_type">
                        <FileIcon mime={file?.type} />
                    </div>
                </div>
                <div className="right">
                    {/* uploadPercentage == 100 ?
                        <>
                            <div className="file_uploaded_ok">
                                <RiCheckLine />
                            </div>
                            {isLoading ? 
                            <div className="loader">
                                <CircularProgress />
                            </div>
                            :
                            <div className="bt_delete_file" onClick={() => onDeleteFile(file) }>
                                <RiDeleteBin6Line />
                            </div>
                            }
                        </>
                    :   
                        <div className="bt_delete_file" onClick={() => cancelUpload() }>
                            <RiDeleteBin6Line />
                        </div>
                        */}

                    

                    <BootstrapTooltip title={file?.name} placement="top">
                        <div className="file_name">
                            { file?.name.length > 10 ? truncateString(file?.name, 10) : file?.name }
                        </div>
                    </BootstrapTooltip>

                    <div className="bt_delete_file" onClick={() => cancelUpload() }>
                        <RiDeleteBin6Line />
                    </div>
                    
                    {message}
                </div>
            </div>

            <div className="progress">
                <div className="progress_bar" style={{ width: uploadPercentage + "%" }}></div>
            </div>

        </FileElement>
    )  
        
}



/*
function uploadFile(file, assetId, folder, onProgress, onUpload){

    const url = `${process.env.REACT_APP_API_SERVER}/utils/upload-files`;

    return new Promise((res, rej) => {

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.responseType = "json";
        xhr.setRequestHeader('Authorization', 'Bearer ' + storage.getItem('ie_access_token'));

        xhr.onload = (evt) => {
            console.log(evt.currentTarget.response)

            onUpload(evt.currentTarget.response);

        }

        xhr.onerror = () => {

        }

        xhr.upload.onprogress = (event) => {
            if(event.lengthComputable){
                const percentage = (event.loaded/event.total)*100;
                onProgress(Math.round(percentage));
            }
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('asset_id', assetId);
        formData.append('folder', folder);
        xhr.send(formData);

    })
}
*/