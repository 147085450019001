import React, { useState, useContext, useEffect, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { ContentContext } from '../../context';
import { AiOutlineZoomIn } from "react-icons/ai";
import { COLORS } from '../../helpers/constants';
import noasset from '../../assets/images/noasset.jpg';
import connect, { handleError } from '../../helpers/connect';

const Wrapper = styled.div`
display:flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
position: relative;
cursor: ${props => props.showMagnify ? "pointer" : "default"};
.magnify{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: ${COLORS.whiteTwoAlpha75};
    font-size: 20px;
}
:hover{
    .magnify{
        display: flex;
    }
}
img{
    float: left;
    height: 100%;
    display: none;
    &.show{
        display: flex;
    }
}
.loader{
    display: flex;
    width: 33px!important;
    height: 33px!important;
    .MuiCircularProgress-root{
        width: 100%!important;
        height: 100%!important;
        svg{
            color: ${COLORS.lightgold};
            width: 100%!important;
            height: 100%!important;
        }
    }
}
`

const Image = (props) => {

    const { user_id, type, filename, user_type, owner_id, showMagnify = false } = props;
    const [ image, setImage ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const imageRef = useRef(null);

    useEffect(() => {
        console.log(filename);
        setLoading(true);
        let blob_ = false;
        const response = getFileByType(filename, type, user_type, owner_id);
        response.then((res) => {
            if(res){
                blob_ = URL.createObjectURL(res.data);
                imageRef.current.src = blob_;
                setImage(blob_);
                setLoading(false);
            }
        }).catch((message)=>{
            setLoading(false);
            if(imageRef.current){
                imageRef.current.src = noasset;
            }
        })

        return function cleanup() {
            URL.revokeObjectURL(blob_);
        };

    }, [filename]);

    const getFileByType = async (filename, type, user_type, owner_id) => {

        const statement = {};

        let usertype = 'owner';
        if(user_type){
            Object.assign(statement, {user_type: user_type});
        }
        if(owner_id){
            Object.assign(statement, {owner_id: owner_id});
        }
        
        const requestURL = `${process.env.REACT_APP_API_SERVER}/images/${type}/${filename}`;
        const result = await connect.get(requestURL, { params: statement, responseType: "blob" });
        return result;

    }
    
    return (
        <Wrapper showMagnify={showMagnify}>
            {showMagnify &&
                <div className="magnify">
                    <AiOutlineZoomIn />
                </div>
            }
            
            {loading ==! null && loading == true ? 
                <div className="loader">
                    <CircularProgress />
                </div>
            : ""
            }

            <img ref={imageRef} className={loading ==! null && loading == true ? "hide" : "show"}/>
        </Wrapper>
    )
}
export default React.memo(Image);
