import React from "react";
import SettingsContainer from "./SettingsContainer";

const Settings = (props) => {

  return (
      <SettingsContainer props={props} location={props.location} history={props.history} />
  );

};

export default Settings;
