import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import "moment-timezone";

import ButtonSolid from '../Elements/Buttons/Solid';
import OverlayErrorMessage from '../Elements/Form/OverlayErrorMessage';
import Input from '../Elements/Form/Input';
import Switch from '../Elements/Form/Switch';

import { validateEmail,
         getErrorMessage } from '../../helpers/functions';

import { AuthContext } from '../../context';
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalTerms from '../../components/Elements/Modals/ModalTerms'
import { breakpoints } from '../../helpers/breakpoints';

import { COLORS } from '../../helpers/constants'

const Wrapper = styled.div`

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .title{
        letter-spacing: 0px;
        font-size: 8vw;
        line-height: 10vw;
        font-weight: 700;
        color: ${COLORS.darkgrey};
    }
    .inner{
        box-sizing: border-box;
        padding: 15px;
    }
    .form_register{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .field{
            width: 100%!important;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .actions{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            .bt_signup{
                width: 100%;
                min-height: 65px;
                p{
                    font-size: 20px;
                }
            }
            .dohave{
                margin-top: 30px;
                font-size: 16px;
                color: ${COLORS.darkgrey};
                button{
                    font-size: 16px;
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    color: ${COLORS.lightgold};
                    cursor: pointer;
                }
                button:hover{
                    color: ${COLORS.darkgrey};
                }
            }
        }
        
        .loader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 150px;
            min-height: 66px;
            svg{
                color: ${COLORS.lightgold};
            }
        }
    }

    @media ${breakpoints.xs} {
        .title{
            font-size: 48px;
            line-height: 55px;
        }
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }

`
const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: calc(50% - 10px);
    .description{
        textarea{
            resize: none;
            height: 180px;
        }
    }
    &.accept_terms{
        align-items: center;
        justify-content: flex-start;
        .terms_and_condition{
            width: 86px;
        }
        label{
            margin-left: 10px;
            font-size: 16px;
        }
        button{
            border: none;
            background-color: transparent;
            padding: 0;
            font-size: 16px;
            cursor: pointer;
            :hover { 
                color: ${COLORS.lightgold}
            }
        }
    }
`
const OverlayCongratulation = styled.div`

    position: absolute;
    background-color: rgba(255, 255, 255, 0.90);
    border: 1px solid rgba(255, 255, 255, 0.90);
    width: 100%;
    height: 100%;
    z-index: 99;
    .inner_overlay{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px 30px 20px;
        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
        background-color: #FFFFFF;
        .mail_validation{
            font-weight: 600;
            color: ${COLORS.lightgold};
        }
        .bt_close_register{
            margin-top: 25px;
        }
        .bt_close_errors{
            margin-top: 25px;
        }
        p{
            width: 100%;
            text-align: center;
            margin: 3px 20px 3px 20px;
            color: ${COLORS.red};
            font-weight: 500;
            span{
                font-weight: 600;
                color: ${COLORS.lightgold};
            }
        }
        .title{
            font-size: 30px;
            line-height: 30px;
            width: 100%;
            text-align: center;
            color: ${COLORS.lightgold};
        }
        .text{
            font-size: 16px;
            line-height: 20px;
            width: 100%;
            text-align: center;
            margin-top: 15px;
            color: ${COLORS.darkgrey};
        }

    }
`

const RegisterContainer = (props) => {

    const { t } = useTranslation();
    
    const history = useHistory();
    const { sendRegister } = useContext(AuthContext);

    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(null);
    const [ errorMessage, setErrorMessage ] =  useState({});
    const [ formHaveError, setFormHaveError ] = useState(false);
    const [ successRegister, setSuccessRegister ] = useState(false);
    const [ registerValidationMail, setRegisterValidationMail ] = useState('');
    const [ showTerms, setShowTerms] = useState(false);
    
    let inputRef = useRef({});

    useEffect(() => {
        
        setFormData({
            first_name: { value: "", required: true, disabled: false, ref: "first_name", error: false },
            last_name: { value: "", required: true, disabled: false, ref: "last_name", error: false },
            email: { value: "", required: true, disabled: false, ref: "email", error: false },
            password: { value: "", required: true, disabled: false, ref: "password", error: false },
            repeat_password: { value: "", required: true, disabled: false, ref: "repeat_password", error: false },
            terms_and_condition: { value: false, required: true, disabled: false, ref: "terms_and_condition", error: false },
        });

        return () => {
            //toast.dismiss();
        }
        
    }, [])

    const handleFormElementChange = (name, value) => {
        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        return null;
    }

    const handleSubmit = (e) => {
        
        e.preventDefault();
        let arrErrorMessage = [];
        let haveError = false;
        
        if(Object.keys(formData).length){
            let updateFormData = {...formData};
            Object.keys(formData).map(function(key) {
                if(formData[key].required){
                    if(!formData[key].value.length && key !== 'terms_and_condition'){
                        haveError = true;
                        updateFormData[key].error = true;
                        const msg = t(`formErrors.mandatory.${formData[key].ref}`);//getErrorMessage(formData[key].ref, 'mandatory');
                        arrErrorMessage.push('- '+msg);
                    }else{
                        updateFormData[key].error = false;
                    }
                    if(key == 'email'){
                        if(formData[key].value.length){
                            const validEmail = validateEmail(formData[key].value);
                            if(!validEmail){
                                haveError = true;
                                updateFormData[key].error = true;
                                const msg = t(`formErrors.${formData[key].ref}`);//getErrorMessage(formData[key].ref, 'format');
                                arrErrorMessage.push('- '+msg);
                            }else{
                                updateFormData[key].error = false;
                            }
                        }
                    }
                    if(key == 'terms_and_condition'){
                        if(formData[key].value === false){
                            haveError = true;
                            updateFormData[key].error = true;
                            const msg = t(`formErrors.mandatory.${formData[key].ref}`);//getErrorMessage(formData[key].ref, 'mandatory');
                            arrErrorMessage.push('- '+msg);
                        }
                    }
                }
            })

            if(formData['password'].value !== formData['repeat_password'].value){
                haveError = true;
                updateFormData['password'].error = true;
                updateFormData['repeat_password'].error = true;
                arrErrorMessage.push('- ' + t('formErrors.repeat_password'));
            }

            if(!haveError){
                setLoading(true);
                const payload = { first_name: updateFormData['first_name'].value,
                                  last_name: updateFormData['last_name'].value,
                                  email: updateFormData['email'].value,
                                  password: updateFormData['password'].value,
                                  repeat_password: updateFormData['repeat_password'].value,
                                  terms_and_condition: updateFormData['terms_and_condition'].value,
                                  timezone: moment.tz.guess()};
                                  
                const response = sendRegister(payload);

                response.then((res) => {
                    console.log(res);
                    if(!res.data.success){
                        if(res.data.errors){
                            Object.keys(res.data.errors).map(function(key) {
                                res.data.errors[key].map((errorText) => {
                                    arrErrorMessage.push('- '+errorText);
                                })
                            })
                            setErrorMessage({title: 'OOPS!',
                                            text: t('register.form.error.message'),
                                            errors: arrErrorMessage,
                                            actions: [{type: 'close', text: t('buttons.close'), goto: null}]});
                            setFormHaveError(true);
                        }
                    }else{
                        setRegisterValidationMail(updateFormData['email'].value);
                        setSuccessRegister(true);
                        resetForm();
                    }
                    setLoading(false);
                })
            }else{
                setErrorMessage({title: 'OOPS!',
                                 text: t('register.form.error.message'),
                                 errors: arrErrorMessage,
                                 actions: [{type: 'close', text: t('buttons.close'), goto: null}]});
                setFormHaveError(true);
            }
            setFormData(updateFormData);
        }
        
    }

    const resetForm = () => {
        Object.keys(inputRef.current).map(function(key) {
            inputRef.current[key].value = '';
        })
        Object.keys(formData).map(function(key) {
            formData[key].value = '';
        })
    }

    useEffect(() => {
      console.log(formData);
    }, [formData])
    
    const handleShowTerms = () => {
        setShowTerms(!showTerms);
    }
    
    return (
        <Wrapper>
            
            <ModalTerms open={showTerms} setOpen={setShowTerms} />

            {successRegister &&
            <OverlayCongratulation>
                <div className="inner_overlay">
                    <div className="title">{t('basics.response_title_success')}</div>
                    <div className="text">{t('register.mail_validate1')} <span className="mail_validation">{registerValidationMail}</span> {t('register.mail_validate2')}</div>
                    <ButtonSolid
                        type="submit"
                        style_type="default"
                        className="bt_close_register"
                        override_css={null}
                        onClick={(e) => setSuccessRegister(!successRegister)}
                    >
                        <p>Close</p>
                    </ButtonSolid>
                </div>
            </OverlayCongratulation>
            }

            <OverlayErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} />

            <div className="title">
                {t(`register.title`)}
            </div>
            <div className="form_register">
                
                <Field className="field">
                    <Input
                        className={`first_name ${formData?.first_name?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="text"
                        label={`${t("labels.first_name")}:`}
                        inputRef={inputRef}
                        elName={formData?.first_name?.ref}
                        value={formData?.first_name?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>

                <Field className="field">
                    <Input
                        className={`last_name ${formData?.last_name?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="text"
                        label={`${t("labels.last_name")}:`}
                        inputRef={inputRef}
                        elName={formData?.last_name?.ref}
                        value={formData?.last_name?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>

                <Field className="field">
                    <Input
                        className={`email ${formData?.email?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="text"
                        label={`${t("labels.email")}:`}
                        inputRef={inputRef}
                        elName={formData?.email?.ref}
                        value={formData?.email?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>

                <Field className="field">
                    <Input
                        className={`password ${formData?.password?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="password"
                        label={`${t("labels.password")}:`}
                        helper={t(`basics.password_rule`)}
                        inputRef={inputRef}
                        passwordShow={true}
                        elName={formData?.password?.ref}
                        value={formData?.password?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>

                <Field className="field">
                    <Input
                        className={`repeat_password ${formData?.repeat_password?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="password"
                        label={`${t("labels.repeat_password")}:`}
                        helper={t(`basics.password_rule`)}
                        inputRef={inputRef}
                        passwordShow={false}
                        elName={formData?.repeat_password?.ref}
                        value={formData?.repeat_password?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>

                <Field className="field accept_terms">
                    <Switch
                        label=""
                        toggled={formData?.terms_and_condition?.value}
                        //setToggled={setNotifyBeneficiaryToggled}
                        color="default"
                        style_type="default"
                        inputRef={inputRef}
                        //ref={el => inputRef.current[formData?.notify_beneficiary?.ref] = el}
                        elName={formData?.terms_and_condition?.ref}
                        className={`terms_and_condition ${formData?.terms_and_condition?.error ? "error" : ""}`}
                        onChange={handleFormElementChange/*handleOnChangeGender*/}
                    />
                    <label>{t("labels.accept_terms_1")} <button onClick={() => handleShowTerms()}>{t("labels.accept_terms_2")}</button></label>
                </Field>

                <div className="actions">
                    {loading ?
                        <div className="loader">
                            <CircularProgress size={50} />
                        </div>
                        :
                        <ButtonSolid
                            type="submit"
                            style_type="default"
                            className="bt_signup"
                            override_css={{color:{normal: "#FFFFFF", hover: COLORS.lightgold},
                                        background:{normal: COLORS.lightgold, hover: '#FFFFFF'},
                                        border:{normal: COLORS.lightgold, hover: COLORS.lightgold}}}
                            onClick={(e) => handleSubmit(e)}
                        >
                            <p>{t("buttons.signup")}</p>
                        </ButtonSolid>
                    }

                    <div className="dohave">
                    
                        {t("basics.doyoy_have_account")} <button onClick={() => history.push('/signin')}>{t("buttons.signin")}</button>
                    </div>

                </div>
            </div>
        
        </Wrapper>
    )
}

export default RegisterContainer
