import React, { useState, useRef, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import { breakpoints } from '../../helpers/breakpoints';

import moment from 'moment';
import "moment-timezone";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { validateEmail } from '../../helpers/functions';
import { ContentContext,
         AuthContext } from '../../context';

import CircularProgress from '@material-ui/core/CircularProgress';
import { isValidPhoneNumber, } from 'react-phone-number-input';

import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import ButtonIcon from '../../components/Elements/Buttons/Icon';
import Input from '../../components/Elements/Form/Input';
import Select from '../../components/Elements/Form/Select';
import DatePicker from '../../components/Elements/Form/DatePicker';
import Title from '../../components/Elements/Title';
import CustomToastMessageError from '../../components/Elements/CustomToastMessageError';
import PhoneInputCountryCode from '../../components/Elements/Form/PhoneInputCountryCode';

import { MdCloudUpload } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { FaCamera } from "react-icons/fa";
import { IoArrowBackCircleOutline } from "react-icons/io5";

import { IcoCameraSolid,
         IcoClose,
         IcoUpload } from '../../helpers/constants';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import { COLORS,
         SIZES } from '../../helpers/constants';
import { toast } from 'react-toastify';

const OverlayWait  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    border-radius: 12px;
    svg{
        color: ${COLORS.lightgold};
    }
    
`

const Wrapper = styled.div`

    display: flex;
    width: 100%;

    flex-direction: row;
    justify-content: center;

    .inner{
        box-sizing: border-box;
        //padding: 15px;
    }

`
const WeapperImageProfile = styled.div`
    
    display: flex;
    flex-direction: column;
    width: 220px;
    justify-content: space-between;
    //align-items: flex-start;

    .box_image{
        position: relative;
        display: flex;
        flex-direction: column;
        height: 220px;
        
    }
    .field{
        width: 100%!important;
        margin-bottom: 10px;
    }
`
const ImageProfile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    width: 220px;
    height: 220px;
    //border: 2px solid transparent;

    background-color: ${COLORS.whiteTwo};
    
    img{
        height: 100%;
    }
`
const ButtonAddImageProfile = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: -2vw;

    .icon{
        width: auto!important;
        height: auto!important;
        font-size: 20px;
    }

    @media ${breakpoints.xs} {
        bottom: -10px;
    }
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`
const Fields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .triple_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
    }
    /*
    .triple_field{
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        .field{
            width: calc(33% - 10px);
            margin-bottom: 10px;
        }
    }
    */
    .double_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        .field{
            margin-bottom: 10px;
        }
    }
    
    
    .single_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
        .box_important{
            display: flex;
            width: 100%;
            flex-direction: row;
            font-size: 13px;
            //
            .text_important{
                width: 100%;
                .red{
                    color: ${COLORS.red}
                }
                p{
                    margin: 0;
                }
            }
            .notify_beneficiary{
                width: 86px;
                align-items: center;
            }
        }
    }

    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {
        .double_field{
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .field{
                margin-bottom: 10px;
            }
        }
        .triple_field{
            width: 100%;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            .field{
                width: calc(33.3% - 15px);
                margin-bottom: 10px;
            }
        }
        .single_field{
            width: 100%;
            flex-direction: column;
            .field{
                width: 100%;
                margin-bottom: 10px;
            }
            .box_important{
                width: 100%;
                flex-direction: row;
                font-size: 13px;
                .text_important{
                    width: 100%;
                }
                .notify_beneficiary{
                    width: 86px;
                    align-items: center;
                }
            }
        }
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }

`
const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    //width: calc(50% - 10px);
    
    .aclaration{
        font-size: 12px;
        margin-top: 3px;
        color: ${COLORS.bluegrey};
    }
    &.field_title{
        margin-top: 15px;
        margin-bottom: 0!important;
    }
    &.notify_beneficiary{
        margin-bottom: 0!important;
    }

    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {
        width: calc(50% - 10px);
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    //margin-right: 20px;
    //width: calc(100% - 350px);
    .box_data{
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        .inner{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding: 20px;
        }
    }
    .box_assets{
        margin-bottom: 20px;
    }
    .box_header_actions{
        max-height: 60px;
        box-shadow: 0!important;
        .inner{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            p.title{
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 0 10px;
                padding: 0;
            }
            .bt_save{
                margin-left: auto;
            }
            .action_back{
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .action_save{
                display: flex;
                flex-direction: row;
                align-items: center;
                .loader{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 150px;
                    min-height: 40px;
                    svg{
                        color: ${COLORS.lightgold};
                    }
                }
            }
        }
    }
    .box_recent{
        margin-top: 20px;
    }
    .box_form{
        width: 100%;
        margin-top: 0;
        .inner_form{
            position: relative;
            display: flex;
            width: 100%;
            flex-direction: column;
            padding: 20px;
            .form_top{
                display: flex;
                width: 100%;
                flex-direction: column;
                padding-bottom: 20px;
                border-bottom: 1px solid ${COLORS.iceblue};
                justify-content: space-between;
                align-items: center;

                .left{
                    display: flex;
                    width: 220px;
                }
                .right{
                    display: flex;
                    width: 100%;
                    margin-top: 20px;
                }
            }
            .form_bottom{
                display: flex;
                width: 100%;
                flex-direction: row;
                //background-color: skyblue;
                .center{
                    width: 100%;
                }
                .title{
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
            .action_save{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: 10px;
            }
        }
    }
    @media ${breakpoints.xs} {
        //min-width: 576px
    }
    @media ${breakpoints.sm} {
        //min-width: 768px
        
    }
    @media ${breakpoints.md} {
        .box_form{
            .inner_form{
                .form_top{
                    flex-direction: row;
                    align-items: flex-start;
                    .right{
                        display: flex;
                        width: calc(100% - 240px);
                        margin-top: 0;
                    }
                }
            }
        }
    }
    @media ${breakpoints.lg} {
        //min-width: 1200px
        //width: 870px;
    }
    @media ${breakpoints.xl} {
        //min-width: 1366px
        //width: 1036px;
    }
    @media ${breakpoints.xxl} {
        //min-width: 1440px
        //width: 1110px;
    }
    @media ${breakpoints.xxxl} {
        //min-width: 1680px
        //width: 1350px;
        //background-color: pink;
    }
    @media ${breakpoints.xxxxl} {
        //min-width: 1920px
        //width: ${SIZES.xxxxl};
        //background-color: skyblue;
    }
`
const ModalStyled = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0!important;
    border-radius: 16px;

    :focus{
        outline: none!important;
    }

    position: absolute;
    top: 0!important;
    overflow: scroll;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: block;

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {
        width: 700px;
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`
const ContainerModalProfileImage = styled.div`
    /*
    background-color: #FFFFFF;
    width: 100%;
    border-radius: 16px;
    outline: 0!important;
    -webkit-box-shadow: 0px 0px 15px -5px #000000;
    box-shadow: 0px 0px 15px -5px #000000;

    .actions{
        display: flex;
        flex-direction: row;
    }
    .content_action_browse{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px dashed ${COLORS.cloudyblue};
        margin-top: 50px;
        padding-top: 40px;
        padding-bottom: 40px;
        input{
            display: none;
        }
    }
    .modal_product{
        padding-top: 20px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        .inner_modal_product{

        }
    }
    */

    width: 100%;
    border-radius: 0;
    outline: 0!important;
    
    padding: 10px;
    box-sizing: border-box;

    .actions{
        display: flex;
        flex-direction: row;
        .bt_cancel_image{
            margin-left: 5px;
        }
    }
    .content_action_browse{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px dashed ${COLORS.cloudyblue};
        margin-top: 50px;
        padding-top: 40px;
        padding-bottom: 40px;
        input{
            display: none;
        }
    }
    .modal_product{
        padding: 5px;
        background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 15px -5px #000000; 
    }

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {
        .modal_product{
            padding: 5px;
            border-radius: 16px;
            .inner_modal_image{
                border-radius: 13px;
                overflow: hidden;
            }
        }
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`
const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 25px;
    //background-color: #FFD200;
    //color: #FFD200;
    cursor: pointer;
    right: 20px;
    top: 20px;
    z-index: 9;
    .icon{
        display: flex;
        width: 35px;
        svg{
            fill: ${COLORS.lightgold};
        }
    }
    :hover{
        .icon{
            svg{
                fill: #000000;
            }
        }
    }
`
const ButtonBrowseProfileImage = styled.div`
    cursor: pointer;
    .icon{
        display: flex;
        width: 60px;
        svg{
            fill: ${COLORS.cloudyblue};
        }
    }
    :hover{
        .icon{
            svg{
                fill: ${COLORS.lightgold};
            }
    }
    }
`



const ProfileContainer = (props) => {

    const { countries,
            states,
            statesByCountry,
            uploadFile,
            editProfile, } = useContext(ContentContext);

    const { isTheVaultOpen,
            setOverlayVaultOpen,
            userData,
            setUserData } = useContext(AuthContext);
    
    const inputFileRef = useRef(null);
    let inputRef = useRef({});

    const gender = [{id: 1, value: 1, description: 'Male'}, {id: 2, value: 2, description: 'Female'}];
    
    const [ timeZoneList, setTimeZoneList ] = useState([]);
    const [ formData, setFormData ] = useState({});
    const [ isWaiting, setIsWaiting ] = useState(false);
    const [ croppedImageProfile, setCroppedImageProfile ] = useState(null);
    const [ loadingStates, setLoadingStates ] = useState(null);
    const [ loading, setLoading ] = useState(null);
    const [ openModal, setOpenModal ] = useState(false);
    const [ src, selectFile ] = useState(null);
    const [ upImg, setUpImg ] = useState();
    const [ completedCrop, setCompletedCrop ] = useState(null);
    const [ changeProfileImage, setChangeProfileImage ] = useState(false);
    const [ crop, setCrop ] = useState({
        unit: 'px',
        width: 250,
        minWidth: 250,
        minHeight: 250,
        maxWidth: 500,
        maxHeight: 500,
        aspect: 1 / 1
    });
    

    useEffect(() => {

        setFormData({
            account_id: { value: userData ? userData.account.id : "", required: false, disabled: false, ref: "account_id", error: false },
            first_name: { value: userData ? userData.first_name : "", required: true, disabled: false, ref: "first_name", error: false },
            middle_name: { value: userData ? userData.middle_name ? userData.middle_name : "" : "", required: false, disabled: false, ref: "middle_name", error: false },
            last_name: { value: userData ? userData.last_name : "", required: true, disabled: false, ref: "last_name", error: false },
            nationality: { value: userData ? userData.account.nationality_id ? userData.account.nationality_id.toString() : "" : "", required: true, disabled: false, ref: "nationality", error: false },
            email: { value: userData ? userData.email : "", required: true, disabled: true, ref: "email", error: false },
            phone_number: { value: userData ? userData.account.phone_number ? userData.account.phone_number : "" : "", required: true, disabled: false, ref: "phone_number", error: false },
            date_birth: { value: userData ? userData.account.date_birth ? userData.account.date_birth : "" : "", required: true, disabled: false, ref: "date_birth", error: false },
            //age: { value: "", required: true, disabled: false, ref: "age", error: false },
            gender: { value: userData ? userData.account.gender ? userData.account.gender.toString() : "" : "", required: true, disabled: false, ref: "gender", error: false },
            //phone_international: { value: "", required: true, disabled: false, ref: "phone_international", error: false },
            notify_beneficiary: { value: "", required: false, disabled: false, ref: "notify_beneficiary", error: false },
            country: { value: userData ? userData.account.country_id ? userData.account.country_id.toString() : "" : "", required: true, disabled: false, ref: "country", error: false },
            city: { value: userData ? userData.account.city ? userData.account.city : "" : "", required: true, disabled: false, ref: "city", error: false },
            state: { value: userData ? userData.account.state_id ? userData.account.state_id.toString() : "" : "", required: true, disabled: true, ref: "state", error: false },
            street: { value: userData ? userData.account.street ? userData.account.street : "" : "", required: true, disabled: false, ref: "street", error: false },
            zip: { value: userData ? userData.account.zip ? userData.account.zip : "" : "", required: true, disabled: false, ref: "zip", error: false },
            image_profile: { value: userData ? userData.profile_image_file : "", required: false, disabled: false, ref: "image_profile", error: false },
            timezone: { value: userData ? userData.timezone : moment.tz.guess(), required: false, disabled: false, ref: "timezone", error: false },
        });

        setCroppedImageProfile(userData.profile_image);
        const responseStatesByCountry = statesByCountry(userData.account.country_id);
        responseStatesByCountry.then((resp) => setLoadingStates(false));

        const timezone_list = [];
        if(moment.tz.names()){
            moment.tz.names().map((name, index) => {
                timezone_list.push({description: name, value: name});
            })
            setTimeZoneList(timezone_list);
        }

        return () => {
            toast.dismiss();
        }
        
    }, [])

    /*
    const handleProfileImage = () => {
        setOpenModal(true);
    }
    */
    const handleProfileImage = () => {

        setOpenModal(!openModal);
        if(!openModal){
            selectFile(null);
            setUpImg(null);
        }

    }

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        
        switch (name) {
            case 'country':
                stateByCountry(name, value, updateFormData);
                break;
            case 'date_birth':
                const years = moment().diff(value, 'years',false);
                break;
        }

        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
    }

    const stateByCountry = (name, value, updateFormData) => {

        if(value.length){
            setLoadingStates(true);
            if(updateFormData[name].value != value){
                
                updateFormData['state'].value = "";
                const responseStatesByCountry = statesByCountry(value);
                responseStatesByCountry.then((resp) => setLoadingStates(false));

            }
            updateFormData['state'].disabled = false;
        }else{
            updateFormData['state'].value = "";
            updateFormData['state'].disabled = true;
        }

    }

    const handleGenerateThumb = () => {

        const croppedImg = getCroppedImg();  
        setCroppedImageProfile(croppedImg);
        setChangeProfileImage(true);
        setOpenModal(false);
        selectFile(null);
        return null;

    }

    const getCroppedImg = () => {

        const canvas = document.createElement("canvas");
        const scaleX = upImg.naturalWidth / upImg.width;
        const scaleY = upImg.naturalHeight / upImg.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
      
        // New lines to be added
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
        
        // console.log("ctx", ctx);
        // console.log("pixelRatio", pixelRatio);
        // console.log("width", canvas.width);
        // console.log("height", canvas.height);
        // console.log("upImg", upImg);
        
        // return null;
        
        ctx.drawImage(
          upImg,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
      
        // As Base64 string
        const base64Image = canvas.toDataURL("image/jpeg");
        return base64Image;

    }

    const onSelectFile = (e) => {
        selectFile(URL.createObjectURL(e.target.files[0]));
        setUpImg(e.target.files[0]);
    }

    const handleBrowseProfileImage = () => {
        inputFileRef.current.click();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        //resetForm();
        
        

        let haveError = false;
        //alert(haveError);
        if(Object.keys(formData).length){

            let updateFormData = {...formData};
            console.log("-0-0-0-0-0-0-0-0-0-0-0-")
            console.log(updateFormData);
            console.log("-0-0-0-0-0-0-0-0-0-0-0-")
            Object.keys(formData).map(function(key) {
                
                if(formData[key].required){

                    if(!formData[key].value.length){

                        haveError = true;
                        updateFormData[key].error = true;
                        
                    }else{
                        updateFormData[key].error = false;
                    }

                    if(key == 'email'){
                        const validEmail = validateEmail(formData[key].value);
                        if(!validEmail){
                            haveError = true;
                            updateFormData[key].error = true;
                        }else{
                            updateFormData[key].error = false;
                        }
                    }

                    if(key == 'age'){
                        const onlyNumber = /^\d+$/;
                        const isNumber = onlyNumber.test(formData[key].value);
                        if(!isNumber){
                            haveError = true;
                            updateFormData[key].error = true;
                        }else{
                            updateFormData[key].error = false;
                        }
                    }
                    
                    // if(key == 'phone_number'){
                    //     const isValidPhone = isValidPhoneNumber(formData[key].value);
                    //     console.log("isValidPhone", isValidPhone)
                    //     if(!isValidPhone){
                    //         haveError = true;
                    //         updateFormData[key].error = true;
                    //     }else{
                    //         updateFormData[key].error = false;
                    //     }
                    // }
                    
                }

                

            });

            if(!haveError){

                if(changeProfileImage){

                    const payload = { base64: true, image: croppedImageProfile, type: "image_profile" };
                    const fileUploades = uploadFile(payload);
                    fileUploades.then((result) => {

                        const fileName = path(['data', 'file_name'], result) || null;
                        updateFormData['image_profile'].value = fileName;

                        setChangeProfileImage(false);
                        handleEditProfile(updateFormData);

                    })

                }else{

                    handleEditProfile(updateFormData);

                }
            }
            console.log(updateFormData);
            setFormData(updateFormData);

        }
    }

    const handleEditProfile = () => {

        setLoading(true);
        setIsWaiting(true);

        const response = editProfile(formData);
        
        response.then((res) => {

            let message = '';
            let responseType = '';
            let errors = [];

            if(res.success === true){
                
                const updatedUserData = {...userData};
                updatedUserData.first_name = res.data.first_name;
                updatedUserData.middle_name = res.data.middle_name;
                updatedUserData.last_name = res.data.last_name;
                updatedUserData.email = res.data.email;
                updatedUserData.is_vault_open = res.data.is_vault_open;
                updatedUserData.profile_image = res.data.profile_image;
                updatedUserData.profile_image_file = res.data.profile_image_file;
                updatedUserData.timezone = res.data.timezone;

                updatedUserData.account.city = res.data.account.city;
                updatedUserData.account.country_id = res.data.account.country_id;
                updatedUserData.account.nationality_id = res.data.account.nationality_id;
                updatedUserData.account.nationality_name = res.data.account.nationality_name;
                updatedUserData.account.date_birth = res.data.account.date_birth;
                updatedUserData.account.gender = res.data.account.gender;
                updatedUserData.account.phone_number = res.data.account.phone_number;
                updatedUserData.account.state_id = res.data.account.state_id;
                updatedUserData.account.street = res.data.account.street;
                updatedUserData.account.zip = res.data.account.zip;
                updatedUserData.account.updated_at = res.data.account.updated_at;

                setUserData(updatedUserData);     
                
                message = 'Your account has been updated';
                responseType = 'success';

                /*
                toast.success('Your account has been edited', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                */
                
            }else{

                responseType = 'error';
                errors.push('An error has occured. Please try again later');

                /*
                toast.error('An error has occured. Please try again later', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
                */
            }

            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setIsWaiting(false);
            setLoading(false);
        })

    }

    return (
        <Wrapper>
            <Container>
            <BoxContainer className="box_form">
                





                    <ModalStyled
                        disableAutoFocus={true}
                        open={openModal}
                        onClose={() => setOpenModal(!openModal)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openModal}>

                            <ContainerModalProfileImage>

                                <ButtonClose
                                    onClick={() => setOpenModal(!openModal)}
                                >
                                    <div className="icon">
                                        <RiCloseCircleLine size={45} />
                                    </div>
                                </ButtonClose>

                                <div className="modal_product">

                                    {src ?
                                        <div className="inner_modal_image">
                                            <ReactCrop
                                                src={src}
                                                onImageLoaded={setUpImg}
                                                //onImageLoaded={onLoad}
                                                crop={crop}
                                                onChange={(c) => setCrop(c)}
                                                onComplete={(c) => setCompletedCrop(c)}
                                            />

                                            <div className="actions">
                                                <ButtonSolid
                                                    type="submit"
                                                    style_type="default"
                                                    className="bt_save"
                                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                                    onClick={() =>
                                                        handleGenerateThumb()
                                                    }
                                                >
                                                    <p>Save Image</p>
                                                </ButtonSolid>

                                                <ButtonSolid
                                                    type="submit"
                                                    style_type="default"
                                                    className="bt_cancel_image"
                                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                                    onClick={() =>
                                                        handleProfileImage()
                                                    }
                                                >
                                                    <p>Cancel</p>
                                                </ButtonSolid>
                                            </div>
                                        </div>

                                    :

                                        <div className="content_action_browse">
                                            <input ref={inputFileRef} type="file" accept="image/*" onChange={onSelectFile} />
                                            <ButtonBrowseProfileImage onClick={handleBrowseProfileImage}>
                                                <div className="icon">
                                                    <MdCloudUpload size={50} />
                                                </div>
                                            </ButtonBrowseProfileImage>
                                        </div>

                                    }

                                </div>
                            </ContainerModalProfileImage>
                            
                        </Fade>

                    </ModalStyled>






                    <div className="inner_form">
                        {isWaiting &&
                        <OverlayWait>
                            <CircularProgress size={45} />
                        </OverlayWait>
                        }
                        <div className="form_top">
                            <div className="left">
                                <WeapperImageProfile className="weapperImageProfile">
                                    
                                    <div className="box_image">
                                        <ImageProfile>
                                            {croppedImageProfile ? 
                                            <img src={croppedImageProfile} />
                                            :
                                            <></>    
                                            }
                                            {/*<img src={profileImage ? imageBase64 : "https://www.fillmurray.com/640/360"} />
                                            <img src={croppedImageProfile ? croppedImageProfile : "https://www.fillmurray.com/640/360"} />*/}
                                        </ImageProfile>
                                        <ButtonAddImageProfile>
                                            <ButtonIcon style_type="solid" onClick={handleProfileImage}>
                                                <div className="icon">
                                                    <FaCamera />
                                                </div>
                                            </ButtonIcon>
                                        </ButtonAddImageProfile>
                                    </div>

                                </WeapperImageProfile>
                            </div>
                            <div className="right">
                                <Fields>
                                    <div className="triple_field">
                                        <Field className="field first_name">
                                            <Input
                                                className={`first_name ${formData?.first_name?.error ? "error" : ""}`}
                                                isRequired={"true"}
                                                style_type="default"
                                                type="text"
                                                label="First Name:"
                                                inputRef={inputRef}
                                                //ref={el => inputRef.current[formData?.first_name?.ref] = el}
                                                elName={formData?.first_name?.ref}
                                                value={formData?.first_name?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                        <Field className="field middle_name">
                                            <Input
                                                className={`middle_name ${formData?.middle_name?.error ? "error" : ""}`}
                                                style_type="default"
                                                label="Middle Name:"
                                                inputRef={inputRef}
                                                type="text"
                                                //ref={el => inputRef.current[formData?.last_name?.ref] = el}
                                                elName={formData?.middle_name?.ref}
                                                value={formData?.middle_name?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                        <Field className="field last_name">
                                            <Input
                                                className={`last_name ${formData?.last_name?.error ? "error" : ""}`}
                                                style_type="default"
                                                label="Last Name:"
                                                inputRef={inputRef}
                                                type="text"
                                                //ref={el => inputRef.current[formData?.last_name?.ref] = el}
                                                elName={formData?.last_name?.ref}
                                                value={formData?.last_name?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                    </div>
                                    <div className="triple_field">
                                        <Field className="field email">
                                            <Input
                                                className={`email ${formData?.email?.error ? "error" : ""}`}
                                                style_type="default"
                                                label="Email:"
                                                type="email"
                                                disabled={formData?.email?.disabled}
                                                inputRef={inputRef}
                                                elName={formData?.email?.ref}
                                                value={formData?.email?.value}
                                                onChange={handleFormElementChange}
                                            />
                                            <div className="aclaration">Please enter only emails with "@" and "." (dot)</div>
                                        </Field>
                                        <Field className="field phone">
                                            {/*
                                            <Input
                                                className={`phone_number ${formData?.phone_number?.error ? "error" : ""}`}
                                                style_type="default"
                                                label="Mobile:"
                                                type="text"
                                                inputRef={inputRef}
                                                elName={formData?.phone_number?.ref}
                                                value={formData?.phone_number?.value}
                                                onChange={handleFormElementChange}
                                            />
                                            */}
                                            <PhoneInputCountryCode
                                                style_type="default"
                                                label="Mobile:"
                                                type="text"
                                                className={`phone_number ${formData?.phone_number?.error ? "error" : ""}`}
                                                inputRef={inputRef}
                                                countryPhone={null}
                                                value={formData?.phone_number?.value}
                                                elName={formData?.phone_number?.ref}
                                                onChange={handleFormElementChange}
                                            />
                                            <div className="aclaration">Please enter only mobile numbers with the "+" sign and country code with this format: +1 (888) 888-8888</div>
                                        </Field>
                                        <Field className="field nationality">
                                            <Select
                                                style_type="default"
                                                label="Nationality:"
                                                inputRef={inputRef}
                                                className={`nationality ${formData?.nationality?.error ? "error" : ""}`}
                                                elName={formData?.nationality?.ref}
                                                data={countries}
                                                optionSelected={formData?.nationality?.value}
                                                onChange={handleFormElementChange/*handleOnChangeTitle*/}
                                            />
                                        </Field>
                                    </div>
                                    <div className="double_field">
                                        <Field className="field date_of_birth">

                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DatePicker
                                                    style_type="default"
                                                    label="Date of birth:"
                                                    type="text"
                                                    disableFuture={true}
                                                    className={`date_birth ${formData?.date_birth?.error ? "error" : ""}`}
                                                    inputRef={inputRef}
                                                    elName={formData?.date_birth?.ref}
                                                    selectedDate={formData?.date_birth?.value}
                                                    onChange={handleFormElementChange}
                                                />
                                            </MuiPickersUtilsProvider>

                                        </Field>
                                        <Field className="field age_gender">
                                            <Select
                                                style_type="default"
                                                label="Gender"
                                                //ref={el => inputRef.current[formData?.gender?.ref] = el}
                                                inputRef={inputRef}
                                                elName={formData?.gender?.ref}
                                                className={`gender ${formData?.gender?.error ? "error" : ""}`}
                                                data={gender}
                                                optionSelected={formData?.gender?.value/*genderSelected*/}
                                                onChange={handleFormElementChange/*handleOnChangeGender*/}
                                            />
                                        </Field>
                                    </div>
                                    <div className="double_field">
                                        <Field className="field field_timezone">
                                            <Select
                                                style_type="default"
                                                label="Timezone:"
                                                inputRef={inputRef}
                                                elName={formData?.timezone?.ref}
                                                className={`category ${formData?.timezone?.error ? "error" : ""}`}
                                                data={timeZoneList}
                                                optionSelected={formData?.timezone?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>

                                    </div>
                                    {/*
                                    <div className="single_field">
                                        <div className="box_important">
                                            <div className="text_important">
                                                <span className="red">IMPORTANT:</span>
                                                <p>Notify this person that they have been named as a  beneficiary so that they can create their account immediately</p>
                                            </div>
                                            <Field className="field notify_beneficiary">
                                                <Switch
                                                    toggled={formData?.notify_beneficiary?.value}
                                                    //setToggled={setNotifyBeneficiaryToggled}
                                                    color="default"
                                                    style_type="default"
                                                    inputRef={inputRef}
                                                    //ref={el => inputRef.current[formData?.notify_beneficiary?.ref] = el}
                                                    elName={formData?.notify_beneficiary?.ref}
                                                    className={`notify_beneficiary ${formData?.notify_beneficiary?.error ? "error" : ""}`}
                                                    onChange={handleFormElementChange}
                                                />
                                            </Field>

                                        </div>
                                        
                                    </div>
                                    */}
                                </Fields>
                            </div>
                        </div>
                        <div className="form_bottom">
                            <div className="center">
                                <Title
                                    className="title"
                                    style_type="default"
                                    override_css={{color: '#313233', fontSize: '21px'}}
                                >
                                    <p>Address</p>
                                </Title>
                                <Fields>
                                    <div className="single_field">
                                        <Field className="field street">
                                            <Input
                                                style_type="default"
                                                type="text"
                                                label="Street:"
                                                inputRef={inputRef}
                                                className={`street ${formData?.street?.error ? "error" : ""}`}
                                                elName={formData?.street?.ref}
                                                value={formData?.street?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                    </div>
                                    <div className="double_field">
                                        <Field className="field country">
                                            <Select
                                                style_type="default"
                                                label="Country:"
                                                inputRef={inputRef}
                                                className={`country ${formData?.country?.error ? "error" : ""}`}
                                                elName={formData?.country?.ref}
                                                data={countries}
                                                optionSelected={formData?.country?.value}
                                                onChange={handleFormElementChange/*handleOnChangeTitle*/}
                                            />
                                        </Field>
                                        <Field className="field state">
                                            
                                            <Select
                                                isLoading={loadingStates}
                                                style_type="default"
                                                label="State:"
                                                //ref={el => inputRef.current[formData?.state?.ref] = el}
                                                inputRef={inputRef}
                                                elName={formData?.state?.ref}
                                                className={`state ${formData?.state?.error ? "error" : ""}`}
                                                data={states}
                                                optionSelected={formData?.state?.value}
                                                disabled={formData?.state?.disabled}
                                                onChange={handleFormElementChange/*handleOnChangeTitle*/}
                                            />

                                        </Field>
                                        <Field className="field city">
                                            <Input
                                                style_type="default"
                                                type="text"
                                                label="City:"
                                                inputRef={inputRef}
                                                className={`city ${formData?.city?.error ? "error" : ""}`}
                                                elName={formData?.city?.ref}
                                                value={formData?.city?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                        <Field className="field zip">
                                            <Input
                                                style_type="default"
                                                type="zip"
                                                label="ZIP:"
                                                inputRef={inputRef}
                                                className={`zip ${formData?.zip?.error ? "error" : ""}`}
                                                elName={formData?.zip?.ref}
                                                value={formData?.zip?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                    </div>
                                </Fields>
                            </div>
                        </div>


                        <div className="action_save">
                            <ButtonSolid 
                                type="submit" 
                                style_type="default" 
                                className="bt_save" 
                                override_css={{color:{normal: "#FFFFFF", 
                                hover: COLORS.darkgrey}}}
                                onClick={(e) => { !isTheVaultOpen ? handleSubmit(e) : setOverlayVaultOpen(true) }}
                            >
                                <p>Save</p>
                            </ButtonSolid>
                        </div>


                    </div>





            </BoxContainer>
            </Container>
        </Wrapper>
    )
}


export default ProfileContainer
