import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { breakpoints } from '../../../helpers/breakpoints';
import { COLORS } from '../../../helpers/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BiEditAlt } from "react-icons/bi";
import { useHistory } from 'react-router';
import { notificate_frecuency } from '../../../helpers/constants';
import { BsExclamationCircle } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { ContentContext } from '../../../context';
import CustomToastMessageError from '../../../components/Elements/CustomToastMessageError';
import { toast } from 'react-toastify';
import OverlayWaiting from '../../../components/Elements/OverlayWaiting';
import Input from '../../../components/Elements/Form/Input';
import Select from '../../../components/Elements/Form/Select';
import PhoneInputCountryCode from '../../../components/Elements/Form/PhoneInputCountryCode';
import DatePicker from '../../../components/Elements/Form/DatePicker';

import { AuthContext } from '../../../context';

import ButtonSolid from '../Buttons/Solid';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';




const Wrapper = styled.div`

    white-space: pre-line;
    border-top: 1px solid #e1e1e1;
    padding-top: 20px;
    width: 100%;
    
    .title{
        font-size: 19px!important;
        font-weight: 600;
        color: #2B2C2D;
        margin-bottom: 13px;
    }
    .actions{
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        .bt_cancel{
            margin-left: 10px;
        }
    }
    .text_form_sended{
        text-align: left;
        >.title{
            font-size: 17px!important;
        }
    }
    .bt_back_to_form{
        margin-top: 25px;
    }

    @media ${breakpoints.xs} {
    }
    @media ${breakpoints.sm} {
    }
    @media ${breakpoints.md} {
    }
    @media ${breakpoints.lg} {
    }
    @media ${breakpoints.xl} {
    }
    @media ${breakpoints.xxl} {
    }
    @media ${breakpoints.xxxl} {
    }
    @media ${breakpoints.xxxxl} {

    }

`
const Fields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    :last-child {
        margin-bottom: 0;
    }
    
    .double_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .triple_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .single_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
        .box_important{
            display: flex;
            width: 100%;
            flex-direction: row;
            font-size: 13px;
            //
            .text_important{
                width: 100%;
                .red{
                    color: ${COLORS.red}
                }
                p{
                    margin: 0;
                }
            }
            .notify_beneficiary{
                width: 86px;
                align-items: center;
            }
        }
    }

    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {
        .double_field{
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .field{
                margin-bottom: 10px;
            }
        }
        .triple_field{
            width: 100%;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            .field{
                width: calc(33.3% - 15px)!important;
                margin-bottom: 10px;
            }
        }
        .single_field{
            width: 100%;
            flex-direction: column;
            .field{
                width: 100%;
                margin-bottom: 10px;
            }
            .box_important{
                width: 100%;
                flex-direction: row;
                font-size: 13px;
                //
                .text_important{
                    width: 100%;
                }
                .notify_beneficiary{
                    width: 86px!important;
                    align-items: center;
                }
            }
        }
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .aclaration{
        font-size: 12px;
        margin-top: 3px;
        color: ${COLORS.bluegrey};
    }
    .phone_international{
        //width: 120px;
        //margin-right: 15px;
    }
    &.age_gender{
        .age{
            width: 100px!important;
            margin-right: 15px;
        }
    }
    &.field_title{
        margin-top: 15px;
        margin-bottom: 0!important;
    }
    &.notify_beneficiary{
        margin-bottom: 0!important;
    }
    
    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {
        width: calc(50% - 10px)!important;
        &.notify_beneficiary{
            margin-bottom: 0!important;
        }
        &.street{
            width: 100%!important;
        }
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`

const FormGetQuote = (props) => {

    const { t } = useTranslation();

    let inputRef = useRef({});
    const history = useHistory();
    const { showGetQuoteNow,
            setShowGetQuoteNow } = props;

    const { addFormGetQuote,
            statesByCountry } = useContext(ContentContext);
    
    const [ isWaiting, setIsWaiting ] = useState(null);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ formSended, setFormSended ] = useState(false);
    
    const gender = [{id: 1, value: 1, description: 'Male'}, {id: 2, value: 2, description: 'Female'}];
    const stateCalifornia = [{id: "3924", value: "3924", description: 'California'}]

    useEffect(() => {

        //const responseStatesByCountry = statesByCountry("231");
        //console.log("responseStatesByCountry", responseStatesByCountry);

        setFormData({
            first_name: { value: "", required: true, disabled: false, ref: "first_name", error: false },
            last_name: { value: "", required: true, disabled: false, ref: "last_name", error: false },
            date_birth: { value: "", required: true, disabled: false, ref: "date_birth", error: false },
            gender: { value: "", required: true, disabled: false, ref: "gender", error: false },
            street_address: { value: "", required: true, disabled: false, ref: "street_address", error: false },
            country: { value: "231", required: false, disabled: false, ref: "country", error: false },
            city: { value: "", required: true, disabled: false, ref: "city", error: false },
            state: { value: stateCalifornia[0].id, required: true, disabled: false, ref: "state", error: false },
            zip: { value: "", required: true, disabled: false, ref: "zip", error: false },
            email: { value: "", required: true, disabled: false, ref: "email", error: false },
            phone_number: { value: "", required: true, disabled: false, ref: "phone_number", error: false },
        });

        return () => {
            toast.dismiss();
        }
        
    }, [])
    
    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        
        switch (name) {
            case 'date_birth':
                const years = moment().diff(value, 'years',false);
                break;
        }

        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        let haveError = false;
        if(Object.keys(formData).length){
            let updateFormData = {...formData};
            Object.keys(formData).map(function(key) {
                if(formData[key].required){
                    if(!formData[key].value.length){
                        haveError = true;
                        updateFormData[key].error = true;
                    }else{
                        updateFormData[key].error = false;
                    }
                }
            })
            if(!haveError){
                handleSendFormGetQuote(updateFormData);
            }
            setFormData(updateFormData);
        }
    }

    const handleSendFormGetQuote = () => {
        
        console.log("formData", formData);
        
        setLoading(true);
        setIsWaiting(true);

        const response = addFormGetQuote(formData);
        response.then(res => {

            let message = '';
            let responseType = '';
            let errors = [];

            if(res.success === true){
                
                message = t(`alerts.request_successfully`);
                responseType = 'success';
                resetForm();
                setFormSended(true);

            }else{

                responseType = 'error';
                errors.push(t('alerts.standar_error'));
                setFormSended(false);

            }

            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            
            setLoading(false);
            setIsWaiting(false);
        })

    }

    const resetForm = () => {
        
        Object.keys(inputRef.current).map(function(key) {
            if(key != 'state' && key != 'country'){
                inputRef.current[key].value = '';
            }
        })
        Object.keys(formData).map(function(key) {
            if(key != 'state' && key != 'country'){
                formData[key].value = '';
            }
            /*
            if(key == 'state'){
                formData['state'].disabled = true;
            }
            */
        })

    }

    return (
        <Wrapper>
            {loading &&
            <OverlayWaiting />
            }

            {!formSended ? 
            <div className="form">
                <Fields>
                    <div className="title">{ t(`plans.quote_personal_information`) }</div>
                    <div className="double_field">
                        <Field className="field email">
                            <Input
                                className={`email ${formData?.first_name?.error ? "error" : ""}`}
                                style_type="default"
                                label="First Name"
                                type="text"
                                inputRef={inputRef}
                                elName={formData?.first_name?.ref}
                                value={formData?.first_name?.value}
                                onChange={handleFormElementChange}
                            />
                        </Field>
                        <Field className="field email">
                            <Input
                                className={`email ${formData?.last_name?.error ? "error" : ""}`}
                                style_type="default"
                                label="Last Name"
                                type="text"
                                inputRef={inputRef}
                                elName={formData?.last_name?.ref}
                                value={formData?.last_name?.value}
                                onChange={handleFormElementChange}
                            />
                        </Field>
                    </div>

                    <div className="double_field">
                        <Field className="field age_gender">
                            <Select
                                style_type="default"
                                label={`${t("labels.gender")}:`}
                                //ref={el => inputRef.current[formData?.gender?.ref] = el}
                                inputRef={inputRef}
                                elName={formData?.gender?.ref}
                                className={`gender ${formData?.gender?.error ? "error" : ""}`}
                                data={gender}
                                optionSelected={formData?.gender?.value}
                                onChange={handleFormElementChange/*handleOnChangeGender*/}
                            />
                        </Field>
                        <Field className="field date_of_birth">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    style_type="default"
                                    label={`${t("labels.date_of_birth")}:`}
                                    type="text"
                                    disableFuture={true}
                                    className={`date_birth ${formData?.date_birth?.error ? "error" : ""}`}
                                    inputRef={inputRef}
                                    elName={formData?.date_birth?.ref}
                                    selectedDate={formData?.date_birth?.value}
                                    onChange={handleFormElementChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Field>
                    </div>
                </Fields>

                <Fields>
                    {/*<div className="title">{ t(`plans.quote_residence`) }</div>*/}
                    <div className="double_field">
                        <Field className="field street_address">
                            <Input
                                className={`street_address ${formData?.street_address?.error ? "error" : ""}`}
                                style_type="default"
                                label={`${t("labels.address")}:`}
                                type="text"
                                inputRef={inputRef}
                                elName={formData?.street_address?.ref}
                                value={formData?.street_address?.value}
                                onChange={handleFormElementChange}
                            />
                        </Field>
                        <Field className="field city">
                            <Input
                                className={`city ${formData?.city?.error ? "error" : ""}`}
                                style_type="default"
                                label="City"
                                type="text"
                                inputRef={inputRef}
                                elName={formData?.city?.ref}
                                value={formData?.city?.value}
                                onChange={handleFormElementChange}
                            />
                        </Field>
                        <Field className="field state">
                            <Select
                                isLoading={false}
                                style_type="default"
                                label={`${t("labels.state")}:`}
                                inputRef={inputRef}
                                elName={formData?.state?.ref}
                                className={`state ${formData?.state?.error ? "error" : ""}`}
                                data={stateCalifornia}
                                optionSelected={stateCalifornia[0].id}
                                disabled={true}
                                onChange={handleFormElementChange/*handleOnChangeTitle*/}
                            />
                            <div className="aclaration">{t(`plans.quote_compliance_purposes`)}</div>
                        </Field>
                        <Field className="field zip">
                            <Input
                                className={`zip ${formData?.zip?.error ? "error" : ""}`}
                                style_type="default"
                                label="ZIP code"
                                type="text"
                                inputRef={inputRef}
                                elName={formData?.zip?.ref}
                                value={formData?.zip?.value}
                                onChange={handleFormElementChange}
                            />
                        </Field>
                    </div>
                </Fields>

                <Fields>
                    <div className="title">{ t(`plans.quote_contact_information`) }</div>
                    <div className="double_field">
                        <Field className="field email">
                            <Input
                                className={`email ${formData?.email?.error ? "error" : ""}`}
                                style_type="default"
                                label={`${t("labels.email")}:`}
                                type="email"
                                inputRef={inputRef}
                                elName={formData?.email?.ref}
                                value={formData?.email?.value}
                                onChange={handleFormElementChange}
                            />
                        </Field>
                        <Field className="field phone">
                            <PhoneInputCountryCode
                                style_type="default"
                                label={`${t("labels.mobile")}:`}
                                type="text"
                                className={`phone_number ${formData?.phone_number?.error ? "error" : ""}`}
                                inputRef={inputRef}
                                countryPhone={null}
                                value={formData?.phone_number?.value}
                                elName={formData?.phone_number?.ref}
                                onChange={handleFormElementChange}
                            />
                            <div className="aclaration">{ t(`notes.mobile`) }</div>
                        </Field>
                    </div>
                </Fields>

                <div className="bottom_text">
                    { t(`plans.quote_agreement`) }
                </div>

                <div className="actions">
                    <ButtonSolid
                        //disabled={isWaiting != null && isWaiting == false ? true : false}
                        type="submit"
                        style_type="default"
                        className="bt_continue"
                        override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}
                        onClick={(e) => handleSubmit(e)}
                    >
                        <p>{ t(`buttons.continue`) }</p>
                    </ButtonSolid>
                    <ButtonSolid
                        disabled={false}
                        type="submit"
                        style_type="default"
                        className="bt_cancel"
                        override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}
                        onClick={() => setShowGetQuoteNow(false)}
                    >
                        <p>{ t(`buttons.cancel`) }</p>
                    </ButtonSolid>
                </div>
            </div>
            :
            <div className="text_form_sended">
                <div className="title">{ t(`plans.quote_thankyou`) }</div>
                <div className='text'>{ t(`plans.quote_firm`) }</div>

                <ButtonSolid
                    type="submit"
                    style_type="default"
                    className="bt_back_to_form"
                    override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}
                    onClick={() => setFormSended(false)}
                >
                    <p>{ t(`buttons.back_to_form`) }</p>
                </ButtonSolid>

            </div>
            }

        </Wrapper>
    )
}

export default FormGetQuote
