import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import CustomToastMessageError from '../../components/Elements/CustomToastMessageError';
import { breakpoints } from '../../helpers/breakpoints';

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import { ContentContext, AuthContext } from '../../context'

import { COLORS } from '../../helpers/constants';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import Input from '../../components/Elements/Form/Input';
import Select from '../../components/Elements/Form/Select';
import Switch from '../../components/Elements/Form/Switch';
import { validateEmail } from '../../helpers/functions';
import { useTranslation } from 'react-i18next';
import OverlayWaiting from '../../components/Elements/OverlayWaiting';
import OverlayErrorMessage from '../../components/Elements/Form/OverlayErrorMessage';


const WrapperStyled = styled.div`
    position: relative;
    label{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        color: #2B2C2D;
        margin-bottom: 4px;
    }
    .field_card{
        background-color: #F5F5F5;
        padding: 15px 13px 15px 13px;
        border-radius: 5px;
        font-family: 'Poppins';
        border: 1px solid #F5F5F5;
        &.error{
            border: 1px solid ${COLORS.red};
        }
    }
    .plans{
        .plan{
            &.selected{
                border: 1px solid black;
            }
        }
    }
    .email{
        //margin-bottom: 10px;
    }
    .phone{
        //margin-bottom: 10px;
    }
    .name{
        //margin-bottom: 10px;
    }
    .form_single{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        :last-child{
            margin-bottom: 0;
        }
    }
    .form_double{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
        :last-child{
            margin-bottom: 0;
        }
    }
    .form_card_element{
        //margin-bottom: 20px;
    }
    .form_payment_method{
        margin-top: 10px;
    }
    .form_actions{
        margin-top: 20px;
    }

    @media ${breakpoints.xs} {
    }
    @media ${breakpoints.sm} {
        .form_double{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
            .field{
                width: calc(50% - 10px);
                margin-bottom: 0;
            }
            :last-child{
                margin-bottom: 0;
            }
        }
    }
    @media ${breakpoints.md} {
    }
    @media ${breakpoints.lg} {
    }
    @media ${breakpoints.xl} {
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`

const CheckoutForm = (props) => {

    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();
    
    const { callback,
            target,
            handleClose,
            planSelected,
            subscribedCongrats,
            setSubscribedCongrats,
            setWaitSubscriptionProcess } = props;

    const { userData,
            setUserData } = useContext(AuthContext);

    const { subscribe,
            create_setup_intent,
            create_customer,
            countries,
            states,
            statesByCountry,
            addPaymentMethod } = useContext(ContentContext);

    const [ formData, setFormData ] = useState({});
    const [ error, setError ] = useState(null);
    const [ cardComplete, setCardComplete ] = useState(false);
    //const [ planSelected, setPlanSelected ] = useState("");
    const [ processing, setProcessing ] = useState(false);
    const [ clientSecret, setClientSecret ] = useState(null);
    const [ loadingStates, setLoadingStates ] = useState(null);
    const [ loading, setLoading ] = useState(null);
    const [ overlayWaiting, setOverlayWaiting ] = useState(true);
    const [ isSubscribed, setIsSubscribed ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState({});

    let inputRef = useRef({});

    useEffect(() => {

        if(userData){
            //console.log("userData", userData)
            setFormData({
                email: { value: userData?.email ? userData.email : "", required: true, disabled: false, ref: "email", error: false },
                phone: { value: userData?.account?.phone_number ? userData.account?.phone_number : "", required: true, disabled: false, ref: "phone", error: false },
                name: { value: "", required: true, disabled: false, ref: "name", error: false },
                country: { value: userData?.account?.country_id ? userData.account.country_id.toString() : "", required: true, disabled: false, ref: "country", error: false },
                line1: { value: userData?.account?.street ? userData.account.street : "", required: true, disabled: false, ref: "line1", error: false },
                line2: { value: "", required: false, disabled: false, ref: "line2", error: false },
                city: { value: userData?.account?.city ? userData.account?.city : "", required: true, disabled: false, ref: "city", error: false },
                zip: { value: userData?.account?.zip ? userData.account.zip : "", required: true, disabled: false, ref: "zip", error: false },
                state: { value: userData?.account?.state_id ? userData.account.state_id.toString() : "", required: true, disabled: true, ref: "state", error: false },
                has_default: { value: false, required: false, disabled: true, ref: "has_default", error: false },
            });
        }

        return () => {
            //toast.dismiss();
        }

    }, [userData])

    const handleCloseError = () => {
        setError(null);
        setErrorMessage({});
    }
    const handleSubmit = async (event) => {

        setOverlayWaiting(true);
        
        event.preventDefault();     

        let haveError = false;
        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {

                if(formData[key].required){
                    
                    if(!formData[key].value.length){
                        haveError = true;
                        updateFormData[key].error = true;
                        
                    }else{
                        updateFormData[key].error = false;
                    }

                    if(key == 'email'){
                        const validEmail = validateEmail(formData[key].value);
                        if(!validEmail){
                            haveError = true;
                            updateFormData[key].error = true;
                        }else{
                            updateFormData[key].error = false;
                        }
                    }

                }

            });

            if(!haveError){
                
                if (!stripe || !elements) {
                    // Stripe.js has not loaded yet. Make sure to disable
                    // form submission until Stripe.js has loaded.
                    return;
                }
        
                const card = elements.getElement(CardElement);
                
                
        
                if (card == null) {
                    setError({code: "stripe_connection_error", 
                              message: t('alerts.standar_error'), 
                              type: "stripe_connection_error"});
                    
                    setOverlayWaiting(false);
                    return;
                }
        
                if (error) {
                    setError(error);
                    card.focus();
                    setOverlayWaiting(false);
                    return;
                }
        
                if (cardComplete) {
                    setProcessing(true);
                }
                
                let client_secret = '';

                switch (target) {
                    case "subscribe":

                        setWaitSubscriptionProcess(true);
                        /*
                        const create_customer_payload = { email: formData.email.value,
                                                  phone: formData.phone.value,
                                                  name: formData.name.value
                                                };//user_id: userData.id
                                                  
                                                  
                        const response_create_customer = await create_customer(create_customer_payload);
                                                */
                        if(!clientSecret){
                            const intent_result = await create_setup_intent();
                            client_secret = intent_result.data.intent.client_secret;
                            setClientSecret(client_secret);
                        }else{
                            client_secret = clientSecret;
                        }
                
                        
                        
                        if(client_secret){
                            const { setupIntent, error } = await stripe.confirmCardSetup(
                                client_secret, {
                                    payment_method: {
                                        card: card,
                                        billing_details: {email: formData.email.value,
                                                        phone: formData.phone.value,
                                                        name: formData.name.value,
                                                        address: {
                                                            city: formData.city.value,
                                                            country: (countries.filter(country => country.id == formData.country.value)[0]).sortname,
                                                            line1: formData.line1.value,
                                                            line2: formData.line2.value,
                                                            postal_code: formData.zip.value,
                                                            state: formData.state.value
                                                        }}
                                    }
                                }
                            );
                        
                            if (error) {
                                setOverlayWaiting(false);
                                setError(error);
                                console.log("payload.error", error.message)
                                setWaitSubscriptionProcess(false);

                                let arrErrorMessage = [];
                                arrErrorMessage.push('- ' + error.message);

                                setErrorMessage({title: t(`basics.response_title_error`),
                                                 text: t(`alerts.response_error`),
                                                 errors: arrErrorMessage,
                                                 actions: [{type: 'close', text: 'Close', goto: null, handleAction: handleCloseError}]});

                                // Display "error.message" to the user...
                            } else {

                                const create_customer_payload = { email: formData.email.value,
                                    phone: formData.phone.value,
                                    name: formData.name.value
                                  };//user_id: userData.id
                                    
                                    
                                const response_create_customer = await create_customer(create_customer_payload);

                                const payload = { payment_method: setupIntent.payment_method, plan_id: planSelected.stripe_price_month_id, plan_name: planSelected.name };
                                
                                const subscribed = await subscribe(payload);

                                resetForm();
                                setOverlayWaiting(false);
                                setIsSubscribed(true);
                                setSubscribedCongrats(true);

                                const userDataUpdate = {...userData};
                                userDataUpdate.subscription.price_id = planSelected.stripe_price_month_id;
                                userDataUpdate.subscription.ends_at = "";
                                userDataUpdate.subscription.status = true;

                                userDataUpdate.subscription.plan.notify = planSelected.name != "Plan_free" ? true : false;
                                userDataUpdate.subscription.plan.assets = planSelected.assets;
                                userDataUpdate.subscription.plan.beneficiaries = planSelected.beneficiaries;

                                setUserData(userDataUpdate);

                                if(callback){
                                    callback();
                                }
                                
                                // The card has been verified successfully...
                            }
                        }
                        setOverlayWaiting(false);


                        break;
                    case "payment_method":
                        
                        if(!clientSecret){
                            const intent_result = await create_setup_intent();
                            client_secret = intent_result.data.intent.client_secret;
                            setClientSecret(client_secret);
                        }else{
                            client_secret = clientSecret;
                        }
                
                        //return null;
                        
                        if(client_secret){
                            const { setupIntent, error } = await stripe.confirmCardSetup(
                                client_secret, {
                                    payment_method: {
                                        card: card,
                                        billing_details: {email: formData.email.value,
                                                        phone: formData.phone.value,
                                                        name: formData.name.value,
                                                        address: {
                                                            city: formData.city.value,
                                                            country: (countries.filter(country => country.id == formData.country.value)[0]).sortname,
                                                            line1: formData.line1.value,
                                                            line2: formData.line2.value,
                                                            postal_code: formData.zip.value,
                                                            state: formData.state.value
                                                        }}
                                    }
                                }
                            );
                        
                            if (error) {
                                setOverlayWaiting(false);
                                setError(error);

                                let arrErrorMessage = [];
                                arrErrorMessage.push('- ' + error.message);

                                setErrorMessage({title: t(`basics.response_title_error`),
                                                 text: t(`alerts.response_error`),
                                                 errors: arrErrorMessage,
                                                 actions: [{type: 'close', text: 'Close', goto: null}]});

                                console.log("payload.error", error.message)
                                // Display "error.message" to the user...
                            } else {
                                //
                                const payload = { payment_method: setupIntent.payment_method, has_default: formData.has_default.value };
                                console.log("payload", payload);

                                const payment_method_added = addPaymentMethod(payload);
                                payment_method_added.then(res => {

                                    let message = '';
                                    let responseType = '';
                                    let errors = [];

                                    if(res.success) {
                                        message = "El nuevo metodo de pago se agrego con éxito.";
                                        responseType = 'success';
                                        resetForm();
                                        if(callback){
                                            callback();
                                            handleClose();
                                        }
                                    }else{
                                        
                                        responseType = 'error';
                                        errors.push(t('alerts.standar_error'));
                                        alert("ERROR SET PAYMENT")
                        
                                    }

                                    toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                                        position: "top-center",
                                        autoClose: 3000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "dark",
                                    });

                                    setOverlayWaiting(false);
                                })
                                


                                


                                

                                
                                
                                
                                
                                
                                // The card has been verified successfully...
                            }
                        }

                        //setOverlayWaiting(false);

                        break;
                
                    default:

                        

                        break;
                }
                
                //console.log("----> confirmCardPayment")
                /*
                intent_result.then(res => {
        
                    const client_secret = res.data.intent.client_secret;
                    console.log("intent secret: ", res.data.intent.client_secret)
                    confirmCardPayment(client_secret);
                    
                })
                */
                
                return null;
                
                
                const payload = await stripe.createPaymentMethod({
                    type: 'card',
                    card,
                    billing_details: {email: formData.email.value,
                                      phone: formData.phone.value,
                                      name: formData.name.value},
                });
                
        
                setProcessing(false);
        
                if (payload.error) {
                    console.log("payload.error", payload.error)
                    setError(payload.error);
                } else {
                    console.log("setPaymentMethod", payload.paymentMethod);
                    //setPaymentMethod(payload.paymentMethod);
                }
            }else{

                setOverlayWaiting(false);

            }

            setFormData(updateFormData);

        }

















        
        

    };

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};

        switch (name) {
            case 'country':
                if(value.length){
                    setLoadingStates(true);
                    if(updateFormData[name].value != value){
                        
                        updateFormData['state'].value = "";
                        const responseStatesByCountry = statesByCountry(value);
                        responseStatesByCountry.then((resp) => setLoadingStates(false));

                    }
                    updateFormData['state'].disabled = false;
                }else{
                    updateFormData['state'].value = "";
                    updateFormData['state'].disabled = true;
                }
                break;
        }


        updateFormData[name].value = value;
        setFormData(updateFormData);

        return null;

    }

    const resetForm = () => {
        
        Object.keys(inputRef.current).map(function(key) {
            inputRef.current[key].value = '';
        })
        Object.keys(formData).map(function(key) {
            formData[key].value = '';
            if(key == 'state'){
                formData['state'].disabled = true;
            }
        })

    }

    useEffect(() => {
      
        console.log("ErrorError", error);
    
      return () => {}
    }, [error])
    
    useEffect(() => {
      console.log("formData", formData);
    
      return () => {}
    }, [formData])
    
    if(!userData){
        return null;
    }

  return (
    <WrapperStyled>

        
        <OverlayErrorMessage className="overlay_error_message" errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
        
        {overlayWaiting &&
            <OverlayWaiting />
        }

        {!isSubscribed ?

        <form onSubmit={handleSubmit}>
            
            <div className="form_single">
                <Select
                    style_type="default"
                    label={t(`labels.country`)}
                    inputRef={inputRef}
                    className={`field country ${formData?.country?.error ? "error" : ""}`}
                    elName={formData?.country?.ref}
                    data={countries}
                    optionSelected={formData?.country?.value}
                    onChange={handleFormElementChange/*handleOnChangeTitle*/}
                />
            </div>
            
            <div className="form_single">
                <Input
                    className={`field line1 ${formData?.line1?.error ? "error" : ""}`}
                    isRequired={"true"}
                    style_type="default"
                    type="text"
                    label={t(`labels.address1`)}
                    inputRef={inputRef}
                    elName={formData?.line1?.ref}
                    value={formData?.line1?.value}
                    onChange={handleFormElementChange}
                />
            </div>

            <div className="form_single">
                <Input
                    className={`field line2 ${formData?.line2?.error ? "error" : ""}`}
                    isRequired={"true"}
                    style_type="default"
                    type="text"
                    label={t(`labels.address2`)}
                    inputRef={inputRef}
                    elName={formData?.line2?.ref}
                    value={formData?.line2?.value}
                    onChange={handleFormElementChange}
                />
            </div>

            <div className="form_double">
                <Input
                    className={`field city ${formData?.city?.error ? "error" : ""}`}
                    isRequired={"true"}
                    style_type="default"
                    type="text"
                    label={t(`labels.city`)}
                    inputRef={inputRef}
                    elName={formData?.city?.ref}
                    value={formData?.city?.value}
                    onChange={handleFormElementChange}
                />
                <Input
                    className={`field zip ${formData?.zip?.error ? "error" : ""}`}
                    isRequired={"true"}
                    style_type="default"
                    type="text"
                    label={t(`labels.zip`)}
                    inputRef={inputRef}
                    elName={formData?.zip?.ref}
                    value={formData?.zip?.value}
                    onChange={handleFormElementChange}
                />
            </div>

            <div className="form_single">
                {/*
                <Input
                    className={`field state ${formData?.state?.error ? "error" : ""}`}
                    isRequired={"true"}
                    style_type="default"
                    type="text"
                    label="State"
                    inputRef={inputRef}
                    elName={formData?.state?.ref}
                    value={formData?.state?.value}
                    onChange={handleFormElementChange}
                />
                */}
                <Select
                    isLoading={loadingStates}
                    style_type="default"
                    label={t(`labels.state`)}
                    //ref={el => inputRef.current[formData?.state?.ref] = el}
                    inputRef={inputRef}
                    elName={formData?.state?.ref}
                    className={`state ${formData?.state?.error ? "error" : ""}`}
                    data={states}
                    optionSelected={formData?.state?.value}
                    disabled={formData?.state?.disabled}
                    onChange={handleFormElementChange/*handleOnChangeTitle*/}
                />
            </div>

            <div className="form_double">
                <Input
                    className={`field email ${formData?.email?.error ? "error" : ""}`}
                    isRequired={"true"}
                    style_type="default"
                    type="text"
                    label={t(`labels.email`)}
                    inputRef={inputRef}
                    elName={formData?.email?.ref}
                    value={formData?.email?.value}
                    onChange={handleFormElementChange}
                />

                <Input
                    className={`field phone ${formData?.phone?.error ? "error" : ""}`}
                    isRequired={"true"}
                    style_type="default"
                    type="text"
                    label={t(`labels.phone`)}
                    inputRef={inputRef}
                    elName={formData?.phone?.ref}
                    value={formData?.phone?.value}
                    onChange={handleFormElementChange}
                />
            </div>

            <div className="form_single">
                <Input
                    className={`field name ${formData?.name?.error ? "error" : ""}`}
                    isRequired={"true"}
                    style_type="default"
                    type="text"
                    label={t(`labels.name_on_card`)}
                    inputRef={inputRef}
                    elName={formData?.name?.ref}
                    value={formData?.name?.value}
                    onChange={handleFormElementChange}
                />
            </div>
            
            <div className="form_card_element">
                <label>{t(`labels.credit_or_debit_card`)}</label>
                <div className={`field_card${error ? " error" : ""}`}>
                    <CardElement 
                        options={{
                            style: {
                            base: {
                                fontSize: '16px',
                                fontWeight: '500',
                                border: '1px solid #F5F5F5',
                                color: '#7884A5',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                            },
                        }}
                        onChange={(e) => {
                            setError(e.error);
                            setCardComplete(e.complete);
                        }}
                        onReady={(e) => {
                            setOverlayWaiting(false);
                        }}
                    />
                </div>
            </div>

            {target == "payment_method" &&

                <div className="form_single form_payment_method">
                    <Switch
                        toggled={formData && formData.has_default && formData.has_default.value}
                        color="default"
                        style_type="default"
                        inputRef={inputRef}
                        label={t(`labels.default_payment`)}
                        elName={formData.has_default?.ref}
                        className={`method_email ${formData && formData.has_default && formData.has_default.error ? "error" : "" }`}
                        onChange={handleFormElementChange}
                    />
                </div>

            }

            <div className='form_actions'>
                <ButtonSolid
                    type="submit" disabled={!stripe || !elements}
                    style_type="default"
                    className="bt_subscribe"
                    override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}
                    //onClick={() => setPlanSelected("price_1MYbgdIls69beLtufTpA1Smm")}
                >
                    <p>{ target == "payment_method" ? t(`buttons.add_new_card`) : t(`buttons.subscribe`) }</p>
                </ButtonSolid>
            </div>

        </form>
        :
        <></>
        }
    </WrapperStyled>
  );
};

export default CheckoutForm;