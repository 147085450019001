import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';


import Menu from '@material-ui/core/Menu';

const Wrapper = styled.div`

    display: flex;
    flex: 1;
    flex-direction: row;
    height: calc(100% - 100px);
    min-height: calc(100% - 100px);
    /*background-color: skyblue;*/
    
    .inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        margin-left: 60px;
        width: calc(100% - 120px);
        /*background-color: skyblue;*/
    } 
    .logo{
        display: flex;
        align-self: flex-start;
        width: 230px;
        img{
            float: left;
            width: 100%;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        width: 550px;
        align-self: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    //background-color: pink;
    /*
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .box_data{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .inner{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 20px;
      @media ${breakpoints.md} {
        flex-direction: row;
      }
      @media ${breakpoints.lg} {
        
      }
    }
  }
  .box_header_actions{
    max-height: 60px;
    box-shadow: 0!important;
    .inner{
      padding: 10px;
    }
  }
  .box_assets{
    margin-bottom: 20px;
  }
  .box_recent{
  }

  @media ${breakpoints.xs} {
      //min-width: 576px
  }
  @media ${breakpoints.sm} {
      //min-width: 768px
      
  }
  @media ${breakpoints.md} {
      //min-width: 992px
      width: 662px;
  }
  @media ${breakpoints.lg} {
      //min-width: 1200px
      width: 870px;
  }
  @media ${breakpoints.xl} {
      //min-width: 1366px
      width: 1036px;
  }
  @media ${breakpoints.xxl} {
      //min-width: 1440px
      width: 1110px;
  }
  @media ${breakpoints.xxxl} {
      //min-width: 1680px
      width: 1350px;
      //background-color: pink;
  }
  @media ${breakpoints.xxxxl} {
      //min-width: 1920px
      width: 1590px;
      //background-color: skyblue;
  }
`
const MenuStyled = styled(Menu)`
`

const HomeContainer = (props) => {

    
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      alert("asd")
      setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
      };

    return (
        <Wrapper>

            upgrade section

        </Wrapper>
    )
}

export default HomeContainer
