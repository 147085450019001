import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { breakpoints } from '../../../helpers/breakpoints';

import BoxContainer from '../../../components/Elements/BoxContainer';
import ButtonSolid from '../../../components/Elements/Buttons/Solid';
import ButtonIcon from '../../../components/Elements/Buttons/Icon';
import Input from '../../../components/Elements/Form/Input';
import Select from '../../../components/Elements/Form/Select';
import PhoneInputCountryCode from '../../../components/Elements/Form/PhoneInputCountryCode';
import { isValidPhoneNumber } from 'react-phone-number-input';
import DatePicker from '../../../components/Elements/Form/DatePicker';
import Title from '../../../components/Elements/Title';
import CustomToastMessageError from '../../../components/Elements/CustomToastMessageError';
import OverlayWaiting from '../../../components/Elements/OverlayWaiting';
import { MdCloudUpload } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";
import { FaCamera } from "react-icons/fa";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { partiesFirstBeneficiary } from '../../../helpers/functions';

import { COLORS,
         SIZES } from '../../../helpers/constants';
         
import { ContentContext, AuthContext } from '../../../context';
import { validateEmail } from '../../../helpers/functions';

import { Modal, Fade, Backdrop, Box } from '@material-ui/core';
        
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { toast } from 'react-toastify';
import { t } from 'i18next';

//import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
//import PhoneInput from 'react-phone-number-input'
//import en from 'react-phone-number-input/locale/en.json';
//import Switch from '@material-ui/core/Switch';

const Wrapper = styled.div`

    display: flex;
    width: 100%;
    padding-top: 17px;
    padding-bottom: 17px;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    @media ${breakpoints.xs} {
        //width: ${SIZES.xs};
    }
    @media ${breakpoints.sm} {
        //width: ${SIZES.sm};
    }
    @media ${breakpoints.md} {
        //width: ${SIZES.md};
    }
    @media ${breakpoints.lg} {
        //width: ${SIZES.lg};
    }
    @media ${breakpoints.xl} {
        //width: ${SIZES.xl};
    }
    @media ${breakpoints.xxl} {
        //width: ${SIZES.xxl};
    }
    @media ${breakpoints.xxxl} {
        //width: ${SIZES.xxxl};
    }
    @media ${breakpoints.xxxxl} {
        //width: ${SIZES.xxxxl};
    }

`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    .box_data{
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        .inner{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding: 20px;
        }
    }
    .box_assets{
        margin-bottom: 20px;
    }
    .box_header_actions{
        max-height: 60px;
        box-shadow: 0!important;
        .inner{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            p.title{
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 0 10px;
                padding: 0;
            }
            .bt_save{
                margin-left: auto;
            }
            .action_back{
                display: flex;
                flex-direction: row;
                align-items: center;
                .icon{
                    width: auto;
                    height: auto;
                    font-size: 37px;
                }
            }
            .action_save{
                display: flex;
                flex-direction: row;
                align-items: center;
                .loader{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 150px;
                    min-height: 40px;
                    svg{
                        color: ${COLORS.lightgold};
                    }
                }
            }
        }
    }
    .box_recent{
        margin-top: 20px;
    }
    .box_form{
        width: 100%;
        margin-top: 20px;
        .inner_form{
            position: relative;
            display: flex;
            width: 100%;
            flex-direction: column;
            padding: 20px;
            .form_top{
                display: flex;
                width: 100%;
                flex-direction: column;
                padding-bottom: 20px;
                border-bottom: 1px solid ${COLORS.iceblue};
                justify-content: space-between;
                align-items: center;
                //background-color: pink;
                .left{
                    display: flex;
                    width: 44vw;
                }
                .right{
                    display: flex;
                    width: 100%;
                    margin-top: 20px;
                }
            }
            .action_save{
                margin-top: 10px;
                align-self: flex-end;
            }
            .form_bottom{
                display: flex;
                width: 100%;
                flex-direction: row;
                //background-color: skyblue;
                .center{
                    width: 100%;
                }
                .title{
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    @media ${breakpoints.xs} {
        .box_form{
            .inner_form{
                .form_top{
                    .left{
                        width: 220px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.sm} {
    }
    @media ${breakpoints.md} {
        .box_form{
            .inner_form{
                .form_top{
                    flex-direction: row;
                    align-items: flex-start;
                    .right{
                        display: flex;
                        width: calc(100% - 240px);
                        margin-top: 0;
                    }
                }
            }
        }
    }
    @media ${breakpoints.lg} {
        //min-width: 1200px
        //width: 870px;
    }
    @media ${breakpoints.xl} {
        //min-width: 1366px
        //width: 1036px;
    }
    @media ${breakpoints.xxl} {
        //min-width: 1440px
        //width: 1110px;
    }
    @media ${breakpoints.xxxl} {
        //min-width: 1680px
        //width: 1350px;
        //background-color: pink;
    }
    @media ${breakpoints.xxxxl} {
        //min-width: 1920px
        //width: ${SIZES.xxxxl};
        //background-color: skyblue;
    }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-left: 20px;
  .box_vip{
    margin-bottom: 20px;
    &.stretched{
      flex: 1;
    }
  }
  .box_countdown{
    &.stretched{
      flex: 1;
    }
  }
`
const BeneficiaryForm = styled.div`
    width: 100%;
`
const Fields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    
    
    
    .double_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .triple_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .single_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
        .box_important{
            display: flex;
            width: 100%;
            flex-direction: row;
            font-size: 13px;
            //
            .text_important{
                width: 100%;
                .red{
                    color: ${COLORS.red}
                }
                p{
                    margin: 0;
                }
            }
            .notify_beneficiary{
                width: 86px;
                align-items: center;
            }
        }
    }

    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {
        .double_field{
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .field{
                margin-bottom: 10px;
            }
        }
        .triple_field{
            width: 100%;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            .field{
                width: calc(33.3% - 15px)!important;
                margin-bottom: 10px;
            }
        }
        .single_field{
            width: 100%;
            flex-direction: column;
            .field{
                width: 100%;
                margin-bottom: 10px;
            }
            .box_important{
                width: 100%;
                flex-direction: row;
                font-size: 13px;
                //
                .text_important{
                    width: 100%;
                }
                .notify_beneficiary{
                    width: 86px!important;
                    align-items: center;
                }
            }
        }
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .aclaration{
        font-size: 12px;
        margin-top: 3px;
        color: ${COLORS.bluegrey};
    }
    .phone_international{
        //width: 120px;
        //margin-right: 15px;
    }
    &.age_gender{
        .age{
            width: 100px!important;
            margin-right: 15px;
        }
    }
    &.field_title{
        margin-top: 15px;
        margin-bottom: 0!important;
    }
    &.notify_beneficiary{
        margin-bottom: 0!important;
    }
    
    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {

    }
    @media ${breakpoints.md} {
        width: calc(50% - 10px)!important;
        &.notify_beneficiary{
            margin-bottom: 0!important;
        }
        &.street{
            width: 100%!important;
        }
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const WeapperImageProfile = styled.div`
    
    display: flex;
    flex-direction: column;
    width: 44vw;
    justify-content: space-between;
    //align-items: flex-start;

    .box_image{
        position: relative;
        display: flex;
        flex-direction: column;
        height: 44vw;
        
    }
    .field{
        width: 100%!important;
        margin-bottom: 10px;
    }

    @media ${breakpoints.xs} {
        width: 220px;
        .box_image{
            height: 220px;
        }
    }
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`
const ImageProfile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    width: 44vw;
    height: 44vw;

    background-color: ${COLORS.whiteTwo};
    
    img{
        height: 100%;
    }

    @media ${breakpoints.xs} {
        width: 220px;
        height: 220px;
    }
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`
const ButtonAddImageProfile = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: -2vw;

    .icon{
        width: auto!important;
        height: auto!important;
        font-size: 20px;
    }
    
    @media ${breakpoints.xs} {
        bottom: -10px;
    }
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`
const ModalStyled = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0!important;
    border-radius: 0;

    :focus{
        outline: none!important;
    }

    position: absolute;
    top: 0!important;
    overflow: scroll;
    margin: 0 auto;

    //width: calc(100% - 30px);
    width: 100%;
    height: 100%;
    display: block;

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {
        //border-radius: 16px;
        //top: 20px!important;
        width: 700px;
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`
const ContainerModalProfileImage = styled.div`

    
    width: 100%;
    border-radius: 0;
    outline: 0!important;
    
    padding: 10px;
    box-sizing: border-box;

    .actions{
        display: flex;
        flex-direction: row;
        .bt_cancel_image{
            margin-left: 5px;
        }
    }
    .content_action_browse{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px dashed ${COLORS.cloudyblue};
        margin-top: 50px;
        padding-top: 40px;
        padding-bottom: 40px;
        input{
            display: none;
        }
    }
    .modal_product{
        padding: 5px;
        background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 15px -5px #000000; 
    }

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {
        .modal_product{
            padding: 5px;
            border-radius: 16px;
            .inner_modal_image{
                border-radius: 13px;
                overflow: hidden;
            }
        }
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`
const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 25px;
    //background-color: #FFD200;
    //color: #FFD200;
    cursor: pointer;
    right: 20px;
    top: 20px;
    z-index: 9;
    .icon{
        display: flex;
        width: 35px;
        svg{
            fill: ${COLORS.lightgold};
        }
    }
    :hover{
        .icon{
            svg{
                fill: #000000;
            }
        }
    }
`
const ButtonBrowseProfileImage = styled.div`
    cursor: pointer;
    .icon{
        display: flex;
        width: 60px;
        svg{
            fill: ${COLORS.cloudyblue};
        }
    }
    :hover{
        .icon{
            svg{
                fill: ${COLORS.lightgold};
            }
    }
    }
`


function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
        return;
    }

    canvas.toBlob(
        (blob) => {
            const previewUrl = window.URL.createObjectURL(blob);

            const anchor = document.createElement('a');
            anchor.download = 'cropPreview.png';
            anchor.href = URL.createObjectURL(blob);
            anchor.click();

            window.URL.revokeObjectURL(previewUrl);
        },
        'image/png',
        1
    );
}


const AddContainer = (props) => {
    
    const { userData,
            setOverlayBeneficiariesLimitReach } = useContext(AuthContext);

    const { parties,
            titles,
            countries,
            states,
            statesByCountry,
            addParty,
            addBeneficiary,
            uploadFile,
            getImageProfile,
            setParties } = useContext(ContentContext);

    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [ selectedBeneficiary, setSelectedBeneficiary ] = useState(null);
    const [ notifyBeneficiaryToggled, setNotifyBeneficiaryToggled] = React.useState(false);
    const [ titleSelected, setTitleSelected ] = useState(null);
    const [ genderSelected, setGenderSelected ] = useState(null);
    const [ loadingStates, setLoadingStates ] = useState(null);
    const [ croppedImageProfile, setCroppedImageProfile ] = useState(null);
    const [ profileImage, setProfileImage ] = useState(null);
    const [ imageBase64, setImageBase64 ] = useState(null);
    const [ loading, setLoading ] = useState(null);
    const [ beneficiariesLimitReach, setBeneficiariesLimitReach ] = useState(null);

    let inputRef = useRef({});
    const [ formData, setFormData ] = useState({});
    
    const gender = [{id: 1, value: 1, description: 'Male'}, {id: 2, value: 2, description: 'Female'}];
    
    useEffect(() => {

        setFormData({
            title: { value: "", required: true, disabled: false, ref: "title", error: false },
            first_name: { value: "", required: true, disabled: false, ref: "first_name", error: false },
            middle_name: { value: "", required: false, disabled: false, ref: "middle_name", error: false },
            last_name: { value: "", required: true, disabled: false, ref: "last_name", error: false },
            email: { value: "", required: true, disabled: false, ref: "email", error: false },
            phone_number: { value: "", required: true, disabled: false, ref: "phone_number", error: false },
            date_birth: { value: "", required: true, disabled: false, ref: "date_birth", error: false },
            //age: { value: "", required: true, disabled: false, ref: "age", error: false },
            gender: { value: "", required: true, disabled: false, ref: "gender", error: false },
            //phone_international: { value: "", required: true, disabled: false, ref: "phone_international", error: false },
            notify_beneficiary: { value: "", required: false, disabled: false, ref: "notify_beneficiary", error: false },
            country: { value: "", required: true, disabled: false, ref: "country", error: false },
            city: { value: "", required: true, disabled: false, ref: "city", error: false },
            state: { value: "", required: true, disabled: true, ref: "state", error: false },
            street: { value: "", required: true, disabled: false, ref: "street", error: false },
            zip: { value: "", required: true, disabled: false, ref: "zip", error: false },
            image_profile: { value: "", required: false, disabled: false, ref: "image_profile", error: false },
        });

        return () => {
            toast.dismiss();
        }
        
    }, [])

    useEffect(() => {

        if(userData && parties){
          let limit_reach = false;
          if(parties.length >= userData.subscription.plan.beneficiaries){
            limit_reach = true;
          }
          setBeneficiariesLimitReach(limit_reach);
          if(limit_reach){
            setOverlayBeneficiariesLimitReach(true);
          }
        }
        
      
        return () => {}
      }, [userData, parties])
    

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleClose();
    }

    const handleMenuClick = useCallback((event, beneficiary, i) => {
        setSelectedBeneficiary(beneficiary);
        setAnchorEl(event.currentTarget);
    }, []);

    const handleBack = () => {
        history.push('/parties');
    }

    const handleOnChangeGender = (e) => {
        setGenderSelected(e.currentTarget.value)
    }
    const handleOnChangeTitle = (e) => {
        setTitleSelected(e.currentTarget.value)
    }

    const resetForm = () => {
        
        Object.keys(inputRef.current).map(function(key) {
            inputRef.current[key].value = '';
        })
        Object.keys(formData).map(function(key) {
            formData[key].value = '';
            if(key == 'state'){
                formData['state'].disabled = true;
            }
        })

        setTitleSelected(null);
        setGenderSelected(null);
        setLoadingStates(null);
        setCroppedImageProfile(null);
        setProfileImage(null);
        setImageBase64(null);
        setSelectedBeneficiary(null);
        setNotifyBeneficiaryToggled(false);

    }


    const handleSubmit = (e) => {
        e.preventDefault();

        let haveError = false;
        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {

                if(formData[key].required){
                    
                    if(!formData[key].value.length){
                        haveError = true;
                        updateFormData[key].error = true;
                        
                    }else{
                        updateFormData[key].error = false;
                    }

                    if(key === 'email'){
                        const validEmail = validateEmail(formData[key].value);
                        if(!validEmail){
                            haveError = true;
                            updateFormData[key].error = true;
                        }else{
                            updateFormData[key].error = false;
                        }
                    }

                    if(key === 'age'){
                        const onlyNumber = /^\d+$/;
                        const isNumber = onlyNumber.test(formData[key].value);
                        if(!isNumber){
                            haveError = true;
                            updateFormData[key].error = true;
                        }else{
                            updateFormData[key].error = false;
                        }
                    }
                    
                    if(key === 'phone_number'){
                        const isValidPhone = isValidPhoneNumber(formData[key].value)
                        if(!isValidPhone){
                            haveError = true;
                            updateFormData[key].error = true;
                        }else{
                            console.log('isValidPhoneisValidPhoneisValidPhoneisValidPhone: ' + key);
                            updateFormData[key].error = false;
                        }
                    }
                    
                }

                

            });

            //console.log(updateFormData);
            //return null;

            if(!haveError){

                setLoading(true);
                
                if(croppedImageProfile){
                    const payload = { base64: true, image: croppedImageProfile, type: "image_profile" };
                    const fileUploades = uploadFile(payload);
                    fileUploades.then((result) => {

                        const fileName = path(['data', 'file_name'], result) || null;
                        updateFormData['image_profile'].value = fileName;

                        handleAddBeneficiary(updateFormData);

                    })
                }else{

                    handleAddBeneficiary(updateFormData);
                    
                }
            }

            setFormData(updateFormData);

        }
    }

    const handleAddBeneficiary = () => {

        
        
        const response = addParty(formData);
        
        response.then((res) => {

            let message = '';
            let responseType = '';
            let errors = [];

            if(res.success === true){

                // const partiesEdit = [...parties];
                // partiesEdit.map((party, index) => {
                    
                //     if(party.id == res.data.id){
                //         party.first_name = res.data.first_name;
                //         party.middle_name = res.data.middle_name;
                //         party.last_name = res.data.last_name;
                //         party.profile_image = res.data.profile_image;
                //         party.email = res.data.email;
                //         party.title.id = res.data.title.id;
                //         party.title.description = res.data.title.description;
                //         party.updated_at = res.data.updated_at;
                //     }else{
                //         if(formData.title.value == "3"){
                //             party.title.id = "2";
                //             party.title.description = "Beneficiary";
                //         }
                //     }
                //     return party;
                // })
                // const partiesData = partiesFirstBeneficiary(partiesEdit);
                // setParties(partiesData);

                resetForm();
                
                message = t(`alerts.item_added`, { name: "beneficiary" });
                responseType = 'success';
                
            }else{

                responseType = 'error';
                errors.push(t('alerts.standar_error'));

            }

            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setLoading(false);
            console.log(res);
        })

    }

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        console.log(name +":"+value);
        
        switch (name) {
            case 'country':
                if(value.length){
                    setLoadingStates(true);
                    if(updateFormData[name].value != value){
                        
                        updateFormData['state'].value = "";
                        const responseStatesByCountry = statesByCountry(value);
                        responseStatesByCountry.then((resp) => setLoadingStates(false));

                    }
                    updateFormData['state'].disabled = false;
                }else{
                    updateFormData['state'].value = "";
                    updateFormData['state'].disabled = true;
                }
                break;
            case 'date_birth':
                const years = moment().diff(value, 'years',false);
                break;
        }

        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
    }

    const [ openModal, setOpenModal ] = useState(false);
    const [ upImg, setUpImg ] = useState(null);
    const imgRef = useRef(null);
    const inputFileRef = useRef( null );
    const imgCroppedRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [ crop, setCrop ] = useState({
        unit: 'px',
        width: 250,
        minWidth: 250,
        minHeight: 250,
        maxWidth: 500,
        maxHeight: 500,
        aspect: 1 / 1
    });
    const [ completedCrop, setCompletedCrop ] = useState(null);
    
    const [ src, selectFile ] = useState(null);

    const getCroppedImg = () => {

        const canvas = document.createElement("canvas");
        const scaleX = upImg.naturalWidth / upImg.width;
        const scaleY = upImg.naturalHeight / upImg.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
      
        // New lines to be added
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
      
        ctx.drawImage(
          upImg,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
      
        // As Base64 string
        const base64Image = canvas.toDataURL("image/jpeg");
        return base64Image;

      }

    const onSelectFile = (e) => {
        selectFile(URL.createObjectURL(e.target.files[0]));
        setUpImg(e.target.files[0]);
    };
    /*
    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);
    */
    useEffect(() => {

        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

    }, [completedCrop]);


    const handleProfileImage = () => {

        setOpenModal(!openModal);
        if(!openModal){
            selectFile(null);
            setUpImg(null);
        }

    }

    const handleGenerateThumb = () => {

        const croppedImg = getCroppedImg();  
        setCroppedImageProfile(croppedImg);
        setOpenModal(false);
        selectFile(null);
        return null;

    }


    const handleBrowseProfileImage = () => {
        inputFileRef.current.click();
    }
    /*
    const resolveImage = (profileImage) => {
        getImageProfile(profileImage).then((res) => {
            setImageBase64(URL.createObjectURL(res.data));
        })
    }
    */
    
    return (
        <Wrapper>

            <Container>
                <form onSubmit={handleSubmit}>
                <BoxContainer className="box_header_actions">
                    <div className="inner">
                        <div className="action_back">
                            <ButtonIcon style_type="default" onClick={handleBack}>
                                <div className="icon">
                                    <IoArrowBackCircleOutline />
                                </div>
                            </ButtonIcon>
                            <p className="title">{t('section_title.new', { section: 'Beneficiary' })}</p>
                        </div>
                    </div>
                </BoxContainer>

                <BoxContainer className="box_form">
                    <ModalStyled
                        disableAutoFocus={true}
                        open={openModal}
                        onClose={() => setOpenModal(!openModal)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openModal}>

                            <ContainerModalProfileImage>

                                <ButtonClose
                                    onClick={() => setOpenModal(!openModal)}
                                >
                                    <div className="icon">
                                       <RiCloseCircleLine size={45} />
                                    </div>
                                </ButtonClose>

                                <div className="modal_product">

                                    {src ?
                                        <div className="inner_modal_image">
                                            <ReactCrop
                                                src={src}
                                                onImageLoaded={setUpImg}
                                                //onImageLoaded={onLoad}
                                                crop={crop}
                                                onChange={(c) => setCrop(c)}
                                                onComplete={(c) => setCompletedCrop(c)}

                                            />

                                            <div className="actions">
                                                <ButtonSolid
                                                    type="submit"
                                                    style_type="default"
                                                    className="bt_save"
                                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                                    onClick={() =>
                                                        handleGenerateThumb()
                                                    }
                                                >
                                                    <p>{t(`buttons.save_image`)}</p>
                                                </ButtonSolid>

                                                <ButtonSolid
                                                    type="submit"
                                                    style_type="default"
                                                    className="bt_cancel_image"
                                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                                    onClick={() =>
                                                        handleProfileImage()
                                                    }
                                                >
                                                    <p>{t(`buttons.cancel`)}</p>
                                                </ButtonSolid>
                                            </div>
                                        </div>

                                    :

                                        <div className="content_action_browse">
                                            <input ref={inputFileRef} type="file" accept="image/*" onChange={onSelectFile} />
                                            <ButtonBrowseProfileImage onClick={handleBrowseProfileImage}>
                                                <div className="icon">
                                                    <MdCloudUpload size={50} />
                                                </div>
                                            </ButtonBrowseProfileImage>
                                        </div>

                                    }

                                </div>
                            </ContainerModalProfileImage>
                            
                        </Fade>

                    </ModalStyled>



                









                    <div className="inner_form">
                        {loading &&
                        <OverlayWaiting />
                        }
                        <div className="form_top">
                            <div className="left">
                                <WeapperImageProfile className="weapperImageProfile">
                                    
                                    <div className="box_image">
                                        <ImageProfile>
                                            {croppedImageProfile ? 
                                            <img src={croppedImageProfile} />
                                            :
                                            <></>    
                                            }
                                            {/*<img src={profileImage ? imageBase64 : "https://www.fillmurray.com/640/360"} />
                                            <img src={croppedImageProfile ? croppedImageProfile : "https://www.fillmurray.com/640/360"} />*/}
                                        </ImageProfile>
                                        <ButtonAddImageProfile>
                                            <ButtonIcon style_type="solid" onClick={handleProfileImage}>
                                                <div className="icon">
                                                    <FaCamera />
                                                </div>
                                            </ButtonIcon>
                                        </ButtonAddImageProfile>
                                    </div>

                                    <Field className="field field_title">
                                        <Select
                                            className={`title ${formData?.title?.error ? "error" : ""}`}
                                            style_type="default"
                                            label={`${t("labels.title")}:`}
                                            inputRef={inputRef}
                                            //ref={el => inputRef.current["title"] = el}
                                            elName={formData?.title?.ref}
                                            data={titles}
                                            optionSelected={formData?.title?.value}
                                            onChange={handleFormElementChange/*handleOnChangeTitle*/}
                                        />
                                    </Field>

                                </WeapperImageProfile>
                            </div>
                            <div className="right">
                                <Fields>
                                    <div className="triple_field">
                                        <Field className="field first_name">
                                            <Input
                                                className={`first_name ${formData?.first_name?.error ? "error" : ""}`}
                                                isRequired={"true"}
                                                style_type="default"
                                                type="text"
                                                label={`${t("labels.first_name")}:`}
                                                inputRef={inputRef}
                                                //ref={el => inputRef.current[formData?.first_name?.ref] = el}
                                                elName={formData?.first_name?.ref}
                                                value={formData?.first_name?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                        <Field className="field middle_name">
                                            <Input
                                                className={`middle_name ${formData?.middle_name?.error ? "error" : ""}`}
                                                style_type="default"
                                                label={`${t("labels.middle_name")}:`}
                                                inputRef={inputRef}
                                                type="text"
                                                //ref={el => inputRef.current[formData?.last_name?.ref] = el}
                                                elName={formData?.middle_name?.ref}
                                                value={formData?.middle_name?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                        <Field className="field last_name">
                                            <Input
                                                className={`last_name ${formData?.last_name?.error ? "error" : ""}`}
                                                style_type="default"
                                                label={`${t("labels.last_name")}:`}
                                                inputRef={inputRef}
                                                type="text"
                                                //ref={el => inputRef.current[formData?.last_name?.ref] = el}
                                                elName={formData?.last_name?.ref}
                                                value={formData?.last_name?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                    </div>
                                    <div className="double_field">
                                        <Field className="field email">
                                            <Input
                                                className={`email ${formData?.email?.error ? "error" : ""}`}
                                                style_type="default"
                                                label={`${t("labels.email")}:`}
                                                type="email"
                                                inputRef={inputRef}
                                                elName={formData?.email?.ref}
                                                value={formData?.email?.value}
                                                onChange={handleFormElementChange}
                                            />
                                            <div className="aclaration">Please enter only emails with "@" and "." (dot)</div>
                                        </Field>
                                        <Field className="field phone">
                                            <PhoneInputCountryCode
                                                style_type="default"
                                                label={`${t("labels.mobile")}:`}
                                                type="text"
                                                className={`phone_number ${formData?.phone_number?.error ? "error" : ""}`}
                                                inputRef={inputRef}
                                                countryPhone={null}
                                                value={formData?.phone_number?.value}
                                                elName={formData?.phone_number?.ref}
                                                onChange={handleFormElementChange}
                                            />
                                            {/* <div className="aclaration">Please enter only mobile numbers with the "+" sign and country code with this format: +1 (888) 888-8888</div> */}
                                            {/*
                                            <PhoneInputCountryCode
                                                style_type="default"
                                                label="Phone:"
                                                type="text"
                                                className={`phone_country_code ${formData?.phone_international?.error ? "error" : ""}`}
                                                inputRef={inputRef}
                                                countryPhone={null}
                                                elName={formData?.phone_international?.ref}
                                                onChange={handleFormElementChange}
                                            />
                                            */}
                                        </Field>
                                        {/*
                                        <Field className="field phone">
                                            <Input
                                                className={`phone_number ${formData?.phone_number?.error ? "error" : ""}`}
                                                style_type="default"
                                                label="Mobile:"
                                                type="text"
                                                inputRef={inputRef}
                                                elName={formData?.phone_number?.ref}
                                                value={formData?.phone_number?.value}
                                                onChange={handleFormElementChange}
                                            />
                                            <div className="aclaration">Please enter only mobile numbers with the "+" sign and country code with this format: +1 (888) 888-8888</div>
                                        </Field>
                                        */}
                                    </div>
                                    <div className="double_field">
                                        
                                        <Field className="field date_of_birth">
                                            <DatePicker
                                                style_type="default"
                                                label={`${t("labels.date_of_birth")}:`}
                                                type="text"
                                                disableFuture={true}
                                                className={`date_birth ${formData?.date_birth?.error ? "error" : ""}`}
                                                inputRef={inputRef}
                                                elName={formData?.date_birth?.ref}
                                                selectedDate={formData?.date_birth?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                        <Field className="field age_gender">
                                            {/*
                                            <Input
                                                className={`age ${formData?.age?.error ? "error" : ""}`}
                                                style_type="default"
                                                type="text"
                                                label="Age:"
                                                inputRef={inputRef}
                                                elName={formData?.age?.ref}
                                                value={formData?.age?.value}
                                                onChange={handleFormElementChange}
                                            />
                                            */}
                                            <Select
                                                style_type="default"
                                                label={`${t("labels.gender")}:`}
                                                //ref={el => inputRef.current[formData?.gender?.ref] = el}
                                                inputRef={inputRef}
                                                elName={formData?.gender?.ref}
                                                className={`gender ${formData?.gender?.error ? "error" : ""}`}
                                                data={gender}
                                                optionSelected={genderSelected}
                                                onChange={handleFormElementChange/*handleOnChangeGender*/}
                                            />
                                        </Field>
                                    </div>
                                    {/*
                                    <div className="single_field">
                                        <div className="box_important">
                                            <div className="text_important">
                                                <span className="red">IMPORTANT:</span>
                                                <p>Notify this person that they have been named as a  beneficiary so that they can create their account immediately</p>
                                            </div>
                                            <Field className="field notify_beneficiary">
                                                <Switch
                                                    label=""
                                                    toggled={formData?.notify_beneficiary?.value}
                                                    //setToggled={setNotifyBeneficiaryToggled}
                                                    color="default"
                                                    style_type="default"
                                                    inputRef={inputRef}
                                                    //ref={el => inputRef.current[formData?.notify_beneficiary?.ref] = el}
                                                    elName={formData?.notify_beneficiary?.ref}
                                                    className={`notify_beneficiary ${formData?.notify_beneficiary?.error ? "error" : ""}`}
                                                    onChange={handleFormElementChange}
                                                />
                                            </Field>

                                        </div>
                                        
                                    </div>
                                    */}
                                </Fields>
                            </div>
                        </div>
                        <div className="form_bottom">
                            <div className="center">
                                <Title
                                    className="title"
                                    style_type="default"
                                    override_css={{color: '#313233', fontSize: '21px'}}
                                >
                                    <p>{t('labels.address')}</p>
                                </Title>
                                <Fields>
                                    <div className="single_field">
                                        <Field className="field street">
                                            <Input
                                                style_type="default"
                                                type="text"
                                                label={`${t("labels.street")}:`}
                                                inputRef={inputRef}
                                                className={`street ${formData?.street?.error ? "error" : ""}`}
                                                elName={formData?.street?.ref}
                                                value={formData?.street?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                    </div>
                                    <div className="double_field">
                                        <Field className="field country">
                                            <Select
                                                style_type="default"
                                                label={`${t("labels.country")}:`}
                                                inputRef={inputRef}
                                                className={`country ${formData?.country?.error ? "error" : ""}`}
                                                elName={formData?.country?.ref}
                                                data={countries}
                                                optionSelected={formData?.country?.value}
                                                onChange={handleFormElementChange/*handleOnChangeTitle*/}
                                            />
                                        </Field>
                                        <Field className="field state">
                                            
                                            <Select
                                                isLoading={loadingStates}
                                                style_type="default"
                                                label={`${t("labels.state")}:`}
                                                //ref={el => inputRef.current[formData?.state?.ref] = el}
                                                inputRef={inputRef}
                                                elName={formData?.state?.ref}
                                                className={`state ${formData?.state?.error ? "error" : ""}`}
                                                data={states}
                                                optionSelected={formData?.state?.value}
                                                disabled={formData?.state?.disabled}
                                                onChange={handleFormElementChange/*handleOnChangeTitle*/}
                                            />

                                        </Field>
                                        <Field className="field city">
                                            <Input
                                                style_type="default"
                                                type="text"
                                                label={`${t("labels.city")}:`}
                                                inputRef={inputRef}
                                                className={`city ${formData?.city?.error ? "error" : ""}`}
                                                elName={formData?.city?.ref}
                                                value={formData?.city?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                        <Field className="field zip">
                                            <Input
                                                style_type="default"
                                                type="zip"
                                                label={`${t("labels.zip")}:`}
                                                inputRef={inputRef}
                                                className={`zip ${formData?.zip?.error ? "error" : ""}`}
                                                elName={formData?.zip?.ref}
                                                value={formData?.zip?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </Field>
                                    </div>
                                </Fields>
                            </div>
                        </div>

                        <div className="action_save">
                            {beneficiariesLimitReach == false &&
                                <ButtonSolid type="submit" disabled={loading} style_type="default" className="bt_save" override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}>
                                    <p>{t('buttons.save')}</p>
                                </ButtonSolid>
                            }

                        </div>

                    </div>
                    
                    

                </BoxContainer>
                

                </form>
            </Container>

            {/*
            <Right>
                <BoxContainer className="box_vip stretched">
                    <div>VIP</div>
                </BoxContainer>
                <BoxContainer className="box_countdown stretched">
                    <div>COUNTDOWN</div>
                </BoxContainer>
            </Right>
            */}

        </Wrapper>
    )
}

export default AddContainer
