import { ReactComponent as IcoDocumentSVG } from '../assets/images/ico_document.svg';
import { ReactComponent as LogoInheritSVG } from '../assets/images/logo_inherit.svg';
import { ReactComponent as IcoDashboardSVG } from '../assets/images/dashboard.svg';

import { ReactComponent as IcoBeneficiariesSVG } from '../assets/images/beneficiaries.svg';
import { ReactComponent as IcoUpgradeSVG } from '../assets/images/upgrade.svg';
import { ReactComponent as IcoMyVaultSVG } from '../assets/images/my_vault.svg';
import { ReactComponent as IcoSettingsSVG } from '../assets/images/settings.svg';
import { ReactComponent as IcoSearchTreeSVG } from '../assets/images/search_tree.svg';
import { ReactComponent as IcoMyLastWillSVG } from '../assets/images/my_last_will.svg';
import { ReactComponent as IcoBlackBoxSVG } from '../assets/images/black_box.svg';
import { ReactComponent as IcoMyAssetsSVG } from '../assets/images/my_assets.svg';
import { ReactComponent as IcoBillingSVG } from '../assets/images/billings.svg';
import { ReactComponent as IcoServicesSVG } from '../assets/images/ico_document.svg';
import { ReactComponent as IcoNotificationsSVG } from '../assets/images/ico_notifications.svg';
import { ReactComponent as IcoPlusSVG } from '../assets/images/plus.svg';
import { ReactComponent as IcoCheckSVG } from '../assets/images/check.svg';
import { ReactComponent as IcoBackSVG } from '../assets/images/back_header.svg';
import { ReactComponent as IcoArrowDownSVG } from '../assets/images/arrow_down.svg';
import { ReactComponent as IcoCameraSolidSVG } from '../assets/images/camera_solid.svg';
import { ReactComponent as IcoErrorOutlineSVG } from '../assets/images/ico_error_outline.svg';
import { ReactComponent as IcoErrorFillSVG } from '../assets/images/ico_error_fill.svg';
import { ReactComponent as SpinnerSVG } from '../assets/images/spinner.svg';
import { ReactComponent as IcoCloseSVG } from '../assets/images/ico_close.svg';
import { ReactComponent as IcoUploadSVG } from '../assets/images/ico_upload.svg';
import { ReactComponent as ImgSelectAssetSVG } from '../assets/images/img_select_asset.svg';
import { ReactComponent as IcoContactUsSVG } from '../assets/images/ico_contactus.svg';



export const IcoDocument = (props) => { return ( <IcoDocumentSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const LogoInherit = (props) => { return ( <LogoInheritSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoDashboard = (props) => { return ( <IcoDashboardSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}

export const IcoBeneficiaries = (props) => { return ( <IcoBeneficiariesSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoUpgrade = (props) => { return ( <IcoUpgradeSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoMyVault = (props) => { return ( <IcoMyVaultSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoSettings = (props) => { return ( <IcoSettingsSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoSearchTree = (props) => { return ( <IcoSearchTreeSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoMyLastWill = (props) => { return ( <IcoMyLastWillSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoBlackBox = (props) => { return ( <IcoBlackBoxSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoMyAssets = (props) => { return ( <IcoMyAssetsSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoBilling = (props) => { return ( <IcoBillingSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoServices = (props) => { return ( <IcoServicesSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoNotifications = (props) => { return ( <IcoNotificationsSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoPlus = (props) => { return ( <IcoPlusSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoCheck = (props) => { return ( <IcoCheckSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoBack = (props) => { return ( <IcoBackSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoArrowDown = (props) => { return ( <IcoArrowDownSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoCameraSolid = (props) => { return ( <IcoCameraSolidSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoErrorOutline = (props) => { return ( <IcoErrorOutlineSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoErrorFill = (props) => { return ( <IcoErrorFillSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const Spinner = (props) => { return ( <SpinnerSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoClose = (props) => { return ( <IcoCloseSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoUpload = (props) => { return ( <IcoUploadSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const ImgSelectAsset = (props) => { return ( <ImgSelectAssetSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}
export const IcoContactUs = (props) => { return ( <IcoContactUsSVG fill={props.fill ? props.fill : "#D0B257"} className={props.className} /> )}



export const ICO_DOCUMENT = 'assets/images/ico_document.svg';
export const ICO_IMAGE = 'assets/images/ico_image.svg';
export const ICO_VIDEO = 'assets/images/ico_video.svg';


export const PAGES = [
    {
        show: true,
        name: 'Dashboard',
        routeName: 'dashboard',
        icon: <IcoDashboard />,
        header_title: 'Welcome to your Dashboard',
    },
    {
        show: true,
        name: 'Parties',
        routeName: 'parties',
        icon: <IcoBeneficiaries />,
        header_title: 'My Parties',
    },
    {
        show: true,
        name: 'My Assets',
        routeName: 'my-assets',
        icon: <IcoMyAssets />,
        header_title: 'My Assets',
    },
    {
        show: true,
        name: 'Assigned Assets',
        routeName: 'assigned-assets',
        icon: <IcoMyAssets />,
        header_title: 'Assigned Assets',
    },
    {
        show: true,
        name: 'My Will',
        routeName: 'my-will',
        icon: <IcoMyLastWill />,
        header_title: 'My Will',
    },
    /*
    {
        show: true,
        name: 'My Vault',
        routeName: 'myVault',
        icon: <IcoMyVault />,
        header_title: 'My Vault',
    },
    {
        show: true,
        name: 'Settings',
        routeName: 'settings',
        icon: <IcoSettings />,
        header_title: 'My Settings',
    },
    {
        show: true,
        name: 'Search Tree',
        routeName: 'searchTree',
        icon: <IcoSearchTree />,
        header_title: '',
    },
    {
        show: true,
        name: 'Black Box',
        routeName: 'blackBox',
        icon: <IcoBlackBox />,
        header_title: 'Black Box',
    },
    {
        show: true,
        name: 'Billing',
        routeName: 'billing',
        icon: <IcoBilling />,
        header_title: 'Billing',
    },
    
    {
        show: true,
        name: 'Services',
        routeName: 'services',
        icon: <IcoServices />,
        header_title: 'Services',
    },
    */
    {
        show: true,
        name: 'Upgrade',
        routeName: 'upgrade',
        icon: <IcoUpgrade />,
        header_title: 'Upgrade Your Account',
    },
    {
        show: true,
        name: 'Contact Us',
        routeName: 'contactus',
        icon: <IcoContactUs />,
        header_title: 'Contact Us',
    }
    
    /*
    {
        show: true,
        name: 'Countdown',
        routeName: 'settings/countdown',
        icon: <IcoServices />,
        header_title: 'Countdown',
    },
    */
    
];

export const COLORS = {
    darkgrey: '#2B2C2D',
    darkgold: '#AD881C',
    lightgold: '#D0B257',
    hovergold: '#FCF2D9',
    //lightgrey: '#000',
    lightgrey: '#F3F3F3',
    lightgreyTwo: '#F9F9F9',
    bluegrey: '#7884A5',
    cloudyblue: '#B7BFCC',
    iceblue: '#F4F9FF',
    red: '#E02020',
    whiteTwo: '#F5F5F5',
    whiteTwoAlpha75: 'rgba(245, 245, 245, 0.75)',
    whiteThree: '#E7E6E6',
    whiteFour: '#E2E2E2',
    green: '#4C8F05',
}; 

export const SIZES = {
    xs: '476px',
    sm: '668px',
    md: '892px',
    lg: '1100px',
    xl: '1266px',
    xxl: '1340px',
    xxxl: '1580px',
    xxxxl: '1590px',//1590px
}

export const month = () => {
    let days_arr = [];
    for (let index = 1; index <= 12; index++) {
        /*
        let dayname = "Days";
        if(index <= 1){
            dayname = "Day";
        }
        */
        days_arr.push({"description": index, "value": index});
    }
    return days_arr;
}

export const days = () => {
    let days_arr = [];
    for (let index = 1; index <= 31; index++) {
        /*
        let dayname = "Days";
        if(index <= 1){
            dayname = "Day";
        }
        */
        days_arr.push({"description": index, "value": index});
    }
    return days_arr;
}

export const hours = () => {
    let hours_arr = [];
    let hourname = "";//"hrs"
    let description = '';
    let cero = 0;

    for (let index = 0; index < 24; index++) {
        if(index > 9){
            description = index;
        }else{
            description = '0'+index;
        }
        hours_arr.push({"description": description + " " + hourname, "value": description});
    }
    return hours_arr;
}

export const mins = () => {
    let mins_arr = [];
    for (let index = 0; index < 60; index++) {
        let minsname = "mins";
        if(index <= 1){
            minsname = "min";
        }
        mins_arr.push({"description": index + " " + minsname, "value": index});
    }
    return mins_arr;
}

export const notificate_frecuency = () => {

    const every_arr = [{"description": "First day of month", "value": "first_day_of_month"},
                       {"description": "Once a day", "value": "once_a_day"},
                       {"description": "Monday", "value": "monday"},
                       {"description": "Tuesday", "value": "tuesday"},
                       {"description": "Wednesday", "value": "wednesday"},
                       {"description": "Thursday", "value": "thursday"},
                       {"description": "Friday", "value": "friday"},
                       {"description": "Saturday", "value": "saturday"},
                       {"description": "Sunday", "value": "sunday"}];

    return every_arr;
}



