import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
    
    display: flex;
    width: 100%;
    cursor: pointer;

    .inner{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: ${props => props.st.size.width};
        padding-left: 18px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 18px;
        border-radius: 10px;
        p{
            margin-left: 12px;
            color: ${props => props.st.color.normal};
            font-size: ${props => props.st.font.size};
            font-weight: ${props => props.st.font.weight};
        }
        .icon{
            display: flex;
            width: ${props => props.st.icon.size.width};
            height: ${props => props.st.icon.size.height};
            margin-right: 6px;
            svg{
                fill: ${props => props.st.icon.color.normal};
            }
        } 
        :hover{
            background-color: ${props => props.st.background.hover};
            p{
                color: ${props => props.st.color.hover};
            }
            .icon{
                svg{
                    fill: ${props => props.st.icon.color.hover};
                }
            } 
        }
        
    }

    &.selected{
        .inner{
            background-color: ${props => props.st.background.hover};
            p{
                color: ${props => props.st.color.hover};
            }
            .icon{
                svg{
                    fill: ${props => props.st.icon.color.hover};
                }
            } 
        }
    }



`

const Sidebar = ({ children, override_css, style_type, className, onClick }) => {
    console.log(className);
    // Los estilos se puede sobreescribir usando el objeto override_css
    const [ styles, setStyle ] = useState(null);
    
    useEffect(() => {

        switch (style_type) {
            case 'default':
                setStyle({

                    color: { normal: override_css?.color?.normal ? override_css.color.normal : "#000000",
                             hover: override_css?.color?.hover ? override_css.color.hover : "#000000" },

                    background: { normal: override_css?.background?.normal ? override_css.background.normal : "transparent",
                                  hover: override_css?.background?.hover ? override_css.background.hover : "#FFF5D5" },

                    font: { size: override_css?.font?.size ? override_css.font.size : "16px",
                            weight: override_css?.font?.weight ? override_css.font.weight : "600" },

                    icon: { size: { width: override_css?.icon?.size?.width ? override_css?.icon?.size?.width : "28px",
                                    height: override_css?.icon?.size?.height ? override_css?.icon?.size?.height : "28px"},
                            color: { normal: override_css?.icon?.color?.normal ? override_css?.icon?.color?.normal : "#000000",
                                     hover: override_css?.icon?.color?.hover ? override_css?.icon?.color?.hover : "#D0B257" } },

                    size: { width: override_css?.size?.width ? override_css.size.width : "100%",
                            height: override_css?.size?.height ? override_css.size.height : "" }

                });
                break;
        
            default:
                break;
        }

    }, []);

    return (
        <>
        {styles &&
        <Wrapper override={override_css} st={styles} className={className} onClick={onClick}>
            <div className="inner">
            { children }
            </div>
        </Wrapper>
        }
        </>
    )
}

export default Sidebar
