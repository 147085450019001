import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

//import { ButtonSidebar } from '../Elements';
import ButtonSidebar from '../Elements/Buttons/Sidebar';
import { useTranslation } from 'react-i18next';

import Top from './Top';
import Bottom from './Bottom';
import { breakpoints } from '../../helpers/breakpoints';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ReactComponent as LogoInheritSVG } from '../../assets/images/logo_inherit.svg';

import { IcoDocument,
         IcoDashboard,
         IcoBeneficiaries, 
         IcoMyVault, 
         IcoSettings, 
         IcoSearchTree, 
         IcoMyLastWill, 
         IcoBlackBox, 
         IcoMyAssets, 
         IcoBilling, 
         IcoServices, 
         LogoInherit,
         PAGES } from '../../helpers/constants';

import ModalPlans from '../../components/Elements/Modals/ModalPlans';
import ModalContactUs from '../../components/Elements/Modals/ModalContactUs';
import { defineButtonSelected } from '../../helpers/functions';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 250px;
    background-color: #FFFFFF;
    //background-color: pink;
`
const Inner = styled.div`
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: column;
    background-color: #FFFFFF;
    padding-bottom: 30px;
    padding-top: 15px;
    align-self: flex-start;
    height: ${props => props.windowHeight};//calc(100% - 200px);
    overflow-y: auto;
`
const MenuElement = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;

    .icon{
        svg{
            //fill: #000000!important;
        }
    }
    .OuterItemBullet{

        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        right: -30px;
        overflow: hidden;
        height: 60px;
        width: 10px;
        //background-color: black;
    }
    .ItemBullet{
        display: flex;
        position: absolute;
        right: 6px;
        background-color: #FFFFFF;
        height: 60px;
        width: 60px;
        border-radius: 15px;
        transform: rotate(45deg);
    }
`
const OuterItemBullet = styled.div`
    
`
const ItemBullet = styled.div`
    
`
const BoxLogo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100px;
    background-color: #000000;
`
const Logo = styled.div`
    display: flex;
    height: 55px;
`

const Sidebar = (props) => {
    
    const { t } = useTranslation();

    const { activeButton } = props;
    const [ highlightedActiveButton, setHighlightedActiveButton ] = useState(activeButton);
    const [ openModalPlans, setOpenModalPlans ] = useState(false);
    const [ openModalContactUs, setOpenModalContactUs ] = useState(false);
    
    const history = useHistory();
    const location = useLocation();
    const { height, width } = useWindowDimensions();

    const goToSection = (section) => {
        switch (section) {
            case "upgrade":
                setOpenModalPlans(!openModalPlans)
                break;
            case "contactus":
                setOpenModalContactUs(!openModalContactUs)
                break;
            default:
                history.push(`/${section}`);
                break;
        }
        
    }
    
    return (
        <Wrapper className="el_sidebar">

            {openModalPlans &&
                <ModalPlans open={openModalPlans} setOpen={setOpenModalPlans} />
            }
            {openModalContactUs &&
                <ModalContactUs open={openModalContactUs} setOpen={setOpenModalContactUs} />
            }
            
            <Top className="top">
                <BoxLogo>
                    <Logo>
                        <LogoInheritSVG />
                    </Logo>
                </BoxLogo>
            </Top>
            <Inner windowHeight={(height - 100)+"px"}>
            {
                PAGES.map((item, index) => {
                    return (
                        item.show &&
                        <MenuElement>
                            <ButtonSidebar style_type="default" className={`${item.routeName == activeButton ? "selected" : ""}`} onClick={() => goToSection(item.routeName)}>
                                <div className="icon">
                                    {item.icon}
                                </div>
                                <p>{t(`menu.${item.routeName}`)}</p>
                            </ButtonSidebar>
                        </MenuElement>
                    )
                })
            }
            </Inner>
            {/*
            <Bottom>
                <BoxLogo>logo</BoxLogo>
            </Bottom>
            */}
        </Wrapper>
    )
}

export default React.memo(Sidebar);
