import React, { useState,
				createContext,
				useEffect,
				useContext,
				useCallback,
				useRef } from "react";

import axios from "axios";
import storage from 'local-storage-fallback';
//import api from "../shared/connect";
//import { AuthContext } from '../context/AuthContext';
import connect, { handleError } from '../helpers/connect';
import { partiesFirstBeneficiary } from '../helpers/functions';

export const ContentContext = createContext({});

export const ContentProvider = ({ children }) => {
	
	const [ titles, setTitles ] = useState(null);
	const [ countries, setCountries ] = useState(null);
	const [ states, setStates ] = useState(null);
	const [ beneficiaries, setBeneficiaries ] = useState(null);
	const [ parties, setParties ] = useState(null);
	const [ assets, setAssets ] = useState(null);
	const [ assetCategories, setAssetCategories ] = useState(null);
	const [ services, setServices ] = useState(null);
	const [ servicesDropdown, setServicesDropdown ] = useState(null);

	const userData = async () => {

        const requestURL = `${process.env.REACT_APP_API_SERVER}/user`;

		try {
			
			const result = await connect.get(requestURL);
			console.log('*******')
			console.log(result.data.data);
			console.log('*******')

			return result.data;

		} catch (err) {
		}

    }

	const dashboard = async () => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/dashboard`;

		try {
			
			const result = await connect.get(requestURL);
			console.log('*******')
			console.log(result.data.data);
			console.log('*******')

			return result.data;

		} catch (err) {
		}

	}

	const standardData = async () => {

		console.log("---> standardData1");
        const requestURL = `${process.env.REACT_APP_API_SERVER}/utils/standard-data`;

		try {
			console.log("---> standardData2");
			const result = await connect.get(requestURL);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
			console.log('*******')
			console.log(result.data.data);
			console.log('*******')

			const titlesArr = [];
			result.data.data.titles.map((title) => {
				titlesArr.push({id: title.id, value: title.id, description: title.description});
			})
			setTitles(titlesArr);

			const countriesArr = [];
			result.data.data.countries.map((country) => {
				countriesArr.push({id: country.id, value: country.id, description: country.name, sortname: country.sortname});
			})
			setCountries(countriesArr);

			const assetCategoriesArr = [];
			result.data.data.asset_categories.map((asset_category) => {
				assetCategoriesArr.push({id: asset_category.id, value: asset_category.id, description: asset_category.description});
			})
			setAssetCategories(assetCategoriesArr);

			const servicesArr = [];
			result.data.data.services.map((service) => {
				console.log(service.title);
				servicesArr.push({id: service.id, title: service.title, description: service.description});
			})
			setServices(servicesArr);

			const servicesDropdownArr = [];
			result.data.data.services.map((service) => {
				servicesDropdownArr.push({id: service.id, value: service.id, description: service.title});
			})
			setServicesDropdown(servicesDropdownArr);

			/*
			if (result.data.success) {
				
				

			} else {
				
				

			}
			*/
			return result.data;

		} catch (err) {
		}

    }

	const sendContactUs = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/utils/contactus`;

		try {
			const result = await connect.post(requestURL, payload);

			return result.data;

		} catch (err) {
		}

	}

	const setUserTimezone = async (payload) => {
		
		const requestURL = `${process.env.REACT_APP_API_SERVER}/user/timezone`;
		const result = await connect.post(requestURL, payload);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;

	}

	const statesByCountry = async (country_id) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/utils/states-by-county`;

		try {
			
			const result = await connect.get(requestURL, {params: {country_id: country_id}});/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
			console.log('*******')
			console.log(result.data.data);
			console.log('*******')

			const statesArr = [];
			result.data.data.map((state) => {
				statesArr.push({id: state.id, value: state.id, description: state.name});
			})
			setStates(statesArr);

			/*
			if (result.data.success) {
				
				

			} else {
				
				

			}
			*/
			return result.data;

		} catch (err) {
		}

	}

	const getSettingCountdown = async () => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/settings/countdown`;
		
		const result = await connect.get(requestURL);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;

	}

	const editSetting = async (payload, section) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/settings/${section}`;

		const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
		
		if(result.data.success) {

			/*
			const assetsEdit = [...assets];
			assetsEdit.map((asset, index) => {
				if(asset.id == result.data.data.id){
				   asset.name = result.data.data.name;
				   asset.description = result.data.data.description;
				   asset.image = result.data.data.image;  
				   asset.image_file = result.data.data.image_file;
				   asset.category.id = result.data.data.category.id;
				   asset.category.description = result.data.data.category.description;
				   asset.updated_at = result.data.data.updated_at;
				}
				return asset;
			})
			
			setAssets(assetsEdit);
			*/

		}

		return result.data;

	}

	const deleteCountdown = async (countdown_id) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/settings/countdown/${countdown_id}`;
		const result = await connect.delete(requestURL);
		console.log('*******')
		console.log(result);
		console.log('*******')

		return result.data;

	}

	const addBeneficiary = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/beneficiary/add`;

		try {
			const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
			console.log('*******')
			console.log(result);
			console.log('*******')

			return result.data;

		} catch (err) {
		}

	}
	
	const getAssignedAsset = async (user_id) => {
		
		const requestURL = `${process.env.REACT_APP_API_SERVER}/asset-distribution/assigned-assets/${user_id}`;
		const result = await connect.get(requestURL);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;
		
	}

	const assignAssetByPartyCode = async (payload) => {

		//const requestURL = `${process.env.REACT_APP_API_SERVER}/asset-distribution/assign-asset/${party_id}`;
		//const result = await connect.get(requestURL);
		const requestURL = `${process.env.REACT_APP_API_SERVER}/asset-distribution/assign-asset`;
		const result = await connect.post(requestURL, payload);
		console.log('assignAssetByPartyCode *******')
		console.log(result);
		console.log('assignAssetByPartyCode *******')
		return result.data;
		
	}

	const getAssetDistribution = async () => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/asset-distribution`;
		
		const result = await connect.get(requestURL);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;

	}

	const getAssetDistributionById = async (asset_id) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/asset-distribution/${asset_id}`;
		
		const result = await connect.get(requestURL);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;

	}

	const saveDistributedAsset = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/asset-distribution/add`;

		const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
		console.log('*******')
		console.log(result);
		console.log('*******')

		//setAssets(prev => [result.data.data, ...prev]);

		return result.data;

	}
	
	const sendNotificationToParty = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/notifications/asset-beneficiary`;

		const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
		console.log('*******')
		console.log(result);
		console.log('*******')

		//setAssets(prev => [result.data.data, ...prev]);

		return result.data;

	}

	const getAssetsDistributedByParty = async (party_id) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/asset-distribution/by-party/${party_id}`;
		
		const result = await connect.get(requestURL);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;

	}
	
	const deletePartyDistribution = async (party_id) => {
		
		const requestURL = `${process.env.REACT_APP_API_SERVER}/asset-distribution/${party_id}`;
		
		const result = await connect.delete(requestURL);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;

	}

	const getAssets = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/my-asset`;

		try {
			
			const result = await connect.get(requestURL);
			console.log('*******')
			console.log(result);
			
			setAssets(result.data.data);			

		} catch (err) {}

	}
	const addAsset = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/my-asset/add`;

		const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
		console.log('*******')
		console.log(result);
		console.log('*******')

		setAssets(prev => [result.data.data, ...prev]);

		return result.data;

	}

	const editAsset = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/my-asset/edit`;

		const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
		
		if(result.data.success) {
			const assetsEdit = [...assets];
			assetsEdit.map((asset, index) => {
				if(asset.id == result.data.data.id){
				   asset.name = result.data.data.name;
				   asset.description = result.data.data.description;
				   asset.image = result.data.data.image;  
				   asset.image_file = result.data.data.image_file;
				   asset.thumb = result.data.data.thumb;
				   asset.thumb_file = result.data.data.thumb_file;
				   asset.category.id = result.data.data.category.id;
				   asset.category.description = result.data.data.category.description;
				   asset.updated_at = result.data.data.updated_at;
				}
				return asset;
			})
			console.log(assetsEdit);
			setAssets(assetsEdit);
		}

		return result.data;

	}

	const deleteAsset = async (asset_id) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/my-asset/${asset_id}`;
		try {
			
			const result = await connect.delete(requestURL);
			return result.data;
			

		} catch (err) {}
	}

	const deleteImageAsset = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/utils/delete-asset-images`;
		const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
		return result;
		
	}

	const deleteFile = async (payload, file, scope) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/images/delete`;

		const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
		
		switch (scope) {
			case 'assets':
				const asset_data = assets.filter((a) => a.id == payload.asset_id)[0];
				const asset_files = asset_data.files.filter((f) => f.id !== file.id);
				const updated_asset = assets.map((a, index) => {
					if(a.id == payload.asset_id) {
						a.files = asset_files;
					}
					return a;
				})

				setAssets(updated_asset);
				break;
		
			default:
				break;
		}

		return true;

	}

	const downloadAssetExtraFile = async (file) => {

		//const requestURL = `http://api.inherit.local/utils/download-asset-file/${file_id}`;
		const requestURL = `${process.env.REACT_APP_API_SERVER}/utils/download-asset-file/${file.id}`;

		const response = axios({

            url: requestURL,
            method: 'GET',
            responseType: 'blob', // important
			headers: { Authorization: `Bearer ${storage.getItem('ie_access_token')}` }

        });

		return response;
		/*
		.then((response) => {
			
			const headerval = response.headers['content-disposition'];
			console.log("------ headerval")
			console.log(file);
			console.log("------ headerval")
			//return null;
            const href = URL.createObjectURL(response.data);
			
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', file.originalname); //or any other extension
            document.body.appendChild(link);
            link.click();
			
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

        });
		*/

		/*
		const result = await connect.get(requestURL, { responseType: "blob" });
		result.then((response) => {
			alert("asd")
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
        
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
		*/

		//const requestURL = `${process.env.REACT_APP_API_SERVER}/utils/download-asset-file/${file_id}`;
		//const requestURL = `http://api.inherit.local/utils/download-asset-file/${file_id}`;
		//const result = await connect.get(requestURL);
		//return result;
		//http://api.inherit.local/v1/utils/download-asset-file/1
	}

	const getAssetByID = async (asset_id) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/my-asset/${asset_id}`;

		const result = await connect.get(requestURL);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;

	}

	const addParty = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/party/add`;

		try {
			const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
			console.log('*******')
			console.log(result);
			console.log("result.data.data.title.description", result.data.data.title.description)
			console.log("Executor", result.data.data.title.description, result.data.data.title.description === "Executor")
			console.log('*******')

			if(result.data.data.title.description === "Executor"){
				const partiesAdd = [...parties];
				partiesAdd.map((party, index) => {
					party.title.id = "2";
                    party.title.description = "Beneficiary";
					return party;
				})
				partiesAdd.unshift(result.data.data);
				console.log("** partiesAdd", partiesAdd)
				const partiesData = partiesFirstBeneficiary(partiesAdd);
				setParties(partiesData);
			}else{
				setParties(prev => [result.data.data, ...prev]);
			}
			
            /*    
                    
                    if(party.id == res.data.id){
                        party.first_name = res.data.first_name;
                        party.middle_name = res.data.middle_name;
                        party.last_name = res.data.last_name;
                        party.profile_image = res.data.profile_image;
                        party.email = res.data.email;
                        party.title.id = res.data.title.id;
                        party.title.description = res.data.title.description;
                        party.updated_at = res.data.updated_at;
                    }else{
                        if(formData.title.value == "3"){
                            party.title.id = "2";
                            party.title.description = "Beneficiary";
                        }
                    }
                    return party;
                })
                const partiesData = partiesFirstBeneficiary(partiesEdit);
                setParties(partiesData);

			setParties(prev => [result.data.data, ...prev]);
				*/
			return result.data;

		} catch (err) {
		}

	}

	const editParty = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/party/edit`;

		try {
			const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
			console.log('*******')
			console.log(result);
			console.log('*******')

			return result.data;

		} catch (err) {
		}

	}

	const editProfile = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/user/profile/edit`;

		try {
			const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
			console.log('*******')
			console.log(result);
			console.log('*******')

			return result.data;

		} catch (err) {
		}

	}

	const uploadFile = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/utils/upload-file`;

		try {
			const result = await connect.post(requestURL, payload);/* , {headers: { Authorization: `Bearer `}} ${authData.accessToken}*/
			console.log('*******')
			console.log(result);
			console.log('*******')

			return result.data;

		} catch (err) {
		}
		
	}

	const createCustomer = () => {

		alert("asd")

	}

	const getImageProfile = async (profileImage) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/images/image_profile/${profileImage}`;

		try {
			
			const result = await connect.get(requestURL, { responseType: "blob" });
			//
			const url = URL.createObjectURL(result.data);
			console.log("///// URL")
			console.log(url);
			console.log("///// URL")
			return result;
			

		} catch (err) {}

	}
	
	const getFile = async (filename) => {

		const requestURL = `http://api.inherit.local/imgs/${filename}`;
		
		const result = await connect.get(requestURL, { responseType: "blob" });
		return result;
		/*
		let img = "";
		const result = await connect.get(requestURL, { responseType: "blob" }).then(res => {
			
			img = res.data;//URL.createObjectURL(res.data);

			let reader = new FileReader();
			reader.readAsDataURL(img); 

			return reader;
			
			//img = URL.createObjectURL(res.data);
		});
		
		//return img;
		*/

		

	}

	const getFileByType = async (filename, type) => {
		const requestURL = `${process.env.REACT_APP_API_SERVER}/images/${type}/${filename}`;
		
		const result = await connect.get(requestURL, { responseType: "blob" });
		//const result = await connect.get(requestURL, {params: {user_type: 'lala'}}, { responseType: "blob" });
		return result;
	}

	const getBeneficiaries = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/beneficiary`;

		try {
			
			const result = await connect.get(requestURL);
			console.log('*******')
			console.log(result);
			console.log('*******')
			setBeneficiaries(result.data.data);

			/*
			if (result.data.success) {
				
				

			} else {
				
				

			}
			*/
			return result.data;
			

		} catch (err) {}

	}

	const deletePartie = async (party_id) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/party/${party_id}`;
		try {
			
			const result = await connect.delete(requestURL);
			console.log('*******')
			console.log(result);
			console.log('*******')
			//setParties(result.data.data);
			return result.data;
			

		} catch (err) {}
	}

	const getParties = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/party`;

		try {
			
			const result = await connect.get(requestURL);
			console.log('//*******')
			console.log(result);
			

			const partiesData = partiesFirstBeneficiary(result.data.data);
			console.log('*******//')
			setParties(partiesData);

			/*
			if (result.data.success) {
				
				

			} else {
				
				

			}
			*/
			return result.data;
			

		} catch (err) {}

	}
	const getPartyByID = async (party_id) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/party/${party_id}`;

		try {
			
			const result = await connect.get(requestURL);
			console.log('*******')
			console.log(result);
			console.log('*******')
			//setParties(result.data.data);
			return result.data;
			

		} catch (err) {}

	}

	const activateTwoFaMailCode = async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/settings/twofa_mailcode`;
		const result = await connect.post(requestURL, payload);
		return result;

	}

	const change_password = useCallback(async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/settings/change_password`;
		const result = await connect.post(requestURL, payload);
		return result;

	}, []);

	// Payments
	const create_setup_intent = useCallback(async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/create_setup_intent`;
		const result = await connect.get(requestURL);
		return result;
		console.log('*******')
		console.log(result);
		console.log('*******')

	}, []);

	const create_customer = useCallback(async (payload) => {
		//console.log('******* payload', payload);
		
		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/create_customer`;
		const result = await connect.post(requestURL, payload);
		return result;
		console.log('*******')
		console.log(result);
		console.log('*******')
		
	}, []);

	
	
	const subscribe = useCallback(async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/subscribe`;
		const result = await connect.post(requestURL, payload);
		return result;
		console.log('*******')
		console.log(result);
		console.log('*******')

	}, []);

	const subscription = useCallback(async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/subscription`;
		const result = await connect.get(requestURL);
		return result.data;
		console.log('*******')
		console.log(result);
		console.log('*******')

	}, []);

	const subscriptionInvoice = useCallback(async (payload, invoiceId) => {
		alert(`${process.env.REACT_APP_WEB_SERVER}/payment/invoice/${invoiceId}`);
		window.location.href = `${process.env.REACT_APP_WEB_SERVER}/payment/invoice/${invoiceId}`;
		//const requestURL = `${process.env.REACT_APP_WEB_SERVER}/payment/invoice/${invoiceId}`;
		//const result = await connect.get(requestURL);
		//return result;

	}, []);

	const addPaymentMethod = useCallback(async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/add_payment_method`;
		const result = await connect.post(requestURL, payload);
		return result.data;
		console.log('*******')
		console.log(result);
		console.log('*******')

	}, []);

	const deletePaymentMethod = useCallback(async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/delete_payment_method`;
		const result = await connect.post(requestURL, payload);
		return result.data;
		console.log('*******')
		console.log(result);
		console.log('*******')

	}, []);

	const setAsDefaultPaymentMethod = useCallback(async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/set_as_default_payment_method`;
		const result = await connect.post(requestURL, payload);
		return result.data;
		console.log('*******')
		console.log(result);
		console.log('*******')

	}, []);
	

	const subscriptionCancel = useCallback(async (payload) => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/cancel`;
		const result = await connect.get(requestURL);
		return result.data;
		console.log('*******')
		console.log(result);
		console.log('*******')

	})
	const subscriptionUpdate = useCallback(async (payload) => {
		
	})
	const subscriptionResume = useCallback(async (payload) => {
		
		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/resume`;
		const result = await connect.get(requestURL);
		return result.data;
		console.log('*******')
		console.log(result);
		console.log('*******')

	})
	const subscriptionChange = useCallback(async (payload) => {
		
		const requestURL = `${process.env.REACT_APP_API_SERVER}/payment/change_subscription`;
		const result = await connect.post(requestURL, payload);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;
	  },
	  [],
	)
	const addFormGetQuote = useCallback(async (payload) => {
		console.log("addFormGetQuote", payload);
		const requestURL = `${process.env.REACT_APP_API_SERVER}/utils/form_get_quote`;
		const result = await connect.post(requestURL, payload);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;
	  },
	  [],
	)

	
	

	const getPlans = async () => {

		const requestURL = `${process.env.REACT_APP_API_SERVER}/utils/plans`;

		const result = await connect.get(requestURL);
		console.log('*******')
		console.log(result);
		console.log('*******')
		return result.data;

	}


	useEffect(() => {
		standardData();
		//getBeneficiaries();
		getParties();
		getAssets();
		dashboard();
		return () => {
			
		}
	}, [])

	
	return (
		<ContentContext.Provider
			value={{
				uploadFile,
				addParty,
				editParty,
				editProfile,
				addBeneficiary,
				addAsset,
				editAsset,
				deleteAsset,
				deleteFile,
				downloadAssetExtraFile,
				deleteImageAsset,
				editSetting,
				deleteCountdown,
				change_password,
				activateTwoFaMailCode,
				titles,
				countries,
				states,
				statesByCountry,
				getFile,
				getImageProfile,
				getFileByType,
				getAssets,
				getAssetByID,
				getParties,
				getPartyByID,
				getBeneficiaries,
				setBeneficiaries,
				getAssetDistribution,
				getAssetDistributionById,
				getAssetsDistributedByParty,
				sendNotificationToParty,
				deletePartyDistribution,
				saveDistributedAsset,
				getSettingCountdown,
				getAssignedAsset,
				getPlans,
				addFormGetQuote,
				assignAssetByPartyCode,
				setUserTimezone,
				beneficiaries,
				parties, setParties,
				deletePartie,
				assetCategories,
				setAssetCategories,
				services, setServices,
				servicesDropdown, setServicesDropdown,
				assets, setAssets,
				create_setup_intent,
				subscribe,
				subscription,
				subscriptionInvoice,
				subscriptionCancel, 
				subscriptionUpdate,
				subscriptionResume,
				subscriptionChange,
				create_customer,
				addPaymentMethod,
				deletePaymentMethod,
				setAsDefaultPaymentMethod,
				sendContactUs
			}}
		>
			{children}
		</ContentContext.Provider>
	);
};
