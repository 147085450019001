import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    p{
        margin: 0;
        color: ${props => props?.override_css?.color ? props.override_css.color : "#313233"};
        font-size: ${props => props?.override_css?.fontSize ? props.override_css.fontSize : "16px"};
        font-weight: ${props => props?.override_css?.fontWeight ? props.override_css.fontWeight : "600"};
        
        letter-spacing: -0.5px;
    }
`

const Title = ({ children, override_css, className }) => {
    return (
        <Wrapper override_css={override_css} className={className}>
            { children }
        </Wrapper>
    )
}

export default Title
