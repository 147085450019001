import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { breakpoints } from '../../helpers/breakpoints';

import CountdownContainer from '../../components/Settings/Countdown/CountdownContainer';
import NotificationsContainer from '../../components/Settings/NotificationsContainer';
import ProfileContainer from '../../components/Settings/ProfileContainer';
import SecurityContainer from '../../components/Settings/SecurityContainer';
import SubscriptionContainer from '../../components/Settings/SubscriptionContainer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { GiHamburgerMenu } from "react-icons/gi";
import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import IconButton from '@material-ui/core/IconButton';

import { COLORS, SIZES } from '../../helpers/constants';

const Wrapper = styled.div`

    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    //padding: 15px;
    box-sizing: border-box;

    .box_header_actions{
      width: calc(100% - 30px);
      margin-bottom: 15px;
      height: 60px!important;
      box-shadow: unset!important;
      justify-content: flex-end;
      .inner{
        display: none;
        flex-direction: row;
      }
      .menu_settings_mobile{
        display: flex;
        flex-direction: row;
        align-items: center;
        .ico_hamburger{
          color: ${COLORS.lightgold};
        }
      }
    }
    
    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {
      .box_header_actions{
        justify-content: flex-start;
        .inner{
          display: flex;
        }
        .menu_settings_mobile{
          display: none;
        }
      }
    }
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`
const MenuAssetsStyled = styled(Menu)`
  ul{
    li{
      &.selected{
        background-color: ${COLORS.lightgold};
        color: #FFFFFF;
      }
    }
  }
`


const ButtonHeaderStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  border-radius: 6px;
  cursor: pointer;

  &.bt_tab{
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    margin-right: 10px;
    :hover:enabled{
      background-color: ${COLORS.lightgold};
      border: 1px solid ${COLORS.lightgold};
      p{
        color: #FFFFFF!important;
      }
    }
    :disabled{
      cursor: default;
      background-color: ${COLORS.whiteTwo};
      border: 1px solid ${COLORS.cloudyblue};
      p{
        color: ${COLORS.cloudyblue};
      }
    }
    &.selected{
      background-color: ${COLORS.lightgold};
      border: 1px solid ${COLORS.lightgold};
      p{
        color: #FFFFFF!important;
      }
    }
  }

  p{

      margin: 0;
      color: ${COLORS.darkgrey}!important;
      font-size: 16px!important;
      font-weight: 600!important;
      letter-spacing: -0.38px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 7px;
      padding-bottom: 7px;
      
  }
  /*
  :hover:enabled{
    .cancel{
      background-color: #FFFFFF;
      border: 1px solid ${COLORS.lightgold};
      p{
        color: ${COLORS.red}!important;
      }
    }
    
      background-color: #FFFFFF;
      border: 1px solid ${COLORS.red};
    
      p{
          color: ${COLORS.red}!important;
      }
      
      :disabled{
          p{
              color: pink;
          }
      }
  }
  
  :disabled{
      background-color: ${COLORS.lightgrey};
      border-color: #ccc;
      p{
          color: #999;
      }
  }
  */
`

const SettingsContainer = (props) => {
  
  const history = useHistory();
  let { section } = useParams();

  const [ showSection, setShowSection ] = useState("");
  const [ menuSettingsEl, setMenuSettingsEl ] = useState(null);

  useEffect(() => {
    console.log("section: " + section);
    setShowSection(section);
    /*
    const path = history.location.pathname.split('/');
    console.log("path: " + path[path.length - 1]);
    setShowSection(path[path.length - 1]);
    */
    return () => {
      
    }
  }, [section])

  const handleGoTo = (goto) => {
    history.push('/settings/'+goto);
  }

  const handleMenuSettingsMobileClick = useCallback((event) => {
    setMenuSettingsEl(event.currentTarget);
  }, []);
  
  const handleCloseSettingsMenu = () => {
    setMenuSettingsEl(null);
  };
  

  return (
    <Wrapper>

      <MenuAssetsStyled
        id="simple-menu"
        className="menu_assets"
        anchorEl={menuSettingsEl}
        keepMounted
        open={Boolean(menuSettingsEl)}
        onClose={handleCloseSettingsMenu}
      >
        <MenuItem onClick={() => { handleGoTo('profile'); handleCloseSettingsMenu()} } className={`${showSection === "profile" ? "selected" : ""}`}>Profile</MenuItem>
        <MenuItem onClick={() => { handleGoTo('notifications'); handleCloseSettingsMenu()} } className={`${showSection === "notifications" ? "selected" : ""}`}>Notifications</MenuItem>
        <MenuItem onClick={() => { handleGoTo('countdown'); handleCloseSettingsMenu()} } className={`${showSection === "countdown" ? "selected" : ""}`}>Countdown</MenuItem>
        <MenuItem onClick={() => { handleGoTo('subscription'); handleCloseSettingsMenu()} } className={`${showSection === "subscription" ? "selected" : ""}`}>Subscription</MenuItem>
        <MenuItem onClick={() => { handleGoTo('security'); handleCloseSettingsMenu()} } className={`${showSection === "security" ? "selected" : ""}`}>Security</MenuItem>
      </MenuAssetsStyled>

      <BoxContainer className="box_header_actions">
        <div className="inner">
          <ButtonHeaderStyled className={`bt_tab${showSection == 'profile' ? ' selected' : ''}`} onClick={() => handleGoTo('profile')}>
            <p>Profile</p>
          </ButtonHeaderStyled>
          {/*
          <ButtonHeaderStyled className={`bt_tab${showSection == 'notifications' ? ' selected' : ''}`} onClick={() => handleGoTo('notifications')}>
            <p>Notifications</p>
          </ButtonHeaderStyled>
          */}
          <ButtonHeaderStyled className={`bt_tab${showSection == 'countdown' ? ' selected' : ''}`} onClick={() => handleGoTo('countdown')}>
            <p>Countdown</p>
          </ButtonHeaderStyled>
          <ButtonHeaderStyled className={`bt_tab${showSection == 'subscription' ? ' selected' : ''}`} onClick={() => handleGoTo('subscription')}>
            <p>Subscription</p>
          </ButtonHeaderStyled>
          <ButtonHeaderStyled className={`bt_tab${showSection == 'security' ? ' selected' : ''}`} onClick={() => handleGoTo('security')}>
            <p>Security</p>
          </ButtonHeaderStyled>
        </div>
        <div className="menu_settings_mobile">
          <IconButton className="more_actions" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleMenuSettingsMobileClick(event)}>
            <GiHamburgerMenu className="ico_hamburger" />
          </IconButton>
        </div>
      </BoxContainer>

      {showSection == 'profile' &&
        <ProfileContainer />
      }

      {showSection == 'notifications' &&
        <NotificationsContainer />
      }

      {showSection == 'countdown' &&
        <CountdownContainer />
      }

      {showSection == 'subscription' &&
        <SubscriptionContainer />
      }

      {showSection == 'security' &&
        <SecurityContainer />
      }
      
    </Wrapper>
  )
}

export default SettingsContainer
