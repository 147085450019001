import React, {useState, useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { toast } from 'react-toastify';
import { breakpoints } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router';
import storage from 'local-storage-fallback';
import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import PartiesItem from '../../components/Elements/Parties/Item';
import AssetsList from '../../components/Elements/Parties/AssetsList';
import ThumbBox from '../../components/Elements/ThumbBox';
import Image from '../../components/Elements/Image';
import NoImage from '../../components/Elements/NoImage';
import OverlayImage from '../../components/Elements/OverlayImage';
import ImageProfile from '../../components/Elements/ImageProfile';
import PartiesDistributions from '../../components/Elements/Dashboard/PartiesDistributions';
import Parties from '../../components/Elements/Dashboard/Parties';
import PortionPill from '../../components/Elements/PortionPill';
import FindAssignedAsset from '../../components/Elements/Dashboard/FindAssignedAsset';
import { default as CountdownData } from '../../components/Elements/Dashboard/Countdown';
import Tooltip from '@mui/material/Tooltip';

import { AiOutlineCamera } from "react-icons/ai";

import { COLORS } from '../../helpers/constants';

import { ContentContext,
         AuthContext } from '../../context';

import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`

    display: flex;
    //flex: 1;
    //padding-left: 30px;
    //padding-right: 30px;
    padding-top: 17px;
    padding-bottom: 17px;
    flex: 1;
    flex-direction: row;
    justify-content: center;

    
    //background-color: pink;
    /*
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 17px;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  >.box_data{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    box-sizing: border-box;

    .inner{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      width: 100%;
    }
  }
  .box_assets,
  .box_beneficiaries,
  .box_assets_assigned{
    display: flex;
    flex-direction: column;
    //padding: 25px;
    margin-bottom: 20px;
    .box_assets_inner,
    .box_beneficiaries_inner,
    .box_assets_assigned_inner{
      padding: 25px;
      >.title{
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      ul.container_owners_asset{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
          margin-bottom: 15px;
          margin-top: 20px;
          border-bottom: 1px solid ${COLORS.whiteThree};
          .owner_asset{
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 5px;
          }
          :last-child{
            border-bottom: 0;
            margin-bottom: 0;
            margin-top: 0;
          }
        }
      }
      .box_assets_assigned_form{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        .error_assigned_asset{
          margin-top: 10px;
          p{
            color: ${COLORS.red};
            margin: 0;
            padding: 0;
          }
        }
      }
    }
    .box_assets_inner{
      >.loader{
        svg{
          color: ${COLORS.lightgold}
        }
      }
    }
  }
  .box_recent{
  }

  @media ${breakpoints.xs} {

  }
  @media ${breakpoints.sm} {
    
  }
  @media ${breakpoints.md} {
      //width: 962px;
      >.box_data{
        margin-bottom: 20px;
        padding: 15px;
        .inner{
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
        }
      }
  }
  @media ${breakpoints.lg} {
      //width: 870px;
  }
  @media ${breakpoints.xl} {
    //width: 1036px;
    >.box_data{
      margin-bottom: 20px;
      padding: 15px;
      .inner{
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
  @media ${breakpoints.xxl} {
      //width: 1110px;
  }
  @media ${breakpoints.xxxl} {
      //width: 1350px;
  }
  @media ${breakpoints.xxxxl} {
      //width: 1590px;
  }
`
const UserData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  //background-color: skyblue;
  .thumb{
    display: flex;
    width: 30vw;
    height: 38vw;
    border: 0;
  }
  .user_image{
    
  }
  .bt_add_picture{
    position: absolute;
    width: 50px!important;
    min-width: unset;
    font-size: 25px;
    line-height: 25px;
    color: #FFFFFF;
    :hover{
      color: ${COLORS.lightgold};
    }
  }
  .data{
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
    margin-top: 4vw;
    
    .datatop{
      display: flex;
      flex-direction: column;
      align-items: center;
      h2.name{
        font-size: 6vw;
        line-height: 6.5vw;
        margin: 0;
        font-weight: 600;
      }
      h3.title{
        font-size: 3.8vw;
        font-weight: 500;
        margin: 0;
        color: ${COLORS.darkgold};
      }
    }
    .databottom{
      //background-color: aquamarine;
      margin-top: 4vw;
      .line{
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        width: 100%;

        h2.label{
          font-size: 3.3vw;
          font-weight: 600;
          margin: 0;
          color: ${COLORS.darkgrey};
        }
        p.value{
          font-size: 3.3vw;
          color: ${COLORS.darkgrey};
          margin: 0;
          margin-left: 3vw;
        }
      }
    }
  }
  @media ${breakpoints.xs} {
    .thumb{
      display: flex;
      width: 180px;
      height: 220px;
      border: 0;
    }
    .data{
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      margin-left: 0;
      margin-top: 15px;
      .datatop{
        h2.name{
          font-size: 32px;
          line-height: 38px;
          margin: 0;
        }
        h3.title{
          font-size: 19px;
          margin: 0;
        }
      }
      .databottom{
        margin-top: 20px;
        .line{
          h2.label{
            font-size: 16px;
            margin: 0;
          }
          p.value{
            font-size: 16px;
            margin: 0;
            margin-left: 6px;
          }
        }
      }
    }
  }
  @media ${breakpoints.sm} {
      //min-width: 768px
  }
  @media ${breakpoints.md} {
    //min-width: 992px
    flex-direction: row;
    width: calc(100% - 161px);
    align-items: flex-start;
    .thumb{
      display: flex;
      width: 180px;
      height: 220px;
      border: 0;
    }
    .data{
      display: flex;
      width: calc(100% - 200px);
      height: 180px;
      flex-direction: column;
      //justify-content: space-between;
      margin-left: 20px;
      margin-top: 0;
      .datatop{
        align-items: flex-start;
        h2.name{
          font-size: 32px;
          line-height: 38px;
          margin: 0;
        }
        h3.title{
          font-size: 19px;
          margin: 0;
        }
      }
      .databottom{
        margin-top: 20px;
        .line{
          h2.label{
            font-size: 16px;
            margin: 0;
          }
          p.value{
            font-size: 16px;
            margin: 0;
            margin-left: 6px;
          }
        }
      }
    }
  }
  @media ${breakpoints.lg} {
    //width: 400px;
    flex-direction: row;
    width: calc(100% - 161px);
    align-items: flex-start;
    .thumb{
      display: flex;
      width: 180px;
      height: 220px;
      border: 0;
    }
    .data{
      display: flex;
      width: calc(100% - 200px);
      height: 180px;
      flex-direction: column;
      //justify-content: space-between;
      margin-left: 20px;
      margin-top: 0;
      .datatop{
        align-items: flex-start;
        h2.name{
          font-size: 32px;
          line-height: 38px;
          margin: 0;
        }
        h3.title{
          font-size: 19px;
          margin: 0;
        }
      }
      .databottom{
        margin-top: 20px;
        .line{
          h2.label{
            font-size: 16px;
            margin: 0;
          }
          p.value{
            font-size: 16px;
            margin: 0;
            margin-left: 6px;
          }
        }
      }
    }
  }
  @media ${breakpoints.xl} {
    width: calc(100% - 410px);
  }
  @media ${breakpoints.xxl} {
    width: calc(100% - 430px);
  }
  @media ${breakpoints.xxxl} {
    width: calc(100% - 610px);
  }
  @media ${breakpoints.xxxxl} {}
`
const Data = styled.div`
  
`
const LineDivider = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin-top: 4vw;
  margin-bottom: 4vw;
  /*
  margin-right: 30px;
  margin-left: 30px;
  */
  background-color: ${COLORS.lightgrey};

  @media ${breakpoints.xs} {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media ${breakpoints.sm} {
    
  }
  @media ${breakpoints.md} {
    
  }
  @media ${breakpoints.lg} {
    
  }
  @media ${breakpoints.xl} {
    width: 1px;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media ${breakpoints.xxl} {
    
  }
  @media ${breakpoints.xxxl} {
    
  }
  @media ${breakpoints.xxxxl} {
    
  }

  @media ${breakpoints.lg} {
    
  }

`
const PartiesStyled = styled.div`

  .loader{
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    svg{
      color: ${COLORS.lightgold}
    }
  }
  
  

  @media ${breakpoints.xs} {
      //min-width: 576px
  }
  @media ${breakpoints.sm} {
      //min-width: 768px
  }
  @media ${breakpoints.md} {
      //min-width: 992px
  }
  @media ${breakpoints.lg} {
      //min-width: 1200px
      //grid-template-columns: repeat(2, 1fr);
      //grid-template-rows: repeat(2, 1fr);
      
      
      //grid-template-columns: 1fr 1fr; 
      //grid-template-rows: min-content min-content; 
      
      
      //background-color: violet;
      //width: 420px;
  }
  @media ${breakpoints.xl} {
      //min-width: 1366px
      //grid-template-columns: repeat(3, 1fr);
      //grid-template-rows: repeat(3, 1fr);
      
      
      //grid-template-columns: 1fr 1fr 1fr; 
      //grid-template-rows: min-content min-content min-content; 
      
      
      //background-color: violet;
      //width: 550px;
  }
  @media ${breakpoints.xxl} {
      //min-width: 1440px
      //background-color: violet;
      //width: 610px;
  }
  @media ${breakpoints.xxxl} {
      //min-width: 1680px
      //background-color: violet;
      //width: 780px;
      width: 100%;
  }
  @media ${breakpoints.xxxxl} {
      //min-width: 1920px
  }
`
const AssetsStyled = styled.ul`

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  margin: 0;
  padding: 0;

  >.loader{
    height: 42px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    svg{
      color: ${COLORS.lightgold}
    }
  }
  .parties_distribution_list{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    align-self: flex-start;
  }
  @media ${breakpoints.xs} {}
  @media ${breakpoints.sm} {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  @media ${breakpoints.md} {
    grid-template-columns: repeat(3, 1fr);
  }
  @media ${breakpoints.lg} {}
  @media ${breakpoints.xl} {}
  @media ${breakpoints.xxl} {}
  @media ${breakpoints.xxxl} {
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${breakpoints.xxxxl} {
    grid-template-columns: repeat(5, 1fr);
  }
`
const AssetStyled = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  min-height: 111px;
  border: 1px solid ${COLORS.whiteFour};
  padding: 10px;
  border-radius: 10px;
  list-style: none;
  background-color: #FFFFFF;
  .is_prorated_chip{
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 0px;
      top: 0px;
      border-radius: 8px;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 25px;
      height: 25px;
      background-color: ${COLORS.whiteFour};
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      color: ${COLORS.bluegrey};
      margin-left: 10px;
      cursor: default;
  }
  .bt_distribute_asset{
    width: 100%;
  }
  .loader{
    svg{
      color: ${COLORS.lightgold};
    }
  }
  .prorated{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.lightgold};
    border-radius: 6px;
    width: 100%;
    height: 42px;
    p{
      width: 100%;
      font-size: 16px;
      text-align: center;
      font-weight: 600;
      color: #FFFFFF;
      margin: 0;
    }
  }
  .asset_data{
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    width: 100%;
    .texts{
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      width: calc(100% - 70px);
      .title{
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
        color: ${COLORS.darkgrey};
        margin-bottom: 0;
      }
      .category{
        font-size: 13px;
        color: ${COLORS.lightgold};
      }
    }
  }
  :first-child{
    margin-left: 0;
  }
  .thumb{
    width: 60px;
    height: 60px;
  }
`

const AssignedAssetsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .elements{
    width: 100%;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: min-content min-content min-content; 
    gap: 10px 10px; 

    margin: 0;
    padding: 0;
    .element{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #FFFFFF;
      border-radius: 16px;
      min-height: 100px;
      padding: 10px;
      border: 1px solid ${COLORS.whiteFour};
      .box_image{
        display: flex;
        flex-direction: row;
        justify-content: center;
        .image{
          width: 110px;
          height: 110px;
          background-color: skyblue;
        }
      }
      .box_info{
        display: flex;
        flex-direction: column;
        justify-self: flex-start;
        width: calc(100% - 240px);
        margin-left: 10px;
        margin-right: auto;
        .title{
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 5px;
          color: ${COLORS.darkgrey};
        }
        .category{
          font-size: 13px;
          font-weight: 600;
          color: ${COLORS.lightgold};
        }
      }
      .box_portion{
        width: 110px;
        margin-left: 10px;
      }
    }
  }
`



const DashboardContainer = (props) => {

  const { t } = useTranslation();
  const history = useHistory();

  const { userData, 
          isTheVaultOpen,
          setOverlayVaultOpen} = useContext(AuthContext);

  const { parties,
          assets,
          getAssetDistribution,
          getAssignedAsset,
          assignAssetByPartyCode } = useContext(ContentContext);
  
  const [ assetDistributed, setAssetDistributed ] = useState(null);
  const [ dashboardParties, setDashboardParties ] = useState(null);
  const [ assetsAssigned, setAssetsAssigned ] = useState(null);
  const [ errorAssignedAsset, setErrorAssignedAsset ] = useState(null);
  const [ loaderAssignedAsset, setLoaderAssignedAsset ] = useState(false);
  const [ showImage, setShowImage ] = useState(null);
  const [ noImage, setNoImage ] = useState(null);
  
 
  useEffect(() => {
    
    if(assets){
      const assetDistributedArr = [];
      const beneficiaries = getAssetDistribution();
      beneficiaries.then((res) => {
        res && assets.map((asset, index) => {
          let exist = res.data.filter(el => el.asset.id == asset.id);
          if(exist.length > 0){
            assetDistributedArr.push({asset_id: asset.id, parties: exist})
          }
        })
        setAssetDistributed(assetDistributedArr);
      })
    }
  }, [assets])

  useEffect(() => {
    if(userData){
      const response = getAssignedAsset(userData.id);
      response.then((res) => {
        setAssetsAssigned(res.data);
      })
    }
    return () => {
      toast.dismiss();
    }
  }, [userData])

  const handleAddNewAsset = () => {
    if(!isTheVaultOpen){
      history.push('/my-assets/add');
    }else{
      setOverlayVaultOpen(true);
    }
  }

  const handleShowImage = (image, noimage) => {

    setNoImage(noimage)
    setShowImage(image);

  }


  return (
    <Wrapper>
      
      {showImage &&
        <OverlayImage image={showImage} setShowImage={setShowImage} noImage={noImage} setNoImage={setNoImage} />
      }

      <Container>
        
        <BoxContainer className="box_data">
          <div className="inner">
            <UserData className="user_data">
                
              <ThumbBox className="thumb" css={{width: '160', height: '220', border: {withBorder: false, color: "#D0B257"}}}>
                <ImageProfile profile_image={userData.profile_image} gender={userData.account.gender} />
                {userData && !userData.profile_image.length &&
                    <ButtonSolid
                      style_type="tab"
                      className="bt_add_picture"
                      onClick={() => history.push('/settings/profile')}
                      override_css={null}>
                      <AiOutlineCamera />
                    </ButtonSolid>  
                }
              </ThumbBox>
                  
               
              <div className="data">
                <div className="datatop">
                  <h2 className="name">{`${userData.first_name} ${userData.middle_name} ${userData.last_name}`}</h2>
                  <h3 className="title">{t(`basics.vault_owner`)}</h3>
                </div>
                <div className="databottom">
                  
                  {userData.account.street ?
                  <div className="line">
                    <h2 className="label">{`${t("labels.address")}:`}</h2>
                    <p className="value">{`${userData.account.street ? userData.account.street + "," : "-"} ${userData.account.city ? userData.account.city + "," : "-"} ${userData.account.state_name ? userData.account.state_name + "," : "-"} ${userData.account.country_name} ${userData.account.zip ? "(" + userData.account.zip + ")" : "-"}`}</p>
                  </div> : "" }

                  {userData.account.phone_number ?
                  <div className="line">
                    <h2 className="label">{`${t("labels.mobile")}:`}</h2>
                    <p className="value">{userData.account.phone_number}</p>
                  </div> : "" }

                  {userData.email ? 
                  <div className="line">
                    <h2 className="label">{`${t("labels.email")}:`}</h2>
                    <p className="value">{userData.email}</p>
                  </div> : "" }

                  {userData.account.nationality_name ?
                  <div className="line">
                    <h2 className="label">{`${t("labels.nationality")}:`}</h2>
                    <p className="value">{userData.account.nationality_name}</p>
                  </div> : "" }
                  
                </div>
              </div>
            </UserData>

            <LineDivider />

            <CountdownData data={userData.countdown} />

          </div>
        </BoxContainer>

        <BoxContainer className="box_beneficiaries">
          <div className="box_beneficiaries_inner">
            <div className="title">{t(`dashboard.my_parties`)}</div>
            <PartiesStyled>
              {parties ? 
                parties.length ?
                  <Parties parties={parties}/>
                :
                  <ButtonSolid
                      style_type="default"
                      className="bt_add_beneficiary"
                      onClick={() => history.push("/parties/add")}
                      override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}>
                      <p>{t(`buttons.add_new_party`)}</p>
                  </ButtonSolid>
              :
              <div className="loader">
                <CircularProgress size={30} />
              </div>
              }
              
            </PartiesStyled>
          </div>
        </BoxContainer>
        
        <BoxContainer className="box_assets">
          <div className="box_assets_inner">
            <div className="title">{t(`dashboard.my_assets`)}</div>
              
                {assets ?
                  assets.length ?
                  <AssetsStyled>
                    {assets.map((asset, i) => {
                      return (
                        <AssetStyled key={'asset' + i}>
                          {asset.is_prorated &&
                          <Tooltip title={t(`basics.assets_equaly_distributed`)} arrow placement="top" enterTouchDelay={0}>
                            <div className="is_prorated_chip">P</div>
                          </Tooltip>
                          }
                          <div className="asset_data">
                            {asset.thumb ? 
                              <ThumbBox className="thumb" css={null} onClick={() => handleShowImage(asset.image_file, false)}>
                                <Image filename={asset.thumb_file} type={"image_asset"} showMagnify={true} />
                              </ThumbBox>
                              :
                              <ThumbBox className="thumb" css={null} onClick={() => handleShowImage(asset.category.description, true)}>
                                <NoImage type={asset.category.description} showMagnify={true} />
                              </ThumbBox>
                            }
                            <div className="texts">
                              <div className="title">{asset.name}</div>
                              <div className="category">{asset.category.description}</div>
                            </div>
                          </div>
                          
                          {
                            assetDistributed ? 
                              assetDistributed.length > 0 ?
                                <div className="parties_distribution_list">
                                  <PartiesDistributions is_prorated={asset.is_prorated} asset={asset} assetDistributed={assetDistributed.filter(el => el.asset_id == asset.id)} />
                                </div>
                              :
                              <ButtonSolid
                                style_type="tab"
                                className="bt_distribute_asset"
                                onClick={() => history.push('/my-will/'+asset.id)}
                                override_css={null}>
                                <p>{t(`buttons.assign_to`)}</p>
                              </ButtonSolid>
                            :
                            <div className="loader">
                              <CircularProgress size={30} />
                            </div>
                          }
                        </AssetStyled>
                      )})
                    }
                    </AssetsStyled>
                :
                  <ButtonSolid
                    style_type="default"
                    className="bt_add_asset"
                    onClick={handleAddNewAsset}
                    override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}>
                    <p>{t(`buttons.add_new_asset`)}</p>
                  </ButtonSolid>
                :
                  <div className="loader">
                    <CircularProgress size={30} />
                  </div>
                }
              
          </div>
        </BoxContainer>
      </Container>

    </Wrapper>
  )
}

export default DashboardContainer
