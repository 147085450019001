import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';

const Wrapper = styled.div`

    display: flex;
    
    background-color: #FFFFFF;
    width: 200px;
    height: 66px;
    border-radius: 10px;
    //margin-top: 5px;
    //margin-bottom: 5px;
    box-sizing: border-box;
    padding: 10px 10px 10px 10px;
    border: 1px solid #FFFFFF;
    cursor: pointer;

    

    :hover{
        border: 1px solid ${COLORS.cloudyblue};
    }
    .order_number{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        width: 25px;
        color: ${COLORS.darkgrey};
    }
    .data{
        display: flex;
        flex-direction: column;
        width: calc(100% - 25px);
        .name{
            //overflow: hidden;
            //white-space: nowrap;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: ${COLORS.darkgrey};
        }
        .category{
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: ${COLORS.lightgold};
            margin-top: 2px;
        }
    }
    &.selected{
        border: 1px solid ${COLORS.cloudyblue};
    }

`

const ElementAsset = (props) => {

    const { asset, assetSelected, index, handleSelectAsset} = props;
 
    return (
        <Wrapper className={`element ${assetSelected && assetSelected.id == asset.id ? 'selected' : ''}`} onClick={() => handleSelectAsset(asset)}>
            <div className="order_number">{`${(index+1) <= 9 ? '0'+(index+1) : (index+1)}.`}</div>
            <div className="data">
                <div className="name">{ asset.name }</div>
                <div className="category">{ asset.category.description }</div>
            </div>
        </Wrapper>
    )
}

export default ElementAsset
