import React, { useState, useContext, useEffect, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AiOutlineZoomIn } from "react-icons/ai";
import styled from 'styled-components';
import { ContentContext } from '../../context';

import { COLORS } from '../../helpers/constants';
import noasset from '../../assets/images/noasset.jpg';

const Wrapper = styled.div`
display:flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
position: relative;

cursor: ${props => props.showMagnify ? "pointer" : "default"};
.magnify{
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: ${COLORS.whiteTwoAlpha75};
    font-size: 20px;
}
:hover{
    .magnify{
        display: flex;
    }
}

img{
    float: left;
    height: 100%;
}
.loader{
    
    display: flex;
    /*
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    width: 150px;
    min-height: 40px;
    */
    svg{
        color: ${COLORS.lightgold};
    }
}
`

const NoImage = (props) => {

    const { type, showMagnify } = props;

    const [ filename, setFilename ] = useState("");

    useEffect(() => {
        
        switch(type){
            case "Aircraft":
                setFilename("asset_aircraft.jpg");
            break;
            case "Watercraft":
                setFilename("asset_watercraft.jpg");
            break;
            case "Motorcycle":
                setFilename("asset_motorcycle.jpg");
            break;
            case "Vehicle":
                setFilename("asset_vehicle.jpg");
            break;
            case "Land":
                setFilename("asset_land.jpg");
            break;
            case "Bank Account":
                setFilename("asset_bankaccount.jpg");
            break;
            case "Crypto Account":
                setFilename("asset_cryptoaccount.jpg");
            break;
            case "Stocks":
                setFilename("asset_stocks.jpg");
            break;
            case "Real Estate":
                setFilename("asset_realestate.jpg");
            break;
            case "Other":
                setFilename("asset_other.jpg");
            break;
            case "Life Insurance Policy":
                setFilename("asset_lifeinsurancepolicy.jpg");
            break;
            case "Inheritance Insurance":
                setFilename("asset_inheritanceinsurance.jpg");
            break;
            
        }

    }, []);
    
    return(
        <Wrapper showMagnify={showMagnify}>
            {showMagnify &&
                <div className="magnify">
                    <AiOutlineZoomIn />
                </div>
            }
            <img src={`/images/${filename}`} />
        </Wrapper>
        
    )
}

export default NoImage
