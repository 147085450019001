import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { AuthContext } from '../../../context';

import ButtonSolid from '../../../components/Elements/Buttons/Solid';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router';
import { COLORS } from '../../../helpers/constants'
/*
import createDOMPurify from 'dompurify'
import { JSDOM } from 'jsdom'

const window = (new JSDOM('')).window
const DOMPurify = createDOMPurify(window)
*/
const OverlayErrorStyled = styled.div`

    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.90);
    border: 1px solid rgba(255, 255, 255, 0.90);
    width: 100%;
    height: 100%;
    z-index: 99;
    
    .inner_overlay{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px 30px 20px;
        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
        background-color: #FFFFFF;
        .actions{
            display: flex;
            flex-direction: row;
            margin-top: 25px;
            .bt{
                margin-left: 5px;
                margin-right: 5px;
            }
            .loader{
                svg{
                    color: ${COLORS.lightgold};
                }
            }
        }
        .error_list{
            margin-top: 15px;
            width: 100%;
            >p{
                width: 100%;
                text-align: center;
                margin: 0;
                color: ${COLORS.red};
                font-weight: 500;
                span{
                    font-weight: 600;
                    color: ${COLORS.lightgold};
                }
            }
        }
        
        .title{
            font-size: 30px;
            line-height: 30px;
            width: 100%;
            text-align: center;
            color: ${COLORS.lightgold};
            font-weight: 600;
        }
        .text{
            font-size: 16px;
            line-height: 22px;
            width: 100%;
            text-align: center;
            margin-top: 10px;
            color: ${COLORS.darkgrey};
        }
    }
    
`

const OverlayErrorMessage = (props) => {

    const history = useHistory();

    const { resend_validation } = useContext(AuthContext);
    const { errorMessage, setErrorMessage, className, handleAction = null } = props;

    const [ formHaveError, setFormHaveError ] = useState(false);
    const [ errorList, setErrorList ] = useState(null);
    const [ title, setTitle ] = useState('');
    const [ text, setText ] = useState('');
    const [ type, setType ] = useState('');
    //const [ goto, setGoto ] = useState('');
    const [ actions, setActions ] = useState(null);
    const [ loader, setLoader ] = useState('');

    //const [ errorMessage, setErrorMessage ] =  useState([]);
    
    const handleCloseModalError = (action) => {
        if(!handleAction){
            switch (action.type) {
                case 'goto':
                    history.push('/'+action.goto);
                    break;
                case 'resend':
                    setLoader(true);
                    const response = resend_validation({email: action.data.email});
                    response.then((res) => {

                        if(res.data.success === true){

                            toast.success(res.data.message, {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            });
                            
                        }else{

                            toast.error(res.data.message, {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            });

                        }
                        
                        setLoader(false);
                    })
                    break;
                default:
                    setFormHaveError(false);
                    setErrorMessage({});
                    setErrorList(null);
                    break;
            }
        }else{
            setFormHaveError(false);
            setErrorMessage({});
            setErrorList(null);
            handleAction();
        }
        
    }

    useEffect(() => {

        if(Object.keys(errorMessage).length){
            console.log(errorMessage.errors);
            if(errorMessage.errors && errorMessage.errors.length){
                setErrorList(errorMessage.errors);
            }
            setTitle(errorMessage.title);
            setText(errorMessage.text);
            setActions(errorMessage.actions);
            setType(errorMessage.type);
            //setGoto(errorMessage.goto);

            console.log(errorMessage);
        }

        return () => {
            toast.dismiss();
        }
        
    },[errorMessage])

    if(!Object.keys(errorMessage).length){
        return null;
    }
    /*
    if(!errorList && type == 'close'){
        return null;
    }
    */
    return (
        <OverlayErrorStyled className={className}>
            <div className="inner_overlay">
                <div className="title">{title}</div>
                <div className="text">{text}</div>
                <div className="error_list">
                    {errorList && errorList.length && errorList.map((error, index) => {
                        return(
                            <p key={'error_list'+index}>{error}</p>
                        )
                    })}
                </div>
                <div className="actions">
                    {loader ?

                    <div className="loader">
                        <CircularProgress size={36} />
                    </div>

                    :
                    actions && actions.map((action, index) => {
                        return(
                        <ButtonSolid
                            type="submit"
                            style_type="default"
                            className="bt"
                            override_css={null}
                            onClick={() => handleCloseModalError(action)}
                        >
                            <p>{ action.text }</p>
                        </ButtonSolid>
                    )})

                    }
                </div>
            </div>
        </OverlayErrorStyled>
    )
}

export default OverlayErrorMessage;
