import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

import ButtonSolid from '../Buttons/Solid';
import PortionPill from '../PortionPill';
import Tooltip from '@mui/material/Tooltip';
import Chip from '../Chip';
import Typography from '@mui/material/Typography';
import Input from '../Form/Input';
import CustomToastMessageError from '../CustomToastMessageError';

import { toast } from 'react-toastify';



const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .chip{
        margin-left: 10px;
    }
    .bt_distribute_asset{
        width: 100%!important;
        //width: 180px!important;
    }
    .parties_el{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: auto;
    }
    .distribute_have_more{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 30px;
        height: 30px;
        background-color: #FFFFFF;
        border-color: ${COLORS.lightgold};
        margin-left: 0;
        //margin-top: 5px;
        border-radius: 30px;
        border: 2px solid ${COLORS.lightgold};
        cursor: pointer;
        .rest{
            color: ${COLORS.lightgold};
            font-weight: 700;
        }
        :hover{
            background-color: ${COLORS.lightgold};
            .rest{
                color: #FFFFFF;
            }
        }
    }
`

const PartiesDistributions = (props) => {
    
    const { t } = useTranslation();

    const { asset, assetDistributed, is_prorated } = props;
    const history = useHistory();
    
    const exist = assetDistributed.filter(el => el.asset_id == asset.id);

    useEffect(() => {
      console.log(assetDistributed);
    
      return () => {
        
      }
    }, [assetDistributed])

    const partiesNames = (parties) => {
        console.log(parties);
        let names = [];
        for(let i = 2; i < parties.length; i++){
            names.push(<div>{`${parties[i].party.first_name} ${parties[i].party.middle_name ? parties[i].party.middle_name : ''} ${parties[i].party.last_name}: ${parties[i].portion}%`}</div>);
        }
        return names;

    }
    
    /**/
    return (
        <Wrapper>
            
            {assetDistributed.length ?
                exist[0].parties.length &&
                    <div className="parties_el">
                    {exist[0].parties.map((el, index) => {
                      if(index < 2){
                        return(
                            <PortionPill tooltip_title={`${el.party.first_name} ${el.party.middle_name ? el.party.middle_name : ''} ${el.party.last_name}: ${el.portion}%`} gender={el.party.gender} is_prorated={asset.is_prorated} profile_image={el.party.profile_image} portion={el.portion} onClick={() => history.push('/my-will/'+el.asset.id)}/>
                        );
                      }
                    })}
                    {exist[0].parties.length > 2 ?
                        <Tooltip title={partiesNames(exist[0].parties)} arrow placement="top" enterTouchDelay={0}>
                            <div className="distribute_have_more" onClick={() => history.push('/my-will/'+asset.id)}>
                                <div className="rest">+{ exist[0].parties.length - 2 }</div>
                            </div>
                        </Tooltip>
                        :
                        <></>
                    }
                    </div>
            :
            <ButtonSolid
                style_type="tab"
                className="bt_distribute_asset"
                onClick={() => history.push('/my-will/'+asset.id)}
                override_css={null}>
                <p>{t(`buttons.assign_to`)}</p>
            </ButtonSolid>
            }
            
        </Wrapper>
    )
}

export default PartiesDistributions
