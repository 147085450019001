import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Parties from './pages/Parties';
import AddBeneficiary from './pages/Parties/AddBeneficiary';
import EditBeneficiary from './pages/Parties/EditBeneficiary';
import MyAssets from './pages/MyAssets';
import AddAsset from './pages/MyAssets/AddAsset';
import EditAsset from './pages/MyAssets/EditAsset';
import MyLastWill from './pages/MyLastWill';
import Settings from './pages/Settings';
import Upgrade from './pages/Upgrade';
import MyAssignedAssets from './pages/MyAssignedAssets';

const Routes = ({ theme }) => {

  return (
    <Switch>
      <Route path="/dashboard" component={Dashboard} exact />
      <Route path="/assigned-assets" component={MyAssignedAssets} exact />
      <Route path="/parties" component={Parties} exact />
      <Route path="/parties/add" component={AddBeneficiary} exact />
      <Route path="/parties/edit/:id" component={EditBeneficiary} exact />
      <Route path="/my-assets" component={MyAssets} exact/>
      <Route path="/my-assets/add" component={AddAsset} exact/>
      <Route path="/my-assets/edit/:id" component={EditAsset} exact />
      <Route path="/my-will/:id?" component={MyLastWill} exact />
      <Route path="/settings" component={Settings} exact />
      <Route path="/settings/:section" component={Settings} exact />
      <Route path="/upgrade" component={Upgrade} exact />
      <Route path="/" exact>
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  );
};

export default Routes;

