import React, { useState,
                useCallback,
                useContext,
                useEffect } from 'react';

import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import { breakpoints } from '../../../helpers/breakpoints';
import CloseIcon from '@material-ui/icons/Close';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';



const Modal = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
    .content{
        position: relative;
        display: flex;
        width: 600px;
        height: 300px;
        background: ${COLORS.whiteTwo};
        border-radius: 16px;
        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.5);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.5);
        .bt_close{
            position: absolute;
            right: 13px;
            top: 13px;
            font-size: 40px;
        }
    }
`;

const ModalStyled = styled(Modal)`
/*
    display: 'flex';
    width: 100%;
    align-items: 'center';
    justify-content: 'center';

    >&.MuiPopover-root{
        display: none!important;
    }

    .content{
        display: flex;
        width: 500px;
    }
    */
    /*
    display: flex;
    //flex: 1;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 17px;
    padding-bottom: 17px;
    flex: 1;
    flex-direction: row;
    */
`

const InnerContainer = styled.div`
    /*
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .content{
        border-radius: 16px;
        width: 600px;
        height: 300px;
        background-color: ${COLORS.whiteTwo};

        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.5);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.5);
    }
    */
`


const ModalBase = (props) => {


    /*
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    */

    //const [open, setOpen] = React.useState(false);

    const { open, setOpen, content } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (

        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >

<DialogTitle id="responsive-dialog-title">
                {"Terms and Conditions"}
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque suscipit, justo vitae suscipit aliquam, nunc dolor tempus tellus, at interdum sem tellus sit amet ipsum. Nulla sollicitudin metus ac eros vehicula, in egestas urna elementum. Praesent iaculis dolor aliquam, efficitur ligula et, pretium eros. Nunc nec neque dapibus, laoreet quam eget, posuere magna. Aliquam quis leo purus. Nullam facilisis imperdiet maximus. Curabitur commodo sed turpis eget tempus. Nunc lorem tortor, fermentum a justo in, volutpat scelerisque neque. Cras felis dui, dignissim vitae tincidunt vitae, sodales sed justo. Mauris ante lacus, lacinia non sapien eu, iaculis fermentum purus. Vestibulum ultrices maximus nisi, sed rhoncus nibh egestas at. Duis sed dolor ligula. Donec et pulvinar ipsum. Nam ullamcorper felis eu placerat venenatis.</p>

                    <p>Mauris ac rhoncus metus. Integer vestibulum elementum massa vitae lacinia. Vestibulum euismod ex eu nisl viverra, sed ullamcorper leo tincidunt. Maecenas ac mollis dui, sed vulputate dui. Ut facilisis et sapien id mollis. Ut sed faucibus sem. Nunc at felis sit amet justo consectetur malesuada in ac nunc.</p>

                    <p>Vivamus ex nisi, sodales a metus a, dignissim laoreet purus. Cras hendrerit tortor sit amet enim posuere bibendum. Nulla in laoreet neque. Vestibulum venenatis ex at sollicitudin suscipit. Nulla nulla dolor, convallis eget efficitur sit amet, luctus ac risus. Aenean nisl orci, vulputate vel lacus a, facilisis venenatis erat. Nullam quis tincidunt odio. Ut ac turpis cursus, volutpat eros vel, lacinia nulla. In sed vestibulum velit. Nulla non arcu tempus, consectetur lorem eu, imperdiet justo. Quisque euismod enim in sapien finibus dignissim eu nec quam. Quisque ac tortor euismod, ornare mauris eget, dignissim nisl. Nulla in arcu ac libero bibendum elementum id sed nisl. Mauris sit amet neque sed velit cursus fringilla.</p>

                    <p>Sed pellentesque risus id pulvinar auctor. Integer tincidunt lorem massa, quis consectetur justo varius congue. Morbi a porta tellus. Suspendisse congue ante in iaculis tempor. Maecenas sed leo eget est fermentum porttitor. Maecenas tincidunt nisi et diam euismod finibus. Quisque eget mauris quis metus placerat placerat at quis mauris. Nulla luctus lectus at luctus lobortis. Curabitur commodo id massa pulvinar hendrerit. Nam et velit in metus tempus sodales eu in justo. Phasellus eget congue mauris. Proin scelerisque, lacus quis ultricies mollis, quam nisl viverra odio, vel bibendum eros nulla sed leo. Proin non ornare nulla, nec mattis mi. Vestibulum at turpis sem.</p>

                    <p>Duis aliquet, erat et vulputate mattis, nibh tellus consequat erat, ac egestas ipsum lorem at lectus. Duis egestas risus sed ante varius, et viverra lacus faucibus. Curabitur egestas viverra mauris non convallis. Quisque mollis mollis porta. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc nec mattis massa, at bibendum eros. Donec convallis feugiat vehicula. Donec odio sapien, vestibulum ut nibh ut, accumsan gravida tellus. Suspendisse quis erat eu nisi egestas mollis vel nec risus. Donec quis varius tellus. Donec at tortor dignissim, iaculis risus sed, fringilla mi. Proin hendrerit et dolor ac porttitor.</p>

                    <p>Ut vestibulum est sem, vitae pulvinar dui semper in. Pellentesque a nunc leo. Pellentesque odio massa, sagittis sed mattis in, dignissim quis tellus. Donec porta molestie erat, at sodales velit rutrum volutpat. In non nisi blandit, facilisis massa sed, efficitur odio. Phasellus viverra, massa ac facilisis euismod, arcu erat sagittis metus, at congue odio turpis eget nunc. In posuere metus in dictum posuere. Maecenas lobortis, lacus lacinia varius tempus, turpis urna mollis libero, non facilisis ex elit id quam. Etiam ut dui id augue luctus porttitor nec eu elit. Aenean id tempus eros. Duis metus ante, efficitur et efficitur eget, ultricies ut sapien. Donec lobortis, urna ac sagittis malesuada, sapien nibh ultricies nulla, vel porttitor felis nibh sed diam. Sed elementum metus ac felis pulvinar, at iaculis risus pretium. Nulla ac metus ultricies, dignissim leo sit amet, consectetur est. Maecenas erat nibh, consectetur quis convallis vel, iaculis ut ante. Proin non augue ut massa molestie consequat nec eget nisi.</p>

                    <p>Morbi euismod consequat enim non malesuada. Donec at justo in sapien euismod tincidunt et molestie velit. Curabitur varius orci accumsan, tincidunt orci a, sodales arcu. Nullam nec laoreet purus, eu efficitur magna. Nullam sodales iaculis nisl id lobortis. Aliquam posuere urna elit, vitae finibus nibh iaculis nec. Mauris aliquam tortor eros, ut placerat odio iaculis quis. Aliquam vitae nisi ut mi congue ultrices. Vivamus sed arcu ante. Praesent in neque dui. Integer ornare vitae diam eget euismod. Morbi tempor neque in justo pulvinar, a consectetur nulla auctor. Nulla facilisi. Pellentesque non finibus ante. Donec varius, risus vel finibus tincidunt, mi est ullamcorper massa, nec aliquam tellus libero ac urna.</p>

                    <p>Sed dui elit, gravida sed efficitur ac, blandit a leo. Phasellus id pharetra nisi, et congue ipsum. Aliquam dapibus, dui sit amet iaculis finibus, sapien mauris efficitur velit, nec ornare nisl quam nec ipsum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Phasellus placerat est id ipsum lacinia congue. Sed et dictum massa. Fusce id arcu ac turpis mollis facilisis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Mauris eget dignissim justo, ac scelerisque velit. Nullam vulputate vehicula dolor vel rutrum. Sed viverra, ante eu venenatis porttitor, arcu augue pretium augue, id imperdiet augue dui id tellus. Etiam pulvinar ac purus eu laoreet. Nunc posuere, justo a interdum pharetra, sapien nibh interdum lectus, at lacinia risus velit id leo. Fusce viverra vulputate enim, eu posuere nunc bibendum at. Praesent ac mauris est.</p>

                    <p>Donec quis fermentum quam. Proin suscipit aliquam massa, vel ultrices quam ornare non. Sed et ligula ligula. Aliquam viverra massa ut felis congue, auctor blandit quam scelerisque. Mauris finibus porta dolor vitae tincidunt. Nullam in velit metus. Nullam tempus urna eget diam lobortis, quis blandit turpis lobortis. Vestibulum luctus dui rhoncus mauris iaculis, at mollis metus consectetur. Vivamus sed odio at felis condimentum sodales. Sed egestas a nisl blandit vulputate. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                    <p>Cras venenatis ornare est a molestie. Nulla augue quam, tristique nec lobortis suscipit, porttitor sit amet dolor. Sed egestas scelerisque tellus, rhoncus aliquam est pharetra vitae. Vestibulum vehicula, mauris ut ultrices elementum, mi felis consectetur mi, sit amet pretium arcu sem eu justo. Integer at varius dolor, a vestibulum magna. Proin ut ultricies massa. Pellentesque placerat tincidunt risus, quis posuere neque pulvinar et. Phasellus ac risus at nibh congue efficitur sed in tellus. Nunc vel nulla suscipit, lacinia mi eget, sagittis metus. Nam consequat nulla nec ante sodales, sit amet cursus augue vehicula. Nullam magna quam, blandit fermentum aliquam ac, maximus nec turpis. In convallis diam enim, sed ullamcorper ipsum scelerisque bibendum. Curabitur ultrices, tortor vitae efficitur pretium, dui eros viverra tortor, non consectetur lacus justo eget nisi. Vestibulum dignissim, tortor non tempor dignissim, quam dolor imperdiet erat, nec viverra nisl quam ut purus. Fusce a facilisis velit. Morbi id consequat est, eu ultrices odio.</p>
                </DialogContentText>
            </DialogContent>

                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Disagree
                    </Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
                
            </Dialog>
        </div>

    )
}

export default ModalBase
