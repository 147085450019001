import React from 'react';
import styled from 'styled-components';
import ThumbBox from '../../Elements/ThumbBox';
import { COLORS } from '../../../helpers/constants';
import { breakpoints } from '../../../helpers/breakpoints';

import Image from '../../../components/Elements/Image';
import NoImage from '../../../components/Elements/NoImage';

const Wrapper = styled.div`

    &.dashboard{
        display: flex;
        flex-direction: row;
        .thumb{
            width: 50px;
            border-radius: 100%;
            overflow: hidden;
            img{
                float: left;
                width: 100%;
            }
        }
    }
    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
    @media ${breakpoints.lg} {
        
    }
`



const AssetsList = (props) => {

    const { assets } = props;
    
    return (
        <Wrapper className={"dashboard"}>
            {
                assets != null ?
                assets.map((asset, index) => 
                    <div className="beneficiary_asset" key={`asset ${index}`}>
                        <div className="thumb">
                            {asset.data.image ? 
                                <Image filename={asset.data.image_file} type={"image_asset"} />
                            :
                                <NoImage type={asset.data.category.description} />
                            }
                        </div>
                        <div className="data">
                            <div className="name">
                                {asset.data.name}
                            </div>
                            <div className="portion">
                                {!asset.data.is_prorated ? asset.portion + '%' : 'Prorated'}
                            </div>
                        </div>
                    </div>
                ) 
                :
                <></>
            }
        </Wrapper>
    )
}

export default AssetsList
