import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { COLORS,
         SIZES } from '../../../helpers/constants';

import { useTranslation } from 'react-i18next';

import { getErrorMessage } from '../../../helpers/functions';

import Input from '../../Elements/Form/Input';
import ButtonSolid from '../../Elements/Buttons/Solid';
import OverlayErrorMessage from '../../Elements/Form/OverlayErrorMessage';
import { ContentContext } from '../../../context';
import CustomToastMessageError from '../../../components/Elements/CustomToastMessageError';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    >.title{
        font-size: 19px;
        margin-bottom: 20px;
    }
    .actions{
        margin-top: 20px;
        .loader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 142px;
            height: 40px;
            svg{
                color: ${COLORS.lightgold};
            }
        }
    }
`
const Fields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    
`
const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .aclaration{
        font-size: 12px;
        margin-top: 3px;
        color: ${COLORS.bluegrey};
    }
    .current_password{
        margin-bottom: 10px;
    }
    .new_password{
        margin-bottom: 10px;
    }
    .repeat_new_password{
    }
`

const ChangePassword = (props) => {

    const { t } = useTranslation();
    
    const { setErrorMessage } = props;
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const { change_password } = useContext(ContentContext);

    let inputRef = useRef({});

    useEffect(() => {
          
        setFormData({
            current_password: { value: "", required: true, disabled: false, ref: "current_password", error: false },
            new_password: { value: "", required: true, disabled: false, ref: "new_password", error: false },
            repeat_new_password: { value: "", required: true, disabled: false, ref: "repeat_new_password", error: false },
        });

        return () => {
            toast.dismiss();
        }
        
    }, [])

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
        
    }

    const handleSubmit = (e) => {
        
        e.preventDefault();
        setLoading(true);

        let arrErrorMessage = [];
        const haveError = checkErrorForm();

        if(!haveError){
            
            const payload = { current_password: formData.current_password.value,
                              new_password: formData.new_password.value,
                              repeat_new_password: formData.repeat_new_password.value };

            const response = change_password(payload);

            response.then((res) => {
                
                setLoading(false);

                if(!res.data.success){
                    console.log(res);
                    if(res.data.errors){
                        /*
                        res.data.errors.map((err) => {
                            arrErrorMessage.push('- '+err);
                        })
                        */

                        Object.keys(res.data.errors).map(function(key) {
                            res.data.errors[key].map((errorText) => {
                                arrErrorMessage.push('- '+errorText);
                            })
                        })

                        setErrorMessage({title: t(`basics.response_title_error`),
                                         text: t(`alerts.response_error`),
                                         errors: arrErrorMessage,
                                         actions: [{type: 'close', text: 'Close', goto: null}]});

                    }
                }else{

                    resetForm();

                    const errors = [];
                    const message = 'Your password has been changed';
                    const responseType = 'success';

                    toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });

                }
                
            })
            
        }else{
            setLoading(false);
        }
    }

    const checkErrorForm = () => {

        let haveError = false;
        let arrErrorMessage = [];
        let updateFormData = {...formData};
        let msg = "";

        if(Object.keys(updateFormData).length){

            Object.keys(updateFormData).map(function(key) {

                if(updateFormData[key].required){

                    if(!updateFormData[key].value.length){

                        haveError = true;

                        updateFormData[key].error = true;
                        msg = getErrorMessage(updateFormData[key].ref, 'mandatory');
                        arrErrorMessage.push('- '+msg);

                    }else{
                        
                        updateFormData[key].error = false;

                    }
                }
            })

            if(updateFormData["new_password"].value != updateFormData["repeat_new_password"].value){

                haveError = true;
                updateFormData["new_password"].error = true;
                updateFormData["repeat_new_password"].error = true;

                arrErrorMessage.push('- Your two passwords are not matching.');

            }else{

                //updateFormData["new_password"].error = false;
                //updateFormData["repeat_new_password"].error = false;

            }

            if(haveError){
                setErrorMessage({title: t(`basics.response_title_error`),
                                 text: t(`alerts.response_error`),
                                 errors: arrErrorMessage,
                                 actions: [{type: 'close', text: 'Close', goto: null}]});

            }

            setFormData(updateFormData);
            return haveError;

        }

    }

    const resetForm = () => {
        
        let updateFormData = {...formData};
        Object.keys(inputRef.current).map(function(key) {
            if(inputRef.current[key]){
                inputRef.current[key].value = '';
                inputRef.current[key].error = false;
            }
        })
        Object.keys(updateFormData).map(function(key) {
            updateFormData[key].value = '';
            updateFormData[key].error = false;
        })
        setFormData(updateFormData);

    }
    

    return (
        <Wrapper>

            <div className="title">Change Password</div>
            <Fields>
                <Field className="field">
                    <Input
                        className={`current_password ${formData?.current_password?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="password"
                        label="Current Password"
                        inputRef={inputRef}
                        elName={formData?.current_password?.ref}
                        value={formData?.current_password?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>
                <Field className="field">
                    <Input
                        className={`new_password ${formData?.new_password?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="password"
                        label="New Password"
                        inputRef={inputRef}
                        helper={t(`basics.password_rule`)}
                        elName={formData?.new_password?.ref}
                        value={formData?.new_password?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>
                <Field className="field">
                    <Input
                        className={`repeat_new_password ${formData?.repeat_new_password?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="password"
                        label="Repeat New Password"
                        inputRef={inputRef}
                        helper={t(`basics.password_rule`)}
                        elName={formData?.repeat_new_password?.ref}
                        value={formData?.repeat_new_password?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>
            </Fields>
            <div className="actions">
                {loading ==! null && loading == true ? 
                    <div className="loader">
                        <CircularProgress size={33} />
                    </div>
                :
                <ButtonSolid
                    type="submit"
                    style_type="default"
                    className="bt_save"
                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                    onClick={(e) => handleSubmit(e)}
                >
                    <p>Change</p>
                </ButtonSolid>
                }
            </div>
                
        </Wrapper>
    )
}

export default ChangePassword;
