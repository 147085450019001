import { useContext, useState, useCallback, Fragment } from 'react';
import styled from 'styled-components';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { SingleFileUploadWithProgress } from './SingleFileUploadWithProgress';
import { ContentContext } from '../../context';
/*
export interface MultipleFileUploadFieldProps{
  assetId: string;
  folder: string;
}

export interface UploadableFile {
    file: File;
  errors: FileError[];
}
*/

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .box_drag{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ccc;
    padding: 20px 20px 20px 20px;
  }
`

const FileElements = styled.div`
    width: 100%;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: min-content; 
    gap: 10px 10px; 
    margin-top: 20px;
`

export function MultipleFileUploadField(props) {

    const { assetId, folder, /*onUpload,*/ onDelete } = props;
    const [files, setFiles] = useState([]);
    const [ listFiles, setListFiles ] = useState([]);
    const { deleteFile, assets, setAssets } = useContext(ContentContext);

    const onDrop = useCallback((accFiles, rejFiles) => {
      console.log("onDrop");
      console.log(accFiles);
      const mappedAcc = accFiles.map((file) => ({ file, errors: [] }));

      console.log(mappedAcc);
      /*
      console.log("existe - - -- - - - existtt")
      accFiles.map((accFile) => {
        
        const existe = files.filter(f => f == accFile);
        console.log(existe);
      });
      console.log("existe - - -- - - - existtt")
      */
      setFiles((curr) => [...curr, ...mappedAcc, ...rejFiles]);
    }, [])
      
    const {getRootProps, getInputProps} = useDropzone({onDrop, accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    }})
    
    /*
    const onDelete_ = (file) => {

      onDelete(file);
      return null;
      console.log(file);
      console.log(files)
      setFiles(curr => curr.filter((fw) => fw.file !== file));
    }
    */

    const onDeleteFile = (file) => {
        
      //alert("lala")
      console.log("+++++++++")
      console.log(files);
      console.log(file);
      console.log("+++++++++")
      //setFiles(prev => prev.filter((f) => f.id !== file.id));
      return null;
      
      const payload = {asset_file_id: file.id,
                       asset_id: file.asset_id,
                       image_folder: 'assets'}
                       console.log(payload);
      const result = deleteFile(payload, file, 'assets');
      result.then((res) => {

        
          setFiles(prev => prev.filter((f) => f.id !== file.id));

      })
      
      
    }

    const onUpload = (file_uploaded) => {
      console.log("file_uploaded --------------------------------")

      const update_asset = [...assets];
      const updated_asset = update_asset.map((a) => {
          if(a.id == assetId){
              a.files.push(file_uploaded.data);
          }
          return a;
      })

      setAssets(updated_asset);
      
      console.log("file_uploaded --------------------------------")
  }
    
    

    return (
      <Wrapper className="wrapper">
        <div {...getRootProps()} className="box_drag">
          <input {...getInputProps()}  />
          <p>Drag 'n' drop some files here, or click to select files</p>
          
          
          

        </div>
        {files.length ?
          <FileElements>
            {files.map((fileWrapper, index) => (
              <SingleFileUploadWithProgress key={index} files={files} file={fileWrapper.file} onUpload={onUpload} onDelete={onDeleteFile} assetId={assetId} folder={folder}/>
            ))}
          </FileElements>
        : <></>
        }
      </Wrapper>
    )

}