import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { validateEmail,
         getErrorMessage } from '../../helpers/functions';

import ButtonSolid from '../Elements/Buttons/Solid';
import OverlayErrorMessage from '../Elements/Form/OverlayErrorMessage';
import Input from '../Elements/Form/Input';
import { useTranslation, Trans } from 'react-i18next';

import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthContext } from '../../context';
import { COLORS } from '../../helpers/constants';
import { breakpoints } from '../../helpers/breakpoints';
import CustomToastMessageError from '../../components/Elements/CustomToastMessageError';
import { toast } from 'react-toastify';
/*
import createDOMPurify from 'dompurify'
import { JSDOM } from 'jsdom'

const window = (new JSDOM('')).window
const DOMPurify = createDOMPurify(window)
*/
const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;

    
    .inner_2fa{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 3vw;
        padding-bottom: 3vw;
        border: 1px solid ${COLORS.lightgold};
        border-radius: 6px;
        >.fields{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            
            width: calc(100% - 6vw);
            box-sizing: border-box;
            >.field{
                box-sizing: border-box;
                width: calc((100% / 6) - 2vw)!important;
                margin: 0;
                input{
                    width: calc(100% - 2px);
                    height: 12vw;
                    border-radius: 6px;
                    padding: 0!important;
                    font-size: 7vw;
                    font-weight: 700;
                    text-align: center;
                    outline: none;

                    color: rgb(120, 132, 165);
                    border: 1px solid rgb(245, 245, 245);
                    background-color: rgb(245, 245, 245);
                    &.error{
                        border-color: red;
                    }
                }
            }
        }
    }
    .actions{
        width: 100%;
        .donthave{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            p{
                margin: 0;
                padding: 0;
            }
            .bt_resend{
                margin-left: 10px;
            }
            .loader_twocode{
                display: flex;
                margin-left: 10px;
                svg{
                    color: ${COLORS.lightgold};
                }
            }
        }
    }
    @media ${breakpoints.xs} {
        .inner_2fa{
            width: 100%;
            padding-top: 15px;
            padding-bottom: 15px;
            >.fields{
                width: calc(100% - 40px);
                >.field{
                    box-sizing: border-box;
                    width: calc((100% / 6) - 20px)!important;
                    input{
                        width: calc(100% - 2px);
                        height: 65px;
                        font-size: 35px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`

const TwoFaMailCodeContainer = (props) => {

    const { t } = useTranslation();
    
    const { email, 
            password } = props;

    const { login,
            setTwofaMailCodeActive } = useContext(AuthContext);
    
    const history = useHistory();
    
    //const [ twofaMailCode, setTwofaMailCode ] = useState("");
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(null);
    const [ isWaiting, setIsWaiting ] = useState(null);
    const [ formHaveError, setFormHaveError ] = useState(false);
    const [ errorMessage, setErrorMessage ] =  useState({});
    const [ successForgot, setSuccessForgot ] = useState(false);
    const [ loadingResend, setLoadingResend ] = useState(false);

    const [ twoFaMailCodes, setTwoFaMailCodes ] = useState({code1: "",
                                                            code2: "", 
                                                            code3: "", 
                                                            code4: "", 
                                                            code5: "", 
                                                            code6: ""});

    const [ twoFaMailCodesError, setTwoFaMailCodesError ] = useState({code1: false, 
                                                                      code2: false, 
                                                                      code3: false, 
                                                                      code4: false, 
                                                                      code5: false, 
                                                                      code6: false});
    
    let inputRef = useRef({});

    useEffect(() => {

        setFormData({
            email: { value: "", required: true, disabled: false, ref: "email", error: false },
        });

        return () => {
            toast.dismiss();
        }
        
    }, [])

    const handleFormElementChange = (name, value) => {
        /*
        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        */
        return null;
        
    }

    const handleResendCode = () => {
        setLoadingResend(true);
        
        const response = login(email, password);
        response.then((res) => {
            console.log(res.data);
            if(res.success){
                
                let message = t(`basics.twocode_resend`);

                toast(<CustomToastMessageError type={'success'} errors={[]} message={message}/>, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

                //setTwofaMailCodeActive(null);

            }else{

                // let arrErrorMessage = [];
                // arrErrorMessage.push('- ' + res.message);
                // setErrorMessage({title: t(`basics.response_title_error`),
                //                  text: t(`alerts.response_error`),
                //                  errors: arrErrorMessage,
                //                  actions: [{type: 'close', text: 'Close', goto: null}]});

                // setFormHaveError(true);

            }
            setLoadingResend(false);
        })
    }

    const handleSubmit = () => {

        setLoading(true);

        let twofaMailCode = twoFaMailCodes.code1 + twoFaMailCodes.code2 + twoFaMailCodes.code3 + twoFaMailCodes.code4 + twoFaMailCodes.code5 + twoFaMailCodes.code6;
        
        const response = login(email, password, twofaMailCode);
        response.then((res) => {
            console.log(res.data);
            if(res.success){
                
                setTwofaMailCodeActive(null);

            }else{

                let arrErrorMessage = [];
                arrErrorMessage.push('- ' + res.message);
                setErrorMessage({title: t(`basics.response_title_error`),
                                 text: t(`alerts.response_error`),
                                 errors: arrErrorMessage,
                                 actions: [{type: 'close', text: 'Close', goto: null}]});

                setFormHaveError(true);

            }
            setLoading(false);
        })
        
    }


    const handleOnChangeAuthCode = (e) => {

        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
    
        let fieldIntIndex = parseInt(fieldIndex, 10);

        let updateTwoFaMailCodes = {...twoFaMailCodes};
        updateTwoFaMailCodes["code"+fieldIntIndex] = value;
        setTwoFaMailCodes(updateTwoFaMailCodes);

        const nextfield = document.querySelector(
          `input[name=code-${fieldIntIndex + 1}]`
        );
    
        if (nextfield !== null) {
          nextfield.focus();
        }

        
        let updateTwoFaMailCodesError = {...twoFaMailCodesError};
        if(value.length > 0){
            updateTwoFaMailCodesError["code"+fieldIntIndex] = false;
        }else{
            updateTwoFaMailCodesError["code"+fieldIntIndex] = true;
        }
        setTwoFaMailCodesError(updateTwoFaMailCodesError);
        

      }

      const handleSubmitMailCode = () => {

        const haveError = checkErrors();

        if(!haveError){

            handleSubmit();

        }else{

            let arrErrorMessage = [];
            arrErrorMessage.push('- Revisa haber ingresado todos los números correctamente.');
            setErrorMessage({title: t(`basics.response_title_error`),
                             text: t(`alerts.response_error`),
                             errors: arrErrorMessage,
                             actions: [{type: 'close', text: 'Close', goto: null}]});

            setFormHaveError(true);
            
        }

      }

      

      const checkErrors = () => {

        let haveError = false;
        let updateTwoFaMailCodesError = {...twoFaMailCodesError};

        Object.keys(twoFaMailCodes).map(function(key) {
            if(twoFaMailCodes[key].length <= 0) {
                updateTwoFaMailCodesError["code"+key[key.length - 1]] = true;
                haveError = true;
            }else{
                updateTwoFaMailCodesError["code"+key[key.length - 1]] = false;
            }
        })

        setTwoFaMailCodesError(updateTwoFaMailCodesError);

        return haveError;

      }
      
      //() => history.push('/signin')

    return (
        <Wrapper>
            
            <OverlayErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} />

            <div className="inner_2fa">
                <div className="fields">
                    <div className="field">
                        <input 
                            id="code-1"
                            name="code-1"
                            type="text" 
                            maxLength="1" 
                            onChange={handleOnChangeAuthCode}
                            value={twoFaMailCodes.code1}
                            className={twoFaMailCodesError.code1 ? "error" : ""}
                        />
                    </div>
                    <div className="field">
                        <input 
                            id="code-2"
                            name="code-2"
                            type="text" 
                            maxLength="1" 
                            onChange={handleOnChangeAuthCode}
                            value={twoFaMailCodes.code2}
                            className={twoFaMailCodesError.code2 ? "error" : ""}
                        />
                    </div>
                    <div className="field">
                        <input 
                            id="code-3"
                            name="code-3"
                            type="text" 
                            maxLength="1" 
                            onChange={handleOnChangeAuthCode}
                            value={twoFaMailCodes.code3}
                            className={twoFaMailCodesError.code3 ? "error" : ""}
                        />
                    </div>
                    <div className="field">
                        <input 
                            id="code-4"
                            name="code-4"
                            type="text" 
                            maxLength="1" 
                            onChange={handleOnChangeAuthCode}
                            value={twoFaMailCodes.code4}
                            className={twoFaMailCodesError.code4 ? "error" : ""}
                        />
                    </div>
                    <div className="field">
                        <input 
                            id="code-5"
                            name="code-5"
                            type="text" 
                            maxLength="1" 
                            onChange={handleOnChangeAuthCode}
                            value={twoFaMailCodes.code5}
                            className={twoFaMailCodesError.code5 ? "error" : ""}
                        />
                    </div>
                    <div className="field">
                        <input 
                            id="code-6"
                            name="code-6"
                            type="text" 
                            maxLength="1" 
                            onChange={handleOnChangeAuthCode}
                            value={twoFaMailCodes.code6}
                            className={twoFaMailCodesError.code6 ? "error" : ""}
                        />
                    </div>
                </div>
            </div>

            <div className="actions">

                {loading ?
                    <div className="loader">
                        <CircularProgress size={50} />
                    </div>
                    :
                    <ButtonSolid
                        type="submit"
                        style_type="default"
                        className="bt_signup"
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.lightgold},
                                    background:{normal: COLORS.lightgold, hover: '#FFFFFF'},
                                    border:{normal: COLORS.lightgold, hover: COLORS.lightgold}}}
                        onClick={(e) => handleSubmitMailCode(e)}
                    >
                        <p>Send Code</p>
                    </ButtonSolid>


                }

                <div className="donthave">
                    <p>{t(`login.expired_twocode`)}</p>
                    {!loadingResend ?
                    <button className="bt_resend" onClick={handleResendCode}>{t(`buttons.resend_code`)}</button>
                    :
                    <div className="loader_twocode">
                        <CircularProgress size={18} />
                    </div>
                    }
                </div>
            </div>

        </Wrapper>
    )
}

export default TwoFaMailCodeContainer;
