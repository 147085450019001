import React, { useEffect, useState, useRef, useContext } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { breakpoints } from '../../helpers/breakpoints';
import CheckoutForm from './../Subscription/CheckoutForm';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import ModalBillingDetails from '../../components/Elements/Modals/ModalBillingDetails';
import { AiFillCaretDown, AiFillCaretUp, AiFillEye } from "react-icons/ai";
import ModalPlans from '../../components/Elements/Modals/ModalPlans';
import ModalPaymentMethod from '../../components/Elements/Modals/ModalPaymentMethod';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';    

import { ContentContext,
         AuthContext } from '../../context';

import OverlayWaiting from '../Elements/OverlayWaiting';

import {
    Elements,
    CardElement
  } from '@stripe/react-stripe-js';

import {loadStripe} from '@stripe/stripe-js';

import Input from '../../components/Elements/Form/Input';

import BoxContainer from '../../components/Elements/BoxContainer';
import { COLORS,
         SIZES } from '../../helpers/constants';

const Wrapper = styled.div`

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    .red{
        color: ${COLORS.red};
    }
    .highlight{
        color: ${COLORS.darkgold};
        font-weight: 600;
    }
    >.inner{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: calc(100% - 30px);
        min-height: 100px;
        padding: 20px 25px;
        justify-content: space-between;
        margin-bottom: 30px;
        position: relative;
        .box_noplan{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .bt_upgrade{
                align-self: flex-start;
            }
        }
        .loader{
            height: 42px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            svg{
                color: ${COLORS.lightgold}
            }
        }
        .form{
            width: 100%;
        }

        .box_plan,
        .box_invoice,
        .box_contact,
        .box_billing,
        .box_payment_method,
        .box_cancel{
            display: flex;
            flex-direction: column;
            width: 100%;
            border-bottom: 1px solid ${COLORS.whiteFour};
            padding-bottom: 25px;
            margin-bottom: 25px;
            >.title{
                display: flex;
                flex-direction: row;
                margin-bottom: 15px;
                >p{
                    font-size: 21px;
                    font-weight: 500;
                    color: #2B2C2D;
                    margin: 0 0 0 0;
                }
                .bt_add_card{
                    margin-left: 15px;
                }
            }
            .data{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                .item{
                    display: flex;
                    flex-direction: column;
                    .name{
                        font-weight: 600;
                    }
                    .value{
                        font-size: 14px;
                        .cancelled{
                            color: ${COLORS.red};
                        }
                    }
                    &.card_name{ 
                        .name{
                            .capitalize{ 
                                text-transform: capitalize; 
                            }
                        }
                    }
                }
                .plan_name{
                    
                }
                
                
            }
        }
        .box_plan{
            .data{
                flex-direction: column;
                justify-content: flex-start;
                .items{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding-right: 0;
                    box-sizing: border-box;
                    .plan_name{
                        width: 250px;
                        margin-bottom: 10px;
                    }
                    .plan_payment{
                        width: 130px;
                        border-left: 0;
                        padding-left: 0;
                        margin-bottom: 10px;
                    }
                    .plan_renewal{
                        width: 130px;
                        border-left: 0;
                        padding-left: 0;
                        margin-bottom: 10px;
                    }
                    .plan_limits{
                        width: 170px;
                        border-left: 0;
                        padding-left: 0;
                    }

                }
                .actions{
                    display: flex;
                    flex-direction: row;
                    margin-top: 30px;
                    margin-left: auto;
                    width: 100%;
                    .bt_change{
                        width: 50%;
                        margin-left: 10px;
                    }
                    .bt_save{
                        width: 50%;
                    }
                }
            }
            
            
        }
        .box_invoice{
            .data{
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start!important;
                .invoice_date{
                    width: 250px;
                }
                .invoice_status{
                    width: 170px;
                    margin-top: 10px;
                }
                .actions{
                    width: 100%;
                    margin-left: auto;
                    margin-top: 30px;
                    .bt_download_invoice{
                        width: 100%;
                    }
                }
            }
        }
        .box_cancel{
            >.title{
                p{
                    color: ${COLORS.red}!important;
                }
            }
        }
        .box_invoice,
        .box_payment_method{
            .invoices,
            .methods{
                .data{
                    border-bottom: 1px dashed ${COLORS.whiteFour};
                    padding-bottom: 10px;
                    padding-top: 10px;
                    align-items: center;
                    
                    :last-child{
                        border-bottom: 0;
                        padding-bottom: 0;
                    }
                    :first-child{
                        padding-top: 0;
                    }
                    .actions{
                        .bt_set_as_default{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            .loader{
                                svg{
                                    color: ${COLORS.lightgold}
                                }
                            }
                        }
                    }
                }
                .bt_billing_details{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: ${COLORS.darkgrey};
                    align-self: flex-start;
                    cursor: pointer;
                    p{
                        font-size: 15px;
                        line-height: 15px;
                    }
                    .icon{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        //width: 20px;
                        //height: 20px;
                        //border-radius: 10px;
                        font-size: 25px;
                        line-height: 25px;
                        //background-color: #000000;
                        margin-left: 8px;
                    }
                    :hover{
                        color: ${COLORS.lightgold};
                    }
                    
                }
            }
            .invoices{
                max-height: 200px;
                overflow-x: hidden;
                overflow-y: auto;
            }
        }
        .box_billing{
            .data{
                /*
                .item{
                    flex-direction: row;
                    .name{
                        width: 130px;
                    }
                }
                */
            }
        }
        .box_payment_method{
            .data{
                align-items: flex-start!important;
                .actions{
                    width: 100%;
                    display: flex;
                    flex-direction: row!important;
                    .bt_set_as_default_card{
                        width: 50%;
                    }
                    .bt_remove_card{
                        width: 50%;
                        margin-left: 10px;
                    }
                }
            }
        }
        
    }
    .overlay_error_message{
        .inner_overlay{
            width: 650px;
        }
    }
    
    @media ${breakpoints.xs} {
        .box_plan{
            //display: none!important;
            .data{
                flex-direction: row!important;
                .items{
                    .plan_name{
                        margin-bottom: 10px;
                    }
                    .plan_payment{
                        margin-bottom: 10px;
                    }
                    .plan_renewal{
                        margin-bottom: 10px;
                    }
                    .plan_limits{
                    }

                }
                .actions{
                    display: flex;
                    flex-direction: column!important;
                    margin-left: auto;
                    margin-top: 0!important;
                    width: 295px;
                    .bt_change{
                        margin-left: 0!important;
                        margin-top: 10px;
                        width: 100%!important;
                    }
                    .bt_save{
                        width: 100%!important;
                    }
                }
            }
        }
        
    }
    @media ${breakpoints.sm} {
        .box_plan{
            .data{
                .actions{
                    width: 295px!important;
                    flex-direction: row!important;
                    .bt_change{
                        width: 150px!important;
                        margin-top: 0!important;
                        margin-left: 10px!important;
                    }
                    .bt_save{
                        width: 150px!important;
                    }
                }
            }
        }
        .box_invoice{
            .data{
                flex-direction: row!important;
                justify-content: flex-start;
                align-items: flex-start!important;
                .invoice_date{
                    width: 250px;
                }
                .invoice_status{
                    width: 170px;
                    margin-top: 0!important;
                }
                .actions{
                    width: 295px!important;
                    margin-left: auto;
                    margin-top: 0!important;
                    .bt_download_invoice{
                        width: 100%;
                    }
                }
            }
        }
        .box_payment_method{
            .data{
                flex-direction: row!important;
                align-items: center!important;
                .actions{
                    width: 295px!important;
                    display: flex;
                    flex-direction: row!important;
                    .bt_set_as_default_card{

                    }
                    .bt_remove_card{
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.md} {
        .box_plan{
            //display: none !important;
            .data{
                .items{
                    display: flex;
                    flex-direction: column!important;
                    width: calc(100% - 295px);
                    padding-right: 20px;
                    box-sizing: border-box;
                    .plan_name{
                        width: 250px;
                    }
                    .plan_payment{
                        width: 130px;
                        border-left: 1px solid #ccc;
                        padding-left: 20px;
                    }
                    .plan_renewal{
                        width: 130px;
                        border-left: 1px solid #ccc;
                        padding-left: 20px;
                    }
                    .plan_limits{
                        width: 170px;
                        border-left: 1px solid #ccc;
                        padding-left: 20px;
                    }

                }
                .actions{
                    display: flex;
                    margin-left: auto;
                }
            }
        }
    }
    @media ${breakpoints.lg} {
        .box_plan{
            .data{
                .items{
                    display: flex;
                    flex-direction: row!important;
                    width: calc(100% - 295px);
                    padding-right: 20px;
                    box-sizing: border-box;
                    .plan_name{
                        width: 200px!important;
                        margin-bottom: 0;
                    }
                    .plan_payment{
                        width: 100px!important;
                        border-left: 1px solid #ccc;
                        padding-left: 20px;
                        margin-bottom: 0;
                    }
                    .plan_renewal{
                        width: 120px!important;
                        border-left: 1px solid #ccc;
                        padding-left: 20px;
                        margin-bottom: 0;
                    }
                    .plan_limits{
                        width: 140px!important;
                        border-left: 1px solid #ccc;
                        padding-left: 20px;
                    }

                }
                .actions{
                    display: flex;
                    flex-direction: row;
                    margin-left: auto;
                    width: 295px;
                    .bt_change{
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.xl} {
        .box_plan{
            .data{
                .items{
                    .plan_name{
                        width: 250px!important;
                    }
                    .plan_payment{
                        width: 130px!important;
                    }
                    .plan_renewal{
                        width: 130px!important;
                    }
                    .plan_limits{
                        width: 170px!important;
                    }

                }
                .actions{
                    display: flex;
                    flex-direction: row;
                    margin-left: auto;
                    width: 295px;
                    .bt_change{
                        margin-left: 10px;
                    }
                }
            }
        }
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`
const Fields = styled.div`

`
const Field = styled.div`

`

const SubscriptionContainer = (props) => {

    const { t } = useTranslation();

    const { assets,
            parties,
            create_setup_intent, 
            create_customer,
            subscription,
            subscriptionInvoice,
            subscriptionCancel, 
            subscriptionUpdate,
            subscriptionResume,
            createCustomer,
            deletePaymentMethod,
            setAsDefaultPaymentMethod } = useContext(ContentContext);
            
    const { userData,
            setUserData,
            overlayDowngradePlan,
            setOverlayDowngradePlan } = useContext(AuthContext);

    const [ formData, setFormData ] = useState({});
    const [ subscriptionData, setSubscriptionData ] = useState(null);
    const [ subscribed, setSubscribed ] = useState(null);
    const [ paymentMethods, setPaymentMethods ] = useState(null);
    const [ activePlan, setActivePlan ] = useState(null);
    const [ subscriptionInvoices, setSubscriptionInvoices ] = useState(null);
    const [ upcomingInvoice, setUpcomingInvoice ] = useState(null);
    const [ showBillingDetails, setShowBillingDetails ] = useState(false);
    const [ billingDetails, setBillingDetails ] = useState(null);
    const [ waitingLoading, setWaitingLoading ] = useState(false);
    const [ openModalPlans, setOpenModalPlans ] = useState(false);
    const [ openModalPaymentMethod, setOpenModalPaymentMethod ] = useState(false);
    const [ loadingAsDefaultPaymentMethod, setLoadingAsDefaultPaymentMethod ] = useState(false);

    let inputRef = useRef({});

    useEffect(() => {
          
        setFormData({
            plan: { value: "", required: true, disabled: false, ref: "plan", error: false },
            holder_name: { value: "", required: true, disabled: false, ref: "holder_name", error: false },
            number: { value: "", required: true, disabled: false, ref: "number", error: false },
            expiration: { value: "", required: true, disabled: false, ref: "expiration", error: false },
            scode: { value: "", required: true, disabled: false, ref: "scode", error: false },
        });

        
        handleLoadData();
        

        return () => {
            toast.dismiss();
        }
        
    }, [])

    const handleLoadData = (action = null) => {
        
        setWaitingLoading(true);
        const subscriptionDataResponse = subscription();
        subscriptionDataResponse.then(res => {
            console.log("subscriptionDataResponse", res)
            setSubscribed(res.data.subscribed);
            if(res.data.subscribed){
                setSubscriptionInvoices(res.data.invoices);
                setUpcomingInvoice(res.data.upcomingInvoice);
                setSubscriptionData(res.data.subscription)
                setPaymentMethods(res.data.paymentMethods);
                setActivePlan(res.data.active_plan);
                console.log("res.data.subscription", res.data.subscription)
                
                if(action == "cancel" || action == "resume"){
                    const userDataUpdate = {...userData};
                    userDataUpdate.subscription.status = res.data.subscription.stripe_status == "active" ? true : false;
                    userDataUpdate.subscription.price_id = res.data.subscription.stripe_price;
                    userDataUpdate.subscription.ends_at = res.data.subscription.ends_at;

                    userDataUpdate.subscription.plan.notify = res.data.active_plan.name != "Plan_free" ? true : false;
                    userDataUpdate.subscription.plan.assets = res.data.active_plan.assets;
                    userDataUpdate.subscription.plan.beneficiaries = res.data.active_plan.beneficiaries;
                    setUserData(userDataUpdate);
                }
                
            }
            setWaitingLoading(false);
        })

    }

    useEffect(() => {

        console.log("- - - - - - - - - - - - - ")
        console.log("subscription userData.subscription", userData.subscription);
        console.log("subscription activePlan", activePlan);

        if(userData && activePlan){
            
            console.log("subscription userData.subscription != activePlan.stripe_price_month_id", userData.subscription.price_id != activePlan.stripe_price_month_id)
            
            if(userData.subscription.price_id != activePlan.stripe_price_month_id){
                console.log("subscription userData.subscription != activePlan.stripe_price_month_id", userData.subscription.price_id != activePlan.stripe_price_month_id)
                handleLoadData();
            }
        }
        console.log("- - - - - - - - - - - - - ")
    
      return () => {}
    }, [userData.subscription.price_id, activePlan])
    

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
        
    }

    const intentTest = () => {

        const result = create_setup_intent();
        console.log(result);

    }

    const handleInvoice = (url) => {

        window.open(url); 

    }

    const handleSubscriptionCancel = () => {
        
        if(assets.length > process.env.REACT_APP_PLANFREE_ASSETS_MAX){
            //setOverlayDowngradePlan({status: true, assets: 1, parties: 3});
            setOverlayDowngradePlan({status: true, assets: process.env.REACT_APP_PLANFREE_ASSETS_MAX, parties: process.env.REACT_APP_PLANFREE_PARTIES_MAX});
            return null;
        }

        if(parties.length > process.env.REACT_APP_PLANFREE_PARTIES_MAX){
            //setOverlayDowngradePlan({status: true, assets: 1, parties: 3});
            setOverlayDowngradePlan({status: true, assets: process.env.REACT_APP_PLANFREE_ASSETS_MAX, parties: process.env.REACT_APP_PLANFREE_PARTIES_MAX});
            return null;
        }
        
        setWaitingLoading(true);
        const canceled = subscriptionCancel();
        canceled.then(res => {

            const userDataUpdate = {...userData};
            userDataUpdate.subscription.status = false;
            userDataUpdate.subscription.price_id = "";
            userDataUpdate.subscription.ends_at = "";

            userDataUpdate.subscription.plan.notify = false;
            userDataUpdate.subscription.plan.assets = process.env.REACT_APP_PLANFREE_ASSETS_MAX;
            userDataUpdate.subscription.plan.beneficiaries = process.env.REACT_APP_PLANFREE_PARTIES_MAX;
            setUserData(userDataUpdate);
            
            handleLoadData("cancel");
            
        })

    }

    const handleSubscriptionResume = () => {

        setWaitingLoading(true);
        const resumed = subscriptionResume();
        resumed.then(res => {
            handleLoadData("resume");
        })
        
    }

    const statusPlan = () => {
        
        let message = "";
        if(subscriptionData.ends_at){
            moment(subscriptionData.created_at).format('MMMM Do YYYY')
        }

    }

    const handleShowBillingDetails = (method) => {
        setBillingDetails(method);
        setShowBillingDetails(!showBillingDetails);
    }

    const handleDeletePaymentMethod = (pm) => {

        setLoadingAsDefaultPaymentMethod(pm);
        const deleted = deletePaymentMethod({payment_method: pm});
        deleted.then(res => {
            setLoadingAsDefaultPaymentMethod(null);
            if(res.success) {
                const pm_filtered = paymentMethods.filter(_pm => _pm.id != pm);
                setPaymentMethods(pm_filtered);
            }else{

                alert("ERROR SET PAYMENT")

            }
        })

    }

    const handleSetAsDefaultPaymentMethod = (pm) => {
        
        setLoadingAsDefaultPaymentMethod(pm);
        const setted = setAsDefaultPaymentMethod({payment_method: pm});
        setted.then(res => {
            setLoadingAsDefaultPaymentMethod(null);
            if(res.success) {
                const pm_update = paymentMethods.map((_pm, i) => {
                    if(_pm.id == pm){
                        _pm.is_default = true;
                    }else{
                        _pm.is_default = false;
                    }
                    return _pm;
                })
                setPaymentMethods(pm_update);
            }else{

                alert("ERROR SET PAYMENT")

            }
            
        })

    }

    useEffect(() => {
      
        console.log("paymentMethods", paymentMethods);
    
      return () => { }
    }, [paymentMethods])
    
     
    //const stripePromise = loadStripe('pk_test_51JE6zZIls69beLtuDxRuEWnGDIpUzoa2Ob4sBsMwcEAGRuA6UYPrl3Frnf09E2NgsD5BUhSmQ270ykNV5KuoZY43009EsEuPpI');

    return (
        <Wrapper>
            
            {openModalPaymentMethod &&
                <ModalPaymentMethod 
                    open={openModalPaymentMethod} 
                    setOpen={setOpenModalPaymentMethod} 
                    callback={handleLoadData} 
                />
            }
            {openModalPlans &&
                <ModalPlans 
                    open={openModalPlans} 
                    setOpen={setOpenModalPlans} 
                    callback={handleLoadData} 
                />
            }

            {showBillingDetails &&
                <ModalBillingDetails 
                    open={showBillingDetails} 
                    setOpen={setShowBillingDetails} 
                    content={billingDetails}
                />
            }

            <BoxContainer className="inner">

                {waitingLoading &&
                    <OverlayWaiting />
                }

                {subscribed == true ? 
                     subscriptionData != null &&
                <>
                <div className="box_plan">
                    <div className="title">
                        <p>{t(`subscription.active_plan`)}</p>
                        { subscriptionData && statusPlan() }
                    </div>
                    <div className="data">
                        <div className="items">
                            <div className="item plan_name">
                                <div className="name">{ activePlan && activePlan.title }</div>
                                <div className='value'>{t(`subscription.started_on`)} { subscriptionData && moment(subscriptionData.created_at).format('MMMM Do YYYY') }</div>
                            </div>
                            <div className="item plan_payment">
                                <div className="name">{t(`subscription.monthly`)}</div>
                                <div className="value">${ activePlan && parseFloat(activePlan.price / 100).toFixed(2) }</div>
                            </div>
                            <div className="item plan_renewal">
                                <div className="name">{t(`subscription.renews_on`)}</div>
                                <div className="value">
                                    { subscriptionData && subscriptionData.ends_at ?
                                        <span className='cancelled'>{t(`subscription.cancelled`)}</span>
                                    :
                                        upcomingInvoice && moment.unix(upcomingInvoice.next_payment_attempt).format("MMM DD, YYYY")
                                    }
                                </div>
                            </div>
                            <div className="item plan_limits">
                                <div className="name">{t(`subscription.limits`)}</div>
                                <div className="value">Assets: 1<br/>Baneficiaries: 3</div>
                            </div>
                        </div>
                        <div className="actions">
                            {subscriptionData == null ?
                            <></>
                            :
                            subscriptionData.ends_at

                            ?
                                <ButtonSolid 
                                    type="submit" 
                                    style_type="default" 
                                    className="bt_change" 
                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                    onClick={(e) => { handleSubscriptionResume(); }}
                                    disabled={false}
                                >
                                    <p>{t(`buttons.resume_plan`)}</p>
                                </ButtonSolid>
                            :
                            <>
                                <ButtonSolid 
                                    type="submit" 
                                    style_type="error" 
                                    className="bt_save" 
                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                    onClick={(e) => { handleSubscriptionCancel(); /*subscriptionInvoice({}, "in_1Ma76hIls69beLtuO4y0IfQd")*/ }}
                                    disabled={false}
                                >
                                    <p>{t(`buttons.cancel_plan`)}</p>
                                </ButtonSolid>
                                <ButtonSolid 
                                    type="submit" 
                                    style_type="default" 
                                    className="bt_change" 
                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                    onClick={(e) => { setOpenModalPlans(!openModalPlans) }}
                                    disabled={false}
                                >
                                    <p>{t(`buttons.change_plan`)}</p>
                                </ButtonSolid>
                            </>
                            }
                        </div>
                    </div>
                </div>

                <div className="box_invoice">
                    <div className="title">
                        <p>{t(`subscription.invoices`)}</p>
                    </div>

                    {subscriptionInvoices != null ?
                        
                        subscriptionInvoices.length > 0 ?
                            <div className="invoices">
                                {
                                    subscriptionInvoices.map((invoice, i) => {
                                        return( 
                                            <div className="data">
                                                <div className="item invoice_date">
                                                    <div className="name">{t(`subscription.billed_date`)}</div>
                                                    <div className='value'>{ `${moment.unix(invoice.lines.data[0].period.start).format("MMM DD")} / ${moment.unix(invoice.lines.data[0].period.end).format("MMM DD, YYYY")}` }</div>
                                                </div>
                                                <div className="item invoice_status">
                                                    <div className="name">{t(`subscription.status`)}</div>
                                                    <div className='value'>{ invoice.status }</div>
                                                </div>
                                                <div className="actions">
                                                    <ButtonSolid 
                                                        type="submit" 
                                                        style_type="default" 
                                                        className="bt_download_invoice" 
                                                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                                        onClick={(e) => { handleInvoice(invoice.invoice_pdf) }}
                                                        disabled={false}
                                                    >
                                                        <p>{t(`buttons.download_invoice`)}</p>
                                                    </ButtonSolid>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        

                        :
                        
                        <>{t(`subscription.no_invoices`)}</>

                        :

                        <></>

                    }
                    
                </div>

                <div className="box_payment_method">
                    <div className="title">
                        <p>{t(`subscription.payment_method`)}</p>
                        <ButtonSolid 
                            type="submit" 
                            style_type="default" 
                            className="bt_add_card" 
                            override_css={{size: {width: "100px", height: "30px"}, font: {size: "12px" }, color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                            onClick={(e) => { setOpenModalPaymentMethod(!openModalPaymentMethod) }}
                            disabled={false}
                        >
                            <p>{t(`buttons.add_new_card`)}</p>
                        </ButtonSolid>
                    </div>
                    {paymentMethods && 
                        <div className="methods">

                            {paymentMethods.map((method, i) => {
                                return(
                                    <div className="data"> 
                                        <div className="item card_name">
                                            <div className="name"><span className="capitalize">{method.card.brand}</span> { method.is_default ? "(default)" : "" }</div>
                                            <div className="value">{t(`subscription.end_in`)} {method.card.last4} ({t(`subscription.expiration_date`)} {`${method.card.exp_year}/${method.card.exp_month}`})</div>
                                            <div className="bt_billing_details" onClick={() => handleShowBillingDetails(method)}>
                                                <p>{t(`subscription.show_bill_datails`)}</p>
                                                <div className="icon">
                                                    <AiFillEye />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="actions">
                                            
                                            {!method.is_default ?
                                                loadingAsDefaultPaymentMethod == method.id ?
                                                    <div className='loader'>
                                                        <CircularProgress size={35} />
                                                    </div>
                                                :
                                            <>
                                                <ButtonSolid 
                                                    type="submit" 
                                                    style_type="default" 
                                                    className="bt_set_as_default_card" 
                                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                                    onClick={(e) => { handleSetAsDefaultPaymentMethod(method.id) }}
                                                    disabled={loadingAsDefaultPaymentMethod}
                                                >
                                                    <p>{t(`buttons.set_as_default`)}</p>
                                                </ButtonSolid>
                                                <ButtonSolid 
                                                    type="submit" 
                                                    style_type="default" 
                                                    className="bt_remove_card" 
                                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                                    onClick={(e) => { handleDeletePaymentMethod(method.id) }}
                                                    disabled={loadingAsDefaultPaymentMethod}
                                                >
                                                    <p>{t(`buttons.remove_card`)}</p>
                                                </ButtonSolid>
                                            </>
                                            :
                                            <></>
                                            }
                                            
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    }
                    
                </div>
                
                {subscriptionData && subscriptionData.ends_at &&
                   <div className="box_cancel">
                        <div className="title">
                            <p>Cancelacion de Plan</p>
                        </div>
                        <div className="data">
                            <div className="text">
                                Ha solicitado la cancelacion de su plan, la misma se hara efectiva el dia <span className="red">{ subscriptionData && moment(subscriptionData.created_at).format('MMMM Do YYYY') }</span>.<br/>
                                Ya no se cobraran (haran?) mas cargos a su tarjeta.
                            </div>
                        </div>
                    </div>
                }
                </>
                :
                subscribed == false &&
                    <div className="box_noplan">
                        <p>{t(`subscription.access_more_features`)}</p>
                        <ButtonSolid 
                            type="submit" 
                            style_type="default" 
                            className="bt_upgrade" 
                            override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                            onClick={(e) => { setOpenModalPlans(!openModalPlans) }}
                            disabled={false}
                        >
                            <p>{t(`menu.upgrade`)}</p>
                        </ButtonSolid>
                    </div>
                }
            </BoxContainer>
        </Wrapper>
    )
}

export default SubscriptionContainer
