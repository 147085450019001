import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import "moment-timezone";
import styled from 'styled-components';
import ButtonSolid from '../Elements/Buttons/Solid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

import { COLORS,
         notificate_frecuency } from '../../helpers/constants';
import { AuthContext } from '../../context';

const Wrapper  = styled.div`
    white-space: pre-line;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(208, 178, 87, 0.55);
    background-color: rgba(0, 0, 0, 0.90);
    z-index: 9999;
    //border-radius: 12px;
    svg{
        color: ${COLORS.lightgold};
    }

    .inner_overlay_vault_open{
        
        width: 500px;
        background-color: #FFFFFF;
        border-radius: 12px;

        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);

        p{
            margin: 0;
        }
        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            text-align: center;
            .notify_setup{
                margin-top: 20px;
                background-color: ${COLORS.whiteTwo};
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 7px;
                padding-bottom: 7px;
                color: #000000;
                text-align: center;
                strong{
                    color: ${COLORS.lightgold};
                }
            }
            .bt_close{
                margin-top: 20px;
            }
        }
    }
 `  

const OverlayBeneficiariesLimitReach = (props) => {

    const { t } = useTranslation();

    const { } = props;

    const { userData,
            overlayBeneficiariesLimitReach,
            setOverlayBeneficiariesLimitReach } = useContext(AuthContext);
        

    useEffect(() => {
    
      return () => {}
    }, [])
    
    //<CircularProgress size={45} />

    if(!overlayBeneficiariesLimitReach){
        return null;
    }
    
    return (
        <Wrapper>
            <div className="inner_overlay_vault_open">
                <div className="content">
                    { t(`basics.limit_reached`, { count: `${userData?.subscription.plan.beneficiaries}`, type: `${userData?.subscription.plan.beneficiaries > 1 ? "beneficiaries" : "beneficiary"}` }) }
                    <ButtonSolid 
                        type="submit" 
                        style_type="default" 
                        className="bt_close" 
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                        onClick={() => setOverlayBeneficiariesLimitReach(!overlayBeneficiariesLimitReach)}
                    >
                        <p>Close</p>
                    </ButtonSolid>
                </div>
            </div>
        </Wrapper>
    )
}

export default OverlayBeneficiariesLimitReach;