import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import BoxContainer from '../../components/Elements/BoxContainer';
import { COLORS,
         SIZES } from '../../helpers/constants';
import Input from '../../components/Elements/Form/Input';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import OverlayErrorMessage from '../../components/Elements/Form/OverlayErrorMessage';
import ChangePassword from './Security/ChangePassword';
import TwoFa from './Security/TwoFa';

const Wrapper = styled.div`

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .highlight{
        color: ${COLORS.darkgold};
        font-weight: 600;
    }
    >.inner{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: calc(100% - 30px);
        padding: 20px 25px;
        justify-content: space-between;

        >.left{
            width: 100%;
        }
        >.right{
            width: 100%;
            margin-top: 35px;
        }
        
    }
    .overlay_error_message{
        .inner_overlay{
            width: 650px;
        }
    }
    
    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {
        >.inner{
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            width: calc(100% - 30px);
            padding: 20px 25px;
            justify-content: space-between;

            >.left{
                width: calc(50% - 20px);
            }
            >.right{
                width: calc(50% - 20px);
                margin-top: 0;
            }
        }
    }
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`

const SecurityContainer = (props) => {

    //const [] = props;
    const [ changeCurrentPassword, setChangeCurrentPassword ] = useState("");
    const [ changeNewPassword, setChangeNewPassword ] = useState("");
    const [ changeConfirmNewPassword, setChangeConfirmNewPassword ] = useState("");
    const [ twofaCurrentPassword, setTwofaCurrentPassword ] = useState("");
    const [ errorMessage, setErrorMessage ] =  useState({});

    let inputRef = useRef({});

    const handleFormElementChange = () => {

        alert("la")

    }

    return (
        <Wrapper>

            <OverlayErrorMessage className="overlay_error_message" errorMessage={errorMessage} setErrorMessage={setErrorMessage} />

            <BoxContainer className="inner">
                
                <div className="left">
                    <ChangePassword setErrorMessage={setErrorMessage} />
                </div>
                <div className="right">
                    <TwoFa setErrorMessage={setErrorMessage} />
                </div>
                
            </BoxContainer>
        </Wrapper>
    )
}

export default SecurityContainer
