import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';

import { PAGES,
         COLORS,
         IcoNotifications  } from '../../helpers/constants';

import { returnPageData, defineHeaderTitle, normalizeForTraslate } from '../../helpers/functions';
import { useTranslation } from 'react-i18next';
//import { ThumbCircle, Title } from '../../components/Elements';

import ThumbCircle from '../Elements/ThumbCircle';

import { AuthContext } from '../../context';

import { useHistory } from 'react-router';
import useOutsideClick from "../../hooks/useOutsideClick";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Wrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;

    height: 100px;
    background-color: #FFFFFF;

    p{
        margin: 0;
    }
`
const AccountNumber = styled.div`
    display: flex;
    margin-top: 7px;
    p{
        font-size: 13px;
        font-weight: 500;
        color: #AD881C;
    }
`
const SeccionTitle = styled.div`
    display: flex;
    p{
        font-size: 20px;
        font-weight: 600;
    }
`
const Left = styled.div`
    display: flex;
    justify-content: center;
    //flex: 1;
    flex-direction: column;
    padding-left: 30px;
    //background-color: gray;
`
const Right = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    //flex: 1;
    padding-right: 30px;
    flex-direction: column;
    //background-color: pink;
    
        .thumb{
            cursor: pointer;
        }
    
`
const InnerRight = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    //background-color: gray;
`
const Notification = styled.div`
    display: flex;
    position: relative;
    margin-right: 40px;
    .red_dot{
        display: flex;
        position: absolute;
        right: 0;
        top: 9px;
        left: 15px;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        border: 2px solid #FFFFFF;
        background-color: #F03434;
    }
    svg{
        width: 22px;
    }
`
const User = styled.div`
    display: flex;
`
const Data = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    p{
        margin: 0;
        &.name{
            font-size: 16px;
            color: ${COLORS.darkgrey};
            font-weight: 600;
            width: 100%;
        }
        &.title{
            font-size: 13px;
            color: ${COLORS.darkgold};
            font-weight: 500;
            width: 100%;
        }
        
    }
`
const MenuStyled = styled.div`
    display: flex;
    position: absolute;
    background-color: #FFFFFF;

    right: ${props => props.menuX ? props.menuX + 'px' : '0'};
    top: ${props => props.menuY ? props.menuY + 'px' : '0'};
    
    width: 250px;
    min-height: 100px;
    transform: translate3d(0, 0, 0);
    border-radius: 10px;

    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.10);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.10);
    
    border: 1px solid ${COLORS.whiteTwo};

    z-index: 99999;

    ::after{
        content: "";
        width: 10px;
        height: 10px;
        transform: rotate(135deg);
        background: #FFFFFF;
        position: absolute;
        z-index: -1;
        top: -5px;
        right: 50px;

        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.20);
    }


    .inner{
        width: 100%;
        padding: 10px 10px 10px 10px;
        background-color: #FFFFFF;
    }
    
    .bt{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid ${COLORS.whiteTwo};
        padding-top: 7px;
        padding-bottom: 7px;
        border-radius: 5px;
        cursor: pointer;

        &.last{
            border-bottom: 0;
        }
        p{
            font-size: 15px;
            font-weight: 500;
            padding-left: 10px;
            padding-right: 10px;
            color: ${COLORS.darkgrey};
        }
        :hover{
            background-color: ${COLORS.lightgold};
            p{
                color: #FFFFFF;
            }
        }
    }
`

const Header = (props) => {

    const { t } = useTranslation();

    const { logout, userData } = useContext(AuthContext);
    const { activeButton, location } = props;
    const [ title, setTitle ] = useState('');

    const { height, width } = useWindowDimensions();
    const [ menuX, setMenuX ] = useState(0);
    const [ menuY, setMenuY ] = useState(0);
    const [ openMenu, setOpenMenu ] = useState(false);

    const ref = useRef();
    const refThumb = useRef();

    const history = useHistory();
    
    useEffect(() => {
        console.log("location.pathname", location.pathname)
        const headerTitle = defineHeaderTitle(location.pathname);
        console.log("headerTitle", headerTitle)
        setTitle(headerTitle);

        return () => {}
    }, [location])
    
    useEffect(() => {
        console.log("*-*-*-*-*-*--*-*")
      console.log(userData);
      console.log("*-*-*-*-*-*--*-*")
    
      return () => {
        
      }
    }, [userData])
    

    useOutsideClick(ref, () => {
        if(openMenu){
            setOpenMenu(false);
        }
        //alert('You clicked outside')
    });



    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {

    
    const position = refThumb.current.getBoundingClientRect();
    

    console.log(position);
    console.log(position.x);
    console.log((width - position.x) + (position.width / 2));

    setMenuX((width - position.right - 20));
    setMenuY(position.bottom + 10);

    setAnchorEl(event.currentTarget);

    setOpenMenu(!openMenu);
    //alert("openMenu: " + openMenu);

  };

  useEffect(() => {
    //alert(openMenu);
  
    return () => {
      
    }
  }, [openMenu])
  

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuHeader = (goto) => {

    history.push('/'+goto);
    setOpenMenu(false);

  }







    return (
        <Wrapper>

            <Left>
                <SeccionTitle>
                    <p>{t(`section_title.${normalizeForTraslate(title)}`)} {/*normalizeForTraslate(title)*/}</p>
                </SeccionTitle>
                <AccountNumber>
                    <p>{t(`basics.account_number`)}: USA { userData && userData.account.number }</p>
                </AccountNumber>
            </Left>
            <Right>
                <InnerRight>
                    {/*
                    <Notification>
                        <div className="red_dot"></div>
                        <IcoNotifications />
                    </Notification>
                    */}
                    <User ref={ref}>

                        {openMenu &&
                        <MenuStyled  menuX={menuX} menuY={menuY} className="menu">
                            <div class="inner">
                                <div className="bt" onClick={() => { handleMenuHeader('settings/profile') }}>
                                    <p>{t(`menu.profile`)}</p>
                                </div>
                                <div className="bt" onClick={() => { handleMenuHeader('settings/countdown') }}>
                                    <p>{t(`menu.countdown`)}</p>
                                </div>
                                <div className="bt" onClick={() => { handleMenuHeader('settings/subscription') }}>
                                    <p>{t(`menu.subscription`)}</p>
                                </div>
                                <div className="bt" onClick={() => { handleMenuHeader('settings/security') }}>
                                    <p>{t(`menu.security`)}</p>
                                </div>
                                <div className="bt last" onClick={() => { logout() }}>
                                    <p>{t(`menu.signout`)}</p>
                                </div>
                            </div>
                        </MenuStyled>
                        }


                        <div ref={refThumb}>
                            <ThumbCircle 
                                className="thumb"
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                css={{width: '70', height: '70', border: {withBorder: false, color: "#FFFFFF"}}}
                                
                            >
                                {userData.profile_image ?
                                <img src={userData.profile_image} />
                                :
                                <div className="text_avatar">
                                    <p>{userData.first_name.slice(0, 1) +''+ userData.last_name.slice(0, 1)}</p>
                                </div>
                                }
                            </ThumbCircle>
                        </div>
                        
                        <Data>
                            <p className="name">{ userData && userData.first_name + ' ' + userData.last_name }</p>
                            <p className="title">{t(`basics.vault_owner`)}</p>
                        </Data>
                    </User>
                </InnerRight>
            </Right>
        </Wrapper>
    )
}

export default Header
