import React from "react";
import UpgradeContainer from "./UpgradeContainer";

const Upgrade = (props) => {

  return (
      <UpgradeContainer props={props} location={props.location} history={props.history} />
  );

};

export default Upgrade;
