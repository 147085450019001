import React from "react";
import HomeContainer from "./HomeContainer";

const Home = (props) => {

  return (
      <HomeContainer props={props} location={props.location} history={props.history} />
  );

};

export default Home;
