import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    display: flex;
`

const Bottom = ({ children }) => {
    return (
        <Wrapper>
            { children }
        </Wrapper>
    )
}

export default Bottom
