import React, { useState,
                useCallback,
                useContext,
                useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { breakpoints } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';
import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import OverlayNotificateBeneficiary from '../../components/Elements/OverlayNotificateBeneficiary';
import OverlayNotificateAllBeneficiaries from '../../components/Elements/OverlayNotificateAllBeneficiaries';
import ThumbBox from '../../components/Elements/ThumbBox';

import { IcoCheck,
         IcoPlus,
         IcoClose,
         COLORS,
         SIZES } from '../../helpers/constants';
         
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { ContentContext,
         AuthContext } from '../../context';

import ModalBase from '../../components/Elements/Modals/ModalBase'
import nobeneficiary from '../../assets/images/no_beneficiary.svg';

import ImageProfile from '../../components/Elements/ImageProfile';
import CustomToastMessageError from '../../components/Elements/CustomToastMessageError';
import { toast } from 'react-toastify';

import { IoIosCloseCircleOutline } from "react-icons/io";

const Wrapper = styled.div`

  display: flex;
  width: 100%;
  padding-top: 17px;
  padding-bottom: 17px;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .inner_parties{
    width: calc(100% - 30px);
    height: 100%;
  }
  
  @media ${breakpoints.xs} {
    .inner_parties{
      //width: calc(100% - 30px);
    }
    //width: ${SIZES.xs};
  }
  @media ${breakpoints.sm} {
    //width: ${SIZES.sm};
  }
  @media ${breakpoints.md} {
    //width: ${SIZES.md};
  }
  @media ${breakpoints.lg} {
    //width: ${SIZES.lg};
  }
  @media ${breakpoints.xl} {
    //width: ${SIZES.xl};
  }
  @media ${breakpoints.xxl} {
    //width: ${SIZES.xxl};
  }
  @media ${breakpoints.xxxl} {
    //width: ${SIZES.xxxl};
  }
  @media ${breakpoints.xxxxl} {
    //width: ${SIZES.xxxxl};
  }
    
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  >.loader{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    svg{
      color: ${COLORS.lightgold};
    }
  }
  .box_data{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .inner{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      padding: 20px;
      @media ${breakpoints.md} {
        flex-direction: row;
      }
      @media ${breakpoints.lg} {
        
      }
    }
  }
  .box_header_actions{
    display: flex;
    width: 100%;
    max-height: 60px;
    box-shadow: 0!important;
    .inner{
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
    }
  }
  .box_assets{
    margin-bottom: 20px;
  }
  .box_recent{
  }

  @media ${breakpoints.xs} {
      //min-width: 576px
  }
  @media ${breakpoints.sm} {
      //min-width: 768px
      
  }
  @media ${breakpoints.md} {
      //width: 662px;
  }
  @media ${breakpoints.lg} {
      //width: 870px;
  }
  @media ${breakpoints.xl} {
      //width: 1036px;
  }
  @media ${breakpoints.xxl} {
      //width: 1110px;
  }
  @media ${breakpoints.xxxl} {
      //width: 1350px;
  }
  @media ${breakpoints.xxxxl} {
      //width: ${SIZES.xxxxl};
  }
`
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 17px;
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
  //width: 100%;
  margin-right: 20px;
  width: calc(100% - 350px);
  .box_data{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .inner{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 20px;
    }
  }
  .box_assets{
    margin-bottom: 20px;
  }
  .box_header_actions{
    max-height: 60px;
    box-shadow: 0!important;
    padding: 10px!important;
    .inner{
      padding: 10px;
    }
  }
  .box_recent{
    margin-top: 20px;
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-left: 20px;
  .box_vip{
    margin-bottom: 20px;
    &.stretched{
      flex: 1;
    }
  }
  .box_countdown{
    &.stretched{
      flex: 1;
    }
  }
`
const Inner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
`
const ButtonInvite = styled.div`
  display: flex;
  width: 100%;
  height: 260px;
  background-color: #FFFFFF;
  border-radius: 12px;
  .inner{}
  .ico{
    display: flex;
    flex: 1;
    flex-grow: 1;
    align-items: flex-end;
    .ico_plus{
      padding-bottom: 10px;
    }
  }
  p.text{
    font-size: 14px;
    color: ${COLORS.lightgold};
    text-align: center;
    display: flex;
    flex: 1;
  }
  .bt_add_beneficiary{
    width: 100%;
    text-align: center;
  }
`
const BeneficiariesStyled = styled.div`

  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  .inner_beneficiaries{
    width: 100%;
    display: grid; 
    margin-top: 20px;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px 15px;
  }

  @media ${breakpoints.xs} {
    //width: ${SIZES.xs};
    .inner_beneficiaries{
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media ${breakpoints.sm} {
    //width: ${SIZES.sm};
    .inner_beneficiaries{
      
    }
  }
  @media ${breakpoints.md} {
    //width: ${SIZES.md};
    .inner_beneficiaries{
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media ${breakpoints.lg} {
    width: 100%;
    .inner_beneficiaries{
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media ${breakpoints.xl} {
    .inner_beneficiaries{
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media ${breakpoints.xxl} {
    .inner_beneficiaries{
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media ${breakpoints.xxxl} {
    //width: ${SIZES.xxxl};
    .inner_beneficiaries{
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media ${breakpoints.xxxxl} {
    //width: ${SIZES.xxxxl};
    .inner_beneficiaries{
      grid-template-columns: repeat(5, 1fr);
    }
  }
  
`
const NoBeneficiaries = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  //background-color: pink;
  .inner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    //margin-top: 20px;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 16px;
    //margin-bottom: 30px;
    .inner_container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 8vw;
      padding-bottom: 8vw;
      .image{
        width: 50vw;
        img{
          float: left;
          width: 100%;
        }
      }
      .title{
        font-size: 5.5vw;
        font-weight: 600;
        color: ${COLORS.darkgrey};
        margin-top: 3vw;
      }
      .description{
        font-size: 3.8vw;
        color: ${COLORS.cloudyblue};
        text-align: center;
        margin-top: 2vw;
      }
      .bt_add_beneficiary{
        margin-top: 6vw;
      }
    }
  }

  @media ${breakpoints.xs} {
    height: 100%;
    .inner{
      justify-content: center;
      align-items: center;
      .inner_container{
        width: 400px;
        padding-top: 40px;
        padding-bottom: 40px;
        .image{
          width: 200px;
        }
        .title{
          font-size: 24px;
          margin-top: 15px;
        }
        .description{
          font-size: 16px;
          margin-top: 10px;
        }
        .bt_add_beneficiary{
          margin-top: 30px;
        }
      }
    }
  }
  @media ${breakpoints.sm} {}
  @media ${breakpoints.md} {}
  @media ${breakpoints.lg} {}
  @media ${breakpoints.xl} {}
  @media ${breakpoints.xxl} {}
  @media ${breakpoints.xxxl} {}
  @media ${breakpoints.xxxxl} {}

`

const Beneficiary = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  /*height: 260px;*/
  min-height: 200px;
  background-color: #FFFFFF;
  border-radius: 12px;

  box-shadow: 4px 0px 13px 0px rgba(0,0,0,0.05);
  -webkit-box-shadow: 4px 0px 13px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 4px 0px 13px 0px rgba(0,0,0,0.05);

  flex-direction: column;

  .delete_overlay{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0,0,0, 0.90);/*208, 178, 87*/
    border-radius: 12px;
    z-index: 999;
    .inner_overlay{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 5vw;
      padding-right: 5vw;
      >.loader{
        svg{
            color: ${COLORS.lightgold};
        }
      }
      .actions{
        margin-top: 3vw;
        >.loader{
          display: flex;
          .MuiCircularProgress-root{
            width: 8vw!important;
            height: 8vw!important;
          }
          svg{
            color: ${COLORS.lightgold};
            width: 8vw;
            height: 8vw;
          }
        }
      }
      p{
        font-size: 3.5vw;
        margin: 0;
        text-align: center;
        color: ${COLORS.whiteTwo};
        font-weight: 600;
      }
      p.username{
        margin: 0;
        margin-top: 1vw;
        font-weight: 700;
        color: ${COLORS.lightgold};
      }
      .bt_delete_beneficiary{
        min-width: 28.4vw;
        height: 8vw;
      }
    }
  }
  .inner{
    
    //justify-content: flex-start!important;
    
  }
  .more_actions{
    position: absolute;
    right: 7px;
    top: 7px;
    padding: 5px!important;
    color: ${COLORS.lightgold};
    :hover {
      background-color: ${COLORS.lightgold};
      color: #FFFFFF;
    }
  }
  .bt_beneficiary{
    width: 100%;
  }
  .bt_notify{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    /*margin-top: 15px;*/
    p{
      width: 100%;
    }
    .icon{
      display: flex;
      position: absolute;
      right: 10px;
      svg{
        fill: ${COLORS.green};
      }
    }
  }
  .notified{
    background-color: ${COLORS.green};
    p{
      color: #FFFFFF;
      font-weight: 600;
      letter-spacing: 0.05px;
    }
    .icon{
      svg{
      }
    }
  }

  @media ${breakpoints.xs} {
    .delete_overlay{
      .inner_overlay{
        padding-left: 25px;
        padding-right: 25px;
        .actions{
          margin-top: 15px;
          >.loader{
            display: flex;
            .MuiCircularProgress-root{
              width: 40px!important;
              height: 40px!important;
            }
            svg{
              color: ${COLORS.lightgold};
              width: 40px;
              height: 40px;
            }
          }
        }
        p{
          font-size: 16px;
          margin: 0;
        }
        p.username{
          margin: 0;
          margin-top: 5px;
          font-weight: 700;
        }
        .bt_delete_beneficiary{
          min-width: 142px;
          height: 40px;
        }
      }
    }
  }
  @media ${breakpoints.sm} {}
  @media ${breakpoints.md} {}
  @media ${breakpoints.lg} {}
  @media ${breakpoints.xl} {}
  @media ${breakpoints.xxl} {}
  @media ${breakpoints.xxxl} {}
  @media ${breakpoints.xxxxl} {}

`
const BeneficiaryData = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  //background-color: pink;
  
  .thumb{
    margin-bottom: 10px;
  }
  h2.name{
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    font-weight: 600;
    color: ${COLORS.darkgrey};
  }
  h3.title{
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: ${COLORS.bluegrey};
    &.title_executor{
      color: ${COLORS.lightgold}
    }
  }

`;
const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 25px;
    //background-color: #FFD200;
    //color: #FFD200;
    cursor: pointer;
    right: 13px;
    top: 13px;
    z-index: 9;
    .icon{
        display: flex;
        svg{
          width: 35px;
          height: 35px;
          fill: ${COLORS.whiteTwo};
        }
    }
    :hover{
        .icon{
            svg{
                fill: ${COLORS.lightgold};
            }
        }
    }
`
const ButtonConfirm = styled.div`

`


const PartiesContainer = (props) => {

  const { t } = useTranslation();

  const { beneficiaries,
          setBeneficiaries,
          getFileByType,
          deletePartie,
          parties,
          setParties,
          getAssetsDistributedByParty } = useContext(ContentContext);
  
  const { userData,
          isTheVaultOpen,
          setOverlayVaultOpen,
          setOverlayBeneficiariesLimitReach } = useContext(AuthContext);

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [ selectedParty, setSelectedParty ] = useState(null);
  const [ selectedPartyToDelete, setSelectedPartyToDelete ] = useState(null);
  const [ deleteElementStatus, setDeleteElementStatus ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(null);
  const [ overlayNotificateBeneficiaryOpen, setOverlayNotificateBeneficiaryOpen ] = useState(false);
  const [ overlayNotificateBeneficiariesOpen, setOverlayNotificateBeneficiariesOpen ] = useState(false);
  const [ loading, setLoading ] = useState("");
  const [ haveAssetsAssigned, setHaveAssetsAssigned ] = useState(null);
  const [ beneficiariesLimitReach, setBeneficiariesLimitReach ] = useState(null);

  useEffect(() => {

    if(userData && parties){
      let limit_reach = false;
      console.log("userData.subscription.plan.beneficiaries", userData.subscription.plan.beneficiaries)
      console.log("parties", parties.length)
      if(parties.length >= userData.subscription.plan.beneficiaries){
        limit_reach = true;
      }
      setBeneficiariesLimitReach(limit_reach);
    }
    
    
    return () => {}
  }, [userData, parties])

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleClose();
    if(!isTheVaultOpen){
      history.push('/parties/edit/'+selectedParty.id);
    }else{
      setOverlayVaultOpen(true);
    }
  }

  const handleDelete = () => {

    setHaveAssetsAssigned(null);
    handleClose();
    if(!isTheVaultOpen){

      setSelectedPartyToDelete(selectedParty);
      setDeleteElementStatus(selectedParty.id);

      const haveAssets = getAssetsDistributedByParty(selectedParty.id);
      haveAssets.then((res) => {
          if(res.data.length){
            setHaveAssetsAssigned(true);
          }else{
            setHaveAssetsAssigned(false);
          }
      })
      
    }else{
      setOverlayVaultOpen(true);
    }
  }

  const handleDeleteParty = () => {
    
    if(!isTheVaultOpen){

      const filteredParties = parties.filter(party => party.id != selectedPartyToDelete.id);
      const result = deletePartie(selectedPartyToDelete.id);
      setLoading("delete_party");

      result.then(res => {

        let message = '';
        let responseType = '';
        let errors = [];

        if(res.success === true){
          
            setParties(filteredParties);
            message = t('alerts.item_deleted', { name: 'beneficiary' });//t(`basics`)'The Beneficiary has been deleted';
            responseType = 'success';
            
        }else{

            responseType = 'error';
            errors.push(t(`alerts.standar_error`));
        }

        toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
        });

        setLoading("");

      })
    }else{
      setOverlayVaultOpen(true);
    }
    
  }

  const handleCloseDeleteParty = () => {
    
    setDeleteElementStatus(null);
    setSelectedParty(null);
    setAnchorEl(null);
    setLoading("");
    
  }

  const handleMenuClick = useCallback((event, party, i) => {
    setSelectedParty(party);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleAddNewBeneficiary = () => {
    if(!isTheVaultOpen){
      if(!beneficiariesLimitReach){
        history.push('/parties/add');
      }else{
        setOverlayBeneficiariesLimitReach(true);
      }
    }else{
      setOverlayVaultOpen(true);
    }
  }



  useEffect(() => {

    setIsLoading(true);
    if(parties){
      setIsLoading(false);
    }

    return () => {}
  }, [parties])

  const ProfileImage = ({user_id, type, filename}) => {

    let blob_ = false;
    const response = getFileByType(user_id, type, filename);
    response.then((res) => {
      blob_ = URL.createObjectURL(new Blob([res.data], {
        type: 'image/jpeg',
      }));
      console.log(blob_);
    })
    console.log(blob_);
    return (
      <img src={"blob:http://localhost:6102/c5068d48-2c53-4696-8ed2-f9bd718f1f29"} />
    );
    
  }

  const handleNotifyBeneficiary = (party) => {
    setSelectedParty(party);
    setOverlayNotificateBeneficiaryOpen(!overlayNotificateBeneficiaryOpen);
  }

  const handleNotifyAll = () => {

    setOverlayNotificateBeneficiariesOpen(!overlayNotificateBeneficiariesOpen);

  }

  useEffect(() => {
    console.log("userData ---------->>>> ", userData);
  
    return () => {}
  }, [userData])
  
  

  return (
    <Wrapper className="wrapper_parties">
      

      {overlayNotificateBeneficiariesOpen &&
        <OverlayNotificateAllBeneficiaries 
          open={overlayNotificateBeneficiariesOpen}
          setOpen={setOverlayNotificateBeneficiariesOpen}
          data={null}
          loaderData={null}
          party={selectedParty}
        />
      }

      {overlayNotificateBeneficiaryOpen &&
        <OverlayNotificateBeneficiary 
          open={overlayNotificateBeneficiaryOpen}
          setOpen={setOverlayNotificateBeneficiaryOpen}
          data={null}
          loaderData={null}
          party={selectedParty}
        />
      }
      
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>{t('buttons.edit')}</MenuItem>
        <MenuItem onClick={handleDelete}>{t('buttons.delete')}</MenuItem>
      </Menu>

      <div className="inner_parties">
      <Container>

        {isLoading != null && isLoading == true ?
          <div className="loader">
            <CircularProgress size={40} />
          </div>
          :
          parties && parties.length ?
          <div className="asd">
          <BoxContainer className="box_header_actions">
            <div className="inner">
              <ButtonSolid
                style_type="tab"
                className="bt_add_beneficiary"
                onClick={handleAddNewBeneficiary}
                override_css={null}>
                <p>{t(`buttons.add_new_party`)}</p>
              </ButtonSolid>
              {userData.subscription.plan.notify &&
                <ButtonSolid
                    style_type="default"
                    className="bt_add_beneficiary"
                    onClick={handleNotifyAll}
                    override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}>
                    <p>{t(`buttons.notify_all`)}</p>
                  </ButtonSolid>
                }
            </div>
          </BoxContainer>

          <BeneficiariesStyled>
            
            <div className="inner_beneficiaries">
            
            {
              parties ?
                parties.length ?
                  parties.map((party, i) => {
                    return(
                      <Beneficiary>
                        
                          {deleteElementStatus == party.id ?
                            <div className="delete_overlay">
                              <div className="inner_overlay">

                                <ButtonClose
                                    onClick={() => handleCloseDeleteParty()}
                                >
                                    <div className="icon">
                                      <IoIosCloseCircleOutline />
                                    </div>
                                </ButtonClose>

                                {haveAssetsAssigned === null ? 
                                  <div className="loader">
                                    <CircularProgress />
                                  </div>
                                :
                                
                                haveAssetsAssigned ?
                                  <p>{t(`parties.delete_assigned_assets`)}</p>
                                :
                                <>
                                  <p>{t(`alerts.you_wish_delete`)}</p>

                                  {selectedParty &&
                                    <p className="username">{`${selectedPartyToDelete.first_name} ${selectedPartyToDelete.middle_name ? selectedPartyToDelete.middle_name : ''} ${selectedPartyToDelete.last_name}`}</p>
                                  }

                                  <div className="actions">
                                    {loading === "delete_party" ? 
                                    <div className="loader">
                                      <CircularProgress />
                                    </div>
                                    :
                                    <ButtonSolid
                                      disabled={haveAssetsAssigned}
                                      className="bt_delete_beneficiary"
                                      onClick={() => handleDeleteParty()}
                                      style_type="tab"
                                      override_css={{
                                        color:{normal: "#000000", hover: COLORS.darkgold},
                                        background:{normal: COLORS.lightgold, hover: "#000000"},
                                      }}
                                    >
                                      <p>{t(`buttons.confirm`)}</p>
                                    </ButtonSolid>
                                    }
                                  </div>
                                </>
                                }
                              

                              </div>
                            </div>
                            :<></>
                          }

                        <IconButton className="more_actions" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleMenuClick(event, party, i)}>
                          <MoreVertIcon />
                        </IconButton>
                      
                        <Inner className="inner">
                          
                          

                          <BeneficiaryData>
                            <ThumbBox className="thumb" css={{width: '67', height: '88', border: {withBorder: false, color: "#D0B257"}}}>
                              <ImageProfile profile_image={party.profile_image} gender={party.gender} />
                            </ThumbBox>
                          
                            <h2 className="name">{`${party.first_name} ${party.middle_name ? party.middle_name : ''} ${party.last_name}`}</h2>
                            <h3 className={`title title_${party.title.description.toLowerCase()}`}>{party.title.description}</h3>
                          </BeneficiaryData>

                          {userData.subscription.plan.notify &&
                            <ButtonSolid
                              style_type="invert"
                              className="bt_beneficiary bt_notify"/*notified*/
                              onClick={() => handleNotifyBeneficiary(party)}
                              override_css={{
                                color:{normal: "#000000", hover: COLORS.darkgold},
                                icon:{width: "18px", height: "18px"},
                              }}>
                              <p>{`${party.is_notified ? t('buttons.notified') : t('buttons.notify')}`}</p>
                              {party.is_notified &&
                              <div className="icon">
                                <IcoCheck />
                              </div>
                              }
                            </ButtonSolid>
                          }

                        </Inner>
                      </Beneficiary>
                  )})
                :<></>
              :<></>
              }
              </div>
          </BeneficiariesStyled>
          </div>
          :
          <NoBeneficiaries>
            <div className="inner">
              <div className="inner_container">
                <div className="image">
                  <img src={nobeneficiary} />
                </div>
                <div className="title">{t(`alerts.no_asset_yet`, { name: "Beneficiary" })}</div>
                <div className="description">{t(`basics.add_new_party`)}</div>
                <ButtonSolid
                  style_type="default"
                  className="bt_add_beneficiary"
                  onClick={handleAddNewBeneficiary}
                  override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}>
                  <p>{t(`buttons.add_new_party`)}</p>
                </ButtonSolid>
              </div>
            </div>
          </NoBeneficiaries>
        }

      </Container>
      </div>

    </Wrapper>
  )
}

export default PartiesContainer
