import React from "react";
import DashboardContainer from "./DashboardContainer";

const Dashboard = (props) => {

  return (
      <DashboardContainer props={props} location={props.location} history={props.history} />
  );

};

export default Dashboard;
