import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { breakpoints } from '../../../helpers/breakpoints';

import BoxContainer from '../../../components/Elements/BoxContainer';
import ButtonSolid from '../../../components/Elements/Buttons/Solid';
import ButtonIcon from '../../../components/Elements/Buttons/Icon';
import PartiesItem from '../../../components/Elements/Parties/Item';
import Input from '../../../components/Elements/Form/Input';
import Select from '../../../components/Elements/Form/Select';
import PhoneInputCountryCode from '../../../components/Elements/Form/PhoneInputCountryCode';
import Switch from '../../../components/Elements/Form/Switch';
import DatePicker from '../../../components/Elements/Form/DatePicker';
import ThumbBox from '../../../components/Elements/ThumbBox';
import Title from '../../../components/Elements/Title';

import { IcoBack,
         IcoCameraSolid,
         Spinner,
         IcoClose,
         IcoUpload } from '../../../helpers/constants';
import { COLORS } from '../../../helpers/constants';
import { formBeneficiary } from '../../../helpers/forms';
import { ContentContext } from '../../../context';
import { validateEmail } from '../../../helpers/functions';
import { isValidPhoneNumber, getCountries, getCountryCallingCode } from 'react-phone-number-input';
import { MdCloudUpload } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";

import { Modal, Fade, Backdrop, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
        
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import flags from 'react-phone-number-input/flags'

import { toast } from 'react-toastify';

//import { getCountries, getCountryCallingCode } from 'react-phone-number-input';
//import PhoneInput from 'react-phone-number-input'
//import en from 'react-phone-number-input/locale/en.json';
//import Switch from '@material-ui/core/Switch';

const Wrapper = styled.div`

    display: flex;
    //flex: 1;
    padding: 10px;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    //background-color: pink;
    /*
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 20px;
    //width: calc(100% - 350px);
    .box_data{
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        .inner{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            padding: 20px;
        }
    }
    .box_assets{
        margin-bottom: 20px;
    }
    .box_header_actions{
        max-height: 60px;
        box-shadow: 0!important;
        .inner{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            p.title{
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 0 10px;
                padding: 0;
            }
            .bt_save{
                margin-left: auto;
            }
            .action_back{
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .action_save{
                display: flex;
                flex-direction: row;
                align-items: center;
                .loader{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 150px;
                    min-height: 40px;
                    svg{
                        color: ${COLORS.lightgold};
                    }
                }
            }
        }
    }
    .box_recent{
        margin-top: 20px;
    }
    .box_form{
        width: 100%;
        margin-top: 20px;
        .inner_form{
            display: flex;
            width: 100%;
            flex-direction: column;
            padding: 20px;
            .form_top{
                display: flex;
                width: 100%;
                flex-direction: row;
                padding-bottom: 20px;
                border-bottom: 1px solid ${COLORS.iceblue};
                //background-color: pink;
                .left{
                    display: flex;
                    width: 320px;
                }
                .right{
                    display: flex;
                    width: 100%;
                }
            }
            .form_bottom{
                display: flex;
                width: 100%;
                flex-direction: row;
                //background-color: skyblue;
                .center{
                    width: 100%;
                }
                .title{
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    @media ${breakpoints.xs} {
        //min-width: 576px
    }
    @media ${breakpoints.sm} {
        //min-width: 768px
        
    }
    @media ${breakpoints.md} {
        //min-width: 992px
        width: 662px;
    }
    @media ${breakpoints.lg} {
        //min-width: 1200px
        width: 870px;
    }
    @media ${breakpoints.xl} {
        //min-width: 1366px
        width: 1036px;
    }
    @media ${breakpoints.xxl} {
        //min-width: 1440px
        width: 1110px;
    }
    @media ${breakpoints.xxxl} {
        //min-width: 1680px
        width: 1350px;
        //background-color: pink;
    }
    @media ${breakpoints.xxxxl} {
        //min-width: 1920px
        width: 1590px;
        background-color: skyblue;
    }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-left: 20px;
  .box_vip{
    margin-bottom: 20px;
    &.stretched{
      flex: 1;
    }
  }
  .box_countdown{
    &.stretched{
      flex: 1;
    }
  }
`
const BeneficiaryForm = styled.div`
    width: 100%;
`
const Fields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    
    
    
    .double_field{
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .field{
            margin-bottom: 10px;
        }
    }
    .single_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
        .box_important{
            display: flex;
            width: 100%;
            flex-direction: row;
            font-size: 13px;
            //
            .text_important{
                width: 100%;
                .red{
                    color: ${COLORS.red}
                }
                p{
                    margin: 0;
                }
            }
            .notify_beneficiary{
                width: 86px;
                align-items: center;
            }
        }
    }
`
const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: calc(50% - 10px);
    .phone_international{
        //width: 120px;
        //margin-right: 15px;
    }
    &.age_gender{
        .age{
            width: 100px!important;
            margin-right: 15px;
        }
    }
    &.field_title{
        margin-top: 15px;
        margin-bottom: 0!important;
    }
    &.notify_beneficiary{
        margin-bottom: 0!important;
    }
`
const WeapperImageProfile = styled.div`
    
    display: flex;
    flex-direction: column;
    width: 220px;
    justify-content: space-between;
    //align-items: flex-start;

    .box_image{
        position: relative;
        display: flex;
        flex-direction: column;
        height: 220px;
        
    }
    .field{
        width: 100%!important;
        margin-bottom: 10px;
    }
`
const ImageProfile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    width: 220px;
    height: 220px;
    border: 2px solid transparent;

    background-color: ${COLORS.whiteTwo};
    
    img{
        height: 100%;
    }
`
const ButtonAddImageProfile = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: -10px;
`
const ModalStyled = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0!important;
    border-radius: 16px;

    :focus{
        outline: none!important;
    }

    position: absolute;
    top: 20px!important;
    overflow: scroll;
    margin: 0 auto;
    width: 700px;
    height: 100%;
    display: block;
`
const ContainerModalProfileImage = styled.div`
    
    width: 100%;
    
    outline: 0!important;
    

    .actions{
        display: flex;
        flex-direction: row;
        .bt_cancel_image{
            margin-left: 5px;
        }
    }
    .content_action_browse{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px dashed ${COLORS.cloudyblue};
        margin-top: 50px;
        padding-top: 40px;
        padding-bottom: 40px;
        input{
            display: none;
        }
    }
    .modal_product{
        padding: 5px;
        background-color: #FFFFFF;
        -webkit-box-shadow: 0px 0px 15px -5px #000000;
        box-shadow: 0px 0px 15px -5px #000000;
        .inner_modal_image{
            border-radius: 0;
            overflow: hidden;
        }
    }

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {
        .modal_product{
            padding: 5px;
            border-radius: 16px;
            .inner_modal_image{
                border-top-left-radius: 13px;
                border-top-right-radius: 13px;
                overflow: hidden;
            }
        }
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`
const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 25px;
    //background-color: #FFD200;
    //color: #FFD200;
    cursor: pointer;
    right: 20px;
    top: 20px;
    z-index: 9;
    .icon{
        display: flex;
        width: 35px;
        svg{
            fill: ${COLORS.lightgold};
        }
    }
    :hover{
        .icon{
            svg{
                fill: #000000;
            }
        }
    }
`
const ButtonBrowseProfileImage = styled.div`
    cursor: pointer;
    .icon{
        display: flex;
        width: 60px;
        svg{
            fill: ${COLORS.cloudyblue};
        }
    }
    :hover{
        .icon{
            svg{
                fill: ${COLORS.lightgold};
            }
    }
    }
`


function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
        return;
    }

    canvas.toBlob(
        (blob) => {
            const previewUrl = window.URL.createObjectURL(blob);

            const anchor = document.createElement('a');
            anchor.download = 'cropPreview.png';
            anchor.href = URL.createObjectURL(blob);
            anchor.click();

            window.URL.revokeObjectURL(previewUrl);
        },
        'image/png',
        1
    );
}


const UploadImages = (props) => {

    const { getImageProfile } = useContext(ContentContext);
    
    const { croppedImage, setCroppedImage, setOriginalImage, handleClose } = props;
    const [ imageBase64, setImageBase64 ] = useState(null);
    const [ openModal, setOpenModal ] = useState(false);
    const [ upImg, setUpImg ] = useState();
    const imgRef = useRef(null);
    const inputFileRef = useRef( null );
    const imgCroppedRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [ crop, setCrop ] = useState({
        unit: 'px',
        width: 250,
        minWidth: 250,
        minHeight: 250,
        maxWidth: 500,
        maxHeight: 500,
        aspect: 1 / 1
    });
    const [ completedCrop, setCompletedCrop ] = useState(null);
    
    const [ src, selectFile ] = useState(null);

    const getCroppedImg = () => {

        const canvas = document.createElement("canvas");
        const scaleX = upImg.naturalWidth / upImg.width;
        const scaleY = upImg.naturalHeight / upImg.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
      
        // New lines to be added
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
        
        ctx.drawImage(
          upImg,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        // As Base64 string
        const base64Image = canvas.toDataURL("image/jpeg");
        return base64Image;

      }
      /*
    const onSelectFile = async (e) => {
        selectFile(URL.createObjectURL(e.target.files[0]));
        console.log("-----> onSelectFile")
        console.log(e.target.files[0]);
        console.log("-----> onSelectFile")
        const originalfile = await toBase64(e.target.files[0]);
        setOriginalImage(originalfile);
        setUpImg(e.target.files[0]);
    };
    */
    const onSelectFile = async (e) => {
        const originalfile = await toBase64(e.target.files[0]);
        setOriginalImage(originalfile);
        selectFile(URL.createObjectURL(e.target.files[0]));
        setUpImg(e.target.files[0]);
    }

    const getBase64File = async() => {
        
    }

    
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    
    useEffect(() => {

        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

    }, [completedCrop]);


    const handleProfileImage = () => {

        setOpenModal(true);

    }

    const handleGenerateThumb = () => {

        const croppedImg = getCroppedImg();  
        setCroppedImage(croppedImg);
        setOpenModal(false);
        selectFile(null);
        handleClose();
        return null;

    }


    const handleBrowseProfileImage = () => {
        inputFileRef.current.click();
      }

    const resolveImage = (profileImage) => {
        getImageProfile(profileImage).then((res) => {
            setImageBase64(URL.createObjectURL(res.data));
        })
    }
    

    
    return (
        <Wrapper>

            <ContainerModalProfileImage>

                <div className="modal_product">

                    {src ?
                        <div className='inner_modal_image'>
                            <ReactCrop
                                src={src}
                                onImageLoaded={setUpImg}
                                //onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                            />

                            <div className="actions">
                                <ButtonSolid
                                    type="submit"
                                    style_type="default"
                                    className="bt_save"
                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                    onClick={() =>
                                        handleGenerateThumb()
                                    }
                                >
                                    <p>Save Image</p>
                                </ButtonSolid>

                                <ButtonSolid
                                    type="submit"
                                    style_type="default"
                                    className="bt_cancel_image"
                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                    onClick={() =>
                                        handleClose()
                                    }
                                >
                                    <p>Cancel</p>
                                </ButtonSolid>
                            </div>
                        </div>

                    :

                        <div className="content_action_browse">
                            <input ref={inputFileRef} type="file" accept="image/*" onChange={onSelectFile} />
                            <ButtonBrowseProfileImage onClick={handleBrowseProfileImage}>
                                <div className="icon">
                                    <MdCloudUpload size={50} />
                                </div>
                            </ButtonBrowseProfileImage>
                        </div>

                    }

                </div>
            </ContainerModalProfileImage>

        </Wrapper>
    )
}

export default UploadImages;
