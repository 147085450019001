import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import { COLORS } from '../../helpers/constants';
import { AuthContext } from '../../context';
import storage from 'local-storage-fallback';

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    border-radius: 12px;
    svg{
        color: ${COLORS.lightgold};
    }
    .box_container{
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 35px 40px 35px 40px;
        width: auto;
        .assign_text{
            width: 600px;
            font-weight: 500;
            margin-bottom: 30px;
            text-align: center;
        }
    }
    
 `  

const OverlayAssignAssetWait = (props) => {

    const { getAssignAssetOwnerData, pcode } = useContext(AuthContext);
    const { setShowAssignOverlay } = props;

    const [ assignAssetOwnerData, setAssignAssetOwnerData ] = useState(null);

    useEffect(() => {
        
        if(storage.getItem('ie_pcode') && storage.getItem('ie_pcode').length > 0) {
            const payload = { party_code: storage.getItem('ie_pcode') }
            const result = getAssignAssetOwnerData(payload);
            result.then((res) => {
                console.log("OverlayAssignAssetWait ********************************")
                console.log(res.data);
                console.log("OverlayAssignAssetWait ********************************")
                setAssignAssetOwnerData(res.data);
            })
        }
    
      return () => {
        
      }
    }, [storage.getItem('ie_pcode')])
    
    

    return (
        <Wrapper>

            <BoxContainer className="box_container">
                {(assignAssetOwnerData !== null && Object.keys(assignAssetOwnerData).length) ?
                <>
                <div className="assign_text">
                    {assignAssetOwnerData.first_name + ' ' + assignAssetOwnerData.last_name} te asigno como beneficiario de alguno/s de sus activo/s, para opoder acceder a esta información tenés que registrarte con el mismo E-Mail al que recibiste la notificacion, o logearte en caso de que ya estes registrado.
                </div>

                <ButtonSolid
                    style_type="default"
                    className="bt_close"
                    onClick={() => setShowAssignOverlay(false)/*setShowOverlayAssignAsset(false)*/}
                    override_css={null}>
                    <p>Close</p>
                </ButtonSolid>
                </>
                :
                <CircularProgress size={45} />
                }

            </BoxContainer>

        </Wrapper>
    )
}

export default OverlayAssignAssetWait;