import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLORS } from '../../helpers/constants';
import ButtonSolid from '../Elements/Buttons/Solid';
import { useTranslation } from 'react-i18next';

import { IoIosCloseCircleOutline } from "react-icons/io";

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0,0,0, 0.90);
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    .overlay_action_inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        p.message,
        p.target_change{
            margin: 0;
            text-align: center;
            color: #F5F5F5;
            font-weight: 600;
        }
        p.target_change{
            color: ${COLORS.lightgold};
            margin-top: 10px;
        }
        .loader{
            margin-top: 15px;
            svg{
                color: ${COLORS.lightgold};
            }
        }
    }
    .bt_confirm{
        margin-top: 15px;
    }

`  
const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 25px;
    //background-color: #FFD200;
    //color: #FFD200;
    cursor: pointer;
    right: 13px;
    top: 13px;
    z-index: 9;
    .icon{
        display: flex;
        svg{
            width: 35px;
            height: 35px;
            fill: ${COLORS.whiteTwo};
        }
    }
    :hover{
        .icon{
            svg{
                fill: ${COLORS.lightgold};
            }
        }
    }
`

const OverlayAction = (props) => {

    const { t } = useTranslation();
    const { message, 
            onClick,
            setShowOverlayAction,
            targetChange,
            loading,
            setLoading } = props;
    
    const handleClose = () => {
        setShowOverlayAction(null);
    }

    return (
        <Wrapper>
            <div className="overlay_action_inner">
                <ButtonClose
                    onClick={() => handleClose()}
                >
                    <div className="icon">
                        <IoIosCloseCircleOutline />
                    </div>
                </ButtonClose>
                <p className="message">{message}</p>   
                <p className="target_change">{targetChange}</p>   
                
                {!loading ? 
                <ButtonSolid
                    disabled={false}
                    className="bt_confirm"
                    onClick={() => onClick()}
                    style_type="tab"
                    override_css={{
                    color:{normal: "#000000", hover: COLORS.darkgold},
                    background:{normal: COLORS.lightgold, hover: "#000000"},
                    }}
                >
                    <p>{t(`buttons.confirm`)}</p>
                </ButtonSolid>  
                :
                <div className="loader">
                    <CircularProgress size={34} />
                </div>
                }         
            </div>
        </Wrapper>
    )
}

export default OverlayAction;