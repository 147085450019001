import React, { useState,
                useCallback,
                useContext,
                useEffect,
                useRef } from 'react';

import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import { validateEmail } from '../../../helpers/functions';
import { breakpoints } from '../../../helpers/breakpoints';
import CloseIcon from '@material-ui/icons/Close';
import { TfiClose } from "react-icons/tfi";

import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import ButtonSolid from '../../../components/Elements/Buttons/Solid';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Input from '../../../components/Elements/Form/Input';
import Textarea from '../../../components/Elements/Form/Textarea';
import { ContentContext } from '../../../context';
import OverlayWaiting from '../../../components/Elements/OverlayWaiting';

const DialogStyled = styled(Dialog)`
    .inner{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px 25px 25px 25px;
        position: relative;

        .bt_close{
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 27px;
            line-height: 27px;
            cursor: pointer;
            color: ${COLORS.darkgrey};
            :hover{
                color: ${COLORS.lightgold};
            }
        }
        .title{
            font-size: 22px;
            line-height: 22px;
            padding-bottom: 15px;
            margin-top: 5px;
            margin-bottom: 20px;
            border-bottom: 1px solid ${COLORS.whiteFour}
        }
        .payment_form{
            //border: 1px solid ${COLORS.whiteFour};
            border-radius: 6px;
            width: 100%;
            box-sizing: border-box;
            padding: 0;
        }
    }
    .form_single{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        .description{
            width: 100%;
            textarea{
                height: 100px;
                resize: none;
            }
        }
        :last-child{
            margin-bottom: 0;
        }
    }
    .form_double{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0;
        .field{
            width: calc(50% - 10px);
            margin-bottom: 10px;
        }
        :last-child{
            margin-bottom: 0;
        }
    }
    .message_sended{
        margin-bottom: 10px;
        margin-top: 20px;
    }
    
    .form_actions{
        margin-top: 20px!important;
    }
`



const ModalPaymentMethod = (props) => {

    const { t } = useTranslation();

    const { sendContactUs } = useContext(ContentContext);

    const { open, 
            setOpen, 
            content, 
            callback = null } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [ formData, setFormData ] = useState({});
    const [ overlayWaiting, setOverlayWaiting ] = useState(false);
    const [ isSended, setisSended ] = useState(false);

    let inputRef = useRef({});

    useEffect(() => {

        setFormData({
            first_name: { value: "", required: true, disabled: false, ref: "first_name", error: false },
            last_name: { value: "", required: true, disabled: false, ref: "last_name", error: false },
            email: { value: "", required: true, disabled: false, ref: "email", error: false },
            message: { value: "", required: true, disabled: false, ref: "message", error: false },
        });

        return () => {
            
        }

    }, [])

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);

        return null;

    }


    


    const handleSubmit = async (event) => {

        setOverlayWaiting(true);
        
        event.preventDefault();     

        let haveError = false;
        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {

                if(formData[key].required){
                    
                    if(!formData[key].value.length){
                        haveError = true;
                        updateFormData[key].error = true;
                        
                    }else{
                        updateFormData[key].error = false;
                    }

                    if(key == 'email'){
                        const validEmail = validateEmail(formData[key].value);
                        if(!validEmail){
                            haveError = true;
                            updateFormData[key].error = true;
                        }else{
                            updateFormData[key].error = false;
                        }
                    }

                }

            });

            if(!haveError){

                const payload = {first_name: formData.first_name.value,
                                 last_name: formData.last_name.value,
                                 email: formData.email.value,
                                 msg: formData.message.value}
                                 
                const result = sendContactUs(payload);
                result.then(res => {
                    setisSended(true);
                    resetForm();
                    setOverlayWaiting(false);
                    
                    setTimeout(() => {
                        setisSended(false);
                    }, 5000);

                })
            }
        }
    }

    const resetForm = () => {
        
        Object.keys(inputRef.current).map(function(key) {
            inputRef.current[key].value = '';
        })
        Object.keys(formData).map(function(key) {
            formData[key].value = '';
            if(key === 'state'){
                formData['state'].disabled = true;
            }
        })

    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
      
        console.log("content", content)
    
      return () => { }
    }, [content])
    


    return (

        <div>
            <DialogStyled
                fullScreen={fullScreen}
                fullWidth={true}
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <div className="inner">

                    {overlayWaiting &&
                        <OverlayWaiting />
                    }

                    <div className="bt_close" onClick={handleClose}>
                        <TfiClose />
                    </div>

                    <div className="title">Contact Us</div>

                    
                    <div className="payment_form">
                        <div className="form_single">
                            <Input
                                className={`field first_name ${formData?.first_name?.error ? "error" : ""}`}
                                isRequired={"true"}
                                style_type="default"
                                type="text"
                                label={t(`labels.first_name`)}
                                inputRef={inputRef}
                                elName={formData?.first_name?.ref}
                                value={formData?.first_name?.value}
                                onChange={handleFormElementChange}
                            />
                        </div>
                        <div className="form_single">
                            <Input
                                className={`field last_name ${formData?.last_name?.error ? "error" : ""}`}
                                isRequired={"true"}
                                style_type="default"
                                type="text"
                                label={t(`labels.last_name`)}
                                inputRef={inputRef}
                                elName={formData?.last_name?.ref}
                                value={formData?.last_name?.value}
                                onChange={handleFormElementChange}
                            />
                        </div>
                        <div className="form_single">
                            <Input
                                className={`field email ${formData?.email?.error ? "error" : ""}`}
                                isRequired={"true"}
                                style_type="default"
                                type="text"
                                label={t(`labels.email`)}
                                inputRef={inputRef}
                                elName={formData?.email?.ref}
                                value={formData?.email?.value}
                                onChange={handleFormElementChange}
                            />
                        </div>
                        <div className="form_single">
                            <Textarea
                                className={`message ${formData?.message?.error ? "error" : ""}`}
                                style_type="default"
                                label={`${t("labels.message")}:`}
                                inputRef={inputRef}
                                type="text"
                                elName={formData?.message?.ref}
                                value={formData?.message?.value}
                                onChange={handleFormElementChange}
                            />

                            

                        </div>
                        
                        {isSended ?
                        <div className='message_sended'>
                            Thank you for reaching out! We have received your message and will get back to you shortly.
                        </div> : ""}
                        
                        <div className='form_actions'>
                            <ButtonSolid
                                type="submit" disabled={false}
                                style_type="default"
                                className="bt_subscribe"
                                override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}
                                onClick={handleSubmit}
                            >
                                <p>Send</p>
                            </ButtonSolid>
                        </div>
                    </div>  

                </div>

            </DialogStyled>
        </div>

    )
}

export default ModalPaymentMethod
