import React, { useEffect, useContext, useState } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { useLocation } from 'react-router';

import LayoutContainer from './components/Layout/LayoutContainer';
import { path } from 'ramda';
import { ContentProvider } from './context';
import OverlayVaultOpen from './components/Elements/OverlayVaultOpen';
import OverlayAssetsLimitReach from './components/Elements/OverlayAssetsLimitReach';
import OverlayBeneficiariesLimitReach from './components/Elements/OverlayBeneficiariesLimitReach';
import OverlayAssignAssetWait from './components/Elements/OverlayAssignAssetWait';
import OverlayDowngradePlan from './components/Elements/OverlayDowngradePlan';
import Home from './pages/Home';
import Routes from './Routes';
import storage from 'local-storage-fallback';
import { AuthContext } from './context';

const isNotAuth = authContext => {
  return path(['isAuth'], authContext) === false;
};

const allowedRoutes = ['/', '/signin', '/signup', '/forgot', '/recovery_password'];

const Auth = ({ history }) => {
  
  const { isLoading, 
          isAuth, 
          isTheVaultOpen, 
          getAssignAssetOwnerData, 
          overlayAssetsLimitReach, 
          overlayBeneficiariesLimitReach, 
          overlayDowngradePlan } = useContext(AuthContext);
          
  const [ showContent, setShowContent ] = useState(null);
  const [ showAssignOverlay, setShowAssignOverlay ] = useState(false);
  const [ assignAssetOwnerData, setAssignAssetOwnerData ] = useState(null);
  const { search, pathname } = useLocation();

  useEffect(() => {
    
   
    if(storage.getItem('ie_access_token')){
      setShowContent(true);
    }

    if(!isLoading && !isAuth && storage.getItem('ie_access_token') == null){
      if(!allowedRoutes.includes(path(['location', 'pathname'], history))){

        let query = '';
        if(search.length > 0) {
          query = '?' + new URLSearchParams(search);
        }

        history.push('/'+query);
      }
    }
    if(!isLoading && isAuth){
      if(allowedRoutes.includes(path(['location', 'pathname'], history))){
        history.push('/dashboard');
      }else{
        if(storage.getItem('ie_pcode') && storage.getItem('ie_pcode').length){
          history.push('/assigned-assets?pcode='+storage.getItem('ie_pcode'));
        }
        
      }
    }
    
  },[isAuth, isLoading, storage.getItem('ie_access_token')])

  useEffect(() => {
    
    //if(pathname === '/assigned-assets'){
      //http://localhost:6103/assigned-asset?pcode=12e78d3ed782300d00051097b59c34914404356c


      if(search.length > 0) {
        const query = new URLSearchParams(search);
        if(query.get("pcode") && query.get("pcode").length > 0){
          //alert(query.get("pcode"))
          setShowAssignOverlay(true);
          storage.setItem('ie_pcode', query.get("pcode"));
            //setRecoveryPass(true);
            //setRecoveryToken(query.get("pcode"));
        }else{
            //history.push('/signin');
        }
      }else{
          //history.push('/signin');
      }

    //}
  
    return () => {
      
    }
  }, [pathname])
  
  

  return (
    <>
      {(!isAuth && !isLoading && !storage.getItem('ie_access_token') && showAssignOverlay && (storage.getItem('ie_pcode') && storage.getItem('ie_pcode').length > 0)) && 
        <OverlayAssignAssetWait setShowAssignOverlay={setShowAssignOverlay} pcode={storage.getItem('ie_pcode')} />
      }
      {isAuth &&
          
          <ContentProvider>
            <LayoutContainer>
              {overlayBeneficiariesLimitReach && <OverlayBeneficiariesLimitReach />}
              {overlayAssetsLimitReach && <OverlayAssetsLimitReach />}
              {overlayDowngradePlan && <OverlayDowngradePlan />}
              {isTheVaultOpen && <OverlayVaultOpen />}
              <Routes />
            </LayoutContainer>
          </ContentProvider>
          
        
      }
      {!isAuth && (
        <Switch>
          <Route path="/signup" exact component={Home} />
          <Route path="/signin" exact component={Home} />
          <Route path="/forgot" exact component={Home} />
          <Route path="/recovery_password" exact component={Home} />
          <Route path="/" exact component={Home} />
        </Switch>
      )}
    </>
  );

};

export default withRouter(Auth);
