import React, { useState, useEffect, useCallback, useReducer, useMemo, useRef } from 'react';
import { path, defaultTo } from 'ramda';
import storage from 'local-storage-fallback';
import axios from 'axios';
/*

import ReactGA from 'react-ga';
import { defaultTo, path } from 'ramda';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import storage from 'local-storage-fallback';
import authContextSlice, { authContextInitialState, loggedOutUser } from './AuthContext.redux';
import connect, { handleError } from '../helpers/connect';
import {
  loggedUserIsCaptain,
  loggedUserIsManager,
  loggedUserIsExpert,
  loggedUserIsDistrict,
  loggedUserIsTerritory,
  loggedUserIsGasStationOwner,
} from '../helpers';
import { resetState } from '../redux/store';

export const AUTH_CONTEXT_ITEMS = {
  MY_PROFILE: 'myProfile',
};
*/

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {

    const [ isAuth, setIsAuth ] = useState(false);
    const [ authToken, setAuthToken ] = useState(null);
    const [ userData, setUserData ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(null);
    const [ isTheVaultOpen, setIsTheVaultOpen ] = useState(null);
    const [ overlayVaultOpen, setOverlayVaultOpen ] = useState(null);
    const [ overlayAssetsLimitReach, setOverlayAssetsLimitReach ] = useState(null);
    const [ overlayBeneficiariesLimitReach, setOverlayBeneficiariesLimitReach ] = useState(null);
    const [ overlayDowngradePlan, setOverlayDowngradePlan ] = useState(null);
    const [ twofaMailCodeActive, setTwofaMailCodeActive ] = useState(false);
    
    useEffect(() => {

      //alert("useEffect 1")
        /*
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('utm_source') === 'mobile_app') {
        setIsMobileApp(true);
        }
        */

        setAuthToken(defaultTo('', storage.getItem('ie_access_token')));

    }, []);



    
    const login = useCallback(
        async (email, password, twofaMailCode = null) => {
          setIsLoading(true);
            const payload = {
                grant_type: 'password',
                client_id: process.env.REACT_APP_API_CLIENT_ID,
                client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
                email,
                password,
                twofaMailCode,
                scope: '*',
            };

            const resultJWT = await axios.post(`${process.env.REACT_APP_API_SERVER}/login`, payload, {
                headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                },
            });
            
            if(resultJWT.data.success){
              
              if(resultJWT.data.twofa_mail_code){

                setTwofaMailCodeActive(true);
                return resultJWT.data;

              }else{

                const accessToken = path(['data', 'access_token'], resultJWT);
                const refreshToken = path(['data', 'refresh_token'], resultJWT);

                // Set local storage.
                storage.setItem('ie_access_token', accessToken);
                storage.setItem('ie_refresh_token', refreshToken);

                updateToken(accessToken);
                setAuthToken(accessToken);

                setIsLoading(false);

                return resultJWT.data;

              }

            }else{

              setIsLoading(false);

            }
            //console.log(resultJWT.data);
            return resultJWT.data;
            

          /*
        } catch (e) {
            //alert("ERROR LOGIN")
            setIsLoading(false);
            console.log(e);

            return {lala: "sasa"};
            //handleError(e);

        }

        return false;
        */

        },[],
    );
    
    const logout = useCallback(async () => {
      
      storage.clear();
      setTwofaMailCodeActive(false);
      setIsAuth(false);

      await axios.get(`${process.env.REACT_APP_API_SERVER}/logout`, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${authToken}`,
        },
      });
      

        /*
        try {
            await axios.get(`${process.env.REACT_APP_API_SERVER}/api/logout`, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${token}`,
                },
            });

            resetState(reduxDispatch);
            updateUserLoginData({ ...loggedOutUser });

            // Remove session storage.
            storage.clear();
        } catch (e) {
            handleError(e);
        }
        */
    }, [authToken]);

    const forgot = useCallback(async (payload) => {

      const requestURL = `${process.env.REACT_APP_API_SERVER}/forgot`;
		  const result = await axios.post(requestURL, payload);
      return result;

    }, []);

    const recovery_password = useCallback(async (payload) => {

      const requestURL = `${process.env.REACT_APP_API_SERVER}/recovery_password`;
		  const result = await axios.post(requestURL, payload);
      return result;

    }, []);

    const sendRegister = useCallback(async (payload) => {
      console.log(payload);
      const requestURL = `${process.env.REACT_APP_API_SERVER}/register`;
		  const result = await axios.post(requestURL, payload);
      return result;
    }, []);

    const sendValidateAccount = useCallback(async (payload) => {
      console.log(payload);
      const requestURL = `${process.env.REACT_APP_API_SERVER}/validate_account`;
		  const result = await axios.post(requestURL, payload);
      return result;
    }, []);

    const resend_validation = useCallback(async (payload) => {
      console.log(payload);
      const requestURL = `${process.env.REACT_APP_API_SERVER}/resend_validation`;
		  const result = await axios.post(requestURL, payload);
      return result;
    }, []);

    const sendCountdownResponseStatus = useCallback(async (payload) => {
      //console.log(payload);
      const requestURL = `${process.env.REACT_APP_API_SERVER}/countdown_response_status`;
		  const result = await axios.post(requestURL, payload);
      return result;
    }, []);
    
    const recupero = useCallback(async (email, dni) => {
        /*
        try {
            const result = await axios.post(`${process.env.REACT_APP_API_SERVER}/api/forgot-password`, null, {
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8',
                    'Access-Control-Allow-Origin': '*',
                },
                params: {
                    email,
                    dni,
                },
            });

            const message = defaultTo('', path(['data', 'data', 'msg'], result));

            document.getElementById('messenger').style.bottom = 0;
            document.getElementById('messenger-inner').innerHTML = message;

            setTimeout(() => {
                document.getElementById('messenger').style.bottom = '-100px';
            }, 7000);

        } catch (e) {
        // Sentry.captureException(e);
        }

        return true;
        */
    }, []);

    const getAssignAssetOwnerData = useCallback(async (payload) => {

      const result = await axios.post(`${process.env.REACT_APP_API_SERVER}/assign-asset-owner`, payload, {
            headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            },
        });
        return result.data;

    }, []);

    const fetchData = useCallback(async () => {
        
          try {
              /*
            if (!fetchedInitialData.current) {
              setIsLoading(true);
            }
            */
            
            if(authToken){
              //alert(authToken);
              const headers = {
                headers: {
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Access-Control-Allow-Origin': '*',
                  Authorization: `Bearer ${authToken}`,
                },
              };
              
              //${process.env.REACT_APP_API_SERVER}/api/user
              const userDataResponse = await axios.get(`${process.env.REACT_APP_API_SERVER}/user`, headers);

              setUserData(path(['data', 'data'], userDataResponse));
              const userId = path(['data', 'id'], userDataResponse);
              const is_vault_open = path(['data', 'data', 'is_vault_open'], userDataResponse);
              
              setIsTheVaultOpen(is_vault_open);
              setOverlayVaultOpen(is_vault_open);

              setIsAuth(true);
              setIsLoading(false);
            }
            //setUserData(userData);
            /*
            const results = await Promise.all([
              axios.get(`${process.env.REACT_APP_API_SERVER}/api/users/${userId}`, headers),
              axios.get(`${process.env.REACT_APP_API_SERVER}/api/notifications?mobile_app=${isMobileApp}`, headers),
              axios.get(`${process.env.REACT_APP_API_SERVER}/api/whatshappening`, headers),
              axios.get(`${process.env.REACT_APP_API_SERVER}/api/users/${userId}/myprofile`, headers),
              axios.get(`${process.env.REACT_APP_API_SERVER}/api/users/${userId}/mystation`, headers),
            ]);
      
            ReactGA.set({ userId });
      
            updateUserData({
              resultUser: userData,
              resultProtagonista: results[0].data,
              resultNotificaciones: results[1].data,
              resultQueEstaPasando: results[2].data,
              resultPerfil: results[3].data,
              resultPerfilMiEstacion: results[4].data,
            });
            */
        
    
          
        } catch (e) {
            /*
          updateUserLoginData({ ...loggedOutUser });
          storage.clear();
          window.location = '/';
          */
        }
        //}, [updateUserData, updateUserLoginData, isMobileApp, token]);
      }, [authToken]);

    const updateToken = useCallback(accessToken => {
        console.log("updateToken: " + accessToken);
        //connect.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }, []);

    useEffect(() => {
      //alert("useEffect 2")
        if (!authToken) {

          //alert("useEffect !authToken")
            //alert(authToken);
            //updateUserLoginData({ ...loggedOutUser });
        } else {

          //alert("useEffect authToken")
            //alert("updateToken");
            updateToken(authToken);
            fetchData();
        }
    //}, [updateUserLoginData, updateToken, authToken, fetchData]);
    }, [updateToken, authToken, fetchData]);


    

    
    /*
  const reduxDispatch = useDispatch();
  const { actions, reducer } = authContextSlice;
  const [state, dispatch] = useReducer(reducer, authContextInitialState);
  const { updateAllUserData, updateUserLoginData, updateQueEstaPasando, updateUserProfileData } = useMemo(
    () => bindActionCreators(actions, dispatch),
    [actions, dispatch],
  );
  const fetchedInitialData = useRef(false);
  const [token, setToken] = useState('');
  const [isMobileApp, setIsMobileApp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = state;

  useEffect(() => {
    if (!fetchedInitialData.current) {
      resetState(reduxDispatch);
    }
  }, [reduxDispatch]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.get('utm_source') === 'mobile_app') {
      setIsMobileApp(true);
    }

    setToken(defaultTo('', storage.getItem('protagt')));
  }, []);

  const updateUserData = useCallback(
    results => {
      const {
        resultUser,
        resultProtagonista,
        resultNotificaciones,
        resultQueEstaPasando,
        resultPerfil,
        resultPerfilMiEstacion,
      } = results;

      fetchedInitialData.current = true;

      updateAllUserData({
        user: { isAuth: true, ...resultUser },
        protagonista: resultProtagonista,
        notificaciones: resultNotificaciones,
        queEstaPasando: resultQueEstaPasando,
        perfil: resultPerfil,
        perfilMiEstacion: resultPerfilMiEstacion,
      });
    },
    [updateAllUserData],
  );

  const updateToken = useCallback(accessToken => {
    connect.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }, []);

  const fetchData = useCallback(async () => {
    try {
      if (!fetchedInitialData.current) {
        setIsLoading(true);
      }

      const headers = {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`,
        },
      };

      const userData = await axios.get(`${process.env.REACT_APP_API_SERVER}/api/user`, headers);
      const userId = path(['data', 'id'], userData);

      const results = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_SERVER}/api/users/${userId}`, headers),
        axios.get(`${process.env.REACT_APP_API_SERVER}/api/notifications?mobile_app=${isMobileApp}`, headers),
        axios.get(`${process.env.REACT_APP_API_SERVER}/api/whatshappening`, headers),
        axios.get(`${process.env.REACT_APP_API_SERVER}/api/users/${userId}/myprofile`, headers),
        axios.get(`${process.env.REACT_APP_API_SERVER}/api/users/${userId}/mystation`, headers),
      ]);

      ReactGA.set({ userId });

      updateUserData({
        resultUser: userData,
        resultProtagonista: results[0].data,
        resultNotificaciones: results[1].data,
        resultQueEstaPasando: results[2].data,
        resultPerfil: results[3].data,
        resultPerfilMiEstacion: results[4].data,
      });

      setIsLoading(false);
    } catch (e) {

      updateUserLoginData({ ...loggedOutUser });
      storage.clear();
      window.location = '/';
    }
  }, [updateUserData, updateUserLoginData, isMobileApp, token]);

  useEffect(() => {
    if (!token) {
      updateUserLoginData({ ...loggedOutUser });
    } else {
      updateToken(token);
      fetchData();
    }
  }, [updateUserLoginData, updateToken, token, fetchData]);

  

  const refetch = useCallback(
    async toRefetch => {
      const userId = path(['data', 'id'], user);

      if (!userId) {
        return;
      }

      switch (toRefetch) {
        case AUTH_CONTEXT_ITEMS.MY_PROFILE: {
          const myProfileData = await connect.get(`/api/users/${userId}/myprofile`);

          if (myProfileData.data) {
            updateUserProfileData(myProfileData.data);
          }
          break;
        }
        default:
      }
      
    },
    [user, updateUserProfileData],
  );

  const ROLES = {
    CAPTAIN: loggedUserIsCaptain(state),
    MANAGER: loggedUserIsManager(state),
    EXPERT: loggedUserIsExpert(state),
    DM: loggedUserIsDistrict(state),
    TM: loggedUserIsTerritory(state),
    STATION_OWNER: loggedUserIsGasStationOwner(state),
  };
  */

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        userData,
        setUserData,
        isLoading,
        login,
        logout,
        forgot,
        recovery_password,
        resend_validation,
        isTheVaultOpen,
        overlayVaultOpen,
        setOverlayVaultOpen,
        overlayAssetsLimitReach,
        setOverlayAssetsLimitReach,
        overlayBeneficiariesLimitReach,
        setOverlayBeneficiariesLimitReach,
        overlayDowngradePlan, 
        setOverlayDowngradePlan,
        sendRegister,
        sendValidateAccount,
        sendCountdownResponseStatus,
        getAssignAssetOwnerData,
        twofaMailCodeActive, setTwofaMailCodeActive
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
