import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    display: flex;
`

const Top = ({ children, className }) => {
    return (
        <Wrapper className={className}>
            { children }
        </Wrapper>
    )
}

export default Top
