import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { COLORS,
         SIZES } from '../../../helpers/constants';
import Input from '../../Elements/Form/Input';
import ButtonSolid from '../../Elements/Buttons/Solid';
import TwoFaGoogle from './TwoFaGoogle';
import TwoFaMailCode from './TwoFaMailCode';


const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    >.title{
        font-size: 19px;
        margin-bottom: 20px;
    }
    .actions{
        margin-top: 20px;
    }
    .two_fa_google{
        padding-bottom: 15px;
        border-bottom: 1px solid #E2E2E2;
    }
    .two_fa_mailcode{
        //margin-top: 15px;
    }

`

const TwoFa = (props) => {

    const { setErrorMessage } = props;
    const [ formData, setFormData ] = useState({});
    const [ twoFactorActive, setTwoFactorActive ] = useState("mailcode");

    let inputRef = useRef({});

    useEffect(() => {
          
        setFormData({
            current_password: { value: "", required: true, disabled: false, ref: "current_password", error: false },
            new_password: { value: "", required: true, disabled: false, ref: "new_password", error: false },
            confirm_new_password: { value: "", required: true, disabled: false, ref: "confirm_new_password", error: false },
        });

        return () => {
            toast.dismiss();
        }
        
    }, [])

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
        
    }

    useEffect(() => {
      console.log(twoFactorActive);
    
      return () => {}
    }, [twoFactorActive])
    

    return (
        <Wrapper> 

            <div className="title">Two Factor Authentication</div>
            {/*
            <div className="two_fa_google">
                <TwoFaGoogle setErrorMessage={setErrorMessage} twoFactorActive={twoFactorActive} setTwoFactorActive={setTwoFactorActive} />
            </div>
            */}
            <div className="two_fa_mailcode">
                <TwoFaMailCode setErrorMessage={setErrorMessage} twoFactorActive={twoFactorActive} setTwoFactorActive={setTwoFactorActive} />
            </div>
            
        </Wrapper>
    )
}

export default TwoFa;
