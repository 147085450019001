import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants'

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    width: ${props => props.css?.width ? props.css.width + 'px' : "100px"};
    height: ${props => props.css?.height ? props.css.height + 'px' : "100px"};
    border: ${props => props.css?.border.withBorder ? '2px solid '+ props.css.border.color : '2px solid transparent' };
    background-color: ${COLORS.whiteTwo};
    img{
        height: 100%;
    }
`

const RoundedThumb = () => {



}




const Thumb = ({ children, css, className, onClick }) => {
    //console.log(css);
    return (
        <Wrapper css={css} className={className} onClick={onClick}>
            { children }
        </Wrapper>
    )
}


export default Thumb
