import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    /*
    .PhoneInput{
        .PhoneInputCountry{
            display: flex;
            flex-direction: row;
        }
    }
    */

    &.error{
        .PhoneInput{
            input{
                border: 1px solid red;
            }
        }
    }

    input{
        display: flex;
        width: calc(100% - 26px);
        background-color: ${props => props.st.input.backgroundColor};
        padding: 13px;
        font-size: ${props => props.st.input.fontSize};
        font-weight: ${props => props.st.input.fontWeight};
        color: ${props => props.st.input.color};
        border: 1px solid #F5F5F5;
        border-radius: 5px;
        font-family: 'Poppins';
        :focus {
            outline: none;
        }
        *:focus {
            outline: none;
        }
        
    }

    label{
        font-size: ${props => props.st.label.fontSize};
        font-weight: ${props => props.st.label.fontWeight};
        color: ${props => props.st.label.color};
        margin-bottom: 4px;
    }

    /*
    display: flex;
    width: 100%;
    flex-direction: column;
    
    

    
    */

`

const PhoneInputCountryCodeContainer = ( props ) => {

    const { className,
            override_css,
            style_type,
            label,
            elName,
            onChange,
            inputRef,
            countryPhone,
            value } = props;

    const [ styles, setStyle ] = useState(null);
    //const [ value, setValue ] = useState("");

    useEffect(() => {

        switch (style_type) {
            case 'default':
                setStyle({

                    input: { color: override_css?.input?.color ? override_css.input.color : COLORS.bluegrey,
                             fontSize: override_css?.input?.fontSize ? override_css.input.fontSize : "16px",
                             fontWeight: override_css?.input?.fontWeight ? override_css.input.fontWeight : "500",
                             backgroundColor: override_css?.input?.backgroundColor ? override_css.input.backgroundColor : COLORS.whiteTwo },

                    label: { fontSize: override_css?.label?.fontSize ? override_css.label.fontSize : "15px",
                             fontWeight: override_css?.label?.fontWeight ? override_css.label.fontWeight : "600",
                             color: override_css?.label?.color ? override_css.label.color : COLORS.darkgrey},

                });
                break;
        
            default:
                break;
        }

    }, []);
    
    return (
        <>
        {styles &&
        <Wrapper override={override_css} st={styles} className={className}>
            {label.length > 0 &&
                <label htmlFor={elName}>{label}</label>
            }

            <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                name={elName}
                value={value}
                onChange={(e) => onChange(elName, e)}
                ef={el => inputRef.current[elName] = el}
            />

            {/*
            <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry={countryPhone}
                country={countryPhone}
                id={elName}
                name={elName}
                value={value}
                ref={el => inputRef.current[elName] = el}
                onChange={(e) => onChange(elName, e)}
            />
            */}
        </Wrapper>
        }
        </>
    )
}

export default PhoneInputCountryCodeContainer;
