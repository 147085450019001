import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Sidebar from '../../components/Layout/Sidebar';

import Header from '../../components/Layout/Header';
import HeaderMobile from '../../components/Layout/HeaderMobile';

import { breakpoints } from '../../helpers/breakpoints';
import useMediaQuery from "../../hooks/useMediaQuery";

import { useHistory, useLocation } from 'react-router';
import { PAGES } from '../../helpers/constants';
import { defineButtonSelected } from '../../helpers/functions';
import BottomLinks from '../BottomLinks';


const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    .el_sidebar{
        display: none;
    }
    .el_sidebar_mobile{
        display: flex;
    }

    @media ${breakpoints.xs} {

    }
    @media ${breakpoints.sm} {
      
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        .el_sidebar{
            display: flex;
        }
        .el_sidebar_mobile{
            display: none;
        }
    }
    @media ${breakpoints.xl} {

    }
    @media ${breakpoints.xxl} {
      
    }
    @media ${breakpoints.xxxl} {
      
    }
    @media ${breakpoints.xxxxl} {
      
    }
`
const Content  = styled.div`
    
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;
    background-color: #F5F6FA;
    
    >.bottom_links{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    @media ${breakpoints.xs} {

    }
    @media ${breakpoints.sm} {
    
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        width: calc(100% - 250px);
    }
    @media ${breakpoints.xl} {

    }
    @media ${breakpoints.xxl} {
    
    }
    @media ${breakpoints.xxxl} {
    
    }
    @media ${breakpoints.xxxxl} {
    
    }
`
const Top  = styled.div`

    display: flex;
    width: 100%;
    height: 100px;
    background-color: #FFFFFF;

`
const LayoutContainer = ({ children }) => {

    const [ activeButton, setActiveButton ] = useState(null);
    const [ showHeader, setShowHeader ] = useState("");

    const history = useHistory();
    const location = useLocation();

    const isMobile = useMediaQuery('(max-width: 575px)');
    const isXS = useMediaQuery(breakpoints.xs);
    const isMD = useMediaQuery(breakpoints.md);
    const isLG = useMediaQuery(breakpoints.lg);

    useEffect(() => {

        const selected = defineButtonSelected(location.pathname);
        setActiveButton(selected);

        return () => {}
    }, [location])

    useEffect(() => {
        
        console.log("isMobile, isXS, isMD, isLG")
        console.log(isMobile, isXS, isMD, isLG);
        console.log("isMobile, isXS, isMD, isLG")
        if(isMobile || isXS || isMD){
            setShowHeader("mobile");
        }
        if(isLG){
            setShowHeader("desktop");
        }

      return () => {}
    }, [isMobile, isXS, isMD, isLG])
    


    return (
        <Wrapper>
            <Sidebar activeButton={activeButton} />
            
            <Content>
                {showHeader === "desktop" &&
                    <Header activeButton={activeButton} location={location} />
                }
                {showHeader === "mobile" &&
                    <HeaderMobile activeButton={activeButton} location={location} />
                }
                { children }

                <div className='bottom_links'>
                    <BottomLinks />
                </div>

            </Content>
        </Wrapper>
    )
}

export default LayoutContainer
