import React from "react";
import MyLastWillContainer from "./MyLastWillContainer";

const MyLastWill = (props) => {

  return (
      <MyLastWillContainer props={props} location={props.location} history={props.history} />
  );

};

export default MyLastWill;
