import React, { useEffect, useState, useRef, useContext } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '../../../helpers/breakpoints';
import { ContentContext } from '../../../context';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import "moment-timezone";

import CircularProgress from '@material-ui/core/CircularProgress';
import CountdownForm from './CountdownForm';

import BoxContainer from '../../../components/Elements/BoxContainer';
import Select from '../../../components/Elements/Form/Select';
import DatePicker from '../../../components/Elements/Form/DatePicker';
import Switch from '../../../components/Elements/Form/Switch';
import Textarea from '../../../components/Elements/Form/Textarea';
import Image from '../../../components/Elements/Image';
import NoImage from '../../../components/Elements/NoImage';
import ButtonSolid from '../../../components/Elements/Buttons/Solid';

import { COLORS, 
         notificate_frecuency,
         days,
         month,
         hours,
         mins } from '../../../helpers/constants';
/*
import { BoxContainer,
    ButtonSolid,
    ButtonIcon,
    PartiesItem,
    Input,
    Textarea,
    Select,
    PhoneInputCountryCode,
    Switch,
    DatePicker,
    ThumbBox,
    Title, } from '../../components/Elements';
*/

const Wrapper = styled.div`

    display: flex;
    width: 100%;

    .inner_form{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;
        .double_field{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;
            .fields{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
            }
            >.title{
                font-size: 15px!important;
                font-weight: 600;
                color: #2B2C2D;
                margin-bottom: 4px;
            }
            .field{
                width: 100%!important;
            }
        }
        
        .box_double{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .box_single{
            width: 100%;
            display: flex;
            flex-direction: row;
        }
        .action_save{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 100%;
            margin-top: 35px;
            padding-top: 25px;
            border-top: 1px solid ${COLORS.whiteFour};
        }
        .box_periodic_notification, .box_notification_method, .box_noresponse{
            display: flex;
            flex-direction: column;
            .title{
                font-size: 19px;
            }
            .inner_box{
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                .form_periodic_notification{
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    //background-color: pink;
                    .field{
                        width: 100%;
                        margin-bottom: 20px;
                    }
                    .field_notificate_frecuency{
                        margin-top: 25px;
                    }
                    .field_notificate_at{
                        margin-bottom: 0;
                        &.day{
                            margin-top: 10px;
                        }
                    }
                }
                .form_notification_method{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .field{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 20px;
                        label{
                            width: 50%;
                        }
                    }
                    .field_method_email{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-between;
                        margin-top: 25px;
                        .method_email{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                    .field_method_text{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        justify-content: space-between;
                        .method_sms{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                        }
                    }
                    .field_method_text_content{
                        margin-bottom: 0;
                        textarea{
                            height: 108px;
                        }
                        .description{

                        }
                    }
                    .is_disabled{
                        opacity: 0.35;
                    }
                }
            }
        }
        .box_periodic_notification{
            width: 100%;
        }
        .box_notification_method{
            width: 100%;
            margin-top: 35px;
        }
        .box_certificate{
            width: 100%;
            margin-top: 35px;
            padding-top: 25px;
            border-top: 1px solid ${COLORS.whiteFour};
            .form_certificate{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 15px;
                .certificate_switch{

                }
            }
        }
        .box_services{
            //width: calc(50% - 20px);
            width: 100%;
            margin-top: 35px;
            padding-top: 25px;
            border-top: 1px solid ${COLORS.whiteFour};
            .form_services{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 15px;
            }
        }
        .box_noresponse{
            width: 100%;
        }
        .box_beneficiary_list{
            width: 100%;
            margin-top: 35px;
            padding-top: 25px;
            border-top: 1px solid ${COLORS.whiteFour};
            .beneficiary_items{
                display: flex;
                flex-direction: row;
                margin-top: 15px;
                
                .beneficiary_item{
                    cursor: pointer;
                    border: 1px solid #FFFFFF;
                    margin-right: 10px;
                    border-radius: 10px;
                    :hover{
                        border: 1px solid ${COLORS.lightgold};
                        &.notselected{
                            opacity: 1;
                        }
                    }
                    &.selected{
                        opacity: 1;
                    }
                    &.notselected{
                        opacity: 0.3;
                    }
                    .thumb{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        border-radius: 10px;
                        overflow: hidden;
                        width: 70px;
                        height: 90px;
                        img{
                            float: left;
                            height: 100%;
                            border: 0;
                        }
                    }
                }
            }
            .beneficiary_asset_items{
                display: flex;
                flex-direction: column;
                //width: 100%;
                float: left;
                .beneficiary_asset_item{
                    display: flex;
                    flex-direction: row;
                    margin-top: 10px;
                    border: 1px dashed ${COLORS.cloudyblue};
                    border-radius: 10px;
                    float: left;
                    &.hide{
                        display: none;
                    }
                    &.show{
                        display: flex;
                    }
                    .beneficiary_asset{
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        margin-bottom: 10px;
                        margin-top: 10px;
                        margin-right: 10px;
                        margin-left: 10px;
                        .thumb{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            border-radius: 10px;
                            overflow: hidden;
                            width: 70px;
                            height: 70px;
                            margin-right: 10px;
                            img{
                                float: left;
                                height: 100%;
                                border: 0;
                            }
                        }
                        .data{
                            display: flex;
                            flex-direction: column;
                            .name{
                                color: ${COLORS.bluegrey};
                                margin-right: 10px;
                                font-size: 16px;
                                font-weight: 500;
                            }
                            .portion{
                                font-size: 16px;
                                font-weight: 600;
                                color: ${COLORS.lightgold};
                            }
                        }
                    }
                }
            }
        }
    }

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {
        .inner_form{
            .double_field{
                .fields{
                    flex-direction: row;
                }
                .field{
                    width: calc(50% - 10px)!important;
                }
            }
            .box_double{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .box_periodic_notification{
                width: calc(50% - 20px);
            }
            .box_notification_method{
                width: calc(50% - 20px);
                margin-top: 0;
            }
            .box_certificate{
                width: 100%;
            }
            .box_beneficiary_list{
                width: 100%;
                margin-top: 35px;
                padding-top: 25px;
                border-top: 1px solid ${COLORS.whiteFour};
                .beneficiary_items{
                    
                }
            }
            .box_periodic_notification, .box_notification_method, .box_noresponse{
                .inner_box{
                    .form_periodic_notification{
                        .field_notificate_at{
                            &.day{
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`

const CountdownFormContainer = (props) => {

    const { t } = useTranslation();

    const { servicesDropdown } = useContext(ContentContext);
    const { formData, setFormData, setFormLoaded, loadingFormData } = props;

    let inputRef = useRef({});
    
    const [ frecuencyHours, setFrecuencyHours ] = useState([]);
    const [ frecuencyDays, setFrecuencyDays ] = useState([]);
    const [ frecuencyMonth, setFrecuencyMonth ] = useState([]);
    const [ notificateFrecuency, setNotificateFrecuency ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    
    useEffect(() => {
        setNotificateFrecuency(notificate_frecuency());
        setFrecuencyHours(hours());
        setFrecuencyDays(days());
        setFrecuencyMonth(month());
    }, [formData])
    
    const handleFormElementChange = (name, value) => {
        
        let updateFormData = {...formData};

        switch (name) {
            case 'notificate_frecuency':
                updateFormData['frecuency_day'].value = '';
                updateFormData['frecuency_month'].value = '';
                updateFormData['frecuency_day'].error = false;
                updateFormData['frecuency_month'].error = false;
                break;
            case 'frecuency_day':
            case 'frecuency_month':
                updateFormData['notificate_frecuency'].value = '';
                updateFormData['notificate_frecuency'].error = false;
                break;
            case 'method_sms':
                updateFormData['method_email'].value = false;
                if(!value){
                    updateFormData['method_sms_text'].value = '';
                }
                break;
            case 'method_email':
                updateFormData['method_sms'].value = false;
                updateFormData['method_sms_text'].value = '';
                break;
        }

        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
    }

    useEffect(() => {
        const timezoneOffset = (new Date()).getTimezoneOffset();
        const split = new Date().toString().split(" ");
        const timeZone = split.slice(-3).join(' ')
    }, [])
    
    useEffect(() => {
        
        if(Object.keys(formData).length){
            setFormLoaded(true);
        }
      
    }, [formData])

    return (
        <Wrapper>
            <div className="inner_form">
                <div className="box_double">
                    <div className="box_periodic_notification">
                        <div className="title">{t(`countdown.frequency_periodic_inquiry`)}</div>
                        
                        <div className="inner_box">
                            <div className="form_periodic_notification">
                                
                                
                                <div className="field field_notificate_frecuency">
                                    <Select
                                        style_type="default"
                                        label={`${t("labels.notify_me_every")}:`}
                                        inputRef={inputRef}
                                        elName={loadingFormData ? formData.notificate_frecuency?.ref : ''}
                                        className={`category ${loadingFormData ? formData.notificate_frecuency.error ? "error" : "" : ""}`}
                                        data={notificateFrecuency}
                                        optionSelected={formData?.notificate_frecuency?.value}
                                        onChange={handleFormElementChange}
                                    />
                                </div>
                                <div className="double_field">
                                    <div className="title">Or every:</div>
                                    <div className="fields">
                                        <div className="field field_notificate_at">
                                            <Select
                                                style_type="default"
                                                label={`${t("labels.month")}:`}
                                                inputRef={inputRef}
                                                elName={loadingFormData ? formData.frecuency_month?.ref : ''}
                                                className={`category ${loadingFormData ? formData.frecuency_month.error ? "error" : "" : ""}`}
                                                data={frecuencyMonth}
                                                optionSelected={formData?.frecuency_month?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </div>
                                        <div className="field field_notificate_at day">
                                            <Select
                                                style_type="default"
                                                label={`${t("labels.day")}:`}
                                                inputRef={inputRef}
                                                elName={loadingFormData ? formData.frecuency_day?.ref : ''}
                                                className={`category ${loadingFormData ? formData.frecuency_day.error ? "error" : "" : ""}`}
                                                data={frecuencyDays}
                                                optionSelected={formData?.frecuency_day?.value}
                                                onChange={handleFormElementChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*
                                <div className="field">
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            style_type="default"
                                            label="Or every:"
                                            type="text"
                                            format="MM/DD"
                                            className={`date_birth ${loadingFormData ? formData.frecuency_date.error ? "error" : "" : ""}`}
                                            inputRef={inputRef}
                                            elName={loadingFormData ? formData.frecuency_date?.ref : ''}
                                            selectedDate={formData?.frecuency_date?.value}
                                            onChange={handleFormElementChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                */}
                                <div className="field field_notificate_at">
                                    <Select
                                        style_type="default"
                                        label={`${t("labels.at")}:`}
                                        inputRef={inputRef}
                                        elName={loadingFormData ? formData.frecuency_hours?.ref : ''}
                                        className={`category ${loadingFormData ? formData.frecuency_hours.error ? "error" : "" : ""}`}
                                        data={frecuencyHours}
                                        optionSelected={formData?.frecuency_hours?.value}
                                        onChange={handleFormElementChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="box_notification_method">
                        <div className="title">{t(`countdown.notification_method`)}</div>
                        
                        <div className="inner_box">

                            <div className="form_notification_method">
                                <div className="field field_method_email">
                                    <Switch
                                        toggled={loadingFormData ? formData.method_email.value : false}
                                        color="default"
                                        style_type="default"
                                        inputRef={inputRef}
                                        label={`${t("labels.email")}:`}
                                        elName={loadingFormData ? formData.method_email?.ref : ''}
                                        className={`method_email ${loadingFormData ? formData.method_email.error ? "error" : "" : ""}`}
                                        onChange={handleFormElementChange}
                                    />
                                </div>
                                {/*
                                <div className="field field_method_text">
                                    <Switch
                                        toggled={loadingFormData ? formData.method_sms.value : false}
                                        color="default"
                                        style_type="default"
                                        inputRef={inputRef}
                                        label={`${t("labels.text_message")}:`}
                                        elName={loadingFormData ? formData.method_sms?.ref : ''}
                                        className={`method_sms ${loadingFormData ? formData.method_sms.error ? "error" : "" : ""}`}
                                        onChange={handleFormElementChange}
                                    />
                                </div>
                                <div className={`field field_method_text_content ${!formData?.method_sms?.value ? 'is_disabled' : ''}`}>
                                    <Textarea
                                        className={`method_sms_text ${loadingFormData ? formData.method_sms_text.error ? "error" : "" : ""}`}
                                        style_type="default"
                                        label={`${t("labels.text_content")}:`}
                                        inputRef={inputRef}
                                        type="text"
                                        elName={loadingFormData ? formData.method_sms_text?.ref : ''}
                                        value={formData?.method_sms_text?.value}
                                        onChange={handleFormElementChange}
                                        disabled={!formData?.method_sms?.value}
                                    />
                                </div>
                                */}
                            </div>
                        </div>
                    </div>

                </div>
                {/*
                <div className="box_single">
                    <div className="box_services">
                        <div className="description">
                            {t(`countdown.upon_passing`)}
                        </div>
                        <div className="form_services">
                            <div className="field field_trustee_services">
                                <Switch
                                    toggled={loadingFormData ? formData.trustee_services.value : false}
                                    color="default"
                                    style_type="default"
                                    inputRef={inputRef}
                                    label=""
                                    elName={loadingFormData ? formData.trustee_services?.ref : ''}
                                    className={`trustee_services ${loadingFormData ? formData.trustee_services.error ? "error" : "" : ""}`}
                                    onChange={handleFormElementChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                */}

                <div className="box_double">
                    <div className="box_certificate">
                        <div className="description">
                            {t(`countdown.death_certificate`)}
                        </div>
                        <div className="form_certificate">
                            <div className="field field_certificate">
                                <Switch
                                    toggled={loadingFormData ? formData.certificate.value : false}
                                    color="default"
                                    style_type="default"
                                    inputRef={inputRef}
                                    label=""
                                    //ref={el => inputRef.current[formData?.notify_beneficiary?.ref] = el}
                                    elName={loadingFormData ? formData.certificate?.ref : ''}
                                    className={`certificate_switch ${loadingFormData ? formData.certificate.error ? "error" : "" : ""}`}
                                    onChange={handleFormElementChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Wrapper>
    )
}

export default CountdownFormContainer
