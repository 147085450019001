import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS, IcoArrowDown } from '../../../helpers/constants';
import { Spinner } from '../../../helpers/constants';

const Wrapper = styled.div`
    
    display: flex;
    width: 100%;
    flex-direction: column;
    
    &.error{
        .selectWrapper{
            select{
                border-color: red;
            }
        }
    }

    .selectWrapper{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        select{
            display: flex;
            width: 100%;
            height: 52px;
            background-color: ${props => props.st.select.backgroundColor};
            padding: 13px;
            font-size: ${props => props.st.select.fontSize};
            font-weight: ${props => props.st.select.fontWeight};
            color: ${props => props.st.select.color};
            border: 1px solid ${props => props.st.select.borderColor};
            border-radius: 5px;
            font-family: 'Poppins';
            -webkit-appearance: none;
            appearance: none;
            padding-right: 57px;

            :focus {
                outline: none;
            }
            *:focus {
                outline: none;
            }
            background-image:
                linear-gradient(45deg, transparent 50%, black 50%),
                linear-gradient(135deg, black 50%, transparent 50%),
                linear-gradient(to right, #ccc, #ccc);
            background-position:
                calc(100% - 23px) calc(1em + 6px),
                calc(100% - 15px) calc(1em + 6px),
                calc(100% - 2.8em) 0.5em;
            background-size:
                8px 8px,
                8px 8px,
                1px 2.1em;
            background-repeat: no-repeat;
        }

        .anima_spinner{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 51px;
            height: 51px;
            svg{
                width: 35px;
                height: 35px;
                g{
                    rect{
                        fill: ${COLORS.lightgold};
                    }
                }
                
                background: transparent!important;
            }
        }

    }
    
    label{
        font-size: ${props => props.st.label.fontSize};
        font-weight: ${props => props.st.label.fontWeight};
        color: ${props => props.st.label.color};
        margin-bottom: 4px;
    }

`

const SelectContainer = ( props ) => {

    const { className,
            override_css,
            style_type,
            label,
            elName,
            data,
            onChange,
            optionSelected,
            disabled,
            inputRef,
            isLoading } = props;

    const [ styles, setStyle ] = useState(null);
    
    useEffect(() => {

        switch (style_type) {
            case 'default':
                setStyle({

                    select: { color: override_css?.select?.color ? override_css.select.color : COLORS.darkgrey,
                              fontSize: override_css?.select?.fontSize ? override_css.select.fontSize : "16px",
                              fontWeight: override_css?.select?.fontWeight ? override_css.select.fontWeight : "600",
                              backgroundColor: override_css?.select?.backgroundColor ? override_css.select.backgroundColor : 'transparent',
                              borderColor: override_css?.select?.borderColor ? override_css.select.borderColor : COLORS.whiteThree },

                    label: { fontSize: override_css?.label?.fontSize ? override_css.label.fontSize : "15px",
                             fontWeight: override_css?.label?.fontWeight ? override_css.label.fontWeight : "600",
                             color: override_css?.label?.color ? override_css.label.color : COLORS.darkgrey},

                });
                break;
        
            default:
                break;
        }

    }, []);

    return (
        <>
        {styles &&
        <Wrapper override={override_css} st={styles} arrowDown={<IcoArrowDown />} className={className}>
            {label.length > 0 &&
                <label htmlFor={elName}>{label}</label>
            }
            <div className="selectWrapper">
                {
                                                
                    isLoading ? 
                    
                    <div className="anima_spinner">
                        <Spinner className="spinner"/>
                    </div>

                    :                        

                    <select ref={el => inputRef.current[elName] = el} key={`select-${elName}`} id={elName} name={elName} onChange={(e) => onChange(elName, e.target.value)} value={optionSelected} disabled={disabled}>
                        <option value="">Select an option</option>
                        {data &&
                            data.map((el, i) => {
                                return(
                                    <React.Fragment>
                                    <option key={elName + i} value={el.value}>{el.description}</option>
                                    {(elName == "nationality" && i == 0) ? <option key={elName + i} value="" disabled>--------------------------------</option> : ""}
                                    </React.Fragment>

                                )
                            })
                        }
                    </select>
                    
                }
            </div>
        </Wrapper>
        }
        </>
    )
}

export default SelectContainer;
