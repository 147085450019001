import React, {useState, useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import storage from 'local-storage-fallback';
import { useHistory, useParams } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import { COLORS } from '../../helpers/constants';


const Wrapper  = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    border-radius: 12px;
    .box_container{
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 35px 40px 35px 40px;
        width: auto;
    }
    .bt_close{

    }
    svg{
        color: ${COLORS.lightgold};
    }
    .assign_loader_text{
        font-weight: 500;
        margin-bottom: 30px;
        text-align: center;
    }
    .assign_response_text{
        width: 600px;
        font-weight: 500;
        margin-bottom: 15px;
        text-align: center;
    }
 `  

const OverlayAssignAsset = (props) => {

    const { t } = useTranslation();

    const history = useHistory();

    const { assignAssetByPartyCodeStatus,
            assignAssetByPartyCodeError,
            setShowOverlayAssignAsset } = props;
    /*
    useEffect(() => {
      
        if(pcode.length){
            alert("muestro modal: " + pcode);
            storage.setItem('ie_pcode', '')
        }
    
      return () => {
        
      }
    }, [pcode])
    */
    
    const handleClose = () => {
        setShowOverlayAssignAsset(false);
        history.push('/assigned-assets');
    }
    
      
    
    return (
        <Wrapper>
            <BoxContainer className="box_container">
            {assignAssetByPartyCodeStatus === null ?
                <>
                    <div className="assign_loader_text">
                        {t(`assigned_assets.loading`)}
                    </div>
                    <CircularProgress size={45} />
                </>
            :
                <>
                    <div className="assign_response_text">
                        {
                            assignAssetByPartyCodeError !== null && assignAssetByPartyCodeError.map((e, i) => {
                                return(
                                    <>{e}</>
                                )
                            })
                        }
                    </div>
                    <ButtonSolid
                        style_type="default"
                        className="bt_close"
                        onClick={() => handleClose()}
                        override_css={null}>
                        <p>{t(`buttons.close`)}</p>
                    </ButtonSolid>
                </>
            }
            </BoxContainer>
        </Wrapper>
    )
}

export default React.memo(OverlayAssignAsset);