import React, {useState, useEffect, useContext} from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';

import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';

import CircularProgress from '@material-ui/core/CircularProgress';

import { COLORS,
         LogoInherit } from '../../helpers/constants';

import { AuthContext } from '../../context';

import logo from '../../assets/images/logo_inherit.svg';

const Wrapper = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    //background-color: skyblue;

    .container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 700px;
      //height: 400px;
      padding: 35px 25px 35px 25px;
      box-sizing: border-box;

      .loader{
        svg{
          color: ${COLORS.lightgold};
        }
      }
      .logo{
        width: 300px;
        img{
          float: left;
          width: 100%;
        }
      }
      .bt_goto{
        width: 200px;
      }
      .title{
        font-size: 32px;
        line-height: 37px;
        margin-top: 30px;
      }
      .text{
        font-size: 20px;
        margin-top: 10px;
        &.wrong{
          font-size: 18px;
          margin-top: 20px;
          text-align: center;
        }
        &.small{
          font-size: 15px;
        }
        .highlight{
          font-weight: 600;
          color: ${COLORS.lightgold};
        }
      }
      .actions{
        margin-top: 50px;
      }

    }
    //background-color: pink;
    /*
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`


const Container = styled.div`

  @media ${breakpoints.xs} {
      //min-width: 576px
  }
  @media ${breakpoints.sm} {
      //min-width: 768px
      
  }
  @media ${breakpoints.md} {
      //min-width: 992px
      width: 662px;
  }
  @media ${breakpoints.lg} {
      //min-width: 1200px
      width: 870px;
  }
  @media ${breakpoints.xl} {
      //min-width: 1366px
      width: 1036px;
  }
  @media ${breakpoints.xxl} {
      //min-width: 1440px
      width: 1110px;
  }
  @media ${breakpoints.xxxl} {
      //min-width: 1680px
      width: 1350px;
      //background-color: pink;
  }
  @media ${breakpoints.xxxxl} {
      //min-width: 1920px
      width: 1590px;
      //background-color: skyblue;
  }
`

const CountdownResponseStatusContainer = (props) => {
  
  const { token } = props;
  const history = useHistory();
  const { isTheVaultOpen, sendCountdownResponseStatus } = useContext(AuthContext);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ haveError, setHaveError ] = useState(false);
  const [ message, setMessage ] = useState(null);
  const [ name, setName ] = useState(null);
  useEffect(() => {
    
    
    if(token){
      //alert(token);
      const payload = {token: token};
      const response = sendCountdownResponseStatus(payload);
      response.then((res) => {

        if(!res.data.success){
          setHaveError(true);
        }else{
          setMessage(res.data.message);
          setName(res.data.name);
        }

        setIsLoading(false);
        console.log(res.data);

      })
    }
    return () => {
      
    }
  }, [token])


    return (
        <Wrapper>

            <BoxContainer className="container">
              {isLoading ?
              <div className="loader">
                <CircularProgress size={40} />
              </div>
              :
              <>
                <div className="logo">
                  <img src={logo} />
                </div>
                {!haveError ?
                <>
                  <div className="title">{name ? "Hi, " + name : ""}</div>
                  <div className="text">{message ? message : ""}</div>
                  <div className="actions">
                    <ButtonSolid
                        type="submit"
                        style_type="default"
                        className="bt_goto"
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                        onClick={() =>
                          history.push('/')
                        }
                    >
                        <p>Go to Inherit</p>
                    </ButtonSolid>
                    {/*
                    <ButtonSolid
                        type="submit"
                        style_type="default"
                        className="bt_signup"
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                        onClick={() =>
                          alert("bt_signup")
                        }
                    >
                        <p>Sign Up</p>
                    </ButtonSolid>
                    */}
                  </div>
                </>
                :
                <>
                  <div className="title">Something went wrong!</div>
                  <div className="text wrong">Please make sure you have entered this page from the alert e-mail we sent to your registered e-mail address.</div>
                  <div className="text small">If the problem continues, please contact <span className="highlight">alert-assitance@inherit.com</span></div>
                </>
                }
              </>
              }
            </BoxContainer>

        </Wrapper>
    )
}

export default CountdownResponseStatusContainer
