import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import "moment-timezone";
import styled from 'styled-components';
import ButtonSolid from '../Elements/Buttons/Solid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

import { COLORS,
         notificate_frecuency } from '../../helpers/constants';
import { AuthContext } from '../../context';

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(208, 178, 87, 0.55);
    background-color: rgba(0, 0, 0, 0.90);
    z-index: 9999;
    //border-radius: 12px;
    svg{
        color: ${COLORS.lightgold};
    }

    .inner_overlay_vault_open{
        
        width: 700px;
        background-color: #FFFFFF;
        border-radius: 12px;

        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);

        p{
            margin: 0;
        }
        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            .notify_setup{
                margin-top: 20px;
                background-color: ${COLORS.whiteTwo};
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 7px;
                padding-bottom: 7px;
                color: #000000;
                text-align: center;
                strong{
                    color: ${COLORS.lightgold};
                }
            }
            .bt_close{
                margin-top: 20px;
            }
        }
    }
 `  

const OverlayVaultOpen = (props) => {

    const { t } = useTranslation();
    const { openOverlayVault } = props;
    //const [ showOverlayVault, setShowOverlayVault ] = useState(openOverlayVault);

    const { userData,
            overlayVaultOpen,
            setOverlayVaultOpen } = useContext(AuthContext);
        
    const [ frecuency, setFrecuency ] = useState("");
    const [ hours, setHours ] = useState("");
    const [ method, setMethod ] = useState("");
    const [ vaultOwnerName, setVaultOwnerName ] = useState("");

    useEffect(() => {

        if(userData){

            setVaultOwnerName(userData.first_name + " " + (userData.middle_name ? userData.middle_name + " " : "") + userData.last_name);
            const notificate_frequency = notificate_frecuency();
            const frequency_data = notificate_frequency.filter(f => f.value === userData.countdown.frecuency);

            if(frequency_data.length){
                setFrecuency(frequency_data[0].description);
            }
            if(userData?.countdown?.date?.length){
                setFrecuency(userData.countdown.date);
            }

            if(userData.countdown.method_email){
                setMethod("Email");
            }
            if(userData.countdown.method_sms){
                setMethod("SMS");
            }

            if(userData?.countdown?.hours?.length){
                setHours(userData.countdown.hours);// + ' hrs'
            }
            

        }
    
      return () => {}
    }, [userData])
    
    //<CircularProgress size={45} />

    if(!overlayVaultOpen){
        return null;
    }
    
    return (
        <Wrapper>
            
            <div className="inner_overlay_vault_open">
                <div className="content">
                    <p className="">{t(`vault_open.vault_open_text1`)}, <strong>{vaultOwnerName}</strong>. {t(`vault_open.vault_open_text2`)} <strong>{userData ? userData.countdown.created_at : "--/--/---- --:--:--"}</strong> {t(`vault_open.vault_open_text3`)}</p>
                    <p className="notify_setup">{t(`labels.notify_me_every`)}: <strong>{frecuency}</strong> {t(`labels.at`)}: <strong>{hours}</strong><br/>{t(`countdown.notification_method`)}: <strong>{method}</strong></p>
                    <ButtonSolid 
                        type="submit" 
                        style_type="default" 
                        className="bt_close" 
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                        onClick={() => setOverlayVaultOpen(!overlayVaultOpen)}
                    >
                        <p>Close</p>
                    </ButtonSolid>
                </div>
            </div>
        </Wrapper>
    )
}

export default OverlayVaultOpen;