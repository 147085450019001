import React, { useState,
                useCallback,
                useContext,
                useEffect } from 'react';

import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import { breakpoints } from '../../../helpers/breakpoints';
import CloseIcon from '@material-ui/icons/Close';
import { IoCloseCircleOutline } from "react-icons/io5";

import moment from 'moment';
import "moment-timezone";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';



const DialogStyled = styled(Dialog)`
    .MuiTypography-root{
        padding-right: 13px;
    }
    .dialog_content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .bt_close{
            display: flex;
            font-size: 45px;
            line-height: 45px;
            cursor: pointer;
            :hover{
                color: ${COLORS.lightgold};
            }
        }
    }
    p{
        padding: 0;
        margin: 0 0 25px 0;
        :last-child{
            margin: 0;
        }
    }
`



const ModalBase = (props) => {

    const { open, setOpen, content } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (

 
            <DialogStyled
                className="dialog"
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >

                <DialogTitle id="responsive-dialog-title">
                    <div className="dialog_content">
                        {"Disclaimer"}
                        <div className="bt_close" onClick={handleClose}>
                            <IoCloseCircleOutline />
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Inherit, Inc. is not a law firm and does not give legal advice. Our products and forms are not a substitute for the advice of an attorney. No attorney-client relationship is created by using this site, and the information you submit is not protected by attorney-client privilege. Our primary office is at 632 Birchwood Ct, Danville, CA, 94506, USA. Use of this site is governed by our Terms & Conditions and Privacy Policy. © 2022 Inherit, Inc.
                    </DialogContentText>
                </DialogContent>

                {/*
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
                */}
                
            </DialogStyled>

    )
}

export default ModalBase
