import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import "moment-timezone";
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import ButtonSolid from '../Elements/Buttons/Solid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLORS,
         notificate_frecuency } from '../../helpers/constants';
import { AuthContext,
         ContentContext } from '../../context';

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(208, 178, 87, 0.55);
    background-color: rgba(0, 0, 0, 0.90);
    z-index: 9999;
    //border-radius: 12px;
    svg{
        color: ${COLORS.lightgold};
    }

    .inner_overlay_vault_open{
        
        width: 700px;
        background-color: #FFFFFF;
        border-radius: 12px;

        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);

        p{
            margin: 0;
        }
        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            .notify_setup{
                margin-top: 20px;
                background-color: ${COLORS.whiteTwo};
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 7px;
                padding-bottom: 7px;
                color: #000000;
                text-align: center;
                strong{
                    color: ${COLORS.lightgold};
                }
            }
            .bt_close{
                margin-top: 20px;
            }
        }
    }
 `  

const OverlayDowngradePlan = (props) => {

    const { t } = useTranslation();

    const { assets,
            parties } = useContext(ContentContext);

    const { userData,
            overlayDowngradePlan,
            setOverlayDowngradePlan } = useContext(AuthContext);
        
    const [ assetsExeed, setAssetsExeed ] = useState(0);
    const [ beneficiariesExeed, setBeneficiariesExeed ] = useState(0);

    useEffect(() => {
        console.log("parties", parties);
        if(parties){
            setBeneficiariesExeed(parties.length - overlayDowngradePlan.parties);
        }
        if(assets){
            setAssetsExeed(assets.length - overlayDowngradePlan.assets);
        }
      return () => {}
    }, [assets, parties])
    
    //<CircularProgress size={45} />

    const counterMessage = (assetsExeed, beneficiariesExeed) => {
        if(assetsExeed && beneficiariesExeed){
            let beneficiary_name = ` ${t('basics.beneficiary')}`;
            let asset_name = ` ${t('basics.asset')}`;
            if(assetsExeed > 1){
                asset_name = ` ${t('basics.assets')}`;
            }
            if(beneficiariesExeed > 1){
                beneficiary_name = ` ${t('basics.beneficiaries')}`;
            }
            return assetsExeed + asset_name + " y " + beneficiariesExeed + beneficiary_name;
        }
        if(assetsExeed && !beneficiariesExeed){
            let asset_name = ` ${t('basics.asset')}`;
            if(assetsExeed > 1){
                asset_name = ` ${t('basics.assets')}`;
            }
            return assetsExeed + asset_name;
        }
        if(!assetsExeed && beneficiariesExeed){
            let beneficiary_name = ` ${t('basics.beneficiary')}`;
            if(beneficiariesExeed > 1){
                beneficiary_name = ` ${t('basics.beneficiaries')}`;
            }
            return beneficiariesExeed + beneficiary_name;
        }
        return "";
    }

    if(!overlayDowngradePlan){
        return null;
    }
    //Actualmente tenes cargados {assets?.length} assets y {parties?.length} beneficiarios, para poder cambiar de plan (a uno menor) o cancelar el actual tenes que eliminar al menos { counterMessage(assetsExeed, beneficiariesExeed) }.
    return (
        <Wrapper>
            <div className="inner_overlay_vault_open">
                <div className="content">
                     
                    { t(`alerts.downgrade_plan`, { assets: assets?.length, beneficiaries: parties?.length }) }
                    <ButtonSolid 
                        type="submit" 
                        style_type="default" 
                        className="bt_close" 
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                        onClick={() => setOverlayDowngradePlan(null)}
                    >
                        <p>Close</p>
                    </ButtonSolid>
                </div>
            </div>
        </Wrapper>
    )
}

export default OverlayDowngradePlan;