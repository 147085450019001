import React from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
    display: flex;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 12px;

    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.07);
    -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.07);
    -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.07);

    >.inner{
        padding: 10px;
    }
`

const BoxContainer = ({ children, className }) => {
    return (
        <Wrapper className={className}>
            { children }
        </Wrapper>
    )
}

export default BoxContainer
