import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import { COLORS } from '../../../helpers/constants';

const Wrapper = styled.div`
    
    display: flex;
    width: 100%;
    flex-direction: column;

    label{
        font-size: ${props => props.st.label.fontSize};
        font-weight: ${props => props.st.label.fontWeight};
        color: ${props => props.st.label.color};
        margin-bottom: 4px;
    }
    &.error{
        
        .MuiSwitch-track{
            border: 1px solid ${COLORS.red};
        }
        //
    }

`
const SwitchStyled = styled(Switch)`
    
    &.MuiSwitch-root{
        width: 86px;
        height: 36px;
        padding: 2px;
    }
    .MuiSwitch-switchBase{
        padding: 6px;
        color: '#ff6a00';
    }
    .MuiSwitch-thumb{
        width: 26px;
        height: 26px;
        background-color: '#fff';
    }
    .MuiSwitch-track{
        background: ${COLORS.whiteTwo};//linear-gradient(to right, #ee0979, #ff6a00);
        opacity: 1 !important;
        border-radius: 20px;
        position: relative;
        font-size: 15px;
        font-weight: 600;
        border: 1px solid ${COLORS.whiteFour};

        &:before,
        &:after{
            display: inline-block;
            position: absolute;
            top: 50%;
            width: 50%;
            transform: translateY(-50%);
            color: #FFFFFF;
            text-align: center;
        }
        &:before{
            color: ${COLORS.lightgold};
            content: "Yes";
            left: 4px;
            opacity: 0;
        }
        &:after{
            color: ${COLORS.bluegrey};
            content: "No";
            right: 4px;
        }
    }
    .Mui-checked{
        &.MuiSwitch-switchBase{
            color: #185a9d;
            transform: translateX(48px);
            :hover{
                background-color: rgba(24,90,257,0.08);
            }
        }
        .MuiSwitch-thumb{
            background-color: #fff;
        }
        
        & + .MuiSwitch-track{
            border: 1px solid ${COLORS.lightgold};
            //background: linear-gradient(to right, #000000 60%, 10%, ${COLORS.darkgold} 0% 40%);
            background: linear-gradient(90deg, #000000 40%, ${COLORS.darkgold} 80%, ${COLORS.lightgold} 100%);
            //background-color: #000000!important;
            &:before{
                opacity: 1;
                
            }
            &:after{
                opacity: 0;
            }
    }
    &.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
        background-color: ${COLORS.whiteFour};
    }
  }
`

const SwitchContainer = ( props ) => {

    const { className, override_css, style_type, label, elName, toggled, onChange, inputRef } = props;
    const [ styles, setStyle ] = useState(null);

    useEffect(() => {

        switch (style_type) {
            case 'default':
                setStyle({

                    input: { color: override_css?.input?.color ? override_css.input.color : COLORS.bluegrey,
                             fontSize: override_css?.input?.fontSize ? override_css.input.fontSize : "17px",
                             fontWeight: override_css?.input?.fontWeight ? override_css.input.fontWeight : "500",
                             backgroundColor: override_css?.input?.backgroundColor ? override_css.input.backgroundColor : COLORS.whiteTwo },

                    label: { fontSize: override_css?.label?.fontSize ? override_css.label.fontSize : "15px",
                             fontWeight: override_css?.label?.fontWeight ? override_css.label.fontWeight : "600",
                             color: override_css?.label?.color ? override_css.label.color : COLORS.darkgrey},

                });
                break;
        
            default:
                break;
        }

    }, []);

    return (
        <>
        {styles &&
        <Wrapper override={override_css} st={styles} className={className}>
            {label.length > 0 &&
                <label htmlFor={elName}>{label}</label>
            }
            <SwitchStyled
                ref={inputRef ? el => inputRef.current[elName] = el : null}
                checked={toggled}
                onChange={(e) => onChange(elName, e.target.checked)}
            />
        </Wrapper>
        }
        </>
    )
}

export default SwitchContainer
