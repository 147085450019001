import React, { useState,
                useCallback,
                useContext,
                useEffect } from 'react';

import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import { breakpoints } from '../../../helpers/breakpoints';
import CloseIcon from '@material-ui/icons/Close';
import { IoCloseCircleOutline } from "react-icons/io5";

import moment from 'moment';
import "moment-timezone";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';



const DialogStyled = styled(Dialog)`
    .MuiTypography-root{
        padding-right: 13px;
    }
    .dialog_content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .bt_close{
            display: flex;
            font-size: 45px;
            line-height: 45px;
            cursor: pointer;
            :hover{
                color: ${COLORS.lightgold};
            }
        }
    }
    p{
        padding: 0;
        margin: 0 0 25px 0;
        :last-child{
            margin: 0;
        }
    }
`



const ModalPrivacyPolicy = (props) => {

    const { open, setOpen, content } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (

 
            <DialogStyled
                className="dialog"
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >

                <DialogTitle id="responsive-dialog-title">
                    <div className="dialog_content">
                        {"Privacy Policy"}
                        <div className="bt_close" onClick={handleClose}>
                            <IoCloseCircleOutline />
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
Effective Date: {moment().format('MM/DD/YYYY')}
<br/><br/><strong>Introduction</strong>
<br/>This privacy policy (“Policy”) describes how Inherit (the “Company,” “we,” and “Inherit”) processes, collects, uses, stores, and enables the sharing of your personal data when using https://www.inherit.com or any subdomains hosted at inherit.com (the “Website”) and the services operated on, through, and by the Company on the Website (collectively, the “Services” and together with the Website, the “Platform”). Please read the following information carefully to understand our practices regarding your personal data and how we will process data. Unless a capitalized term is defined herein, such capitalized term shall have the meaning as defined in the Terms of Use, which is incorporated herein by reference and incorporated herein as if set forth at length. If you do not agree to and consent to our use of your personal data in line with this Policy, please do not use our website. 
<br/><br/><strong>What is Personal Data?</strong>
<br/>We collect information about you in a range of forms, including personal data. As used in this Policy, “personal data” includes any information which, either alone or in combination with other information we process about you, identifies you as an individual, including, for example, your name, postal address, email address and telephone number. It does not include personal information that is encoded or anonymized or otherwise uploaded to the Platform by you, except as otherwise provided herein, or publicly available information that has not been combined with non-public information. 
<br/><br/><strong>Collecting Your Personal Data</strong>
<br/>We collect certain information about you through the establishment of the User Account. 
<br/><br/><strong>Information You Give Us</strong>
<br/>The primary source of personal data we collect is provided by you during your use of the Platform. This includes:
<br/>• the personal data you provide when you register to use the Website, including your name, postal address, email address, telephone number, and username;
<br/>• any and all information related to your User Account and public profiles on other websites and with other companies that you provide in connection with the Services, provided that Inherit can only access information to which it is provided lawful access. If all you provide is User account information, all Inherit can access is where you store data, not what data is actually stored there;
<br/>• the information you provide when you report a problem with our website or when we provide you with customer support;
<br/>• the personal data you provide when paying for any Services thorough the Platform; and
<br/>• the information you provide when you correspond with us by phone, email, or otherwise.
<br/>• The personal data you provide while creating your contacts such as, nominees, including their name, postal address, email address, telephone numbers, and relationship.
<br/><br/><strong>Information from Social Networking Websites</strong>
<br/>The Website may include interfaces that allow you to connect with social networking sites (each a “SNS”). If you connect to a SNS through the Website, you authorize us to access, use, and store the information that you authorized the SNS to provide us based on your settings with that SNS. We will access, use, and store that information in accordance with this Policy. You can revoke our access to the information you provide in this way at any time by amending the appropriate settings from within your account settings on the applicable SNS, provided any such information that you have imported from such SNS prior to revoking access may be maintained on the Platform. 
<br/><br/><strong>Information Automatically Collected</strong>
<br/>Inherit may automatically collect, store, and analyze information from your computer or mobile device when you access the Platform. For example, when visiting the Website, we collect IP addresses and web site usage information, including the type of browser you use, access times, pages viewed, location, the page you visited before navigating to our platform, information about the computer or mobile device you use to access our platform, including the hardware model, operating system and version, unique device identifiers and mobile network information. This information helps us evaluate how our visitors and subscribers use and navigate our platform on an aggregate basis, including but not limited to the number and frequency of visitors and subscribers to each web page, and the length of their visits. We reserve the right to match your IP address (or any other data) with other data about you in any way permitted by applicable law. Automated Decision Making and Profiling. We do not use your personal data for the purposes of automated decision-making. However, we may do so in the future in order to fulfill obligations imposed by law, in which case we will inform you of any such processing and provide you with an opportunity to object, in each case to the extent permitted by law. 
<br/><br/><strong>Information We Collect From Other Sources</strong>
<br/>We may also collect information about you from additional online and offline sources including commercially available third-party sources for the purposes of verifying eligibility and securely offering our services to you. We may combine this information with the personal data and other information we have collected about you under this Policy. 
<br/><br/><strong>Cookies</strong>
<br/>When you access Inherit from a computer or other device, we may collect certain information from that device about your browser type, location and Internet protocol address through cookies or similar technologies. Cookies are small amounts of data that a website sends to a web browser or application on a visitor’s computer or other devices. We use cookies to support the operation of the Platform and other Inherit applications. For example, our session cookies may be used for authentication purposes, which are necessary to provide you with the Services available through our Website and to use certain features such as access to secure areas in the Platform. We do not link the information we store in cookies to any personal information you submit while on our site or other Inherit applications. If you reject cookies, you may not be able to access our Website or other Inherit applications. Our third party service providers also use cookies to collect information that is analyzed in aggregate form to help us understand how our Website is being used. Our third party service providers may also employ clear gifs, images, and scripts that help them better manage content on our site. These third party cookies or similar technologies may be able to recognize your computer or device both when you visit our Website or another website serviced by that third party. Inherit may also use other tools, such as Google Analytics or other platforms, to help us analyze how you and other visitors use our Website and to improve its functionality. Inherit does not provide any personal information about you to such tools and any information collected by such tools is done anonymously without identifying an individual User. We do not associate information collected by analytics tools with information you may have provided to us. 
<br/><br/><strong>Disabling cookies</strong>
<br/>You may be able to remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit” facility). Many browsers are set to accept cookies until you change your settings. If you do not accept our cookies, you may experience some inconvenience in your use of our Website. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our Website. 
<br/><br/><strong>Advertising</strong>
<br/>We may permit other companies to serve third-party advertisements when you visit and use the Website. These companies may collect and use click stream information, browser type, time and date, the subject of advertisements clicked or scrolled over during your visits to the Website and other websites in order to provide advertisements about goods and services likely to be of interest to you. These companies typically use tracking technologies to collect this information. Other companies’ use of their tracking technologies is subject to their own privacy policies. 
<br/><br/><strong>Using Your Personal Data</strong>
<br/>We may use your personal data as follows:
<br/>• to operate, maintain, and improve the Website, products, and Services;
<br/>• to manage your account, including to communicate with you regarding your account, if you have an account on our Website;
<br/>• to provide the Services for which you have signed up;
<br/>• to respond to your comments and questions and to provide customer service;
<br/>• to send information including technical notices, updates, security alerts, and support and administrative messages;
<br/>• with your consent, to send you marketing e-mails about upcoming promotions, and other news, including information about products and services offered by us and our affiliates. You may opt-out of receiving such information at any time: such marketing emails tell you how to “opt-out.” Please note, even if you opt out of receiving marketing emails, we will still send you non-marketing emails. Non-marketing emails include emails about your account with us (if you have one) and our business dealings with you and any changes to the Policy or the Terms of Use;
<br/>• to process payments you make via our Website;
<br/>• to detect and prevent fraudulent transactions;
<br/>• to enforce our Terms of Use;
<br/>• to ensure internal quality control;
<br/>• as we believe necessary or appropriate (a) to comply with applicable laws; (b) to comply with lawful requests and legal process, including to respond to requests from public and government authorities; (c) to enforce our Policy; and (d) to protect our rights, privacy, safety or property, and/or that of you or others;
<br/>• for analysis and study services; and
<br/>• as described in the “Sharing of your Personal Data” section below.
<br/><br/><strong>Sharing Your Personal Data We may share your personal data as follows:</strong>
<br/>• Third Parties Designated by or Consented to by You. We may share your personal data with third parties where you have provided your consent to do so. Once you direct Inherit to send personal data to a third party, Inherit is not liable nor responsible for any such personal data sent to the requested third party, and you hereby release and indemnify Inherit and the Company Parties in connection with such authorized disclosures.
<br/>• Related Companies. We may share your personal data with other companies related to Inherit as part of the delivery of Services to you. If all or a portion of the Company is sold, personal data may be transferred as part of the transaction.
<br/>• Our Third Party Service Providers. We may share your personal data with our third-party service providers who provide services such as data analysis, payment processing, information technology, related infrastructure provision, customer service, email delivery, auditing, and other similar services.
<br/>• Legal Requirements. We may release personal information to third parties: (1) to comply with valid legal requirements such as a law, regulation, search warrant, subpoena, or court order, or (2) in special cases, such as a physical threat to you or others, a threat to homeland security, a threat to our system or network, or cases in which we believe it is reasonably necessary to investigate or prevent harm, fraud, abuse, or illegal conduct.
<br/>• Aggregated Data. We may share aggregated non-personal information about you with third parties. This is information that is combined with the non-personal information of other users and does not include personal data. We also may not limit the third parties’ use of the aggregated information.
<br/>• Third Party Sites. Our Website may contain links to third-party websites and features. This Policy does not cover the privacy practices of such third parties. These third parties have their own privacy policies and we do not accept any responsibility or liability for their websites, features, or policies. Please read their privacy policies before you submit any data to them.
<br/><br/><strong>User Generated Content</strong>
<br/>You may share personal data with us when you submit User generated content to the Website, including via any forums, message boards, and blogs on the Website. Please note that any information you post or disclose on the Website will become public information, and may be available to other users of the Website and to the general public. We urge you to be very careful when deciding to disclose your personal data, or any other information, on our Website. Such personal data and other information will not be private or confidential if it is published on public areas of the Website. If you provide feedback to us, we may use and disclose such feedback on the Website, provided we do not associate such feedback with your personal data. If you have provided your consent to do so, we may post your first and last name along with your feedback. We will collect any information contained in such feedback and will treat the personal data in it in accordance with this Policy. 
<br/>Location of Personal Data and the Website 
<br/>Inherit is based in the United States. If you are visiting the Website from the European Union or other regions with laws governing data collection and use, no matter where you are located, you consent to the processing and transferring of your information including your personal data in and to the United States and other countries. The laws of the United States and other countries governing data collection and use may not be as comprehensive or protective as the laws of the country where you live. By accessing and using our Services and our platform, you consent that Inherit may transfer, process, store, use and disclose your data and personal data as described in this Policy. 
<br/><br/><strong>Security</strong>
<br/>We seek to use reasonable organizational, technical, and administrative measures to protect personal data within our Company. Unfortunately, no transmission or storage system can be guaranteed to be completely secure, and transmission of information via the Internet is not completely secure, as further set forth in the Terms of Use. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us at support@inherit.com. Inherit is not responsible for the circumvention of any privacy settings or security measures contained on the Platform by any users or third parties. The following are among the steps Inherit takes to secure your personal data:
<br/>• User Accounts and Password Requirements. Every registered User must have a unique email address and choose a password that complies with stringent requirements designed to ensure such passwords are difficult to decode. If the Website detects periods of inactivity during a User’s session, the User may be automatically signed off and need to log in again to continue using the Website.
<br/>• Authentication. Through our use of cookies, we take note of the devices you primarily use to access Inherit. If we notice access from devices we don’t recognize, we may ask you to re-authenticate your account to ensure only recognized users are accessing the Platform. This provides an extra layer of security to prevent access by an individual who has determined your email address and password.
<br/>• Encryption. We use the highest standard in network communications – 256-bit SSL – to provide secure access irrespective of the devices you use, whether it be your desktop, mobile phone, or tablet. We encrypt data not only in transit, but also while held in storage.
<br/>• Security Checks and Testing. We scan all files uploaded to Inherit for known viruses and malware and reject files that show evidence of such viruses and malware. We utilize third-party services to test our service for security issues – including scanning our ports, testing for SQL injection, and other potential security weaknesses.
<br/>• Policies for Employees and Contractors. All employees and contractors go through intensive training on security policies and are held accountable for adhering to such policies. Our internal policies and processes are in place to prevent employees and contractors from receiving unauthorized access to personal data. Inherit strictly logs access to any User account, whether by the User, an administrator, an employee or your caretakers, and conducts frequent audits to detect unauthorized access.
<br/><br/><strong>Retention</strong>
<br/>We will only retain your personal data as long reasonably required for you to use the Website or for your designees to access the User Content, or as otherwise provided in the Terms of Use unless you close your account/cancel any subscription(s), or to resolve disputes, establish legal defenses, conduct audits, pursue legitimate business purposes, enforce our agreements, and comply with applicable law. 
<br/><br/><strong>Our Policy on Children</strong>
<br/>Our website is not directed to children under 18 and Inherit will not knowingly collect personal data from an individual under the age of 16. If we are alerted that we have inadvertently collected such personal data, we will delete such information from our files as soon as reasonably practicable. 
<br/><br/><strong>Your Rights</strong>
<br/>• Opt-out. You may contact us anytime to opt-out of (i) direct marketing communications; (ii) automated decision-making and/or profiling; (iii) our collection of sensitive personal data; (iv) any new processing of your personal data that we may carry out beyond the original purpose; or (v) certain other processing of your personal data. Please note that your use of some of the Websites may be ineffective upon opt-out.
<br/>• You may access the information we hold about you at any time via your profile/account or by contacting support@inherit.com.
<br/>• You can also contact us to update or correct any inaccuracies in your personal data by contacting support@inherit.com.
<br/>• Your personal data is portable – i.e. you to have the flexibility to download your data to other service providers as you wish, subject to certain conditions.
<br/>• Erase and forget. In certain situations, for example, when the information we hold about you is no longer relevant or is incorrect, you can request that we erase your data.
<br/>If you wish to exercise any of these rights, you may do so in the settings section of the Inherit application. Additionally, you may contact us at support@inherit.com if the application does not provide the features. In your request, please make clear: (i) what personal data is concerned; and (ii) which of the above rights you would like to enforce. For your protection, we may only implement requests with respect to the personal data associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable and in any event, within 30 days of your request. Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting such change or deletion. Changes The Company reserves the right to amend this Policy at any time. The Company shall notify you of upcoming changes by posting a conspicuous notice on the homepage of the Website and by sending notice through your account and/or via email. For this reason, it is imperative that you keep your email address and account information current. If you continue to use the Platform following the effectiveness of such amendments, you consent to such amendments and agree to abide by them in connection with your use of the Platform following the effective date of such amendments. 
<br/><br/><strong>Complaints</strong>
<br/>We are committed to resolving any complaints about our collection or use of your personal data. If you would like to make a complaint regarding this Policy or our practices in relation to your personal data, please contact us through the information listed on our Website. We will reply to your complaint as soon as we can and in any event, within 30 days. We hope to resolve any complaint brought to our attention, however, if you feel that your complaint has not been adequately resolved, you reserve the right to contact your local data protection supervisory authority. 
<br/><br/><strong>Contact Information</strong> 
<br/>We welcome your comments or questions about this Policy. You may contact us at our email support@inherit.com, or by writing to us at Inherit, Inc., 632 Birchwood Ct, Danville, CA, 94506, USA.

                    </DialogContentText>
                </DialogContent>
                {/*
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
                */}
            </DialogStyled>

    )
}

export default ModalPrivacyPolicy
