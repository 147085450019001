import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ContentContext } from '../../../context';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { breakpoints } from '../../../helpers/breakpoints';
import MomentUtils from '@date-io/moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import CountdownForm from './CountdownForm';

import ThumbBox from '../../../components/Elements/ThumbBox';
import ImageProfile from '../../../components/Elements/ImageProfile';
import BoxContainer from '../../../components/Elements/BoxContainer';
import Select from '../../../components/Elements/Form/Select';
import DatePicker from '../../../components/Elements/Form/DatePicker';
import Switch from '../../../components/Elements/Form/Switch';
import Textarea from '../../../components/Elements/Form/Textarea';
import OverlayImage from '../../../components/Elements/OverlayImage';
import Image from '../../../components/Elements/Image';
import NoImage from '../../../components/Elements/NoImage';
import ButtonSolid from '../../../components/Elements/Buttons/Solid';
import AssetsList from '../../../components/Elements/Parties/AssetsList';

import { COLORS, 
         notificate_frecuency,
         days,
         hours,
         mins } from '../../../helpers/constants';

const Wrapper = styled.div`

    display: flex;
    width: 100%;
    >.loader{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .inner_beneficiaries{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 100%;

        .box_beneficiary_list{
            width: 100%;
            border-top: 1px solid ${COLORS.whiteFour};
            .title{
                margin-bottom: 15px;
            }
            .beneficiary_items{
                
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                
                
                .beneficiary_item{
                    cursor: pointer;
                    border: 1px solid #FFFFFF;
                    margin-right: 2vw;
                    border-radius: 10px;
                    :hover{
                        border: 1px solid ${COLORS.lightgold};
                        &.notselected{
                            opacity: 1;
                        }
                    }
                    &.selected{
                        opacity: 1;
                    }
                    &.notselected{
                        opacity: 0.3;
                    }
                    .thumb{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        border-radius: 10px;
                        overflow: hidden;
                        width: 14vw;
                        height: 18vw;
                        img{
                            float: left;
                            height: 100%;
                            border: 0;
                        }
                    }
                }
            }
            .beneficiary_asset_items{
                display: flex;
                flex-direction: column;
                width: 100%;
                float: left;
                .beneficiary_asset_item{
                    display: flex;
                    flex-direction: column;
                    margin-top: 10px;
                    border: 1px dashed ${COLORS.cloudyblue};
                    border-radius: 10px;
                    float: left;
                    &.hide{
                        display: none;
                    }
                    &.show{
                        display: flex;
                    }
                    .beneficiary_asset{
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        margin-bottom: 10px;
                        margin-top: 10px;
                        margin-right: 10px;
                        margin-left: 10px;
                        .thumb{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            border-radius: 10px;
                            overflow: hidden;
                            width: 70px;
                            height: 70px;
                            margin-right: 10px;
                            img{
                                float: left;
                                height: 100%;
                                border: 0;
                            }
                        }
                        .data{
                            display: flex;
                            flex-direction: column;
                            .name{
                                color: ${COLORS.bluegrey};
                                margin-right: 10px;
                                font-size: 16px;
                                font-weight: 500;
                            }
                            .portion{
                                font-size: 16px;
                                font-weight: 600;
                                color: ${COLORS.lightgold};
                            }
                        }
                    }
                }
            }
        }
    }

    @media ${breakpoints.xs} {
        .inner_beneficiaries{

            .box_beneficiary_list{
                .beneficiary_items{
                    .beneficiary_item{
                        margin-right: 10px;
                        .thumb{
                            width: 70px;
                            height: 90px;
                        }
                    }
                }
                .beneficiary_asset_items{
                    width: auto;
                    .beneficiary_asset_item{
                        flex-direction: row;
                    }
                }
            }
        }

    }
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`

const CountdownBeneficiaries = (props) => {

    const { t } = useTranslation();

    const { title } = props;

    const { getAssetDistribution } = useContext(ContentContext);
    
    const [ isLoading, setIsLoading ] = useState(false);
    const [ notificateBeneficiaries, setNotificateBeneficiaries ] = useState([]);
    const [ beneficiaryAssetSelected, setBeneficiaryAssetSelected ] = useState("");
    const [ showImage, setShowImage ] = useState(null);
    const [ noImage, setNoImage ] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        const beneficiaries = getAssetDistribution();
        beneficiaries.then((res) => {
            if(res){
                const notificateBeneficiaryArr = [];
                res.data.forEach(element => {
                    let exist = notificateBeneficiaryArr.filter(nb => nb.id == element.party.id);
                    if(!exist.length){
                        notificateBeneficiaryArr.push(element.party);
                    }
                })
                notificateBeneficiaryArr.forEach(element => {
                    const assetsArray = [];
                    let assets = res.data.filter(nb => nb.party.id == element.id);
                    assets.forEach(asset => {
                        assetsArray.push({...asset.asset, "portion": asset.portion, "is_prorated": asset.asset.is_prorated});
                    })
                    element["assets"] = assetsArray;
                })
                setNotificateBeneficiaries(notificateBeneficiaryArr);
                setIsLoading(false);
            }
        })
    }, [])

    const handleShowImage = (image, noimage) => {
        setNoImage(noimage)
        setShowImage(image);
    }

    const BeneficiaryAssetList = useCallback(({assets}) => {
        
        return ( 
            assets.map((asset, index) => 
                //ARREGLAR ERROR EN LOS ASSETS Y BENEFICIARIOS

                <div className="beneficiary_asset" key={`asset ${index}`}>
                    
                    {asset.thumb ? 
                        <div className="thumb" onClick={() => handleShowImage(asset.image_file, false)}>
                            <Image filename={asset.thumb_file} showMagnify={true} type={"image_asset"} />
                        </div>
                    :
                        <div className="thumb" onClick={() => handleShowImage(asset.category.description, true)}>
                            <NoImage type={asset.category.description} showMagnify={true} />
                        </div>
                    }
                    <div className="data">
                        <div className="name">
                            {asset.name}
                        </div>
                        <div className="portion">
                            {!asset.is_prorated ? asset.portion + '%' : 'Prorated'}
                        </div>
                    </div>
                </div>
                
            ) 
        )
        
    }, [])

    const handleSelectedBeneficiaryAssets = (id) => {
        setBeneficiaryAssetSelected(id);
    }

    return (
        <Wrapper className="countdown_beneficiaries">
            {showImage &&
                <OverlayImage image={showImage} setShowImage={setShowImage} noImage={noImage} setNoImage={setNoImage} />
            }

            {isLoading ?
            <div className="loader">
                <CircularProgress size={33} />
            </div>
            :
            notificateBeneficiaries.length ?
            <div className="inner_beneficiaries">
                <div className="box_beneficiary_list">
                    { title && <div className="title">{title}</div> }
                    <div className="beneficiary_items">
                        {notificateBeneficiaries.map((party, index) => {
                            return(
                                <div key={'party-item-'+index} className={`beneficiary_item ${beneficiaryAssetSelected == party.id ? 'selected' : 'notselected'}`} onClick={() => handleSelectedBeneficiaryAssets(party.id)}>
                                    <ThumbBox className="thumb" css={{width: '67', height: '88', border: {withBorder: false, color: "#D0B257"}}}>
                                        <ImageProfile profile_image={party.profile_image} gender={party.gender} />
                                    </ThumbBox>
                                </div>
                            )
                        })}
                    </div>
                    <div className="beneficiary_asset_items">
                    {notificateBeneficiaries.map((party, index) => {
                        return(
                            <div key={'asset-item-'+index} className={`beneficiary_asset_item ${beneficiaryAssetSelected == party.id ? 'show' : 'hide'}`}>
                                <BeneficiaryAssetList assets={party.assets} />
                            </div>
                        )
                    })}
                    </div>
                </div>
            </div>
            : <></>}
        </Wrapper>
    )
}

export default CountdownBeneficiaries
