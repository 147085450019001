import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 6px;
    border: 1px solid ${COLORS.lightgold};
    background-color: ${COLORS.lightgold};
    padding: 5px 7px 5px 7px;
    cursor: ${props => props.onClick ? 'pointer' : 'default'};


    text-align: left;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
    font-weight: 600;
    color: #FFFFFF;


    :hover{
      background-color: ${props => props.onClick ? '#FFFFFF' : COLORS.lightgold};
      color: ${props => props.onClick ? COLORS.lightgold : '#FFFFFF'};
    }
`

const PortionPill = (props) => {

    const { type, text, onClick, className } = props;
    
    return (
        <Wrapper className={className} onClick={onClick}>
            {text}
        </Wrapper>
    )
}

export default PortionPill
