import React, {useState, useEffect, useContext, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import CircularProgress from '@material-ui/core/CircularProgress';
import BoxContainer from '../../components/Elements/BoxContainer';
import Image from '../../components/Elements/Image';
import NoImage from '../../components/Elements/NoImage';
import { IoMdClose } from "react-icons/io";

import { COLORS } from '../../helpers/constants';

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    overflow: scroll;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    border-radius: 0;
    svg{
        color: ${COLORS.lightgold};
    }
    >.container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: calc(100% - 30px);
        min-width: 253px;
        width: auto;
        position: relative;
        margin-top: 20px;
        margin-bottom: 20px;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
        -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);
        -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.3);

        .image{
            float: left;
            width: 100%;
            overflow: hidden;
            border-radius: 13px;
            img{
                width: 100%;
            }
            .loader{
                padding-top: 30px;
                padding-bottom: 30px;

                width: 40px!important;
                height: 40px!important;
                }
            }
        }
       
        .bt_close{
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            right: 0;
            top: 0;
            width: 45px;
            height: 45px;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 12px;
            background-color: ${COLORS.whiteTwo};
            cursor: pointer;
            z-index: 99;
            svg{
                float: left;
                width: 30px;
                height: 30px;
                color: ${COLORS.bluegrey};
            }
            :hover{
                background-color: ${COLORS.lightgold};
                svg{
                    color: #FFFFFF;
                }
            }
        }
    }
    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {
        >.container{
            max-width: 1000px;
            min-width: 253px;
        }
    }
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} { }
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
 `  

const OverlayImage = (props) => {

    const { image,
            setShowImage,
            noImage,
            setNoImage } = props;
    
    const imageRef = useRef(null);
    
    useEffect(() => {
        /*
        setLoading(true);
        let blob_ = false;
        const response = getFileByType(filename, type, user_type, owner_id);
        response.then((res) => {
            if(res){
                blob_ = URL.createObjectURL(res.data);
                imageRef.current.src = blob_;
                setImage(blob_);
                setLoading(false);
            }
        }).catch((message)=>{
            setLoading(false);
            if(imageRef.current){
                imageRef.current.src = noasset;
            }
        })

        return function cleanup() {
            URL.revokeObjectURL(blob_);
        };    
        */
    }, [image])

    const handleClose = () => {
        setShowImage(null);
    }
    
    return (
        <Wrapper noImag={noImage}>
            <BoxContainer className="container">
                <div className="bt_close" onClick={handleClose}>
                    <IoMdClose />
                </div>
                {!noImage ? 
                <div className="image">
                    <Image filename={image} type={"image_asset"} />
                </div>
                :
                <div className="noimage">
                    <NoImage type={image} />
                </div>
                }
            </BoxContainer>
        </Wrapper>
    )
}

export default OverlayImage;