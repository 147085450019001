import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../helpers/breakpoints';
import { COLORS } from '../../../helpers/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BiEditAlt } from "react-icons/bi";
import { useHistory } from 'react-router';
import { notificate_frecuency } from '../../../helpers/constants';
import { BsExclamationCircle } from "react-icons/bs";
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../../context';

import ButtonSolid from '../Buttons/Solid';


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //background-color: pink;
    width: 100%;
    .bt_countdown{
        display: flex;
    }
    >.outer_box_data{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        >.box_data{
            display: flex;
            flex-direction: column;
            >.data{
                font-size: 3.3vw;
                line-height: 3.3vw;
                font-weight: 400;
                margin-right: 6vw;
                :last-child{
                    margin-right: 0;
                }
                .title{
                    margin-bottom: 3vw;
                    font-weight: 400;
                }
                .text{
                    font-weight: 600;
                    margin-bottom: 4vw;
                    :last-child{
                        margin-bottom: 0;
                    }
                    .value{
                        padding: 0.3vw 2vw 0.3vw 2vw;
                        font-weight: 400;
                        margin-right: 5px;
                        background-color: ${COLORS.whiteTwo};
                        border: 1px solid ${COLORS.whiteFour};
                    }
                }
            }
            .notification_method{
                margin-top: 4.2vw;
            }
        }
        >.vault_open_dashboar_message{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            margin-top: 6vw;
            padding-top: 1.4vw;
            padding-bottom: 1.4vw;
            background-color: ${COLORS.red};
            color: #FFFFFF;
            .icon{
                font-size: 4vw;
                line-height: 4vw;
            }
            p{
                margin: 0;
                font-size: 3.2vw;
                line-height: 4vw;
                font-weight: 600;
                margin-left: 1vw;
            }
        }
    }
    >.next_notification{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 20px;
        >.title{
            font-weight: 600;
            color: ${COLORS.darkgold};
        }
        .date{
            display: flex;
            padding: 3px 15px 3px 15px;
            font-weight: 600;
            margin-top: 5px;
            color: #FFFFFF;
            background-color: ${COLORS.lightgold};
            border: 1px solid ${COLORS.darkgold};
        }
    }
    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 3vw;
        >.title{
            font-size: max(3.9vw, 18px);
            line-height: 3.6vw;
            font-weight: 600;
        }
        >.bt_edit{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 5.5vw;
            height: 5.5vw;
            margin-left: 1vw;
            font-size: 2.8vw;
            background-color: ${COLORS.whiteThree};
            cursor: pointer;
            &.selected{
              background-color: ${COLORS.lightgold};
              color: #FFFFFF;
            }
            :hover{
              background-color: ${COLORS.lightgold};
              color: #FFFFFF;
            }
        }
    }

    @media ${breakpoints.xs} {
        >.outer_box_data{
            >.box_data{
                >.data{
                    font-size: 16px;
                    line-height: 16px;
                    font-weight: 400;
                    margin-right: 0;
                    :last-child{
                        margin-right: 0;
                    }
                    .title{
                        margin-bottom: 15px;
                    }
                    .text{
                        margin-bottom: 20px;
                        :last-child{
                            margin-bottom: 0;
                        }
                        .value{
                            padding: 3px 10px 3px 10px;
                            margin-right: 5px;
                        }
                    }
                }
                .notification_method{
                    margin-top: 22px;
                }
            }
            >.vault_open_dashboar_message{
                margin-top: 30px;
                padding-top: 7px;
                padding-bottom: 7px;
                .icon{
                    font-size: 20px;
                    line-height: 20px;
                }
                p{
                    font-size: 16px;
                    line-height: 20px;
                    margin-left: 5px;
                }
            }
        }
        .header{
            margin-bottom: 17px;
            >.title{
                font-size: 18px;
                line-height: 18px;
            }
            >.bt_edit{
                width: 25px;
                height: 25px;
                margin-left: 5px;
                font-size: 16px;
            }
        }
    }
    @media ${breakpoints.sm} {
        >.outer_box_data{
            >.box_data{
                flex-direction: row;
                >.data{
                    margin-right: 30px;
                }
                .notification_method{
                    margin-top: 0;
                }
            }
        }
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        width: 370px;
        >.outer_box_data{
            >.box_data{
                flex-direction: column;
                >.data{
                    margin-right: 0;
                }
                .notification_method{
                    margin-top: 22px;
                }
            }
        }
    }
    @media ${breakpoints.xxl} {
        width: 390px;
    }
    @media ${breakpoints.xxxl} {
        width: 570px;
        >.outer_box_data{
            >.box_data{
                flex-direction: row;
                >.data{
                    margin-right: 30px;
                }
                .notification_method{
                    margin-top: 0;
                }
            }
        }
    }
    @media ${breakpoints.xxxxl} {}

`

const Countdown = (props) => {

    const { t } = useTranslation();
    const { isTheVaultOpen } = useContext(AuthContext);

    const history = useHistory();
    const { data } = props;
    const [ frecuency, setFrecuency ] = useState("");
    const [ hours, setHours ] = useState("");
    const [ method, setMethod ] = useState("");
    const [ loading, setLoading ] = useState(true);
    
    useEffect(() => {

        if(data){
            const notificate_frequency = notificate_frecuency();
            const frequency_data = notificate_frequency.filter(f => f.value === data.frecuency);

            if(frequency_data.length){
                setFrecuency(frequency_data[0].description);
            }
            if(data.date.length){
                setFrecuency(data.date);
            }

            if(data.method_email){
                setMethod("Email");
            }
            if(data.method_sms){
                setMethod("SMS");
            }

            if(data.hours.length){
                setHours(data.hours);// + ' hrs'
            }

            setLoading(false);
        }else{

            setLoading(false);

        }
    
      return () => {
        
      }
    }, [data])
    

    return (
        <Wrapper>

            <div className="header">
                <div className="title">{t(`dashboard.active_countdown`)}</div>
                {data ?
                    <div className="bt_edit" onClick={() => { history.push('settings/countdown') }}>
                        <BiEditAlt />
                    </div>
                    : 
                    <></>    
                }
            </div>

            {!loading ? 
                data ?
                <div className="outer_box_data">
                <div className="box_data">
                    <div className="data">
                        <div className="title">{t(`countdown.frequency_periodic_inquiry`)}:</div>
                        <div className="text">{t(`labels.notify_me_every`)}: <span className="value">{frecuency}</span></div>
                        <div className="text">{t(`labels.at`)}: <span className="value">{hours}</span></div>
                    </div>
                    <div className="data notification_method">
                        <div className="title">{t(`countdown.notification_method`)}: </div>
                        <div className="text"><span className="value">{method}</span></div>
                    </div>
                </div>
                {isTheVaultOpen &&
                    <div className="vault_open_dashboar_message">
                        <div className="icon">
                            <BsExclamationCircle />
                        </div>
                        <p>{t(`dashboard.vault_open`)}</p>
                    </div>
                }
                </div>
                :
                <ButtonSolid
                    style_type="default"
                    className="bt_countdown"
                    onClick={() => history.push("/settings/countdown")}
                    override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}>
                    <p>{t(`buttons.add_new_countdown`)}</p>
                </ButtonSolid>
            :
                <div className="loader">
                    <CircularProgress size={30} />
                </div>
            }
        </Wrapper>
    )
}

export default Countdown
