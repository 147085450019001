import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';
import { ContentContext } from '../../context';
import { useHistory } from 'react-router';
import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import FileUploadElement from '../../components/Elements/Upload/FileUploadElement';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RiErrorWarningFill } from "react-icons/ri";
import { COLORS } from '../../helpers/constants';
import { MultipleFileUploadField } from '../../components/Uploads/MultipleFileUploadField';
import { SingleFileUploadWithProgress } from '../../components/Uploads/SingleFileUploadWithProgress';
import { textNumCode, 
         validateFileFormat } from '../../helpers/functions';
import { toast } from 'react-toastify';
import CustomToastMessageError from '../../components/Elements/CustomToastMessageError';
            

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 15px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    svg{
        color: ${COLORS.lightgold};
    }
    .inner_overlay_extra_documents{
        width: calc(100% - 30px);
        box-sizing: border-box;
        padding: 30px;

        box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.20);
        -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.20);
        -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.20);
    }

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {
        align-items: center;
        margin-top: 0;
        .inner_overlay_extra_documents{
            width: 750px;
        }
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`  
const FileElementsStyled = styled.div`
    display: flex;
    flex-direction: column;
    .asset_name{
        border-bottom: 1px dashed ${COLORS.lightgold};
        margin: 0 0 12px 0;
        p{
            font-size: 27px;
            font-weight: 600;
            color: ${COLORS.lightgold};
            margin: 0 0 5px 0;
        }
    }
    .title{
        p{
            font-size: 20px;
            font-weight: 600;
            color: #2B2C2D;
            margin-bottom: 4px;
            margin: 0;
        }
    }
    .text{
        margin-bottom: 10px;
        p{
            font-size: 15px;
            font-weight: 500;
            color: #2B2C2D;
            margin: 0;
        }
    }
    .asset_document_wait{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 1px dashed ${COLORS.red};
        color: ${COLORS.red};
        padding: 10px 10px 10px 10px;
        width: 100%;
        p{
            margin: 0 0 0 5px;
        }
        svg{
            color: ${COLORS.red};
        }
    }
    .documents{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .actions{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-top: 10px;
        .bt_cancel{
            display: flex;
            margin-left: 10px;
        }
        .bt_save{
            margin-left: auto;
        }
        .bt_add_file{
        }
        .bt_file_upload{
            display: none;
        }
    }
`
const FileElements = styled.div`
    width: 100%;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: min-content; 
    gap: 10px 10px; 
    margin-top: 10px;
    margin-bottom: 10px;
`
const ActualFilesStyled = styled.div`

    width: 100%;
    display: grid; 
    grid-template-columns: repeat(1, 1fr);
    gap: 10px 10px; 
    margin-top: 10px;
    margin-bottom: 10px;

    @media ${breakpoints.xs} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media ${breakpoints.sm} {
        grid-template-columns: repeat(3, 1fr);
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`

const OverlayExtraDocuments = (props) => {

    const { t } = useTranslation();

    const history = useHistory();

    const { type,
            asset,
            assetId,
            setShowOverlayExtraDocuments,
            handleCloseOutside = null } = props;
    
    const [ files, setFiles ] = useState([]);
    const [ actualFiles, setActualFiles ] = useState([]);
    const { deleteFile, assets, setAssets } = useContext(ContentContext);
    const [ loading, setLoading ] = useState(false);
    const [ saveDisabled, setSaveDisabled ] = useState(true);
    const [ fileToUpload, setFileToUpload ] = useState(null);

    const filesToCancelRef = useRef();
    filesToCancelRef.current = files;

    const buttonInput = useRef(null);

    const onUpload = (file_uploaded) => {

        const update_asset = [...assets];
        const updated_asset = update_asset.map((a) => {
            if(a.id == assetId){
                a.files.push(file_uploaded.data);
            }
            return a;
        })
        setAssets(updated_asset);
        
    }

    useEffect(() => {

            if(assets){
                const assetSelected = assets.filter(asset => asset.id === assetId);
                if(assetSelected.length > 0){
                    setFiles(assetSelected[0].files);
                }
            }

        return () => {

        }

    }, [assets])

    const handleSubmit = (e) => {
        e.preventDefault();
        const file_status_saved = files.map(f => {
            f.status_saved = true;
            return f;
        })
        setFiles(file_status_saved);
    }

    const handleClose = () => {
        if(handleCloseOutside){
            handleCloseOutside();
        }else{
            setShowOverlayExtraDocuments(false);
        }
        //history.push('/my-assets');
    }

    const changeHandler = (event) => {
        
        let message = '';
        let errors = [];
        let responseType = '';
        let haveError = false;

        const file_data = event.target.files[0];
        const file_valid = validateFileFormat(file_data.type);
        const size = file_data.size / 1024 / 1024;
        
        if(size <= 5){
            if(file_valid){
                file_data['tempId'] = textNumCode(6, 6);
                file_data['status'] = false;
                file_data['status_saved'] = false;
                file_data['id'] = '';
                setFileToUpload(file_data);
            }else{
                haveError = true;
                responseType = 'error';
                errors.push(t(`alerts.uploader_error_format`));
            }
            
        }else{

            haveError = true;
            //message = 'The Beneficiary has been added';
            responseType = 'error';
            errors.push(t(`alerts.uploader_error_size`));

        }

        if(haveError){
            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }

	};


    useEffect(() => {

        console.log(files);
      return () => {
        
      }
    }, [files])
    

    useEffect(() => {

        if(type === 'edit'){

            if(asset.files){
                setFiles(asset.files);
                //setActualFiles(asset.files)
            }
            //setFiles(asset.files);

        }

        return () => {

        }

    }, [])

    
    
    
    const handleAddNewFile = () => {
        buttonInput.current.click();
    }

    useEffect(() => {
    
      return () => {
        
        const files_pending = filesToCancelRef.current.filter(f => f.status_saved == false);
        if(files_pending.length > 0) {

            files_pending.map((f, i) => {

                const payload = {asset_file_id: f.id,
                                 asset_id: assetId,
                                 image_folder: 'assets'}

            })

        }

      }
    }, [])


    const onDeleteFile = (file) => {

    }

    return (
        <Wrapper>
            <BoxContainer className="inner_overlay_extra_documents">
                
                <FileElementsStyled>

                    {type === 'edit' && 
                        <div className="asset_name">
                            <p>Asset: {asset.name}</p>
                        </div>
                    }

                    <div className="title">
                        <p>{t(`basics.add_more_documents`)}:</p>
                    </div>
                    <div className="text">
                        <p>{t(`basics.document_format_admited`)}</p>
                    </div>
                    
                    <div className="documents">
                        {/*assetId !== null ?
                            <MultipleFileUploadField assetId={assetId} folder={"assets"} onUpload={onUpload} />
                        :
                            <div className="asset_document_wait">
                                <RiErrorWarningFill size="20" />
                                <p>Before uploading new documents please save the information on your asset.</p>
                            </div>
                        */}

                        {/*<MultipleFileUploadField assetId={assetId} folder={"assets"} onUpload={onUpload} onDelete={handleDeleteDocuments} />*/}
                        
                        
                        <ActualFilesStyled>
                            {files.map((actual_file, index) => {

                                return(
                                    <FileUploadElement name={actual_file.originalname} id={actual_file.id} withProgress={false} onDelete={onDeleteFile} file={actual_file} assetId={assetId} files={files} setFiles={setFiles} />
                                )
                                
                            })}
                        </ActualFilesStyled>
                        {(type === 'edit' && files.length) ? 
                        <></>
                        :
                            <></>
                            
                        }
                        
                        {fileToUpload && 
                                <SingleFileUploadWithProgress files={files} setFiles={setFiles} file={fileToUpload} setFileToUpload={setFileToUpload} onUpload={onUpload} assetId={assetId} folder={"assets"}/>
                            
                        }

                        {/*files.length ? 
                        <FileElements>
                            {files.map((fileWrapper, index) => (
                            <SingleFileUploadWithProgress key={index} files={files} setFiles={setFiles} file={fileWrapper} onUpload={onUpload} assetId={assetId} folder={"assets"}/>
                            ))}
                        </FileElements>
                        : <></>
                        */}


                        {loading ==! null && loading == true ? 
                            <div className="loader">
                                <CircularProgress size={33} />
                            </div>
                        :
                            <div className="actions">
                                <input className="bt_file_upload" ref={buttonInput} type="file" name="file" onChange={changeHandler} />
                                <ButtonSolid
                                    type="file"
                                    style_type="tab"
                                    className="bt_add_file"
                                    override_css={null}
                                    onClick={(e) => handleAddNewFile()}
                                >
                                    <p>{t(`buttons.add_new_file`)}</p>
                                </ButtonSolid>
                                <ButtonSolid
                                    type="submit"
                                    style_type="default"
                                    className="bt_cancel"
                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                    onClick={() => handleClose()}
                                >
                                    <p>{t(`buttons.close`)}</p>
                                </ButtonSolid>
                            </div>
                        }

                    </div>
                </FileElementsStyled>
            </BoxContainer>
        </Wrapper>
    )
}

export default OverlayExtraDocuments;