import React, { useState,
                useCallback,
                useContext,
                useEffect } from 'react';

import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import { breakpoints } from '../../../helpers/breakpoints';
import CloseIcon from '@material-ui/icons/Close';
import { TfiClose } from "react-icons/tfi";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CheckoutForm from '../../Subscription/CheckoutForm';
import { Elements, } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51JE6zZIls69beLtuDxRuEWnGDIpUzoa2Ob4sBsMwcEAGRuA6UYPrl3Frnf09E2NgsD5BUhSmQ270ykNV5KuoZY43009EsEuPpI');

const DialogStyled = styled(Dialog)`
    .inner{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px 25px 25px 25px;
        position: relative;

        .bt_close{
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 27px;
            line-height: 27px;
            cursor: pointer;
            color: ${COLORS.darkgrey};
            :hover{
                color: ${COLORS.lightgold};
            }
        }
        .title{
            font-size: 22px;
            line-height: 22px;
            padding-bottom: 15px;
            margin-top: 5px;
            margin-bottom: 20px;
            border-bottom: 1px solid ${COLORS.whiteFour}
        }
        /*
        .field{
            display: flex;
            flex-direction: row;
            margin-bottom: 15px;
            .label{
                width: 130px;
                font-weight: 600;
            }
            .value{
                font-size: 15px;
            }
            :last-child{
                margin-bottom: 0;
            }
        }
        */
        .payment_form{
            //border: 1px solid ${COLORS.whiteFour};
            border-radius: 6px;
            width: 100%;
            box-sizing: border-box;
            padding: 0;
        }
    }
    
`



const ModalPaymentMethod = (props) => {

    const { open, 
            setOpen, 
            content, 
            callback = null } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
      
        console.log("content", content)
    
      return () => { }
    }, [content])
    


    return (

        <div>
            <DialogStyled
                fullScreen={fullScreen}
                fullWidth={true}
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <div className="inner">

                    <div className="bt_close" onClick={handleClose}>
                        <TfiClose />
                    </div>

                    <div className="title">Add New Payment Method</div>

                    
                    <div className="payment_form">
                        <Elements stripe={stripePromise}>
                            <CheckoutForm callback={callback} target={"payment_method"} handleClose={handleClose} />
                        </Elements>
                    </div>  

                </div>

            </DialogStyled>
        </div>

    )
}

export default ModalPaymentMethod
