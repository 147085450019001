import React, { useEffect, useState, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '../../../helpers/breakpoints';
import { ContentContext,
         AuthContext } from '../../../context';

import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import CountdownForm from './CountdownForm';
import CountdownBeneficiaries from './CountdownBeneficiaries';
import CustomToastMessageError from '../../../components/Elements/CustomToastMessageError';

import BoxContainer from '../../../components/Elements/BoxContainer';
import ButtonSolid from '../../../components/Elements/Buttons/Solid';
import OverlayWaiting from '../../../components/Elements/OverlayWaiting';
import OverlayTimezone from '../../../components/Elements/OverlayTimezone';


import { COLORS,
         SIZES } from '../../../helpers/constants';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .inner{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px 25px;
        position: relative;
        width: calc(100% - 30px);
        
        .action_save{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            margin-top: 35px;
            padding-top: 25px;
            border-top: 1px solid ${COLORS.whiteFour};
            .bt_delete{
                margin-left: 0;
                margin-top: 5px;
            }
        }

    }

    .countdown_beneficiaries{
        margin-top: 35px;
        .title{
            padding-top: 25px;
        }
    }

    @media ${breakpoints.xs} {
        .inner{
            padding: 20px 25px;
            width: calc(100% - 30px);
            .action_save{
                flex-direction: row;
                justify-content: flex-end;
                width: 100%;
                margin-top: 35px;
                padding-top: 25px;
                .bt_delete{
                    margin-left: 10px;
                    margin-top: 0;
                }
            }
        }
    }
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}

`

const CountdownContainer = (props) => {

    const { t } = useTranslation();

    const { getAssetDistribution, 
            editSetting,
            getSettingCountdown,
            deleteCountdown } = useContext(ContentContext);

    const { isTheVaultOpen,
            setOverlayVaultOpen,
            userData,
            setUserData } = useContext(AuthContext);
    
    const [ formData, setFormData ] = useState({});
    const [ countdownId, setCountdownId ] = useState(null);
    const [ loadingFormData, setLoadingFormData ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    //const [ isWaiting, setIsWaiting ] = useState(null);
    const [ haveTimezone, setHaveTimezone ] = useState(null);
    const [ formLoaded, setFormLoaded ] = useState(false);

    useEffect(() => {

        const countdownData = getSettingCountdown();
        countdownData.then((res) => {

            setFormData({
                id: { value: res.success ? res.data.id : '', required: false, disabled: false, ref: "id", error: false },
                notificate_frecuency: { value: res.success ? res.data.frecuency : '', required: true, disabled: false, ref: "notificate_frecuency", error: false }, 
                frecuency_hours: { value: res.success ? res.data.hours : '', required: true, disabled: false, ref: "frecuency_hours", error: false }, 
                frecuency_date: { value: res.success ? res.data.date : '', required: true, disabled: false, ref: "frecuency_date", error: false }, 
                frecuency_month: { value: res.success ? res.data.month : '', required: true, disabled: false, ref: "frecuency_month", error: false }, 
                frecuency_day: { value: res.success ? res.data.day : '', required: true, disabled: false, ref: "frecuency_day", error: false }, 
                method_email: { value: res.success ? res.data.method_email : false, required: true, disabled: false, ref: "method_email", error: false }, 
                method_sms: { value: false, required: false, disabled: false, ref: "method_sms", error: false },
                method_sms_text: { value: '', required: false, disabled: false, ref: "method_sms_text", error: false }, 
                //method_sms: { value: res.success ? res.data.method_sms : false, required: true, disabled: false, ref: "method_sms", error: false },
                //method_sms_text: { value: res.success ? res.data.method_sms_text : '', required: true, disabled: false, ref: "method_sms_text", error: false }, 
                //trustee_services: { value: res.success ? res.data.trustee_services : false, required: true, disabled: false, ref: "trustee_services", error: false }, 
                certificate: { value: res.success ? res.data.certificate : false, required: true, disabled: false, ref: "certificate", error: false }, 
                timezone: { value: res.success ? res.data.timezone : false, required: true, disabled: false, ref: "timezone", error: false }, 
            });

            setCountdownId(res.success ? res.data.id : null)
            setLoadingFormData(true);

        })

        return () => {
            toast.dismiss();
        }

    }, [])
    

    const handleSubmitForm = (e) => {

        e.preventDefault();

        let haveError = false;

        if(Object.keys(formData).length){

            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {
                updateFormData[key].error = false;
            })

            if(updateFormData['method_email'].value == false && updateFormData['method_sms'].value == false){

                updateFormData['method_email'].error = true;
                updateFormData['method_sms'].error = true;
                haveError = true;

            }

            if(!updateFormData['frecuency_month'].value && !updateFormData['frecuency_day'].value && !updateFormData['notificate_frecuency'].value){

                updateFormData['notificate_frecuency'].error = true;
                updateFormData['frecuency_month'].error = true;
                updateFormData['frecuency_day'].error = true;
                haveError = true;

            }else{

                if(!updateFormData['notificate_frecuency'].value){

                    if(!updateFormData['frecuency_month'].value && !updateFormData['frecuency_day'].value){

                        updateFormData['frecuency_month'].error = true;
                        updateFormData['frecuency_day'].error = true;
                        haveError = true;

                    }else{

                        if(!updateFormData['frecuency_month'].value){
                            updateFormData['frecuency_month'].error = true;
                            haveError = true;
                        }
                        if(!updateFormData['frecuency_day'].value){
                            updateFormData['frecuency_day'].error = true;
                            haveError = true;
                        }

                    }

                }

            }

            if(!updateFormData['frecuency_hours'].value.length){
                updateFormData['frecuency_hours'].error = true;
                haveError = true;
            }else{
                updateFormData['frecuency_hours'].error = false;
            }
            
            if(!haveError){
                handleSendSetting(updateFormData);
            }
            
            setFormData(updateFormData);
        }

    }

    const resetForm = () => {

        let resetFormData = {...formData};

        Object.keys(resetFormData).map(function(key) {
            resetFormData[key].error = false;
            resetFormData[key].value = '';
        })

        setFormData(resetFormData);

        const update_user_data = {...userData};
        update_user_data.countdown = false;
        setUserData(update_user_data);
        setCountdownId(null);
        
    }

    const resetFormErrors = () => {
        Object.keys(formData).map(function(key) {
            formData[key].error = false;
        })
    }

    const handleSendSetting = () => {

        setLoading(true);

        const response = editSetting(formData, 'countdown');
        response.then(res => {

            let message = '';
            let responseType = '';
            let errors = [];

            if(res.success === true){

                message = 'The Countdown has been saved';
                responseType = 'success';

                const update_user_data = {...userData};
                const countdown_data_update = {...update_user_data.countdown, ...res.data};
                update_user_data.countdown = countdown_data_update;
                setUserData(update_user_data);
                setCountdownId(countdown_data_update.id);
                
            }else{

                responseType = 'error';
                errors.push('An error has occured. Please try again later');

            }

            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setLoading(false);
            
        })

    }

    const handleDeleteCountdown = (e) => {
        
        if(countdownId){
            setLoading(true);
            const response = deleteCountdown(countdownId);
            response.then(res => {

                setLoading(false);
                let message = '';
                let responseType = '';
                let errors = [];

                if(res.success === true){

                    message = 'The Countdown configuration has been deleted';
                    responseType = 'success';

                    resetForm();
                    
                }else{

                    responseType = 'error';
                    errors.push('An error has occured. Please try again later');

                }

                toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

            })
        }

    }

    useEffect(() => {

        if(userData){
            setHaveTimezone(userData.timezone);
        }
    
      return () => {
        
      }
    }, [formData, userData])
    

    return (
        <Wrapper>
            <BoxContainer className="inner">

                {(haveTimezone === null || haveTimezone === false) && 
                    <OverlayTimezone setHaveTimezone={setHaveTimezone} />
                }

                {(!formLoaded || loading) &&
                    <OverlayWaiting />
                }

                <CountdownForm 
                    loadingFormData={loadingFormData}
                    formData={formData} 
                    setFormData={setFormData}
                    setFormLoaded={setFormLoaded}
                />

                <CountdownBeneficiaries 
                    title={t(`countdown.actual_beneficiaries`) + ":"}
                />

                <div className="action_save">
                    
                    <ButtonSolid 
                        type="submit" 
                        style_type="default" 
                        className="bt_save" 
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                        onClick={(e) => { !isTheVaultOpen ? handleSubmitForm(e) : setOverlayVaultOpen(true) }}
                        disabled={loading}
                    >
                        <p>Save</p>
                    </ButtonSolid>
                    {userData.countdown ?
                        <ButtonSolid 
                            type="submit" 
                            style_type="default" 
                            className="bt_delete" 
                            override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}, background:{normal: COLORS.red, hover: "#FFFFFF"}}}
                            onClick={(e) => { !isTheVaultOpen ? handleDeleteCountdown(e) : setOverlayVaultOpen(true) }}
                            disabled={loading}
                        >
                            <p>{t(`buttons.delete_countdown`)}</p>
                        </ButtonSolid>
                    : <></>}

                </div>

            </BoxContainer>
        </Wrapper>
    )
}

export default CountdownContainer
