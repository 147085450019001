import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../helpers/breakpoints';
import { toast } from 'react-toastify';
import { COLORS,
         SIZES } from '../../../helpers/constants';
import Input from '../../Elements/Form/Input';
import Switch from '../../Elements/Form/Switch';
import ButtonSolid from '../../Elements/Buttons/Solid';
import { AuthContext, ContentContext } from '../../../context';
import CustomToastMessageError from '../../../components/Elements/CustomToastMessageError';
import { getErrorMessage } from '../../../helpers/functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation, Trans } from 'react-i18next';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    .field_switch{
        margin-top: 20px;
    }
    .actions{
        margin-top: 20px;
        .loader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 142px;
            height: 40px;
            svg{
                color: ${COLORS.lightgold};
            }
        }
    }
    .inner{
        display: flex;
        flex-direction: column;
        width: 100%;
        .description{
            width: 100%;
        }
    }
    .head{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        >.title{
            font-weight: 600;
            font-size: 18px;
            margin-right: 10px;
        }
    }

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {
        .inner{
            display: flex;
            flex-direction: column;
            width: 100%;
            .description{
                //width: 640px;
            }
        }
    }
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} { }
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`
const Fields = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    
`
const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .aclaration{
        font-size: 12px;
        margin-top: 3px;
        color: ${COLORS.bluegrey};
    }
    &.age_gender{
        .age{
            width: 100px!important;
            margin-right: 15px;
        }
    }
    &.field_title{
        margin-top: 15px;
        margin-bottom: 0!important;
    }
    &.notify_beneficiary{
        margin-bottom: 0!important;
    }

    .current_password{
        margin-bottom: 10px;
    }
    .new_password{
        margin-bottom: 10px;
    }
    .confirm_new_password{
    }
`

const TwoFa = (props) => {

    const { t } = useTranslation();

    const { setErrorMessage } = props;
    
    const { userData,
            setUserData } = useContext(AuthContext);

    const { activateTwoFaMailCode } = useContext(ContentContext);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ twoFactorMailActive, setTwoFactorMailActive ] = useState(true);
    

    let inputRef = useRef({});

    useEffect(() => {
          
        setFormData({
            current_password: { value: "", required: true, disabled: false, ref: "current_password", error: false },
            twofa_mail_code_status: { value: userData ? userData.two_factor_code_status : false, required: false, disabled: false, ref: "twofa_mail_code_status", error: false },
        });

        return () => {
            toast.dismiss();
        }
        
    }, [])

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
        
    }

    const handleActiveTwoFactor = () => {

        setTwoFactorMailActive(!twoFactorMailActive);
        resetForm();
        if(twoFactorMailActive == false){
            
        }

    }
    

    const handleSubmit = (e) => {
        
        e.preventDefault();
        setLoading(true);

        let arrErrorMessage = [];
        const haveError = checkErrorForm();
        const status = formData.twofa_mail_code_status.value;

        if(!haveError){
            
            const payload = { current_password: formData.current_password.value,
                              status: formData.twofa_mail_code_status.value };

            const response = activateTwoFaMailCode(payload);

            response.then((res) => {
                
                setLoading(false);  
                
                if(!res.data.success){
                    
                    if(res.data.errors){

                        Object.keys(res.data.errors).map(function(key) {
                            res.data.errors[key].map((errorText) => {
                                arrErrorMessage.push('- '+errorText);
                            })
                        })

                        setErrorMessage({title: t(`basics.response_title_error`),
                                         text: t(`alerts.response_error`),
                                         errors: arrErrorMessage,
                                         actions: [{type: 'close', text: 'Close', goto: null}]});

                    }
                }else{

                    resetForm();

                    const errors = [];
                    const message = `The E-mail Code was ${status ? "activated" : "deactivated"} successfully. Next time you log in, we will send you a security code via e-mail.`;
                    const responseType = 'success';

                    toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    
                    const updatedUserData = {...userData};
                    updatedUserData.two_factor_code_status = payload.status;
                    setUserData(updatedUserData); 

                }
                
            })
            
        }else{
            setLoading(false);
        }
    }

    const checkErrorForm = () => {

        let haveError = false;
        let arrErrorMessage = [];
        let updateFormData = {...formData};
        let msg = "";

        if(Object.keys(updateFormData).length){

            Object.keys(updateFormData).map(function(key) {

                if(updateFormData[key].required){

                    if(!updateFormData[key].value.length){

                        haveError = true;

                        updateFormData[key].error = true;
                        msg = getErrorMessage(updateFormData[key].ref, 'mandatory');
                        arrErrorMessage.push('- '+msg);

                    }else{
                        
                        updateFormData[key].error = false;

                    }
                }
            })

            if(haveError){
                setErrorMessage({title: t(`basics.response_title_error`),
                                 text: t(`alerts.response_error`),
                                 errors: arrErrorMessage,
                                 actions: [{type: 'close', text: 'Close', goto: null}]});
            }

            setFormData(updateFormData);
            return haveError;

        }

    }

    const resetForm = () => {
        
        let updateFormData = {...formData};

        if(inputRef.current["current_password"]){
            inputRef.current["current_password"].value = '';
            inputRef.current["current_password"].error = false;
        }

        updateFormData["current_password"].value = '';
        updateFormData["current_password"].error = false;

        setFormData(updateFormData);

    }

    return (
        <Wrapper>

            <div className="head">
                <div className="title">Mail Code</div>
            </div>

            
            <div className="inner">
                <div className="description">
                    {t('security.mail_code_description')} <span className="highlight">{userData.email}</span>
                </div>

                <div className="field field_switch">
                    <Switch
                        className={`twofa_mail_code_status ${formData?.twofa_mail_code_status?.error ? "error" : ""}`}
                        toggled={formData?.twofa_mail_code_status?.value}
                        color="default"
                        style_type="default"
                        inputRef={null}
                        label="Activate"
                        elName={formData?.twofa_mail_code_status?.ref}
                        value={formData?.twofa_mail_code_status?.value}
                        //className={`field_switch`}
                        onChange={handleFormElementChange}
                    />
                </div>
                
                <Fields>
                    <Field className="field">
                        <Input
                            className={`current_password ${formData?.current_password?.error ? "error" : ""}`}
                            isRequired={"true"}
                            style_type="default"
                            type="password"
                            label="Current Password"
                            inputRef={inputRef}
                            elName={formData?.current_password?.ref}
                            value={formData?.current_password?.value}
                            onChange={handleFormElementChange}
                        />
                    </Field>
                </Fields>
                <div className="actions">
                    {loading ==! null && loading == true ? 
                        <div className="loader">
                            <CircularProgress size={33} />
                        </div>
                    :
                    <ButtonSolid
                        type="submit"
                        style_type="default"
                        className="bt_save"
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                        onClick={(e) => handleSubmit(e)}
                    >
                        <p>Save</p>
                    </ButtonSolid>
                    }
                </div>
            </div>

        </Wrapper>
    )
}

export default TwoFa;
