import connect, { handleError } from './connect';

export const normalizeForTraslate = (string) => {
    return (string.replace(/ /g,"_")).toLowerCase();
}
export const defineButtonSelected = (routeName) => {

    let selected = null;
    let routeNameSplited = routeName.split("/");
    let route = routeNameSplited[1] + "/" + (routeNameSplited[2] ? routeNameSplited[2] : '');

    if(routeNameSplited[1] == 'my-will'){
        route = 'my-will/';
    }

    console.log("defineButtonSelected ---* ---* ---* ---* ---* ---* ---* ---*")
    console.log(routeNameSplited);
    console.log(route);
    console.log("defineButtonSelected ---* ---* ---* ---* ---* ---* ---* ---*")

    switch (route) {
        case 'dashboard/':
            selected = 'dashboard';
            break;
        case 'parties/':
        case 'parties/add':
        case 'parties/edit':
            selected = 'parties';
            break;
        case '/beneficiaries':
            selected = 'beneficiaries';
            break;
        case '/settings':
            selected = 'settings';
            break;
        case 'my-assets/':
        case 'my-assets/edit':
        case 'my-assets/add': 
            selected = 'my-assets';
            break;
        case 'my-will/':   
            selected = 'my-will';
        default:
            break;
    }

    return selected;

}

export const defineHeaderTitle = (routeName) => {

    let selected = null;
    let routeNameSplited = routeName.split("/");
    let route = routeNameSplited[1] + "/" + (routeNameSplited[2] ? routeNameSplited[2] : '');

    if(routeNameSplited[1] == 'my-will'){
        route = 'my-will/';
    }
    
    console.log("defineHeaderTitle ---* ---* ---* ---* ---* ---* ---* ---*")
    console.log(routeNameSplited);
    console.log(route);
    console.log("defineHeaderTitle ---* ---* ---* ---* ---* ---* ---* ---*")

    switch (route) {
        case 'dashboard/':
            selected = 'Welcome to you Dashboard';
            break;
        case 'parties/':
        case 'parties/add':
        case 'parties/edit':
            selected = 'My Parties';
            break;
        case 'settings/':
            selected = 'Settings';
        case 'settings/profile':
            selected = 'Settings - Profile';
            break;
        case 'settings/notifications':
            selected = 'Settings - Notifications';
            break;
        case 'settings/countdown':
            selected = 'Settings - Countdown';
            break;
        case 'settings/subscription':
            selected = 'Settings - Subscription';
            break;
        case 'settings/security':
            selected = 'Settings - Security & Privacy';
            break;
        case 'my-assets/':
        case 'my-assets/edit':
        case 'my-assets/add':
            selected = 'My Assets';
            break;
        case 'my-will/':
            selected = 'My Will';
            break;
        case 'assigned-assets/':
            selected = 'Assigned Assets';
            break;
        case 'upgrade/':
            selected = 'Upgrade Your Account';
            break;
        case 'contactus/':
            selected = 'Contact Us';
            break;
        default:
            break;
    }

    return selected;

}

export const returnPageData = (pages, routeName) => {

    const pageData = pages.filter(page => page.routeName == routeName);
    console.log(pageData);
    return pageData;
    
}

export const upload = (file, onUploadProgress) => {

    let formData = new FormData();
    formData.append("file", file);
    return connect.post("/upload", formData, { headers: {
                                                "Content-Type": "multipart/form-data",
                                          }, onUploadProgress });

};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const partiesFirstBeneficiary = (parties) => {
    //console.log("executor ----------------")
    const executor = parties.filter(party => party.title.description.toLowerCase() === 'executor');
    const rest = parties.filter(party => party.title.description.toLowerCase() !== 'executor');
    console.log("executor ----------------")
    console.log(executor);
    console.log("executor ----------------")

    return [...executor, ...rest];
}

export const truncateString = (string, length) => {

    return string.substr(0, length) + "\u2026";

}

export function textNumCode( textLength, numLength ){

    let textCode = "";
    let numCode = "";
    let result="";

    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    const num = "0123456789";

    for( var i=0; i < textLength; i++ )
    {
        textCode += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    }
 
    for( var i=0; i < numLength; i++ )
    {
        numCode += num.charAt(Math.floor(Math.random() * num.length));
    }
 
    result = textCode+numCode;
 
    textCode = "";
    numCode = "";
 
    return result;
 }

 export function getErrorMessage(field, validate_type){

    switch (field) {
        case 'email':
            if(validate_type == 'mandatory'){
                return 'The e-mail field is mandatory.';
            }
            if(validate_type == 'format'){
                return 'The e-mail address you have entered is incorrect. Please remember that it should include the symbols @ and . (dot).';
            }
            break;
        case 'password':
            if(validate_type == 'mandatory'){
                return 'The password field is mandatory.';
            }
            break;
        case 'first_name':
            if(validate_type == 'mandatory'){
                return 'The first name field is mandatory.';
            }
            break;
        case 'last_name':
            if(validate_type == 'mandatory'){
                return 'The last name field is mandatory.';
            }
            break;
        case 'repeat_password':
            if(validate_type == 'mandatory'){
                return 'The repeat password field is mandatory.';
            }
            break;
        case 'terms_and_condition':
            if(validate_type == 'mandatory'){
                return 'In order to continue you must accept our terms and conditions.';
            }
            break;
        case 'current_password':
            if(validate_type == 'mandatory'){
                return 'The current password field is mandatory.';
            }
            break;
        case 'new_password':
            if(validate_type == 'mandatory'){
                return 'The new password field is mandatory.';
            }
            break;
        case 'repeat_new_password':
            if(validate_type == 'mandatory'){
                return 'The repeat new password field is mandatory.';
            }
            break;
        default:
            break;
    }

 }

 export function validateFileFormat(type){
    if(type == "image/jpeg" || 
       type == "image/jpg" ||
       type == "application/msword" ||
       type == "application/vnd.ms-excel" ||
       type == "application/pdf" ||
       type == "application/vnd.ms-powerpoint" ||
       type == "image/x-png" ||
       type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
       type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
       type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"){
        return true;

    }
    return false;
 }