import React from "react";
import EditContainer from "./EditContainer";

const EditBeneficiary = (props) => {

  return (
    <EditContainer props={props} location={props.location} history={props.history} />
  );

};

export default EditBeneficiary;
