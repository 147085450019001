import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../helpers/breakpoints';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { ContentContext, AuthContext } from '../../../context';
import UploadImages from '../../../components/Uploads/Images';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import { RiDeleteBin6Line, RiErrorWarningFill } from "react-icons/ri";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useTranslation } from 'react-i18next';

import BoxContainer from '../../../components/Elements/BoxContainer';
import ButtonSolid from '../../../components/Elements/Buttons/Solid';
import ButtonIcon from '../../../components/Elements/Buttons/Icon';
import OverlayWaiting from '../../../components/Elements/OverlayWaiting';
import OverlayExtraDocuments from '../../../components/Elements/OverlayExtraDocuments';
import CustomToastMessageError from '../../../components/Elements/CustomToastMessageError';
import { RiCloseCircleLine } from "react-icons/ri";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { FaCamera } from "react-icons/fa";

import { IcoCameraSolid, 
         IcoBack,
         COLORS } from '../../../helpers/constants';
         
import { MultipleFileUploadField } from '../../../components/Uploads/MultipleFileUploadField';

import CircularProgress from '@material-ui/core/CircularProgress';
import Form from '../../../components/Assets/Form';
import { path } from 'ramda';

const Wrapper = styled.div`

    display: flex;
    //flex: 1;
    //padding-left: 30px;
    //padding-right: 30px;
    padding-top: 17px;
    padding-bottom: 17px;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    
    //background-color: pink;
    /*
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    //margin-right: 20px;
    
    //width: calc(100% - 350px);

    .box_inner{
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        box-sizing: border-box;
        padding: 20px;
        position: relative;
        .form{
            display: flex;
            width: 100%!important;
            flex-direction: column;
            margin-top: 40px;
            .form_element{
                &.blocked{
                    opacity: .3;
                }
            }
            .action_save{
                align-self: flex-end;
                margin-top: 20px;
            }
        }
        
    }
    
    .box_assets{
        margin-bottom: 20px;
    }
    .box_header_actions{
        max-height: 60px;
        box-shadow: 0!important;
        .inner{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            p.title{
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 0 10px;
                padding: 0;
            }
            .bt_save{
                margin-left: auto;
            }
            .action_back{
                display: flex;
                flex-direction: row;
                align-items: center;
                .icon{
                    width: auto;
                    height: auto;
                    font-size: 37px;
                }
            }
            .action_save{
                display: flex;
                flex-direction: row;
                align-items: center;
                .loader{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 150px;
                    min-height: 40px;
                    svg{
                        color: ${COLORS.lightgold};
                    }
                }
            }
        }
    }
    .box_recent{
        margin-top: 20px;
    }
    
    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        .box_inner{
            flex-direction: row;
            .form{
                width: calc(100% - 250px)!important;
                margin-top: 0;
            }
        }
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`




const ModalStyled = styled(Modal)`
    /*
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0!important;
    border-radius: 16px;

    :focus{
        outline: none!important;
    }

    position: absolute;
    top: 20px!important;
    overflow: scroll;
    margin: 0 auto;
    width: 700px;
    height: 100%;
    display: block;
    */

    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0!important;
    border-radius: 0;

    :focus{
        outline: none!important;
    }

    position: absolute;
    top: 0!important;
    overflow: scroll;
    margin: 0 auto;

    //width: calc(100% - 30px);
    width: 100%;
    height: 100%;
    display: block;

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {
        //border-radius: 16px;
        //top: 20px!important;
        width: 700px;
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`
const BoxImage = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 44vw;
    
    &.blocked{
        opacity: .3;
    }
    label{
        font-size: 15px;
        font-weight: 600;
        color: #2B2C2D;
        margin-bottom: 4px;
    }
    .image_inner{
        width: 44vw;
        height: 44vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 10px;
        .image{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            overflow: hidden;

            background-color: ${COLORS.whiteTwo};
            border-radius: 10px;
            border: 1px solid ${COLORS.cloudyblue};

            


            img{
                float: left;
                height: 100%;
            }
        }
        .bt_add_image{
            position: absolute;
            bottom: -20px;
        }
        .bt_delete_image{
            position: absolute;
            bottom: -20px;
            .icon{
                width: unset;
                height: unset;
                font-size: 20px;
            }
        }
    }
    @media ${breakpoints.xs} {

        width: 220px;
        .image_inner{
            width: 220px;
            height: 220px;
        }

    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        align-self: flex-start;
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const BoxFiles = styled.div`
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    width: 350px;
    height: 324px;
    
    
    
    .dzu-dropzone{
        border: 2px dashed ${COLORS.cloudyblue};
        background-color: ${COLORS.whiteTwo};
        border-radius: 10px;
    }
`
const ButtonDelete = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: ${COLORS.red};
    cursor: pointer;

    position: absolute;
    bottom: -20px;

    svg{
        width: 22px;
        height: 22px;
        fill: #FFFFFF;
    }
    :hover{
        background-color: ${COLORS.darkgrey};
    }
`
const ButtonAddImage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: ${COLORS.lightgold};
    cursor: pointer;

    position: absolute;
    bottom: -20px;
    font-size: 20px;

    svg{
        //width: 19px;
        //height: 19px;
        fill: #FFFFFF;
    }
    :hover{
        background-color: ${COLORS.darkgrey};
    }
`
const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 25px;
    cursor: pointer;
    right: 20px;
    top: 20px;
    z-index: 9;
    .icon{
        display: flex;
        font-size: 50px;
        svg{
            fill: ${COLORS.lightgold};
        }
    }
    :hover{
        .icon{
            svg{
                fill: #000000;
            }
        }
    }
`
const FileElements = styled.div`
    display: flex;
    flex-direction: column;
    .title{
        p{
            font-size: 20px;
            font-weight: 600;
            color: #2B2C2D;
            margin-bottom: 4px;
            margin: 0;
        }
    }
    .text{
        margin-bottom: 20px;
        p{
            font-size: 15px;
            font-weight: 500;
            color: #2B2C2D;
            margin: 0;
        }
    }
    .asset_document_wait{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px dashed ${COLORS.red};
        color: ${COLORS.red};
        padding: 10px 10px 10px 10px;
        p{
            margin: 0 0 0 5px;
        }
    }
`
const FileElement = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    border: 1px solid ${COLORS.cloudyblue};
    //background-color: ${COLORS.whiteTwo};
    border-radius: 5px;
    width: 250px;
    padding: 5px 5px 5px 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    .left{
        //background-color: skyblue;
        width: 40px;
        margin-right: 10px;
        .file_type{
            width: 40px;
            height: 40px;
            //background-color: ${COLORS.whiteTwo};
            border: 1px solid ${COLORS.whiteFour};
            border-radius: 5px;
            overflow: hidden;
        }
    }
    .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //background-color: pink;
        width: calc(100% - 85px);
        .bt_delete_file{
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            background-color: ${COLORS.red};
            border-radius: 3px;
            right: 5px;
            top: 5px;
            cursor: pointer;
            svg{
                width: 15px;
                height: 15px;
                fill: #FFFFFF;
            }
            :hover{
                background-color: ${COLORS.darkgrey};
            }
        }
        .file_name{
            font-size: 13px;
        }
        .loader_bar{
            height: 5px;
            //width: ${props => props.percent}%;
            background-color: skyblue;
        }
    }
`




const AddContainer = (props) => {

    const { t } = useTranslation();

    const history = useHistory();
    
    const { userData, setOverlayAssetsLimitReach } = useContext(AuthContext);
    const { getAssets, addAsset, uploadFile, assets, setAssets, deleteFile } = useContext(ContentContext);

    const [ openModal, setOpenModal ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(null);
    const [ croppedImage, setCroppedImage ] = useState(null);
    const [ originalImage, setOriginalImage ] = useState(null);
    const [ filesUpload, setFilesUpload ] = useState([]);
    const [ assetId, setAssetId ] = useState(null);
    const [ isWaiting, setIsWaiting ] = useState(null);
    const [ showOverlayExtraDocuments, setShowOverlayExtraDocuments ] = useState(false);
    const [ assetsLimitReach, setAssetsLimitReach ] = useState(null);

    let inputRef = useRef({});

    const handleBack = () => {
        history.push('/my-assets');
    }

    useEffect(() => {

        setFormData({
            name: { value: "", required: true, disabled: false, ref: "name", error: false },
            asset_category_id: { value: "", required: true, disabled: false, ref: "asset_category_id", error: false },
            description: { value: "", required: true, disabled: false, ref: "description", error: false },
            image: { value: "", required: false, disabled: false, ref: "image", error: false },
            thumb: { value: "", required: false, disabled: false, ref: "thumb", error: false },
        });

        return () => {
            toast.dismiss();
        }
        
    }, [])

    useEffect(() => {

        if(userData && assets){
          let limit_reach = false;
          console.log("userData.subscription.plan.assets", userData.subscription.plan.assets)
          console.log("assets", assets.length)
          if(assets.length >= userData.subscription.plan.assets){
            limit_reach = true;
          }
          setAssetsLimitReach(limit_reach);
          if(limit_reach){
            setOverlayAssetsLimitReach(true);
          }
        }
      
        return () => {}
      }, [userData, assets])
    
    const handleSubmit = (e) => {
        e.preventDefault();

        setLoading(true);
        //setIsWaiting(true);

        let haveError = false;

        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {

                if(formData[key].required){
                    if(!formData[key].value.length){
                        haveError = true;
                        updateFormData[key].error = true;
                    }else{
                        updateFormData[key].error = false;
                    }
                }
                
            })

            /*
            if(!haveError){
                handleAddAsset(updateFormData);
            }
            */

            if(!haveError){
                
                if(croppedImage){
                    const payload = { base64: true, image: croppedImage, original_image: originalImage, type: "image_asset" };
                    //console.log(payload);
                    //return null;
                    const fileUploades = uploadFile(payload);
                    fileUploades.then((result) => {

                        const fileName = path(['data', 'file_name_original'], result) || null;
                        const fileNameThumb = path(['data', 'file_name_thumb'], result) || null;
                        updateFormData['image'].value = fileName;
                        updateFormData['thumb'].value = fileNameThumb;

                        handleSendAsset(updateFormData);
                        //handleAddBeneficiary(updateFormData);

                    })
                }else{

                    handleSendAsset(updateFormData);
                    //handleAddBeneficiary(updateFormData);
                    
                }
            }else{

                setLoading(false);
                
            }





            setFormData(updateFormData);
        }

        
        
    }

    const handleSendAsset = () => {

        //croppedImageProfile
        setLoading(true);
        setIsWaiting(true);

        const response = addAsset(formData);
        response.then(res => {

            let message = '';
            let responseType = '';
            let errors = [];

            if(res.success === true){

                setAssetId(res.data.id);
                setShowOverlayExtraDocuments(true);

                message = t(`alerts.item_added`, { name: 'asset' });
                responseType = 'success';

            }else{

                responseType = 'error';
                errors.push(t('alerts.standar_error'));

            }

            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            
            setLoading(false);
            setIsWaiting(false);
        })

    }
    const resetForm = () => {
        
        Object.keys(inputRef.current).map(function(key) {
            inputRef.current[key].value = '';
        })
        Object.keys(formData).map(function(key) {
            formData[key].value = '';
        })

        /*
        setCroppedImageProfile(null);
        setProfileImage(null);
        setImageBase64(null);
        */

    }

    const handleUploadImage = () => {

        setOpenModal(true);

    }
    const handleDeleteImage = () => {

        setCroppedImage(null);
        setOriginalImage(null);
        resetForm();
        
    }

    const onUpload = (file_uploaded) => {
        console.log("file_uploaded --------------------------------")

        const update_asset = [...assets];
        const updated_asset = update_asset.map((a) => {
            if(a.id == assetId){
                a.files.push(file_uploaded.data);
            }
            return a;
        })

        setAssets(updated_asset);
        
        console.log("file_uploaded --------------------------------")
    }

    const handleCloseOverlayExtraDocuments = () => {
        setShowOverlayExtraDocuments(false);
        setIsWaiting(null);
        handleDeleteImage();
    }
    
    
    
    return (
        <Wrapper>

            <ModalStyled
                disableAutoFocus={true}
                open={openModal}
                onClose={() => setOpenModal(!openModal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className="inner_modal">
                        <ButtonClose
                            onClick={() => setOpenModal(!openModal)}
                        >
                            <div className="icon">
                                <RiCloseCircleLine size={45} />
                            </div>
                        </ButtonClose>

                        <UploadImages
                            croppedImage={croppedImage}
                            setCroppedImage={setCroppedImage}
                            setOriginalImage={setOriginalImage}
                            handleClose={() => setOpenModal(!openModal)}
                        />
                    </div>
                </Fade>

            </ModalStyled>

            <Container>
                
                <BoxContainer className="box_header_actions">
                    <div className="inner">
                        <div className="action_back">
                            <ButtonIcon style_type="default" onClick={handleBack}>
                                <div className="icon">
                                    <IoArrowBackCircleOutline />
                                </div>
                            </ButtonIcon>
                            <p className="title">{t(`section_title.new`, { section: "Asset" })}</p>
                        </div>
                    </div>
                </BoxContainer>
                <BoxContainer className="box_inner">

                    {showOverlayExtraDocuments &&
                        <OverlayExtraDocuments 
                            assetId={assetId} 
                            setShowOverlayExtraDocuments={setShowOverlayExtraDocuments} 
                            handleCloseOutside={handleCloseOverlayExtraDocuments}
                            type="add" 
                        />
                    }

                    {isWaiting &&
                        <OverlayWaiting />
                    }
                    <BoxImage className={`${isWaiting != null && isWaiting == false ? 'blocked' : ''}`}>
                        <label>{t(`labels.image_preview`)}</label>
                        <div className="image_inner">
                            <div className="image">
                                {croppedImage ? 
                                    <img src={croppedImage} />
                                :
                                    <></>    
                                }
                            </div>
                            {isWaiting == null ?
                                croppedImage ? 
                                    <ButtonDelete onClick={handleDeleteImage}>
                                        <RiDeleteBin6Line />
                                    </ButtonDelete>
                                :
                                    <ButtonAddImage onClick={handleUploadImage}>
                                        <FaCamera />
                                    </ButtonAddImage>
                                :
                                <></>
                            }
                        </div>
                    </BoxImage>
                    
                    {/*
                    <BoxFiles>
                        asd
                    </BoxFiles>
                    */}
                    <div className="form">
                        <Form formData={formData} setFormData={setFormData} inputRef={inputRef} className={`form_element ${isWaiting != null && isWaiting == false ? 'blocked' : ''}`} />
                        {/*
                        <FileElements>
                            <div className="title">
                                <p>Add extra documents:</p>
                            </div>
                            <div className="text">
                                <p>Formats admitted: .doc .docx .xls .xlsx .pdf .ppt .jpg .jpeg .png, and the size should not exceed 5 megabytes.</p>
                            </div>

                            {assetId !== null ?
                                <MultipleFileUploadField assetId={assetId} folder={"assets"} onUpload={onUpload} />
                            :
                                <div className="asset_document_wait">
                                    <RiErrorWarningFill size="20" />
                                    <p>Before uploading new documents please save the information on your asset.</p>
                                </div>
                            }
                        </FileElements>
                        */}
                        <div className="action_save">
                            {assetsLimitReach == false ?
                                loading ==! null && loading == true ? /**/
                                <div className="loader">
                                    <CircularProgress size={33} />
                                </div>
                                :
                                    <ButtonSolid
                                        disabled={isWaiting != null && isWaiting == false ? true : false}
                                        type="submit"
                                        style_type="default"
                                        className="bt_save"
                                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                        onClick={(e) => handleSubmit(e)}
                                    >
                                        <p>{t(`buttons.save`)}</p>
                                    </ButtonSolid>
                            :
                            <></>
                            }
                        </div>


                    </div>

                    

                    

                </BoxContainer>
            </Container>

            {/*
            <Right>
                <BoxContainer className="box_vip stretched">
                    <div>VIP</div>
                </BoxContainer>
                <BoxContainer className="box_countdown stretched">
                    <div>COUNTDOWN</div>
                </BoxContainer>
            </Right>
            */}

        </Wrapper>
    )
}

export default AddContainer
