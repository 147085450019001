import React, { useState, useRef, useEffect, useContext } from 'react';

import styled from 'styled-components';
import { useHistory } from 'react-router';
import { validateEmail,
         getErrorMessage } from '../../helpers/functions';

import ButtonSolid from '../Elements/Buttons/Solid';
import OverlayErrorMessage from '../Elements/Form/OverlayErrorMessage';
import Input from '../Elements/Form/Input';
import TwoFaMailCodeContainer from '../Home/TwoFaMailCodeContainer';

import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthContext } from '../../context';
import { COLORS } from '../../helpers/constants'
import { breakpoints } from '../../helpers/breakpoints';

import { useTranslation, Trans } from 'react-i18next';

/*
import createDOMPurify from 'dompurify'
import { JSDOM } from 'jsdom'

const window = (new JSDOM('')).window
const DOMPurify = createDOMPurify(window)
*/
const Wrapper = styled.div`

    white-space: pre-line;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    .title{
        letter-spacing: 0px;
        font-size: 9.6vw;
        line-height: 11vw;
        font-weight: 700;
        color: ${COLORS.darkgrey};
    }
    .description{
        font-size: 3.5vw;
    }
    .twofa_title{
        letter-spacing: 0px;
        font-size: 6vw;
        line-height: 9vw;
        font-weight: 700;
        color: ${COLORS.darkgrey};
    }
    >.inner{
        box-sizing: border-box;
        padding: 15px;
    }
    .form_register,
    .form_2fa{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .field{
            width: 100%;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .actions{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            .bt_signup{
                width: 100%;
                min-height: 65px;
                
                p{
                    font-size: 20px;
                }
            }
            .donthave{
                margin-top: 30px;
                font-size: 16px;
                color: ${COLORS.darkgrey};
                button{
                    font-size: 16px;
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    color: ${COLORS.lightgold};
                    cursor: pointer;
                }
                button:hover{
                    color: ${COLORS.darkgrey};
                }
            }
        }
        a.bt_forgot{
            display: flex;
            align-self: flex-end;
            text-decoration: none;
            margin-top: 10px;
            font-weight: 500;
            color: ${COLORS.darkgold};
            :hover{
                color: ${COLORS.darkgrey};
            }
        }
        .loader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 150px;
            min-height: 65px;
            svg{
                color: ${COLORS.lightgold};
            }
        }
    }

    @media ${breakpoints.xs} {
        .title{
            font-size: 48px;
            line-height: 55px;
        }
        .twofa_title{
            font-size: 30px;
            line-height: 45px;
        }
        .description{
            font-size: 16px;
        }
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {

    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }

`
const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: calc(50% - 10px);
    .description{
        textarea{
            resize: none;
            height: 180px;
        }
    }
`

const LoginContainer = (props) => {

    const { t } = useTranslation();
    
    const { login,
            twofaMailCodeActive, } = useContext(AuthContext);
    
    const history = useHistory();

    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(null);
    const [ formHaveError, setFormHaveError ] = useState(false);
    const [ errorMessage, setErrorMessage ] =  useState([]);
    
    let inputRef = useRef({});

    useEffect(() => {

        setFormData({
            email: { value: "", required: true, disabled: false, ref: "email", error: false },
            password: { value: "", required: true, disabled: false, ref: "password", error: false },
        });

        return () => {
            //toast.dismiss();
        }
        
    }, [])

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
        
    }

    const handleSubmit = (e) => {
        
        e.preventDefault();
        setLoading(true);

        let arrErrorMessage = [];
        const haveError = checkErrorForm();
        
        

        if(!haveError){
            const response = login(formData['email'].value, formData['password'].value);
            response.then((res) => {
                
                if(!res.success){
                    if(res.errors){
                        
                        Object.keys(res.errors).map(function(key) {
                            res.errors[key].map((errorText) => {
                                arrErrorMessage.push('- '+errorText);
                            })
                        })
                        
                        setErrorMessage({title: 'OOPS!',
                                         text: t('login.form.error.message'),
                                         errors: arrErrorMessage,
                                         actions: [{type: 'close', text: 'Close', goto: null}]});

                        setFormHaveError(true);

                    }else{
                        
                        arrErrorMessage.push('- '+res.message);

                        let actionButtons = [];

                        if(res.type === 'activation'){
                            actionButtons.push({type: 'resend', text: 'Resend Validation Mail', goto: null, data: {email: res.data.email}});
                        }
                        actionButtons.push({type: 'close', text: 'Close', goto: null});

                        setErrorMessage({title: 'OOPS!',
                                         text: t('login.form.error.message'),
                                         errors: arrErrorMessage,
                                         actions: actionButtons});

                    }

                    setLoading(false);
                    
                }
            })
        }else{
            setLoading(false);
        }
    }

    const checkErrorForm = () => {

        let haveError = false;
        let arrErrorMessage = [];

        if(formData){
            if(Object.keys(formData).length){
                
                let updateFormData = {...formData};

                Object.keys(formData).map(function(key) {
                    
                    if(formData[key].required){
                        if(!formData[key].value.length){
                            haveError = true;

                            updateFormData[key].error = true;
                            const msg = t(`formErrors.mandatory.${formData[key].ref}`);//getErrorMessage(formData[key].ref, 'mandatory');
                            arrErrorMessage.push('- '+msg);

                        }else{
                            updateFormData[key].error = false;
                        }
                        
                        if(key === 'email'){
                            if(formData[key].value.length){
                                const validEmail = validateEmail(formData[key].value);
                                if(!validEmail){
                                    haveError = true;
                                    updateFormData[key].error = true;

                                    const msg = t(`formErrors.${formData[key].ref}`);//getErrorMessage(formData[key].ref, 'format');
                                    arrErrorMessage.push('- '+msg);
                                    
                                }else{
                                    updateFormData[key].error = false;
                                }
                            }
                        }

                    }
                    
                })

                if(haveError){
                    
                    setErrorMessage({title: 'OOPS!',
                                     text: t('login.form.error.message'),
                                     errors: arrErrorMessage,
                                     actions: [{type: 'close', text: 'Close', goto: null}]});

                    setFormHaveError(true);

                }

                setFormData(updateFormData);
                return haveError;

            }
        }

    }

    const checkErrorFormMailCode = () => {

        let haveError = false;
        let arrErrorMessage = [];


    }

    return (
        <Wrapper>
            
            <OverlayErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
            
            {twofaMailCodeActive == false ? 
            <>
            <div className="title">
                {t(`login.title`)}
            </div>

            <div className="form_register">

                <Field className="field">
                    <Input
                        className={`email ${formData?.email?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="text"
                        label={`${t("labels.email")}:`}
                        inputRef={inputRef}
                        elName={formData?.email?.ref}
                        value={formData?.email?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>

                <Field className="field">
                    <Input
                        className={`password ${formData?.password?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="password"
                        label={`${t("labels.password")}:`}
                        inputRef={inputRef}
                        elName={formData?.password?.ref}
                        value={formData?.password?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>

                <a href="javascript:void(0);" className="bt_forgot" onClick={() => history.push('/forgot')}>
                    {t("basics.forgot")}
                </a>

                <div className="actions">

                    {loading ?
                        <div className="loader">
                            <CircularProgress size={50} />
                        </div>
                        :
                        <ButtonSolid
                            type="submit"
                            style_type="default"
                            className="bt_signup"
                            override_css={{color:{normal: "#FFFFFF", hover: COLORS.lightgold},
                                        background:{normal: COLORS.lightgold, hover: '#FFFFFF'},
                                        border:{normal: COLORS.lightgold, hover: COLORS.lightgold}}}
                            onClick={(e) => handleSubmit(e)}
                        >
                            <p>{t("buttons.signin")}</p>
                        </ButtonSolid>


                    }

                    <div className="donthave">
                        {t("basics.dont_have_account")} <button onClick={() => history.push('/signup')}>{t("buttons.signup")}</button>
                    </div>
                </div>
            </div>
            </>
            :
            <>
            <div className="twofa_title">
                LOGIN VERIFICATION
            </div>

            <div className="description">
                {t('login.twocode')}
            </div>

            <div className="form_2fa">
                
                <TwoFaMailCodeContainer email={formData?.email?.value} password={formData?.password?.value} />
                {/*
                <div className="actions">

                    {loading2Fa ?
                        <div className="loader">
                            <CircularProgress size={50} />
                        </div>
                        :
                        <ButtonSolid
                            type="submit"
                            style_type="default"
                            className="bt_signup"
                            override_css={{color:{normal: "#FFFFFF", hover: COLORS.lightgold},
                                        background:{normal: COLORS.lightgold, hover: '#FFFFFF'},
                                        border:{normal: COLORS.lightgold, hover: COLORS.lightgold}}}
                            onClick={(e) => handleSubmitMailCode(e)}
                        >
                            <p>Send Code</p>
                        </ButtonSolid>


                    }

                    <div className="donthave">
                        Se vencio su código? <button onClick={() => history.push('/signup')}>Resend Code</button>
                    </div>
                </div>
                */}
            </div>
            
            
            </>
            }
        </Wrapper>
    )
}

export default LoginContainer;
