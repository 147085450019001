import React, { useContext, useState, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from 'react-i18next';
import { breakpoints } from '../../helpers/breakpoints';
import { ContentContext,
         AuthContext } from '../../context';
import CircularProgress from '@material-ui/core/CircularProgress';

import { AiOutlineWarning } from "react-icons/ai";
import { IoIosCloseCircleOutline, IoMdClose } from "react-icons/io";
import { BiEditAlt, BiMailSend } from "react-icons/bi";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import ElementAsset from "../../components/Elements/MyLastWill/ElementAsset";
import ElementParty from "../../components/Elements/MyLastWill/ElementParty";

import Image from '../../components/Elements/Image';
import NoImage from '../../components/Elements/NoImage';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import OverlayImage from '../../components/Elements/OverlayImage';
import ImageProfile from '../../components/Elements/ImageProfile';
import imgSelectAsset from '../../assets/images/img_select_asset.svg'
import { COLORS } from '../../helpers/constants';

import { styled as muiStyled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

//import 'swiper/modules/navigation/navigation.scss';
import { Navigation, Pagination } from 'swiper';

const BootstrapTooltip = muiStyled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} enterTouchDelay={0} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: COLORS.bluegrey,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: COLORS.bluegrey,
    }})
);

const WrapperStyled = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;
    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {
      
    }
    @media ${breakpoints.xxxl} {
      min-height: ${props => props.windowHeight};
    }
    @media ${breakpoints.xxxxl} { }
`
const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
  box-sizing: border-box;
  padding-top: 17px;
  padding-bottom: 17px;

  @media ${breakpoints.xs} {
      //min-width: 576px
  }
  @media ${breakpoints.sm} {
      //min-width: 768px
      
  }
  @media ${breakpoints.md} {
      //min-width: 992px
      //width: 662px;
  }
  @media ${breakpoints.lg} {
      //min-width: 1200px
      //width: 870px;
  }
  @media ${breakpoints.xl} {
      //min-width: 1366px
      //width: 1036px;
  }
  @media ${breakpoints.xxl} {
      //min-width: 1440px
      //width: 1110px;
  }
  @media ${breakpoints.xxxl} {
      //min-width: 1680px
      //width: 1350px;
  }
  @media ${breakpoints.xxxxl} {
      //min-width: 1920px
      //width: 1590px;
      //background-color: skyblue;
  }
`
const StepperStyled = styled.div`
  background-color: #000000;
  width: 300px;
  border-radius: 16px;
`
const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  width: 100%;
  border-radius: 16px;
  box-sizing: border-box;
  
  .top{
    display: flex;
    flex-direction: column;
    margin-bottom: 8vw;
    margin-top: 7vw;
    width: calc(100% - 14vw);
    .header{
      .title{
        font-weight: 600;
        font-size: 6vw;
        color: ${COLORS.darkgrey};
        margin-bottom: 1vw;
      }
      .description{
        font-size: 3.2vw;
        color: ${COLORS.bluegrey};
      }
    }
  }
  .bottom{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    .title_box{
      width: 100%;
      height: 30px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      font-weight: 600;
      color: ${COLORS.darkgrey};
    }
    .container{
      border-radius: 6px;
      
      padding: 10px 15px 10px 15px;
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      height: auto;

    }
    .box_distribution{
      width: 100%;
      margin-top: 30px;
      .container{
        .no_asset_selected{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10vw;
          .img_select_asset{
            display: flex;
            width: 40vw;
          }
          p{
            width: 80vw;
            margin: 0;
            padding: 0;
            color: ${COLORS.cloudyblue};
            text-align: center;
          }
          p.title{
            font-weight: 700;
            font-size: 3.5vw;
            margin-top: 4vw;
          }
          p.description{
            font-weight: 500;
            font-size: 2.9vw;
            line-height: 3.9vw;
            margin-top: 2vw;
          }
        }
        .asset_distribution_info{
          background-color: #FFFFFF;
          border-radius: 16px;
          padding: 3vw;

          position: relative;
          
          .bt_edit_distribution,
          .bt_edit_notify{
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 5vw;
            height: 5vw;
            right: 3vw;
            top: 3vw;
            font-size: 3.1vw;
            background-color: ${COLORS.whiteThree};
            cursor: pointer;
            &.selected{
              background-color: ${COLORS.lightgold};
              color: #FFFFFF;
            }
            :hover{
              background-color: ${COLORS.lightgold};
              color: #FFFFFF;
            }
          }
          .bt_edit_notify{
            top: 9vw;
          }
          .prorate_overlay{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.9);
            border-radius: 16px;
            left: 0;
            top: 0;
            z-index: 1;
            p{
              color: #FFFFFF;
              font-weight: 600;
            }
          }

          .asset{
            display: flex;
            flex-direction: row;
            .thumb{
              border-radius: 10px;
              overflow: hidden;
              width: 18vw;
              height: 18vw;
              margin-right: 2vw;
              img{
                float: left;
                width: 100%;
                border: 0;
              }
            }
            .data{
              width: calc(100% - 100px);
              padding-top: 1vw;
              .name{
                font-weight: 600;
                font-size: 3.8vw;
                line-height: 4.1vw;
                color: ${COLORS.darkgrey};
              }
              .category{
                font-weight: 600;
                font-size: 2.9vw;
                line-height: 3.2vw;
                color: ${COLORS.lightgold};
                margin-top: 1vw;
              }
            }
          }
          .loaderParties{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-left: 0;
            /*
            width: calc(100% - 100px);
            margin-left: 100px;
            */
            margin-top: 10px;
            min-height: 70px;
            border-radius: 10px;
            border: 1px dashed ${COLORS.bluegrey};
            .loader{
              width: 33px;
              height: 33px;
              svg{
                color: ${COLORS.bluegrey};
              }
            }
          }
          .firstBeneficiary{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-left: 0;
            /*
            width: calc(100% - 100px);
            margin-left: 100px;
            */
            margin-top: 10px;
            min-height: 70px;
            border-radius: 10px;
            border: 1px dashed ${COLORS.bluegrey};
            p{
              font-size: 14px;
              font-weight: 500;
              color: ${COLORS.bluegrey};
            }
          }
          .parties{
            width: 100%;
            margin-left: 0;
            /*
            width: calc(100% - 100px);
            margin-left: 100px;
            */
            margin-top: 10px;
            box-sizing: border-box;
            border-radius: 10px;
            
            &.not_edit{
              border: 0;
              .inner{
                padding: 0;
              }
            }
            .inner{
              display: grid; 
              grid-template-columns: repeat(1, 1fr);
              gap: 10px;
            }
            .prorated_container{
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              height: 40px;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              p{
                margin: 0;
                padding: 0;
                font-size: 2.7vw;
                line-height: 3.9vw;
                font-weight: 600;
                color: ${COLORS.bluegrey};
              }
              .icon{
                color: ${COLORS.bluegrey};
                font-size: 4vw;
                margin-right: 1vw;
              }
            }

            .party{
              position: relative;
              box-sizing: border-box;
              padding: 1vw 1vw 1vw 1vw;
              border-radius: 10px;
              border: 1px solid ${COLORS.whiteThree};
              display: flex;
              flex-direction: row;
              .left{
                width: 11vw;
                margin-right: 2vw;
                .thumb{
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  width: 11vw;
                  height: 14vw;
                  overflow: hidden;
                  border-radius: 2vw;
                  background-color: ${COLORS.whiteTwo};
                  img{
                    float: left;
                    height: 100%;
                  }
                }
              }
              .right{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 1vw 1vw 1vw 0;
                width: calc(100% - 13vw);
                .party_name{
                  font-weight: 600;
                  font-size: 2.8vw;
                  line-height: 3vw;
                  color: ${COLORS.bluegrey};
                }
                .text_portion{
                  width: 100%;
                  text-align: center;
                  font-size: 3.1vw;
                  font-weight: 600;
                  color: ${COLORS.bluegrey};
                  margin-top: 2.1vw;
                  padding: 0.4vw 0 0.4vw 0; 
                  border: 1px solid ${COLORS.whiteTwo};
                  background-color: #FFFFFF;/*${COLORS.whiteTwo}*/
                  border-radius: 6px;
                }
                .input_portion{
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  width: 100%;
                  margin-top: 2vw;
                  font-weight: 600;
                  font-size: 3.1vw;
                  color: ${COLORS.bluegrey};
                  .input_portion_value{
                    width: 2.2vw;
                    font-size: 2.4vw;
                    font-weight: 700;
                    text-align: center;
                  }
                  input{ 
                    width: calc(100% - 3vw);
                    margin-right: 2px;
                    color: ${COLORS.bluegrey};
                    background-color: ${COLORS.whiteTwo};
                    border: 1px solid ${COLORS.whiteThree};
                    font-weight: 600;
                    font-size: 3.1vw;
                    text-align: center;
                    height: 6vw;
                    border-radius: 6px;
                    outline: 0!important;
                    box-sizing: border-box;

                    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
                    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
                    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
                    :disabled{
                      opacity: .5;
                    }
                  }
                }
              }
              
              .bt_remove_party,
              .loaderDeleteParty{
                color: #FFFFFF;
                width: 4vw;
                height: 4vw;
                border-radius: 10px;
                border: 0;
                padding: 0;
                margin: 0;
                right: -1.4vw;
                top: -1.4vw;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                line-height: 3.1vw;
                font-size: 3.1vw;
                position: absolute;
                background-color: ${COLORS.red};
                cursor: pointer;
                :hover{
                  background-color: ${COLORS.darkgold}
                }
              }
              .loaderDeleteParty{
                cursor: default;
                background-color: ${COLORS.darkgold};
                :hover{
                  background-color: ${COLORS.darkgold};
                }
                svg{
                  color: #FFFFFF;
                }
              }
              
            }
          }
          .error_tohigh{
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-left: 0;
            /*
            width: calc(100% - 100px);
            margin-left: 100px;
            */
            margin-top: 2vw;
            font-size: 2.9vw;
            color: ${COLORS.red};
          }
          .actions{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            margin-left: 0;
            /*
            width: calc(100% - 100px);
            margin-left: 100px;
            */
            margin-top: 2vw;
            .bt_cancel{
              width: calc(50% - 1.1vw);
              margin-left: 2vw;
              margin-right: auto;
              p{
                font-size: 3.3vw;
                padding-left: 4vw;
                padding-right: 4vw;
                padding-top: 1.2vw;
                padding-bottom: 1.2vw;
              }
            }
            .bt_prorate{
              width: 100%;
              margin-left: auto;
              margin-top: 2vw;
              p{
                font-size: 3.3vw!important;
                padding-left: 4vw;
                padding-right: 4vw;
                padding-top: 1.2vw;
                padding-bottom: 1.2vw;
              }
            }
            .bt_save{
              width: calc(50% - 5px);
              p{
                font-size: 3.3vw;
                padding-left: 4vw;
                padding-right: 4vw;
                padding-top: 1.2vw;
                padding-bottom: 1.2vw;
              }
              :disabled{
                p{
                  color: ${COLORS.cloudyblue}!important;
                }
              }
            }
            .loader_distribute{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              width: 8vw;
              height: 8vw;
              svg{
                color: ${COLORS.darkgold};
              }
            }
          }
        }

      }
    }
    .not_have_add{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 0;
      p{
        width: 100%;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        color: ${COLORS.bluegrey};
      }
    }
    .box_assets{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-right: 0;
      .slider_assets{
        display: flex;
        flex-direction: row;
        align-items: stretch;
        width: 100%;
        height: 86px;//100px;
        background-color: ${COLORS.lightgreyTwo};
        box-sizing: border-box;
        padding: 10px;
        border-radius: 12px;
        margin-bottom: 10px;
      }
      .container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .column_assets{
          display: none;
          width: 100%;
        }
      }
      .loader{
        padding-top: 20px;
        svg{
          color: ${COLORS.lightgold};
        }
      }
      .elements{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .element{
          display: flex;
          background-color: #FFFFFF;
          width: 100%;
          border-radius: 10px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          padding: 10px 10px 10px 10px;
          border: 1px solid #FFFFFF;
          cursor: pointer;

          :hover{
            border: 1px solid ${COLORS.cloudyblue};
          }

          .order_number{
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            width: 25px;
            color: ${COLORS.darkgrey};
          }
          .data{
            display: flex;
            flex-direction: column;
            width: calc(100% - 25px);
            .name{
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: ${COLORS.darkgrey};
            }
            .category{
              font-weight: 600;
              font-size: 12px;
              line-height: 15px;
              color: ${COLORS.lightgold};
              margin-top: 2px;
            }
          }
          &.selected{
            border: 1px solid ${COLORS.cloudyblue};
          }
        }
      }
    }
    .box_beneficiaries{
      width: 100%;
      margin-left: 0;
      margin-top: 30px;
      margin-bottom: 30px;
      .loader{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 0;
        svg{
          color: ${COLORS.lightgold};
        }
      }
      .slider_parties{
        display: flex;
        width: 100%;
        height: 86px;
        background-color: ${COLORS.lightgreyTwo};
        box-sizing: border-box;
        padding: 10px;
        border-radius: 12px;
        margin-bottom: 10px;

        &.inactive{
          .element{
            opacity: .5;
              cursor: default!important;
              border: 1px solid #FFFFFF;
              :hover{
                border: 1px solid #FFFFFF;
              }
            }
        }

      }
      .container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .column_parties{
          display: none;
          width: 100%;
        }
      }
      .elements{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .element{
          display: flex;
          background-color: #FFFFFF;
          width: 100%;
          border-radius: 10px;
          margin-top: 5px;
          margin-bottom: 5px;
          box-sizing: border-box;
          padding: 7px 10px 7px 10px;
          border: 1px solid #FFFFFF;
          cursor: pointer;

          :hover{
            border: 1px solid ${COLORS.cloudyblue};
          }

          .thumb{
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: ${COLORS.whiteTwo};
            width: 40px;
            height: 50px;
            margin-right: 8px;
            overflow: hidden;
            border-radius: 6px;
            img{
              float: left;
              height: 100%;
            }
          }
          .data{
            display: flex;
            flex-direction: column;
            width: calc(100% - 53px);
            .name{
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
              color: ${COLORS.darkgrey};
            }
            .title{
              width: 100%;
              font-size: 13px;
              line-height: 16px;
              text-align: center;
              font-weight: 600;
              color: ${COLORS.lightgold};
              text-align: left;
              margin-top: 2px;
            }
          }
          &.selected{
            border: 1px solid ${COLORS.cloudyblue};
          }
        }
        &.inactive{
          .element{
            opacity: .5;
              cursor: default!important;
              border: 1px solid #FFFFFF;
              :hover{
                border: 1px solid #FFFFFF;
              }
            }
          
        }
      }
      

    }
  }

  @media ${breakpoints.xs} {
    .top{
      margin-bottom: 40px;
      margin-top: 35px;
      width: calc(100% - 70px);
      .header{
        .title{
          font-size: 30px;
          margin-bottom: 5px;
        }
        .description{
          font-size: 16px;
        }
      }
    }
    .bottom{

      .box_distribution{

        .container{





          .no_asset_selected{
            margin-top: 50px;
            .img_select_asset{
              width: 150px;
            }
            p{
              width: 400px;
              margin: 0;
              padding: 0;
            }
            p.title{
              font-size: 18px;
              margin-top: 20px;
            }
            p.description{
              font-size: 14px;
              line-height: 19px;
              margin-top: 10px;
            }
          }






          .asset_distribution_info{
            background-color: #FFFFFF;
            border-radius: 16px;
            padding: 15px;

            position: relative;
            
            .bt_edit_distribution,
            .bt_edit_notify{
              position: absolute;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 25px;
              height: 25px;
              right: 15px;
              top: 15px;
              font-size: 16px;
              background-color: ${COLORS.whiteThree};
              cursor: pointer;
              &.selected{
                background-color: ${COLORS.lightgold};
                color: #FFFFFF;
              }
              :hover{
                background-color: ${COLORS.lightgold};
                color: #FFFFFF;
              }
            }
            .bt_edit_notify{
              top: 45px;
            }
            .prorate_overlay{
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.9);
              border-radius: 16px;
              left: 0;
              top: 0;
              z-index: 1;
              p{
                color: #FFFFFF;
                font-weight: 600;
              }
            }

            .asset{
              display: flex;
              flex-direction: row;
              .thumb{
                border-radius: 10px;
                overflow: hidden;
                width: 90px;
                height: 90px;
                margin-right: 10px;
                img{
                  float: left;
                  width: 100%;
                  border: 0;
                }
              }
              .data{
                width: calc(100% - 100px);
                padding-top: 5px;
                .name{
                  font-weight: 600;
                  font-size: 19px;
                  line-height: 21px;
                  color: ${COLORS.darkgrey};
                }
                .category{
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 17px;
                  color: ${COLORS.lightgold};
                  margin-top: 5px;
                }
              }
            }
            .loaderParties{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              margin-left: 0;
              margin-top: 10px;
              min-height: 70px;
              border-radius: 10px;
              border: 1px dashed ${COLORS.bluegrey};
              .loader{
                width: 33px;
                height: 33px;
                svg{
                  color: ${COLORS.bluegrey};
                }
              }
            }
            .firstBeneficiary{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              margin-left: 0;
              margin-top: 10px;
              min-height: 70px;
              border-radius: 10px;
              border: 1px dashed ${COLORS.bluegrey};
              p{
                font-size: 14px;
                font-weight: 500;
                color: ${COLORS.bluegrey};
              }
            }
            .parties{
              width: 100%;
              margin-left: 0;
              margin-top: 10px;
              box-sizing: border-box;
              border-radius: 10px;
              
              &.not_edit{
                border: 0;
                .inner{
                  padding: 0;
                }
              }
              .inner{
                display: grid; 
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
              }
              .prorated_container{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 40px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                p{
                  margin: 0;
                  padding: 0;
                  font-size: 15px;
                  line-height: 19px;
                  font-weight: 600;
                  color: ${COLORS.bluegrey};
                }
                .icon{
                  color: ${COLORS.bluegrey};
                  font-size: 25px;
                  margin-right: 5px;
                }
              }

              .party{
                position: relative;
                box-sizing: border-box;
                padding: 5px 5px 5px 5px;
                border-radius: 10px;
                border: 1px solid ${COLORS.whiteThree};
                display: flex;
                flex-direction: row;
                .left{
                  width: 55px;
                  margin-right: 10px;
                  .thumb{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 55px;
                    height: 70px;
                    overflow: hidden;
                    border-radius: 10px;
                    background-color: ${COLORS.whiteTwo};
                    img{
                      float: left;
                      height: 100%;
                    }
                  }
                }
                .right{
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: space-between;
                  box-sizing: border-box;
                  padding: 5px 5px 5px 0;
                  width: calc(100% - 65px);
                  .party_name{
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 15px;
                    color: ${COLORS.bluegrey};
                  }
                  .text_portion{
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    color: ${COLORS.bluegrey};
                    margin-top: 12px;
                    padding: 2px 0 2px 0; 
                    border: 1px solid ${COLORS.whiteTwo};
                    background-color: #FFFFFF;/*${COLORS.whiteTwo}*/
                    border-radius: 6px;
                  }
                  .input_portion{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    margin-top: 10px;
                    font-weight: 600;
                    font-size: 16px;
                    color: ${COLORS.bluegrey};
                    .input_portion_value{
                      width: 13px;
                      font-size: 14px;
                      font-weight: 700;
                      text-align: center;
                    }
                    input{ 
                      width: calc(100% - 15px);
                      margin-right: 2px;
                      color: ${COLORS.bluegrey};
                      background-color: ${COLORS.whiteTwo};
                      border: 1px solid ${COLORS.whiteThree};
                      font-weight: 600;
                      font-size: 16px;
                      text-align: center;
                      height: 30px;
                      border-radius: 6px;
                      outline: 0!important;
                      box-sizing: border-box;

                      box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
                      -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
                      -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);

                    }
                  }
                }
                
                .bt_remove_party,
                .loaderDeleteParty{
                  color: #FFFFFF;
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  border: 0;
                  padding: 0;
                  margin: 0;
                  right: -7px;
                  top: -7px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  line-height: 16px;
                  font-size: 16px;
                  position: absolute;
                  background-color: ${COLORS.red};
                  cursor: pointer;
                  :hover{
                    background-color: ${COLORS.darkgold}
                  }
                }
                .loaderDeleteParty{
                  cursor: default;
                  background-color: ${COLORS.darkgold};
                  :hover{
                    background-color: ${COLORS.darkgold};
                  }
                  svg{
                    color: #FFFFFF;
                  }
                }
                
              }
            }
            .error_tohigh{
              display: flex;
              flex-direction: row;
              width: 100%;
              /*
              width: calc(100% - 100px);
              margin-left: 100px;
              */
              margin-top: 10px;
              font-size: 14px;
              color: ${COLORS.red};
            }
            .actions{
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-left: 0;
              margin-top: 10px;
              .bt_cancel{
                width: calc(50% - 5px);
                max-width: auto;
                margin-left: 5px;
                p{
                  font-size: 16px;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-top: 7px;
                  padding-bottom: 7px;
                }
              }
              .bt_prorate{
                width: 100%;
                margin-left: auto;
                margin-top: 10px;
                p{
                  font-size: 16px!important;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-top: 7px;
                  padding-bottom: 7px;
                }
                &.cancel{
                  height: 40px;
                  width: 100%;
                }
              }
              .bt_save{
                width: calc(50% - 5px);
                max-width: auto;
                p{
                  font-size: 16px;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-top: 7px;
                  padding-bottom: 7px;
                }
                :disabled{
                  p{
                    color: ${COLORS.cloudyblue}!important;
                  }
                }
              }
              .loader_distribute{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                svg{
                  color: ${COLORS.darkgold};
                }
              }
            }
          }




        }
      }

    }
  }
  @media ${breakpoints.sm} {
    .bottom{
      .box_distribution{
        .container{
          .asset_distribution_info{
            .actions{
              .bt_cancel{
                width: calc(33% - 5px);
                max-width: 250px;
                margin-left: 5px;
                p{
                  font-size: 16px;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-top: 7px;
                  padding-bottom: 7px;
                }
              }
              .bt_prorate{
                width: 150px;
                margin-left: auto;
                margin-top: 0;
                p{
                  font-size: 16px!important;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-top: 7px;
                  padding-bottom: 7px;
                }
                &.cancel{
                  height: 40px;
                  width: 170px;
                }
              }
              .bt_save{
                width: calc(33% - 5px);
                max-width: 250px;
                p{
                  font-size: 16px;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-top: 7px;
                  padding-bottom: 7px;
                }
                :disabled{
                  p{
                    color: ${COLORS.cloudyblue}!important;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
  @media ${breakpoints.md} {
    .bottom{
      .box_distribution{
        .container{
          .asset_distribution_info{
            .parties{
              .inner{
                display: grid; 
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;
              }
            }
          }
        }
      }
    }
  }
  @media ${breakpoints.lg} {}
  @media ${breakpoints.xl} {
    .bottom{
      .box_distribution{
        .container{
          .asset_distribution_info{
            .parties{
              .inner{
                display: grid; 
                grid-template-columns: repeat(4, 1fr);
                gap: 10px;
              }
            }
          }
        }
      }
    }
  }
  @media ${breakpoints.xxl} {}
  @media ${breakpoints.xxxl} {

    .bottom{
      flex-direction: row;
      width: calc(100% - 70px);
      .container{
        padding: 10px 15px 10px 15px;
        height: ${props => props.windowHeight};
        background-color: ${COLORS.lightgreyTwo};
      }
      .box_assets{
        width: 300px;
        margin-right: 20px;
        .slider_assets{
          display: none;
        }
        .container{
          .column_assets{
            display: flex;
          }
        }
      }
      .box_beneficiaries{
        margin-top: 0;
        width: 300px;
        margin-left: 20px;
        .loader{
          padding-top: 20px;
        }
        .slider_parties{
          display: none;
        }
        .container{
          .column_parties{
            display: flex;
          }
        }
      }
      .box_distribution{
        margin-top: 0;
        width: calc(100% - 640px);
        .container{




          .asset_distribution_info{
            background-color: #FFFFFF;
            border-radius: 16px;
            padding: 15px;

            position: relative;
            
            .bt_edit_distribution,
            .bt_edit_notify{
              position: absolute;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 25px;
              height: 25px;
              right: 15px;
              top: 15px;
              font-size: 16px;
              background-color: ${COLORS.whiteThree};
              cursor: pointer;
              &.selected{
                background-color: ${COLORS.lightgold};
                color: #FFFFFF;
              }
              :hover{
                background-color: ${COLORS.lightgold};
                color: #FFFFFF;
              }
            }
            .bt_edit_notify{
              top: 45px;
            }
            .prorate_overlay{
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.9);
              border-radius: 16px;
              left: 0;
              top: 0;
              z-index: 1;
              p{
                color: #FFFFFF;
                font-weight: 600;
              }
            }

            .asset{
              display: flex;
              flex-direction: row;
              .thumb{
                border-radius: 10px;
                overflow: hidden;
                width: 90px;
                height: 90px;
                margin-right: 10px;
                img{
                  float: left;
                  width: 100%;
                  border: 0;
                }
              }
              .data{
                width: calc(100% - 100px);
                padding-top: 5px;
                .name{
                  font-weight: 600;
                  font-size: 19px;
                  line-height: 21px;
                  color: ${COLORS.darkgrey};
                }
                .category{
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 17px;
                  color: ${COLORS.lightgold};
                  margin-top: 5px;
                }
              }
            }
            .loaderParties{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              margin-left: 0;
              /*
              width: calc(100% - 100px);
              margin-left: 100px;
              */
              margin-top: 10px;
              min-height: 70px;
              border-radius: 10px;
              border: 1px dashed ${COLORS.bluegrey};
              .loader{
                width: 33px;
                height: 33px;
                svg{
                  color: ${COLORS.bluegrey};
                }
              }
            }
            .firstBeneficiary{
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 100%;
              margin-left: 0;
              /*
              width: calc(100% - 100px);
              margin-left: 100px;
              */
              margin-top: 10px;
              min-height: 70px;
              border-radius: 10px;
              border: 1px dashed ${COLORS.bluegrey};
              p{
                font-size: 14px;
                font-weight: 500;
                color: ${COLORS.bluegrey};
              }
            }
            .parties{
              width: 100%;
              margin-left: 0;
              /*
              width: calc(100% - 100px);
              margin-left: 100px;
              */
              margin-top: 10px;
              box-sizing: border-box;
              border-radius: 10px;
              
              &.not_edit{
                border: 0;
                .inner{
                  padding: 0;
                }
              }
              .inner{
                display: grid; 
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;
              }
              .prorated_container{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 40px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                p{
                  margin: 0;
                  padding: 0;
                  font-size: 15px;
                  line-height: 19px;
                  font-weight: 600;
                  color: ${COLORS.bluegrey};
                }
                .icon{
                  color: ${COLORS.bluegrey};
                  font-size: 25px;
                  margin-right: 5px;
                }
              }

              .party{
                position: relative;
                box-sizing: border-box;
                padding: 5px 5px 5px 5px;
                border-radius: 10px;
                border: 1px solid ${COLORS.whiteThree};
                display: flex;
                flex-direction: row;
                .left{
                  width: 55px;
                  margin-right: 10px;
                  .thumb{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    width: 55px;
                    height: 70px;
                    overflow: hidden;
                    border-radius: 10px;
                    background-color: ${COLORS.whiteTwo};
                    img{
                      float: left;
                      height: 100%;
                    }
                  }
                }
                .right{
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: space-between;
                  box-sizing: border-box;
                  padding: 5px 5px 5px 0;
                  width: calc(100% - 65px);
                  .party_name{
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 15px;
                    color: ${COLORS.bluegrey};
                  }
                  .text_portion{
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    color: ${COLORS.bluegrey};
                    margin-top: 12px;
                    padding: 2px 0 2px 0; 
                    border: 1px solid ${COLORS.whiteTwo};
                    background-color: #FFFFFF;/*${COLORS.whiteTwo}*/
                    border-radius: 6px;
                  }
                  .input_portion{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    margin-top: 10px;
                    font-weight: 600;
                    font-size: 16px;
                    color: ${COLORS.bluegrey};
                    .input_portion_value{
                      width: 13px;
                      font-size: 14px;
                      font-weight: 700;
                      text-align: center;
                    }
                    input{ 
                      width: calc(100% - 15px);
                      margin-right: 2px;
                      color: ${COLORS.bluegrey};
                      background-color: ${COLORS.whiteTwo};
                      border: 1px solid ${COLORS.whiteThree};
                      font-weight: 600;
                      font-size: 16px;
                      text-align: center;
                      height: 30px;
                      border-radius: 6px;
                      outline: 0!important;
                      box-sizing: border-box;

                      box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
                      -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);
                      -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.15);

                    }
                  }
                }
                
                .bt_remove_party,
                .loaderDeleteParty{
                  color: #FFFFFF;
                  width: 20px;
                  height: 20px;
                  border-radius: 10px;
                  border: 0;
                  padding: 0;
                  margin: 0;
                  right: -7px;
                  top: -7px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  line-height: 16px;
                  font-size: 16px;
                  position: absolute;
                  background-color: ${COLORS.red};
                  cursor: pointer;
                  :hover{
                    background-color: ${COLORS.darkgold}
                  }
                }
                .loaderDeleteParty{
                  cursor: default;
                  background-color: ${COLORS.darkgold};
                  :hover{
                    background-color: ${COLORS.darkgold};
                  }
                  svg{
                    color: #FFFFFF;
                  }
                }
                
              }
            }
            .error_tohigh{
              display: flex;
              flex-direction: row;
              width: 100%;//calc(100% - 100px);
              margin-left: 0;//100px;
              margin-top: 10px;
              font-size: 14px;
              color: ${COLORS.red};
            }
            .actions{
              display: flex;
              flex-direction: row;
              width: 100%;
              margin-left: 0;
              margin-top: 10px;
              .bt_cancel{
                width: calc(33% - 5px);
                margin-left: 10px;
                p{
                  font-size: 16px;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-top: 7px;
                  padding-bottom: 7px;
                }
              }
              .bt_prorate{
                width: 150px;
                margin-left: auto;
                margin-top: 0;
                p{
                  font-size: 16px!important;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-top: 7px;
                  padding-bottom: 7px;
                }
                &.cancel{
                  height: 40px;
                  width: 170px;
                }
              }
              .bt_save{
                width: calc(33% - 5px);
                p{
                  font-size: 16px;
                  padding-left: 20px;
                  padding-right: 20px;
                  padding-top: 7px;
                  padding-bottom: 7px;
                }
                :disabled{
                  p{
                    color: ${COLORS.cloudyblue}!important;
                  }
                }
              }
              .loader_distribute{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                svg{
                  color: ${COLORS.darkgold};
                }
              }
            }
          }




        }
      }
    }

  }
  @media ${breakpoints.xxxxl} {}

`
const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 25px;
    cursor: pointer;
    right: 13px;
    top: 13px;
    z-index: 9;
    .icon{
        display: flex;
        svg{
          width: 35px;
          height: 35px;
          fill: ${COLORS.whiteTwo};
        }
    }
    :hover{
        .icon{
            svg{
                fill: ${COLORS.lightgold};
            }
        }
    }
`
const ButtonStyled = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  border-radius: 6px;
  cursor: pointer;

  &.bt_prorate, &.bt_add_beneficiary, &.bt_add_asset{
    background-color: ${COLORS.lightgold};
    border: 1px solid ${COLORS.lightgold};
    width: 100%;
    :hover:enabled{
      background-color: #FFFFFF;
      border: 1px solid ${COLORS.lightgold};
      p{
        color: ${COLORS.lightgold}!important;
      }
    }
    :disabled{
      cursor: default;
      background-color: ${COLORS.whiteTwo};
      border: 1px solid ${COLORS.cloudyblue};
      p{
        color: ${COLORS.cloudyblue};
      }
    }
  }
  &.bt_add_beneficiary, &.bt_add_asset{
    margin-top: 5px;
    max-width: 220px;
  }

  &.cancel{
    background-color: ${COLORS.red}!important;
    border: 1px solid ${COLORS.red}!important;

    :hover:enabled{
      background-color: #FFFFFF!important;
      border: 1px solid ${COLORS.red}!important;
      p{
        color: ${COLORS.red}!important;
      }
    }

  }

  p{

      margin: 0;
      color: #FFFFFF!important;
      font-size: 16px!important;
      font-weight: 600!important;
      letter-spacing: -0.38px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 7px;
      padding-bottom: 7px;
      
  }
  @media ${breakpoints.xs} {}
  @media ${breakpoints.sm} {}
  @media ${breakpoints.md} {}
  @media ${breakpoints.lg} {}
  @media ${breakpoints.xl} {}
  @media ${breakpoints.xxl} {}
  @media ${breakpoints.xxxl} {
    &.bt_add_beneficiary, &.bt_add_asset{
      max-width: 100%;
    }
  }
  @media ${breakpoints.xxxxl} {}
`
const SwiperStyled = styled(Swiper)`
  display: flex;
  width: 100%;//calc(100% - 30px);
  overflow: unset;
  //padding: 10px 0 10px 0;
  //overflow: hidden;
  
  .content-hide{
    display: none;
  }
  .content-show{
    display: block;
  }
  .swiper-wrapper{
    .swiper-slide{
      width: auto!important;
      border-radius: 10px;
      box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.15);
      -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.15);
      -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.15);
    }
  }
  .next,
  .prev{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26px!important;
    height: 26px!important;
    border-radius: 13px;
    background-color: #FFFFFF;
    position: absolute;
    top: calc(50% - 13px);
    z-index: 9999;
    cursor: pointer;
  }
  .next,
  .prev{
    &.swiper-button-disabled{
      opacity: 0.5;
      cursor: default;
    }
  }
  .next{
    right: 10px;
  }
  .prev{
    left: 10px;
  }
  @media ${breakpoints.xs} {
    //width: 544px;
  }
  @media ${breakpoints.sm} {
    //width: 448px;
  }
  @media ${breakpoints.md} {
    //width: 672px;
  }
  @media ${breakpoints.lg} {
    //width: 840px;
  }

  /*position: absolute;*/
`


const MyLastWillContainer = (props) => {

  const { t } = useTranslation();

  const history = useHistory();
  let { id:asset_id_param } = useParams();
  const { height, width } = useWindowDimensions();

  const { assets,
          setAssets,
          parties,
          getAssetDistribution,
          getAssetDistributionById,
          deletePartyDistribution,
          saveDistributedAsset } = useContext(ContentContext);
  
  const { userData,
          isTheVaultOpen,
          setOverlayVaultOpen,
          setOverlayBeneficiariesLimitReach } = useContext(AuthContext);

  const [ partySelected, setPartySelected ] = useState("");
  const [ assetSelected, setAssetSelected ] = useState(null);
  const [ distributedAssets, setDistributedAssets ] = useState([]);
  const [ distributedParties, setDistributedParties ] = useState(null);
  const [ distributedInitParties, setDistributedInitParties ] = useState([]);
  const [ partiesSelected, setPartiesSelected ] = useState([]);
  const [ distributedPartiesLoader, setDistributedPartiesLoader ] = useState(false);
  const [ distributedLoader, setDistributedLoader ] = useState(false);
  const [ deletePartyLoader, setDeletePartyLoader ] = useState("");
  const [ toHigh, setToHigh ]  = useState(false);
  const [ splitIqual, setSplitIqual ]  = useState(false);
  const [ errorMessage, setErrorMessage ]  = useState(false);
  const [ error, setError ] = useState(false);
  const [ isProratedInit, setIsProratedInit ] = useState(false);
  const [ isProrated, setIsProrated ] = useState(false);
  const [ editDistribution, setEditDistribution ] = useState(false);
  const [ totalDistributionCount, setTotalDistributionCount ] = useState(false);
  const [ canClick, setCanClick ] = useState(false);
  const [ showImage, setShowImage ] = useState(null);
  const [ noImage, setNoImage ] = useState(null);
  const [ atLeatOneSaved, setAtLeatOneSaved ] = useState(false);
  const [ beneficiariesLimitReach, setBeneficiariesLimitReach ] = useState(null);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const distributionBoxEl = useRef(null);
  
  useEffect(() => {

    if(userData && parties){
      let limit_reach = false;
      if(parties.length >= userData.subscription.plan.beneficiaries){
        limit_reach = true;
      }
      setBeneficiariesLimitReach(limit_reach);
    }
    
    
    return () => {}
  }, [userData, parties])

  const handleAddNewBeneficiary = () => {
    if(!isTheVaultOpen){
      if(!beneficiariesLimitReach){
        history.push('/parties/add');
      }else{
        setOverlayBeneficiariesLimitReach(true);
      }
    }else{
      setOverlayVaultOpen(true);
    }
  }

  const handleSelectParty = (party) => {
    if(!distributedParties.length){
      setEditDistribution(true);
    }
    setPartySelected(party);
    addDistributedAssets(party, assetSelected, '0');

  };

  const addDistributedAssets = (party, asset, portion) => {

    const distributedAssetsObject = {party: {id: party.id, data: party},
                                     asset: {id: asset.id, data: asset},
                                     portion: portion}

    const distributedPartyObject = {party: {id: party.id, data: party},
                                    portion: portion,
                                    saved: false}

    setDistributedParties(prev => [...prev, distributedPartyObject]);
    
    setDistributedAssets(prev => [...prev, distributedAssetsObject]);

  }

  useEffect(() => {
    
    if(asset_id_param){
      setEditDistribution(false);
      if(assets){
        const assetParamSelected = assets.filter(asset => asset.id == asset_id_param);
        if(assetParamSelected){
          
          setAssetSelected(assetParamSelected[0]);
        }
      }
    }else{
      setAssetSelected(null);
    }
    return () => {};
  }, [assets, asset_id_param])
  

  const handleSelectAsset = (asset) => {
    history.push('/my-will/'+asset.id)
  };

  useEffect(() => {
    
    if(assetSelected){

      setDistributedPartiesLoader(true);
      setDistributedParties([]);

      setIsProratedInit(assetSelected.is_prorated);
      setIsProrated(assetSelected.is_prorated);

      const asset_distributed = getAssetDistributionById(assetSelected.id);
      asset_distributed.then((res) => {
  
        if(res){
          setDistributedInitParties([]);
          const res_copy = res.data.map(a => {return {...a}})
          const data = normalize_data(res.data);
          const data2 = normalize_data(res_copy);
          setDistributedInitParties(data);
          setDistributedParties(data2);

          if(data.length){
            setAtLeatOneSaved(true);
          }

        }

        setDistributedPartiesLoader(false);

      })
    }

    return () => {
      
    }
  }, [assetSelected])

  const normalize_data = (data) => {
    const array_data = [];
    data.map((party) => {
      const distributedPartyObject = {party: {id: party.id, data: party.data},
                                      portion: party.portion,
                                      saved: party.saved,
                                      prorated: party.prorated}

      array_data.push(distributedPartyObject);
    })
    return array_data;
  }

  const handleRemoveParty = (distributedParty) => {

    setDeletePartyLoader("");
    const distributedPartyRemoved = distributedParties.filter(da => da.party.id != distributedParty.party.id);
    setIsProrated(false);
    setDistributedParties(distributedPartyRemoved);

    if(distributedParties.length <= 1){
      if(atLeatOneSaved){
        sendDistributedAsset(distributedPartyRemoved);
        setAtLeatOneSaved(false);
      }
    }

  }

  const handleSaveDistribution = () => {
    
    const total = calculateTotal();
    let errorDistribution = false;

    if(total < 100){
      errorDistribution = true;
      setError(errorDistribution);        
      setErrorMessage(t(`alerts.total_distribution`));
      return null;
    }else{

      if(distributedParties != null){
        const haveZero = distributedParties.filter((dp => Number(dp.portion) === 0));
        if(haveZero.length){
          errorDistribution = true;
          setError(errorDistribution);        
          setErrorMessage(t("alerts.cannot_have_0_distribution"));
          return null;
        }
      }
      errorDistribution = false;
      setError(errorDistribution);  
      setErrorMessage("");
    }
    
    if(!errorDistribution){
      sendDistributedAsset(distributedParties);
    }

  }

  const sendDistributedAsset = (distributedParties) => {

    setDistributedLoader(true);
    const payload = { parties: distributedParties,
                      asset_id: assetSelected.id,
                      is_prorated: isProrated };

    const saved = saveDistributedAsset(payload);
    saved.then((res) => {

      const data = normalize_data(res.data.parties);

      setDistributedInitParties(res.data.parties);

      if(res.data.parties.length){
        setAtLeatOneSaved(true);
      }
      
      const assetProrated = assets.map((asset, index) => {
        if(asset.id == assetSelected.id){
          asset.is_prorated = isProrated;
          asset.is_assigned = distributedParties.length ? true : false;
        }
        return asset;
      })
      
      setAssets(assetProrated);
      setDistributedLoader(false);

    })

  }

  const handleCancelDistribution = () => {
    const update = distributedInitParties.map(a => {return {...a}});
    setDistributedParties(update);
    setEditDistribution(false);
    setIsProrated(isProratedInit);
  }

  const handleProrateOverlay = () => {
    setSplitIqual(!splitIqual);
  }

  const handleCancelProrateDistribution = () => {
    setIsProrated(false);
    const total = calculateTotal();
    setTotalDistributionCount(total);
  }
  useEffect(() => {
    
    const total = calculateTotal();
    setTotalDistributionCount(total);
  
    return () => {}
  }, [isProrated])
  
  const handleProrateDistribution = () => {

    const equal_portion = (100 / distributedParties.length).toFixed(2);
    const prorated = distributedParties.map((party, index) => {
      party.portion = equal_portion;
      return party;
    })
    setDistributedParties(prorated);
    setIsProrated(true);

  }

  useEffect(() => {
    
    const total = calculateTotal();
    setTotalDistributionCount(total);

    if(total > 100){
      setToHigh(true);
      setError(true);
      setErrorMessage(t(`alerts.total_distribution`));
    }else{
      setToHigh(false);
      setError(false);
      setErrorMessage("");
    }
  
  }, [distributedParties])

  const calculateTotal = () => {

    if(!isProrated){
      let total = 0;
      if(distributedParties != null){
        for (let index = 0; index < distributedParties.length; index++) {
          const element = distributedParties[index];
          if(!isNaN(distributedParties[index].portion)){
            total = total + Number(distributedParties[index].portion);//.slice(0, -1)
          }
        }
      }
      return parseFloat(total).toFixed(2);
    }else{
      return 100;
    }

  }
  
  const partyIsSelected = (party_id) => {

    let exist = [];
    if(distributedParties != null){
      exist = distributedParties.filter(da => da.party.data.id == party_id);
    }
    return exist.length ? true : false;

  }

  const handleEditDistributeAsset = (status) => {

    setEditDistribution(!editDistribution);
    if(!editDistribution == true){
      setTotalDistributionCount(calculateTotal());
    }else{
      handleCancelDistribution();
    }

  }

  useEffect(() => {

    if(!isTheVaultOpen){
      if(assetSelected){
        if(editDistribution){
          setCanClick(true);
        }else{
          if(!distributedParties || distributedParties.length == 0){
            setCanClick(true);
          }else{
            setCanClick(false);
          }
        }
      }
    }
    return () => {
      
    }
  }, [assetSelected, isTheVaultOpen, editDistribution, parties, distributedParties])




  const handlePartyPortion = (distributedParty, value) => {

    const updateDistributedParty = distributedParties.map((da, index) => {

      if(da.party.id == distributedParty.party.id){
        if(value.match(/^([0-9]{1,3})?(\.)?([0-9]{1,2})?$/)){
          da.portion = value;//parseFloat(value).toFixed(2);
        }
      }
      return da;

    });

    setTotalDistributionCount(calculateTotal());
    setDistributedParties(updateDistributedParty);

  }

  const [number, setNumber] = useState('')

  const handleNumber = (e) => {

      let input = e.target.value

      if(input.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/))
          setNumber(input)

  }

  const handleFloat = () => {
      setNumber(parseFloat(number) || '')
  }

  const handleShowImage = (image, noimage) => {

    setNoImage(noimage)
    setShowImage(image);

  }

  return (
    <WrapperStyled 
      className="WrapperStyled"
      windowHeight={(height - 100)+"px"}
    >

      {showImage &&
        <OverlayImage image={showImage} setShowImage={setShowImage} noImage={noImage} setNoImage={setNoImage} />
      }

      <ContainerStyled className="ContainerStyled">
        
        <ContentStyled 
          className="ContentStyled" 
          ref={distributionBoxEl} 
          windowHeight={(height - 350)+"px"}
          elHeight={(distributionBoxEl.current?.clientHeight - 180) + "px"}>

          <div className="top">
            <div className="header">
              <div className="title">{t(`my_will.title`)}</div>
              <div className="description">{t(`my_will.description`)}</div>
            </div>
          </div>




          


          





          <div className="bottom">

            <div className="box_assets">
              <div className="title_box">
                {t(`basics.assets`)}
              </div>
              <div className="container">
                







                {assets && assets.length > 0 &&
                  <div className="slider_assets">
                    {assets &&
                      <SwiperStyled
                        onInit={(swiper) => {
                          swiper.params.navigation.prevEl = prevRef.current;
                          swiper.params.navigation.nextEl = nextRef.current;
                          swiper.navigation.init();
                          swiper.navigation.update();
                        }}

                        modules={[Navigation]}
                        spaceBetween={10}
                        slidesPerView={"auto"}
                        onSlideChange={() => console.log("")}
                        onSwiper={(swiper) => console.log("")}
                        className="swiper_assets"
                      >
                        {
                          assets.map((asset, index) => {
                            return(
                              <SwiperSlide>
                                <ElementAsset asset={asset} assetSelected={assetSelected} index={index} handleSelectAsset={handleSelectAsset} />
                              </SwiperSlide>
                            )
                          })
                        }

                        <div ref={prevRef} className="prev">
                          <FaChevronLeft />
                        </div>
                        <div ref={nextRef} className="next">
                          <FaChevronRight />
                        </div>

                      </SwiperStyled>
                    }
                  </div>
                }








                <div className="column_assets">
                  {assets === null ?

                    <div className="loader">
                        <CircularProgress size={33} />
                    </div>
                    
                    :
                    
                    <div className="elements">

                      {assets && assets.length > 0 &&
                        assets && assets.map((asset, index) => {
                          return(
                            <div className={`element ${assetSelected && assetSelected.id == asset.id ? 'selected' : ''}`} onClick={() => handleSelectAsset(asset)}>
                              <div className="order_number">{`${(index+1) <= 9 ? '0'+(index+1) : (index+1)}.`}</div>
                              <div className="data">
                                <div className="name">{ asset.name }</div>
                                <div className="category">{ asset.category.description }</div>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>

                  }
                </div>
                
                <ButtonStyled type="submit" style_type="invert" className="bt_add_asset" onClick={() => history.push('/my-assets/add')}>
                    <p>{t(`buttons.add_new_asset`)}</p>
                </ButtonStyled>

                {/*<p>{t(`alerts.not_upload_yet`, { name: "assets" })}</p>*/}
                {/*assets && assets.length <= 0 &&
                  <div className="not_have_add">
                    <ButtonStyled type="submit" style_type="invert" className="bt_add_asset" onClick={() => history.push('/my-assets/add')}>
                        <p>{t(`buttons.add_new_asset`)}</p>
                    </ButtonStyled>
                  </div>
                */}
                
              </div>
            </div>

            <div className="box_distribution">
              <div className="title_box">
                {t(`basics.distribution`)}
              </div>

              <div className="container">
                {assetSelected ? 
                  <div className="asset_distribution_info">
                    {distributedParties != null && distributedParties.length > 0 &&
                      <div className={`bt_edit_distribution ${editDistribution ? 'selected' : ''}`} onClick={ () => !isTheVaultOpen ? handleEditDistributeAsset() : setOverlayVaultOpen(true) }>
                        <BiEditAlt />
                      </div>
                    }
                    {splitIqual &&
                    <div className="prorate_overlay">
                        <ButtonClose
                          onClick={() => handleProrateOverlay()}
                        >
                          <div className="icon">
                            <IoIosCloseCircleOutline />
                          </div>
                        </ButtonClose>
                        <p>{t(`tooltips.distribute_equally`)}</p>
                        <ButtonSolid
                          className="bt_delete_beneficiary"
                          onClick={() => handleProrateDistribution()}
                          style_type="tab"
                          override_css={{
                            color:{normal: "#000000", hover: COLORS.darkgold},
                            background:{normal: COLORS.lightgold, hover: "#000000"},
                          }}
                        >
                          <p>{t(`buttons.confirm`)}</p>
                        </ButtonSolid>
                    </div>
                    }
                    <div className="asset">
                      
                        {assetSelected.thumb ? 
                          <div className="thumb" onClick={() => handleShowImage(assetSelected.image_file, false)}>
                            <Image filename={assetSelected.thumb_file} showMagnify={true} type={"image_asset"} />
                          </div>
                        :
                          <div className="thumb" onClick={() => handleShowImage(assetSelected.category.description, true)}>
                            <NoImage type={assetSelected.category.description} showMagnify={true} />
                          </div>
                        }
                      
                      <div className="data">
                        <div className="name">{ assetSelected.name }</div>
                        <div className="category">{ assetSelected.category.description }</div>
                      </div>
                    </div>
                    {distributedPartiesLoader ?
                    
                      <div className="loaderParties">
                        <div className="loader">
                            <CircularProgress size={33} />
                        </div>
                      </div>
                    :
                    distributedParties != null && distributedParties.length > 0 ?
                    <>
                      <div className={`parties ${!editDistribution ? 'not_edit' : ''}`}>
                        <div className="inner">
                        {distributedParties.map((distributedParty, index) => {
                          return(
                            <div className="party" key={"party-"+distributedParty.party.id}>
                              {deletePartyLoader == distributedParty.party.id ?
                                <div className="loaderDeleteParty">
                                  <CircularProgress size={15} />
                                </div>
                              :
                                editDistribution &&
                                <button className="bt_remove_party" onClick={() => handleRemoveParty(distributedParty)}>
                                  <IoMdClose />
                                </button>
                              }
                              <div className="left">
                                <div className="thumb">
                                  <ImageProfile profile_image={distributedParty.party.data.profile_image} gender={distributedParty.party.data.gender} />
                                </div>
                              </div>
                              <div className="right">
                                <div className="party_name">{`${distributedParty.party.data.first_name} ${distributedParty.party.data.middle_name ? distributedParty.party.data.middle_name : ''} ${distributedParty.party.data.last_name}`}</div>
                                {editDistribution ?
                                  <div className="input_portion">
                                    <input disabled={isProrated} placeholder='00.00' value={distributedParty.portion} onChange={(e) => handlePartyPortion(distributedParty, e.target.value)} onBlur={handleFloat}/>
                                    {/*<input type="number" step="0.01" max="100" min="0.01" maxLength="5" value={distributedParty.portion} onChange={(e) => handlePartyPortion(distributedParty, e.target.value)}/>*/}
                                    <div className="input_portion_value">%</div>
                                  </div>
                                :
                                  <div className="text_portion">
                                    { distributedParty.portion }%
                                  </div>
                                }
                              </div>
                            </div>
                          )
                        })}
                        </div>

                        {isProrated &&
                          <div className="prorated_container">
                            <AiOutlineWarning className="icon" />
                            <p>{t(`alerts.prorated`)}</p>
                          </div>
                        }
                      
                      </div>

                      
                      
                      {error && <div className="error_tohigh">{ errorMessage }</div>}

                      <div className="actions">

                        {distributedLoader ?
                          <div className="loader_distribute">
                            <CircularProgress size={25} />
                          </div>
                          :
                          (distributedParties && editDistribution) &&
                          <>
                            <ButtonSolid disabled={(!isProrated && totalDistributionCount != 100) ? true : (isProrated && distributedParties.length <= 1) ? true : false} type="submit" style_type="default" className="bt_save" override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}} onClick={() => handleSaveDistribution()}>
                                <p>{`${t(`buttons.save`)} ${!isProrated ? '(' + (totalDistributionCount ? totalDistributionCount : 0)+'%)' : ''}`}</p>
                            </ButtonSolid>
                            <ButtonSolid type="submit" style_type="invert" className="bt_cancel" override_css={{color:{normal: "#000000", hover: "#FFFFFF"}}} onClick={() => handleCancelDistribution()}>
                                <p>{t(`buttons.cancel`)}</p>
                            </ButtonSolid>

                            {!isProrated ?
                              distributedParties && distributedParties.length > 1 ? 
                                <BootstrapTooltip title={t(`tooltips.distribute_equally`)} placement="top">
                                  <ButtonStyled type="submit" style_type="invert" className="bt_prorate" onClick={() => handleProrateDistribution()}>
                                      <p>{t(`buttons.prorate`)}</p>
                                  </ButtonStyled>
                                </BootstrapTooltip>
                              :<></>
                              
                            :
                            <ButtonStyled type="submit" style_type="invert" className="bt_prorate cancel" onClick={() => handleCancelProrateDistribution()}>
                                <p>{t(`buttons.cancel_prorate`)}</p>
                            </ButtonStyled>
                            
                            }
                          </>
                        }
                      </div>
                    </>
                    :
                    
                    <div className="firstBeneficiary">
                      <p>{t(`buttons.add_first`, { name: "beneficiary" })}</p>
                    </div>
                    
                    }
                    
                  </div>
                :
                <div className="no_asset_selected">
                  <img src={imgSelectAsset} className="img_select_asset" />
                  <p className="title">{t(`my_will.no_assets`)}</p>
                  <p className="description">{t(`my_will.no_assets_description`)}</p>
                </div>
                }
              </div>
            </div>

            <div className="box_beneficiaries">
              <div className="title_box">
                {t(`basics.beneficiaries`)}
              </div>
              <div className="container">
                

                





              {parties && parties.length > 0 && (distributedParties ? distributedParties.length != parties.length : true) &&
                <div className={`slider_parties ${!canClick && 'inactive'}`}>
                  { distributedPartiesLoader ? 
                        <div className="loader">
                            <CircularProgress size={33} />
                        </div>
                  :
                  parties &&
                    <SwiperStyled
                      onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                      }}

                      modules={[Navigation]}
                      spaceBetween={10}
                      slidesPerView={"auto"}
                      onSlideChange={() => console.log("")}
                      onSwiper={(swiper) => console.log("")}
                      className="swiper_assets"
                    >
                      {
                        parties.map((party, index) => {
                          if(partyIsSelected(party.id)){
                            return null;
                          }
                          return(
                            <SwiperSlide>
                              <ElementParty party={party} index={index} canClick={canClick} handleSelectParty={handleSelectParty} />
                            </SwiperSlide>
                          )
                        })
                      }

                      <div ref={prevRef} className="prev">
                        <FaChevronLeft />
                      </div>
                      <div ref={nextRef} className="next">
                        <FaChevronRight />
                      </div>

                    </SwiperStyled>
                  }
                </div>
                }










                <div className="column_parties">
                  {parties && parties.length > 0 &&
                    <div className={`elements ${!canClick && 'inactive'}`}>
                      
                      { distributedPartiesLoader ? 
                        <div className="loader">
                            <CircularProgress size={33} />
                        </div>
                        :
                        parties && parties.map(party => {
                        if(partyIsSelected(party.id)){
                          return null;
                        }
                        return(
                          <div className="element" onClick={() => { canClick && handleSelectParty(party) }}>

                            <div className="thumb">
                              <ImageProfile profile_image={party.profile_image} gender={party.gender} />
                            </div>

                            <div className="data">
                              <div className="name">{`${party.first_name} ${party.middle_name ? party.middle_name : ''} ${party.last_name}`}</div>
                              <div className="title">{ party.title.description }</div>
                            </div>
                          </div>
                        )})}
                    </div>
                  }
                </div>
                
                <ButtonStyled type="submit" style_type="invert" className="bt_add_beneficiary" onClick={handleAddNewBeneficiary}>
                    <p>{t(`buttons.add_new_beneficiary`)}</p>
                </ButtonStyled>

                {/*<p>{t(`alerts.not_upload_yet`, { name: "beneficiaries" })}</p>*/}
                {/*parties && (parties.length <= 0 || (distributedParties && distributedParties.length == parties.length)) &&
                  <div className="not_have_add">
                    <ButtonStyled type="submit" style_type="invert" className="bt_add_beneficiary" onClick={handleAddNewBeneficiary}>
                        <p>{t(`buttons.add_new_beneficiary`)}</p>
                    </ButtonStyled>
                  </div>
                */}
              </div>
            </div>

          </div>
          
        </ContentStyled>

      </ContainerStyled>
    </WrapperStyled>
  )
}

export default MyLastWillContainer
