/*
import { useEffect, useState } from "react"
import storage from 'local-storage-fallback';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';
import { RiDeleteBin6Line } from "react-icons/ri";
import { truncateString,
         textNumCode } from '../../helpers/functions';
import { ContentContext } from '../../context';
import { FileUploadElement } from '../Elements';
*/

import { ReactComponent as IcoFileDoc } from '../../../assets/images/ico_file_doc.svg';
import { ReactComponent as IcoFilePpt } from '../../../assets/images/ico_file_ppt.svg';
import { ReactComponent as IcoFileImg } from '../../../assets/images/ico_file_img.svg';
import { ReactComponent as IcoFileJpg } from '../../../assets/images/ico_file_jpg.svg';
import { ReactComponent as IcoFilePng } from '../../../assets/images/ico_file_png.svg';
import { ReactComponent as IcoFileXls } from '../../../assets/images/ico_file_xls.svg';
import { ReactComponent as IcoFilePdf } from '../../../assets/images/ico_file_pdf.svg';

const FileIcon = (props) => {

    const { mime } = props;

    const Icon = ({mime}) => {
        
        if(mime){

            const type = (mime.split("/"))[0];
            const ext = (mime.split("/"))[1];
            
            switch (type) {
                case "image":
                    if(ext == 'jpg' || ext == 'jpeg') {
                        return ( <IcoFileJpg className="ico"/> );
                    }
                    if(ext == 'png') {
                        return ( <IcoFilePng className="ico"/> );
                    }
                    return ( <IcoFileImg className="ico"/> );
                    break;
                case "application":
                    if(ext == 'vnd.ms-excel' || ext == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
                    //if(ext == 'xls' || ext == 'xlsx'){
                        return ( <IcoFileXls className="ico"/> );
                    }
                    if(ext == 'msword' || ext == 'vnd.openxmlformats-officedocument.wordprocessingml.document'){
                    //if(ext == 'doc' || ext == 'docx'){
                        return ( <IcoFileDoc className="ico"/> );
                    }
                    if(ext == 'pdf'){
                        return ( <IcoFilePdf className="ico"/> );
                    }
                    if(ext == 'vnd.ms-powerpoint' || ext == 'vnd.openxmlformats-officedocument.presentationml.presentation'){
                    //if(ext == 'ppt'){
                        return ( <IcoFilePpt className="ico"/> );
                    }
                    break;
                default:
                    <></>
                    break;
            }
        }
        
        return (
            <></>
        )

    }
    
    return (
        <Icon mime={mime} />
    )    
        
}

export default FileIcon;
