import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Auth from './Auth';
import { AuthContext } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from 'react-router-dom';
import { breakpoints } from './helpers/breakpoints';

import CountdownResponseStatus from './pages/CountdownResponseStatus';


const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: 100%;
    top: 0;
    left: 0;
    transform: none;
    padding: 4px!important;
  }
  .Toastify__toast {}
  .Toastify__toast-body {}
  .Toastify__progress-bar {}

  @media ${breakpoints.xs} {}
  @media ${breakpoints.sm} {}
  @media ${breakpoints.md} {
    &&&.Toastify__toast-container {
      width: 800px;
      top: 1em;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media ${breakpoints.lg} {}
  @media ${breakpoints.xl} {}
  @media ${breakpoints.xxl} {}
  @media ${breakpoints.xxxl} {}
  @media ${breakpoints.xxxxl} {}
`;

const App = () => {
  
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { isTheVaultOpen, sendCountdownResponseStatus } = useContext(AuthContext);
  const [ countdownResponseStatus, setCountdownResponseStatus ] = useState(null);
  const [ countdownResponseStatusToken, setCountdownResponseStatusToken ] = useState('');

  if(isTheVaultOpen == null){
    //return null;
  }
  
  useEffect(() => {
    if(pathname){
      if(pathname == '/countdown-response-status'){
        if(search.length > 0) {
          const query = new URLSearchParams(search);
          if(query.get("token") && query.get("token").length > 0){
            setCountdownResponseStatusToken(query.get("token"));
            setCountdownResponseStatus(true);
          }else{
              history.push('/signin');
          }
        }else{
          history.push('/signin');
        }

      }else{
        setCountdownResponseStatus(false);
      }
  }else{
      history.push('/signin');
  }
  }, [pathname]);

  if(countdownResponseStatus == null){
    return null;
  }

  return (
    <>
      {countdownResponseStatus ? 
        <CountdownResponseStatus token={countdownResponseStatusToken} />
      :
        <>
        <Auth />
        <StyledToastContainer />
        </>
      }
    </>
  );
}

export default App;
