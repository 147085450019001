import React from "react";
import PartiesContainer from "./PartiesContainer";

const Parties = (props) => {

  return (
      <PartiesContainer props={props} location={props.location} history={props.history} />
  );

};

export default Parties;
