import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import ButtonSolid from '../Elements/Buttons/Solid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLORS } from '../../helpers/constants';
import { AuthContext } from '../../context';
import { useHistory } from 'react-router';

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(208, 178, 87, 0.55);
    background-color: rgba(0, 0, 0, 0.90);
    z-index: 2;
    //border-radius: 12px;
    svg{
        color: ${COLORS.lightgold};
    }

    .inner_overlay_validate_action{
        
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 700px;
        background-color: #FFFFFF;
        border-radius: 12px;

        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);

        p{
            margin: 0;
        }
        .content_overlay{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 30px;
            .box_inner{
                display: flex;
                flex-direction: column;
                .actions{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                    .bt_signup{
                        margin-right: 10px;
                    }
                }
            }
        }
    }
 `  

const OverlayValidateAction = (props) => {

    const history = useHistory();
    const { open, setOpen, success, message } = props;

    if(!open){
        return null;
    }
    
    return (
        <Wrapper>
            <div className="inner_overlay_validate_action">
                <div className="content_overlay">
                    {success !== null ?
                        success ? 
                            <div className="box_inner">
                                <p>{message}</p>

                                <div className="actions">
                                    <ButtonSolid
                                        type="submit"
                                        style_type="tab"
                                        className="bt_signup"
                                        override_css={null}
                                        onClick={() => { history.push('/signin'); setOpen(!open); }}
                                    >
                                        <p>Sign In</p>
                                    </ButtonSolid>
                                </div>
                            </div>
                            :
                            <div className="box_inner">
                                <p>{message}</p>

                                <div className="actions">
                                    <ButtonSolid 
                                        type="submit" 
                                        style_type="default" 
                                        className="bt_close" 
                                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                        onClick={() => setOpen(!open)}
                                    >
                                        <p>Close</p>
                                    </ButtonSolid>
                                </div>
                            </div>
                    :
                    <CircularProgress size={45} />
                    }
                    
                </div>
            </div>
        </Wrapper>
    )
}

export default OverlayValidateAction;