import React from 'react';
import styled from 'styled-components';
import ThumbBox from '../../Elements/ThumbBox';
import { COLORS } from '../../../helpers/constants';
import { breakpoints } from '../../../helpers/breakpoints';
import ImageProfile from '../ImageProfile';

const Wrapper = styled.div`
    display: flex;
    //usar el modulo para eliminar el margen derecho
    //margin-right: ${props => (props.index / 0) ? '0' : '20px'};
    
    p{
        margin: 0;
        color: "#313233";
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.38px;
    }

    @media ${breakpoints.xs} {
        //min-width: 576px
        width: 100%;
    }
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {
        //min-width: 1366px
        width: 173px!important;
        //background-color: skyblue;
    }
    @media ${breakpoints.xxl} {
        //min-width: 1440px
        width: 193px!important;
        //background-color: skyblue;
    }
    @media ${breakpoints.xxxl} {
        //min-width: 1680px
        width: 253px!important;
        //background-color: pink;
    }
    @media ${breakpoints.xxxxl} {
        //min-width: 1920px
        width: 300px;
    }
`
const UserData = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    width: calc(100% - 72px);
    .name{
        font-weight: 600;
        font-size: 14px;
    }
    .title{
        font-weight: 500;
        font-size: 12px;
        color: ${COLORS.cloudyblue};
        &.title_executor{
            color: ${COLORS.lightgold}
        }
    }
`

const Item = (props) => {

    const { index, image, name, title, gender, className, onClick } = props;
    
    return (
        <Wrapper index={index} className={className} onClick={onClick}>
            <ThumbBox css={{width: '63', height: '63', border: {withBorder: false, color: "#D0B257"}}}>
                <ImageProfile profile_image={image} gender={gender} />
            </ThumbBox>
            <UserData>
                <div className="name">{name}</div>
                <div className={`title title_${title.toLowerCase()}`}>{title}</div>
            </UserData>
        </Wrapper>
    )
}

export default Item
