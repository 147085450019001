import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';



const Wrapper = styled.button`

    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${props => props.st.size.width};
    height: ${props => props.st.size.height || "40px"};
    background-color: ${props => props.st.background.normal};
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid ${props => props.st.border.normal};

    p{

        margin: 0;
        color: ${props => props.st.color.normal}!important;
        font-size: ${props => props.st.font.size};
        font-weight: ${props => props.st.font.weight};;
        letter-spacing: -0.38px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
        text-align: center;
        
    }
    
    :hover:enabled{

        background-color: ${props => props.st.background.hover};
        border: 1px solid ${props => props.st.border.hover};

        p{
            color: ${props => props.st.color.hover}!important;
        }
        :disabled{
            p{
                color: pink;
            }
        }
    }

    

    .icon{
        width: ${props => props.st.icon.width};
    }
    
    :disabled{
        opacity: .1;
        cursor: default;
        //background-color: ${COLORS.lightgrey};
        //border-color: ${COLORS.lightgrey};
        p{
            //color: ${COLORS.bluegrey}!important;
        }
    }


`

const Solid = (props) => {

    const { children, override_css, style_type, className, onClick, type, disabled } = props;
    // Los estilos se puede sobreescribir usando el objeto override_css
    const [ styles, setStyle ] = useState(null);
    
    useEffect(() => {
        console.log(style_type);
        switch (style_type) {
            case 'default':
                setStyle({

                    color: { normal: override_css?.color?.normal ? override_css.color.normal : "#FFFFFF",
                             hover: override_css?.color?.hover ? override_css.color.hover : "#000000" },

                    background: { normal: override_css?.background?.normal ? override_css.background.normal : "#000000",
                                  hover: override_css?.background?.hover ? override_css.background.hover : "#FFFFFF" },

                    border: { normal: override_css?.border?.normal ? override_css.border.normal : "#000000",
                              hover: override_css?.border?.hover ? override_css.border.hover : "#000000" },

                    font: { size: override_css?.font?.size ? override_css.font.size : "16px",
                            weight: override_css?.font?.weight ? override_css.font.weight : "600" },

                    size: { width: override_css?.size?.width ? override_css.size.width : "142px",
                            height: override_css?.size?.height ? override_css.size.height : "40px" },

                    icon: { width: override_css?.icon?.width ? override_css.icon.width : "15px",
                            height: override_css?.icon?.height ? override_css.icon.height : "15px" }

                });
                break;
            case 'error':
                setStyle({

                    color: { normal: "#FFFFFF",
                             hover: COLORS.darkgrey },

                    background: { normal: COLORS.red ,
                                  hover: "#FFFFFF" },

                    border: { normal: "#000000",
                              hover: "#000000" },

                    font: { size: "16px",
                            weight: "600" },

                    size: { width: "142px",
                            height: "40px" },

                    icon: { width: "15px",
                            height: "15px" }

                });
                break;
            case 'tab':
                setStyle({

                    color: { normal: override_css?.color?.normal ? override_css.color.normal : "#FFFFFF",
                             hover: override_css?.color?.hover ? override_css.color.hover : COLORS.lightgold },

                    background: { normal: override_css?.background?.normal ? override_css.background.normal : COLORS.lightgold,
                                  hover: override_css?.background?.hover ? override_css.background.hover : "#FFFFFF" },

                    border: { normal: override_css?.border?.normal ? override_css.border.normal : "#D0B257",
                              hover: override_css?.border?.hover ? override_css.border.hover : COLORS.lightgold },

                    font: { size: override_css?.font?.size ? override_css.font.size : "16px",
                            weight: override_css?.font?.weight ? override_css.font.weight : "600" },

                    size: { width: override_css?.size?.width ? override_css.size.width : "142px",
                            height: override_css?.size?.height ? override_css.size.height : "40px" },

                    icon: { width: override_css?.icon?.width ? override_css.icon.width : "15px",
                            height: override_css?.icon?.height ? override_css.icon.height : "15px" }


                });
                break;
            case 'inbox':

                setStyle({

                    color: { normal: override_css?.color?.normal ? override_css.color.normal : "#000000",
                             hover: override_css?.color?.hover ? override_css.color.hover : "#FFFFFF" },

                    background: { normal: override_css?.background?.normal ? override_css.background.normal : "#FFFFFF",
                                  hover: override_css?.background?.hover ? override_css.background.hover : "#000000" },

                    border: { normal: override_css?.border?.normal ? override_css.border.normal : "#ECEDEF",
                              hover: override_css?.border?.hover ? override_css.border.hover : "#000000" },

                    font: { size: override_css?.font?.size ? override_css.font.size : "10px",
                            weight: override_css?.font?.weight ? override_css.font.weight : "600" },

                    size: { width: override_css?.size?.width ? override_css.size.width : "142px",
                            height: override_css?.size?.height ? override_css.size.height : "26px" },

                    icon: { width: override_css?.icon?.width ? override_css.icon.width : "15px",
                            height: override_css?.icon?.height ? override_css.icon.height : "15px" }

                });
                break;
            case 'invert':

                setStyle({

                    color: { normal: override_css?.color?.normal ? override_css.color.normal : "#FFFFFF",
                             hover: override_css?.color?.hover ? override_css.color.hover : "#000000" },

                    background: { normal: override_css?.background?.normal ? override_css.background.normal : "#FFFFFF",
                                  hover: override_css?.background?.hover ? override_css.background.hover : "#000000" },

                    border: { normal: override_css?.border?.normal ? override_css.border.normal : "#000000",
                              hover: override_css?.border?.hover ? override_css.border.hover : "#000000" },

                    font: { size: override_css?.font?.size ? override_css.font.size : "16px",
                            weight: override_css?.font?.weight ? override_css.font.weight : "600" },

                    size: { width: override_css?.size?.width ? override_css.size.width : "142px",
                            height: override_css?.size?.height ? override_css.size.height : "40px" },
                    
                    icon: { width: override_css?.icon?.width ? override_css.icon.width : "15px",
                            height: override_css?.icon?.height ? override_css.icon.height : "15px" }

                });
                break;
        
            default:
                break;
        }

        return () => { 
            setStyle(null)
        }
        
    }, []);

    return (
        <>
        {styles &&
        <Wrapper override={override_css} st={styles} className={className} onClick={onClick} type={type} disabled={disabled}>
            { children }
        </Wrapper>
        }
        </>
    )
}

export default Solid
