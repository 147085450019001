import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
//import { ButtonSidebar } from '../Elements';
import ButtonSidebar from '../Elements/Buttons/Sidebar';
import ThumbCircle from '../Elements/ThumbCircle';

import Top from './Top';
import Bottom from './Bottom';
import { breakpoints } from '../../helpers/breakpoints';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ReactComponent as LogoInheritSVG } from '../../assets/images/logo_inherit.svg';

import { PAGES, COLORS } from '../../helpers/constants';
import { defineButtonSelected } from '../../helpers/functions';
import ModalPlans from '../../components/Elements/Modals/ModalPlans';

const MenuElement = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;

    .icon{
        width: 28px;
        height: 28px;
        svg{
            //display: flex;
            float: left;
            width: 100%;
            //fill: #000000!important;
        }
    }
    .OuterItemBullet{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        right: -30px;
        overflow: hidden;
        height: 60px;
        width: 10px;
    }
    .ItemBullet{
        display: flex;
        position: absolute;
        right: 6px;
        background-color: #FFFFFF;
        height: 60px;
        width: 60px;
        border-radius: 15px;
        transform: rotate(45deg);
    }
`

const DrawerStyled = styled(Drawer)`
    .inner_drawer{
        width: 300px!important;
        .logo{
            display: flex;
            margin-left: 15px;
            margin-top: 10px;
            .logo_inherit{
                width: 180px;
                height: 64px;
            }
        }
        .inner_drawer_menu{
            margin-top: 15px;
        }
        .drawer_user_data{
            margin-top: 15px;
            margin-left: 20px;
            .user_data{
                margin-top: 10px;
                p.name,
                p.title{
                    fonts-size: 16px;
                    margin: 0;
                    font-weight: 600;
                }
                p.title{
                    font-size: 14px;
                    color: ${COLORS.lightgold};
                }
            }
            .thumb{
                width: 60px;
                height: 60px;
            }
        }
        .horizontal_line{
            width: calc(100% - 20px);
            margin-left: 10px;
            margin-top: 20px;
            margin-bottom: 30px;
            height: 2px;
            background-color: ${COLORS.lightgrey};
        }
        .inner_drawer_menu_settings{
            margin-top: 15px;
            ul{
                padding: 0;
                list-style: none;
                li{
                    font-weight: 600;
                    font-size: 16px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    p{
                        margin: 0;
                        padding-left: 20px;
                    }
                    &.sign_out{
                        color: ${COLORS.red};
                    }
                }
            }
        }
    }

    @media ${breakpoints.xs} {
    
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }

`

const SidebarMobile = (props) => {
    
    const { t } = useTranslation();
    const history = useHistory();
    const { activeButton, 
            userData,
            handleMenuHeader,
            logout } = props;

    const { openDrawer, setOpenDrawer } = props;

    const [anchor, setAnchor] = useState(true);
    const [ openModalPlans, setOpenModalPlans ] = useState(false);

    const goToSection = (section) => {
        //history.push(`/${section}`);
        

        switch (section) {
            case "upgrade":
                setOpenModalPlans(!openModalPlans)
                break;
            default:
                history.push(`/${section}`);
                break;
        }
        setOpenDrawer(false);
    }

    const handleMenuHeader_ = (section) => {
        if(section !== "logout"){
            handleMenuHeader(section);
        }else{
            logout();
        }
        setOpenDrawer(false);

    }

    return (
        <Fragment>
            {openModalPlans &&
                <ModalPlans open={openModalPlans} setOpen={setOpenModalPlans} />
            }
            <DrawerStyled
                anchor={"left"}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
            <div className="inner_drawer">
                {/*
                <div className="logo">
                    <LogoInheritSVG className="logo_inherit" />
                </div>
                */}
                <div className="drawer_user_data">
                    <ThumbCircle 
                        className="thumb"
                        onClick={() => alert("nada")}
                        css={{width: '12', height: '12', border: {withBorder: false, color: "#FFFFFF"}}}
                        
                    >
                        {userData.profile_image ?
                        <img src={userData.profile_image} />
                        :
                        <div className="text_avatar">
                            <p>{userData.first_name.slice(0, 1) +''+ userData.last_name.slice(0, 1)}</p>
                        </div>
                        }
                    </ThumbCircle>

                    <div className="user_data">
                        <p className="name">{ userData && userData.first_name + ' ' + userData.last_name }</p>
                        <p className="title">Vault Owner</p>
                    </div>

                </div>
                    


                <div className="inner_drawer_menu">
                {
                    PAGES.map((item, index) => {
                        return (
                            item.show &&
                            <MenuElement>
                                <ButtonSidebar style_type="default" className={`${item.routeName == activeButton ? "selected" : ""}`} onClick={() => goToSection(item.routeName)}>
                                    <div className="icon">
                                        {item.icon}
                                    </div>
                                    <p>{t(`menu.${item.routeName}`)}</p>
                                </ButtonSidebar>
                            </MenuElement>
                        )
                    })
                }
                </div>

                <div className="horizontal_line"></div>

                <div className="inner_drawer_menu_settings">
                    <ul> 
                        <li onClick={() => handleMenuHeader_("settings/profile") }><p>{t(`menu.profile`)}</p></li>
                        {/*<li onClick={() => handleMenuHeader_("settings/notifications") }><p>{t(`menu.notifications`)}</p></li>*/}
                        <li onClick={() => handleMenuHeader_("settings/countdown") }><p>{t(`menu.countdown`)}</p></li>
                        <li onClick={() => handleMenuHeader_("settings/subscription") }><p>{t(`menu.subscription`)}</p></li>
                        <li onClick={() => handleMenuHeader_("settings/security") }><p>{t(`menu.security`)}</p></li>
                        <li className="sign_out" onClick={() => handleMenuHeader_("logout") }><p>{t(`menu.signout`)}</p></li>
                    </ul>
                </div>

            </div>
          </DrawerStyled>
        </Fragment>
    )
}

export default React.memo(SidebarMobile);
