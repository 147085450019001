import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { COLORS,
         SIZES } from '../../../helpers/constants';
import Input from '../../Elements/Form/Input';
import ButtonSolid from '../../Elements/Buttons/Solid';
import Switch from '../../Elements/Form/Switch';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    >.inner{
        display: flex;
        flex-direction: column;
        width: 100%;
        .description{
            width: 640px;
        }
    }
    .actions{
        margin-top: 20px;
    }
    .head{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        //margin-bottom: 20px;
        >.title{
            font-weight: 600;
            font-size: 18px;
            margin-right: 10px;
        }
    }
`
const Fields = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    
`
const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .aclaration{
        font-size: 12px;
        margin-top: 3px;
        color: ${COLORS.bluegrey};
    }
    &.age_gender{
        .age{
            width: 100px!important;
            margin-right: 15px;
        }
    }
    &.field_title{
        margin-top: 15px;
        margin-bottom: 0!important;
    }
    &.notify_beneficiary{
        margin-bottom: 0!important;
    }

    .current_password{
        margin-bottom: 10px;
    }
    .new_password{
        margin-bottom: 10px;
    }
    .confirm_new_password{
    }
`

const TwoFa = (props) => {

    const { twoFactorActive, setTwoFactorActive } = props;
    const [ formData, setFormData ] = useState({});
    //const [ twoFactorActive, setTwoFactorActive ] = useState("google");
    
    let inputRef = useRef({});

    useEffect(() => {
          
        setFormData({
            current_password: { value: "", required: true, disabled: false, ref: "current_password", error: false },
        });

        return () => {
            toast.dismiss();
        }
        
    }, [])

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
        
    }

    const handleActiveTwoFactor = () => {

        const twoFactor = twoFactorActive == "google" ? "" : "google";
        setTwoFactorActive(twoFactor);

    }

    return (
        <Wrapper>

            <div className="head">
                <div className="title">Google Authentication App</div>
                <div className="field field_switch">
                    <Switch
                        toggled={twoFactorActive == "google" ? true : false}
                        color="default"
                        style_type="default"
                        inputRef={inputRef}
                        label=""
                        elName={"field_switch"}
                        className={`field_switch`}
                        onChange={handleActiveTwoFactor}
                    />
                </div>
            </div>
            
            {twoFactorActive == "google" ?
            <div className="inner">
                <div className="description">
                    Before you begin, please make sure you have downloaded the <span className="highlight">Google Authenticator App</span>
                </div>
                <Fields>
                    <Field className="field">
                        <Input
                            className={`current_password ${formData?.current_password?.error ? "error" : ""}`}
                            isRequired={"true"}
                            style_type="default"
                            type="password"
                            label="Current Password"
                            inputRef={inputRef}
                            elName={formData?.current_password?.ref}
                            value={formData?.current_password?.value}
                            onChange={handleFormElementChange}
                        />
                    </Field>
                </Fields>
                <div className="actions">
                    <ButtonSolid
                        type="submit"
                        style_type="default"
                        className="bt_save"
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                        onClick={() =>
                            alert("save")
                        }
                    >
                        <p>Get Started</p>
                    </ButtonSolid>
                </div>
            </div>
            : <></>    
            }
        </Wrapper>
    )
}

export default TwoFa;
