import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import ModalDisclaimer from './Elements/Modals/ModalDisclaimer';
import ModalTerms from './Elements/Modals/ModalTerms';
import ModalPrivacyPolicy from './Elements/Modals/ModalPrivacyPolicy';
import { breakpoints } from '../helpers/breakpoints';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    margin-top: 25px;
    color: #2B2C2D;
    .link{
        margin-bottom: 7px;
        cursor: pointer;
        :hover{
            color: #D0B257;
        }
        :last-child{
            margin-bottom: 0;
        }
    }
    .divide{
        display: none;
    }

    @media ${breakpoints.xs} {
        flex-direction: row;
        justify-content: space-between;
        align-items: unset;
        width: 450px;
        margin-bottom: 25px;
        margin-top: 25px;
        .link{
            :hover{
                color: #D0B257;
            }
        }
        .divide{
            display: flex;
        }
    }
    @media ${breakpoints.sm} {
    }
    @media ${breakpoints.md} {
    }
    @media ${breakpoints.lg} {
    }
    @media ${breakpoints.xl} {
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`

const BottomLinks = (props) => {
    
    const { t } = useTranslation();
     
    const history = useHistory();
    const location = useLocation();

    const [ openModalPrivacyPolicy, setOpenModalPrivacyPolicy ] = useState(false);
    const [ openModalDisclaimer, setOpenModalDisclaimer ] = useState(false);
    const [ openModalTerms, setOpenModalTerms ] = useState(false);

    const handleModalLegal = (section) => {
        setOpenModalTerms(false);
        setOpenModalDisclaimer(false);
        setOpenModalPrivacyPolicy(false);
        switch (section) {
            case "disclaimer":
                setOpenModalDisclaimer(true);
                break;
            case "privacy-policy":
                setOpenModalPrivacyPolicy(true);
                break;
            case "terms-and-conditions":
                setOpenModalTerms(true);
                break;
            default:
                
                break;
        }
        
    }
    
    return (
        <Wrapper className="bottom_links">

            <ModalDisclaimer 
                open={openModalDisclaimer} 
                setOpen={setOpenModalDisclaimer}
            />
            <ModalTerms 
                open={openModalTerms} 
                setOpen={setOpenModalTerms}
            />
            <ModalPrivacyPolicy 
                open={openModalPrivacyPolicy} 
                setOpen={setOpenModalPrivacyPolicy}
            />

            <div className="link" onClick={() => handleModalLegal("disclaimer")}>{ t(`footer.disclaimer`) }</div>
            <span className="divide">|</span>
            <div className="link" onClick={() => handleModalLegal("privacy-policy")}>{ t(`footer.privacy_policy`) }</div>
            <span className="divide">|</span>
            <div className="link" onClick={() => handleModalLegal("terms-and-conditions")}>{ t(`footer.terms_and_condition`) }</div>
        </Wrapper>
    )
}

export default BottomLinks;
