import React, { useEffect, useState, useCallback, useContext, useRef, Fragment } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../helpers/breakpoints';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import { ContentContext,
         AuthContext } from '../../../context';
import { useTranslation } from 'react-i18next';
import { Modal, Fade, Backdrop } from '@material-ui/core';
import { MultipleFileUploadField } from '../../../components/Uploads/MultipleFileUploadField';
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosCloseCircleOutline } from "react-icons/io";

import OverlayExtraDocuments from '../../../components/Elements/OverlayExtraDocuments';
import BoxContainer from '../../../components/Elements/BoxContainer';
import ButtonSolid from '../../../components/Elements/Buttons/Solid';
import ButtonIcon from '../../../components/Elements/Buttons/Icon';
import Image from '../../../components/Elements/Image';
import NoImage from '../../../components/Elements/NoImage';
import UploadImages from '../../../components/Uploads/Images';
import FileUploadElement from '../../../components/Elements/Upload/FileUploadElement';
import CustomToastMessageError from '../../../components/Elements/CustomToastMessageError';
import { RiCloseCircleLine } from "react-icons/ri";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { FaCamera } from "react-icons/fa";

import { IcoCameraSolid, 
         IcoBack,
         COLORS } from '../../../helpers/constants';

import CircularProgress from '@material-ui/core/CircularProgress';
import Form from '../../../components/Assets/Form';
import { path } from 'ramda';



const Wrapper = styled.div`

    display: flex;
    //flex: 1;
    //padding-left: 30px;
    //padding-right: 30px;
    padding-top: 17px;
    padding-bottom: 17px;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    
    //background-color: pink;
    /*
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    //margin-right: 20px;
    //width: calc(100% - 350px);

    .box_inner{
        
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        box-sizing: border-box;
        padding: 20px;
        position: relative;
        .form{
            display: flex;
            width: 100%!important;
            flex-direction: column;
            margin-top: 40px;
            .action_save{
                align-self: flex-end;
                margin-top: 20px;
            }
            .files{
                font-size: 15px;
                font-weight: 600;
                color: ${COLORS.bluegrey};
                cursor: pointer;
                .number{
                    color: ${COLORS.darkgold};
                }
                :hover{
                    color: ${COLORS.darkgold};
                }
            }
        }
    }
    
    .box_assets{
        margin-bottom: 20px;
    }
    .box_header_actions{
        max-height: 60px;
        box-shadow: 0!important;
        .inner{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            p.title{
                font-size: 18px;
                font-weight: 600;
                margin: 0 0 0 10px;
                padding: 0;
            }
            .bt_save{
                margin-left: auto;
            }
            .action_back{
                display: flex;
                flex-direction: row;
                align-items: center;
                .icon{
                    width: auto;
                    height: auto;
                    font-size: 37px;
                }
            }
            .action_save{
                display: flex;
                flex-direction: row;
                align-items: center;
                .loader{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 150px;
                    min-height: 40px;
                    svg{
                        color: ${COLORS.lightgold};
                    }
                }
            }
        }
    }
    .box_recent{
        margin-top: 20px;
    }

    @media ${breakpoints.xs} {
        //min-width: 576px
    }
    @media ${breakpoints.sm} {
        //min-width: 768px
        
    }
    @media ${breakpoints.md} {
        .box_inner{
            flex-direction: row;
            .form{
                width: calc(100% - 250px)!important;
                margin-top: 0;
            }
        }
    }
    @media ${breakpoints.lg} {
        //min-width: 1200px
        //width: 870px;
    }
    @media ${breakpoints.xl} {
        //min-width: 1366px
        //width: 1036px;
    }
    @media ${breakpoints.xxl} {
        //min-width: 1440px
        //width: 1110px;
    }
    @media ${breakpoints.xxxl} {
        //min-width: 1680px
        //width: 1350px;
        //background-color: pink;
    }
    @media ${breakpoints.xxxxl} {
        //min-width: 1920px
        //width: 1590px;
        //background-color: skyblue;
    }
`
const Files = styled.div`
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: min-content; 
    gap: 10px 10px; 
    margin-bottom: 20px;
`



const ModalStyled = styled(Modal)`

    /*
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0!important;
    border-radius: 16px;

    :focus{
        outline: none!important;
    }

    position: absolute;
    top: 20px!important;
    overflow: scroll;
    margin: 0 auto;
    width: 700px;
    height: 100%;
    display: block;
    */

    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0!important;
    border-radius: 0;

    :focus{
        outline: none!important;
    }

    position: absolute;
    top: 0!important;
    overflow: scroll;
    margin: 0 auto;

    //width: calc(100% - 30px);
    width: 100%;
    height: 100%;
    display: block;

    @media ${breakpoints.xs} {}
    @media ${breakpoints.sm} {
        //border-radius: 16px;
        //top: 20px!important;
        width: 700px;
    }
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`
const BoxImage = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 44vw;
    height: 44vw;

    .image_inner{
        width: 44vw;
        height: 44vw;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        border-radius: 10px;
        .image{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            overflow: hidden;

            background-color: ${COLORS.whiteTwo};
            border-radius: 10px;
            border: 3px solid ${COLORS.lightgold};

            img{
                float: left;
                height: 100%;
            }
        }
        .bt_add_image{
            position: absolute;
            bottom: -20px;
        }
        .bt_delete_image{
            position: absolute;
            bottom: -20px;
            .icon{
                width: unset;
                height: unset;
                font-size: 20px;
            }
        }
    }
    @media ${breakpoints.xs} {

        width: 220px;
        height: 220px;

        .image_inner{
            width: 220px;
            height: 220px;
        }

    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        align-self: flex-start;
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const ButtonDelete = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: ${COLORS.red};
    cursor: pointer;

    position: absolute;
    bottom: -20px;

    svg{
        width: 22px;
        height: 22px;
        fill: #FFFFFF;
    }
    :hover{
        background-color: ${COLORS.darkgrey};
    }
`
const ButtonAddImage = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: ${COLORS.lightgold};
    cursor: pointer;

    position: absolute;
    bottom: -20px;
    font-size: 20px;
    svg{
        //width: 19px;
        //height: 19px;
        fill: #FFFFFF;
    }
    :hover{
        background-color: ${COLORS.darkgrey};
    }
`
const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 25px;
    cursor: pointer;
    right: 20px;
    top: 20px;
    z-index: 9;
    .icon{
        display: flex;
        font-size: 50px;
        svg{
            fill: ${COLORS.lightgold};
        }
    }
    :hover{
        .icon{
            svg{
                fill: #000000;
            }
        }
    }
`
const OverlayWait  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    border-radius: 12px;
    svg{
        color: ${COLORS.lightgold};
    }
 `   




const AddContainer = (props) => {

    const { t } = useTranslation();

    let { id:asset_id } = useParams();

    const history = useHistory();

    const { isTheVaultOpen,
            setOverlayVaultOpen } = useContext(AuthContext);

    const { uploadFile, 
            editAsset, 
            deleteFile, 
            assets, 
            setAssets, 
            deleteImageAsset } = useContext(ContentContext);

    const [ openModal, setOpenModal ] = useState(false);
    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(null);
    const [ croppedImage, setCroppedImage ] = useState(null);
    const [ originalImage, setOriginalImage ] = useState(null);
    const [ isWaiting, setIsWaiting ] = useState(false);
    const [ isImageLoading, setImageLoadingg ] = useState(false);
    const [ imageDeleted, setImageDeleted ] = useState(false);
    const [ asset, setAsset ] = useState(false);
    const [ files, setFiles ] = useState([]);
    const [ selectedAsset, setSelectedAsset ] = useState(null);
    const [ showOverlayExtraDocuments, setShowOverlayExtraDocuments ] = useState(false);

    let inputRef = useRef({});

    const handleBack = () => {
        history.push('/my-assets');
    }

    useEffect(() => {

        setIsWaiting(true);
        
        if(assets){
            //alert(assetSelected);
            const assetSelected = assets.filter(a => a.id == asset_id);

            if(assetSelected.length > 0){
                
                setFiles(assetSelected[0].files);

                setFormData({
                    id: { value: assetSelected[0].id, required: false, disabled: false, ref: "id", error: false },
                    name: { value: assetSelected[0].name, required: true, disabled: false, ref: "name", error: false },
                    asset_category_id: { value: assetSelected[0].category.id.toString(), required: true, disabled: false, ref: "asset_category_id", error: false },
                    description: { value: assetSelected[0].description, required: true, disabled: false, ref: "description", error: false },
                    image: { value: assetSelected[0].image_file, required: false, disabled: false, ref: "image", error: false },
                    thumb: { value: assetSelected[0].thumb_file, required: false, disabled: false, ref: "thumb", error: false },
                });

                setAsset(assetSelected[0]);
                setFiles(assetSelected[0].files);
                setIsWaiting(false);
            }
        }

    }, [assets])

    useEffect(() => {
        return () => {
            toast.dismiss();
        }
    }, [])
    
    const handleSubmit = (e) => {
        
        e.preventDefault();

        setIsWaiting(true);
        setLoading(true);

        let haveError = false;

        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {

                if(formData[key].required){
                    if(!formData[key].value.length){
                        haveError = true;
                        updateFormData[key].error = true;
                    }else{
                        updateFormData[key].error = false;
                    }
                }
                
            })
            
            if(!haveError){
                if(croppedImage){
                    const payload = { base64: true, image: croppedImage, original_image: originalImage, type: "image_asset" };
                    const fileUploades = uploadFile(payload);
                    fileUploades.then((result) => {

                        const fileName = path(['data', 'file_name_original'], result) || null;
                        const fileNameThumb = path(['data', 'file_name_thumb'], result) || null;
                        updateFormData['image'].value = fileName;
                        updateFormData['thumb'].value = fileNameThumb;

                        handleSendAsset(updateFormData);

                    })
                }else{

                    handleSendAsset(updateFormData);
                    
                }
                
            }else{
                
                setIsWaiting(false);
                setLoading(false);

            }

            setFormData(updateFormData);
        }

        
        
    }

    const handleSendAsset = () => {

        const response = editAsset(formData);
        response.then(res => {
            
            let message = '';
            let responseType = '';
            let errors = [];

            if(res.success === true){

                message = 'The Asset has been edited';
                responseType = 'success';
                
            }else{

                responseType = 'error';
                errors.push('An error has occured. Please try again later');

            }

            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setIsWaiting(false);
            setLoading(false);
        })

    }

    const handleUploadImage = () => {

        setOpenModal(true);

    }
    const handleDeleteImage = () => {

        setImageDeleted(true);
        setCroppedImage(null);
        setOriginalImage(null);

        
        const payload = { asset_id: asset.id, image_folder: 'image_asset', image: formData.image.value, thumb: formData.thumb.value };
        //console.log(payload);
        //Eliminar imagen fisicamente
        const response = deleteImageAsset(payload);
        response.then(res => {
            console.log("----> deleteImageAsset");
            const assetsEdit = [...assets];
			assetsEdit.map((asset, index) => {
				if(asset.id == payload.asset_id){
				   asset.image = false;  
				   asset.image_file = '';
				   asset.thumb = false;
				   asset.thumb_file = '';
				}
				return asset;
			})

            let updateFormData = {...formData};
            updateFormData['image'].value = '';
            updateFormData['thumb'].value = '';
            setFormData(updateFormData);
            
			setAssets(assetsEdit);
        })
        
    }

    
    const handleFilesAttachedClick = useCallback((event, asset) => {
        setSelectedAsset(asset);
        setShowOverlayExtraDocuments(true);
      }, []);
    
    
    return (
        <Wrapper>
            
            {showOverlayExtraDocuments &&
                <OverlayExtraDocuments assetId={selectedAsset.id} setShowOverlayExtraDocuments={setShowOverlayExtraDocuments} type="edit" asset={selectedAsset} />
            }

            <ModalStyled
                disableAutoFocus={true}
                open={openModal}
                onClose={() => setOpenModal(!openModal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className="inner_modal">
                        <ButtonClose
                            onClick={() => setOpenModal(!openModal)}
                        >
                            <div className="icon">
                                <RiCloseCircleLine size={45} />
                            </div>
                        </ButtonClose>

                        <UploadImages
                            croppedImage={croppedImage}
                            setCroppedImage={setCroppedImage}
                            setOriginalImage={setOriginalImage}
                            handleClose={() => setOpenModal(!openModal)}
                        />
                    </div>
                </Fade>

            </ModalStyled>
            
            <Container>
                <BoxContainer className="box_header_actions">
                    <div className="inner">
                        <div className="action_back">
                            <ButtonIcon style_type="default" onClick={handleBack}>
                                <div className="icon">
                                    <IoArrowBackCircleOutline />
                                </div>
                            </ButtonIcon>
                            <p className="title">Edit Asset</p>
                        </div>
                    </div>
                </BoxContainer>
                <BoxContainer className="box_inner">
                    
                    {isWaiting &&
                        <OverlayWait>
                            <CircularProgress size={45} />
                        </OverlayWait>
                    }

                    <BoxImage>
                        <div className="image_inner">
                            <div className="image">
                                {croppedImage ?
                                    <img src={croppedImage} />
                                :
                                    <>
                                    {formData.thumb?.value && 
                                        <Image filename={formData.thumb.value} type={"image_asset"} />
                                    }
                                    </>
                                }
                                
                                {(croppedImage || formData.thumb?.value) ?
                                    <ButtonDelete onClick={handleDeleteImage}>
                                        <RiDeleteBin6Line />
                                    </ButtonDelete>
                                :
                                    <ButtonAddImage onClick={handleUploadImage}>
                                        <FaCamera />
                                    </ButtonAddImage>
                                }
                            </div>
                        </div>
                    </BoxImage>
                    <div className="form">
                        <Form formData={formData} setFormData={setFormData} inputRef={inputRef} />
                        
                        <div className="files" onClick={(event) => handleFilesAttachedClick(event, asset)}>
                            {t(`labels.files_attached`)}: <span className="number">{asset && asset.files.length}</span>
                        </div>

                        <div className="action_save">
                            {loading ==! null && loading == true ? /**/
                                <div className="loader">
                                    <CircularProgress size={33} />
                                </div>
                            :
                                <ButtonSolid
                                    type="submit"
                                    style_type="default"
                                    className="bt_save"
                                    override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                    onClick={(e) => { !isTheVaultOpen ? handleSubmit(e) : setOverlayVaultOpen(true) }}
                                >
                                    <p>Save</p>
                                </ButtonSolid>
                            }
                        </div>

                    </div>
                </BoxContainer>
                
            </Container>

        </Wrapper>
    )
}

export default AddContainer
