import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../helpers/constants';
import { RiDeleteBin6Line } from "react-icons/ri";
import { truncateString } from '../../../helpers/functions';
import FileIcon from './FileIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ContentContext } from '../../../context';

import { styled as muiStyled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import axios from "axios";

const BootstrapTooltip = muiStyled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} enterTouchDelay={0}/>
    ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: COLORS.bluegrey,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: COLORS.bluegrey,
    }})
);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    border: 1px solid ${COLORS.lightgold};
    justify-content: space-between;
    //background-color: ${COLORS.whiteTwo};
    //border-radius: 5px;

    border-radius: 5px;
    //width: 250px;
    width: 100%;
    //min-width: calc(25% - 8px);
    //padding: 7px 7px 7px 7px;
    //margin-right: 10px;
    //margin-bottom: 10px;
    box-sizing: border-box;
    /*
    :last-child{
        margin-right: auto;
        margin-left: 10px;
    }
    */
    .inner_file_upload_element{
        display: flex;
        flex-direction: row;
        padding: 7px 7px 7px 7px;
    }
    .actions_file_upload_element{
        display: flex;
        flex-direction: row;
        width: 100%;
        .bt_delete,
        .bt_download{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 25px;
            width: 50%;
            background-color: ${COLORS.red};
            //border: 1px solid ${COLORS.red};
            cursor: pointer;
            p{
                font-size: 12px;
                font-weight: 700;
                margin: 0;
                padding: 0;
                color: #FFFFFF;
            }
            :hover{
                background-color: ${COLORS.whiteTwo};
                p{
                    color: ${COLORS.red};
                }
            }
        }
        .bt_download{
            background-color: ${COLORS.lightgold};
            border-top: 1px solid ${COLORS.lightgold};
            border-right: 1px solid ${COLORS.lightgold};

            border-bottom-left-radius: 5px;

            :hover{
                background-color: ${COLORS.whiteTwo};
                p{
                    color: ${COLORS.lightgold};
                }
            }
        }
        .bt_delete{
            border-top: 1px solid ${COLORS.red};
            border-left: 1px solid ${COLORS.red};
            border-bottom-right-radius: 5px;
            :hover{
                border-top: 1px solid ${COLORS.lightgold};
                border-left: 1px solid ${COLORS.lightgold};
            }
        }
        .loader_delete,
        .loader_download{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 25px;
            background-color: ${COLORS.whiteTwo};
            border-top: 1px solid ${COLORS.lightgold};
            border-left: 1px solid ${COLORS.lightgold};
            .MuiCircularProgress-root{
                height: 15px!important;
                width: 15px!important;
                svg{
                    color: ${COLORS.red};
                }
            }
        }
        .loader_download{
            .MuiCircularProgress-root{
                svg{
                    color: ${COLORS.lightgold};
                }
            }
        }
    }
    .left{
        //background-color: skyblue;
        width: 30px;
        margin-right: 7px;
        .file_type{
            width: 30px;
            height: 33px;
            //background-color: ${COLORS.whiteTwo};
            //border: 1px solid ${COLORS.whiteFour};
            //border-radius: 5px;
            overflow: hidden;
            .ico{
                width: 30px;
                fill: ${COLORS.lightgold}!important;
            }
        }
    }
    .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //background-color: pink;
        width: calc(100% - 44px);
        
        .bt_delete_file{
            position: absolute;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            background-color: ${COLORS.red};
            border-radius: 3px;
            right: 1px;
            top: 1px;
            cursor: pointer;
            svg{
                width: 15px;
                height: 15px;
                fill: #FFFFFF;
            }
            :hover{
                background-color: ${COLORS.darkgrey};
            }
        }
        .file_name{
            font-size: 13px;
            line-height: 17px;
            font-weight: 600;
            color: ${COLORS.darkgrey};
            cursor: pointer;
        }
        .progress{
            height: 5px;
            background-color: #E2E2E2;
            .progress_bar{
                height: 5px;
                background-color: #7884A5;
            }
        }
    }
`

const FileUploadElement = (props) => {

    const { t } = useTranslation();

    const { file,
            files,
            setFiles,
            assetId } = props
    
    const { deleteFile,
            downloadAssetExtraFile } = useContext(ContentContext);

    const [filename, setFilename] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {

        let file_name = file.originalname ? file.originalname : file.name;
        setFilename(file_name);
        setIsLoading(false);

    }, [file])


    const onDeleteFile = (file) => {
        setIsLoading(true);
        const payload = {asset_file_id: file.id,
                         asset_id: assetId,
                         image_folder: 'assets'}
                         console.log(payload);
        const result = deleteFile(payload, file, 'assets');
        result.then((res) => {

            const response = files.filter(f => f.id !== file.id);
            setIsLoading(false);
            setFiles(response);

        })
        
    }

    const handleDownloadFile = (file) => {

        setIsDownloading(true);
        const result = downloadAssetExtraFile(file);
        result.then((response) => {
			
			const headerval = response.headers['content-disposition'];
			//return null;
            const href = URL.createObjectURL(response.data);
			
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', file.originalname); //or any other extension
            document.body.appendChild(link);
            link.click();
			
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            setIsDownloading(false);

        });
        
    }

    return(
        <>
        <Wrapper key={file.id}>
            <div class="inner_file_upload_element">
                <div className="left">
                    <div className="file_type">
                        <FileIcon className="ico" mime={file.mime}/>
                    </div>
                </div>
                <div className="right">
                    
                    <BootstrapTooltip title={filename} placement="top">
                    <div className="file_name">
                        { 
                            filename && filename.length > 14 ? truncateString(filename, 25) : filename 
                        }
                        
                    </div>
                    </BootstrapTooltip>
                </div>
            </div>
                
            <div className="actions_file_upload_element">
                { isDownloading ?
                    <div className="loader_download">
                        <CircularProgress />
                    </div>
                :
                <div className="bt_download" onClick={() => handleDownloadFile(file)}>
                    <p>{t(`buttons.download`)}</p>
                </div>
                }

                { isLoading ?
                    <div className="loader_delete">
                        <CircularProgress />
                    </div>
                :
                    <div className="bt_delete" onClick={() => onDeleteFile(file)}>
                        <p>{t(`buttons.delete`)}</p>
                    </div>
                }
                    
                
            </div>
        </Wrapper>
        
        </>
    )
}

export default FileUploadElement;