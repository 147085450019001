import axios from 'axios';
import storage from 'local-storage-fallback';

window.ARSMS = {
  showLoader: 0,
};

let { showLoader } = window.ARSMS;

const shouldShowLoader = count => {
  try {
    if (count > 0) {
      document.getElementById('progress').style['z-index'] = 99999;
    } else {
      document.getElementById('progress').style['z-index'] = -1;
    }
  } catch (e) { }
};

const sendMessage = msg => {
  document.getElementById('messenger').style.bottom = 0;
  document.getElementById('messenger-inner').innerHTML = msg;

  setTimeout(() => {
    document.getElementById('messenger').style.bottom = '-100px';
  }, 7000);
};

export const handleError = error => {
  if (error.request) { }

  if (error.message) {

    // The refresh token is invalid, prevent a loop.
    if (error.message === 'invalid-refresh-token') {
      storage.removeItem('refresh');
      storage.removeItem('protagt');
      window.location.reload();
    }

    sendMessage(error.message);
  }

  if (error.response) {

    const message = error.response.data.error === 'invalid_credentials' ? 'Error de Contraseña.' : error.response.data.message;
    sendMessage(message);

  }

};

const connect = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
});

connect.interceptors.request.use(
  config => {
    showLoader += 1;
    shouldShowLoader(showLoader);

    const token = storage.getItem('ie_access_token');
    console.log("token: " + token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.common.Authorization = `Bearer ${token}`;
    } else { }

    return config;
  },
  () => {
    showLoader -= 1;
    shouldShowLoader(showLoader);
  },
);

connect.interceptors.response.use(
  res => {
    showLoader -= 1;
    shouldShowLoader(showLoader);

    return res;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      const refreshToken = storage.getItem('refresh');

      if (!refreshToken) {
        throw new Error('invalid-refresh-token');
      }

      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message.includes('refresh token')
      ) {
        throw new Error('invalid-refresh-token');
      }

      const payload = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
        scope: '*',
      };

      try {
        const { data } = await connect.post('/oauth/token', payload);

        showLoader -= 1;
        shouldShowLoader(showLoader);

        storage.setItem('protagt', data.access_token);
        storage.setItem('refresh', data.refresh_token);

        connect.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
        originalRequest.headers.Authorization = `Bearer ${data.access_token}`;

        return connect(originalRequest);
      } catch (e) {

        showLoader -= 1;
        shouldShowLoader(showLoader);
        handleError(e);

      }
    } else {
      showLoader -= 1;
      shouldShowLoader(showLoader);

      return Promise.reject(error);
    }
  },
);

export default connect;
