import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';
import Tooltip from '@mui/material/Tooltip';
import ImageProfile from '../../components/Elements/ImageProfile';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 25px;
    border: 1px solid ${COLORS.whiteFour};
    padding: 4px 4px 4px 4px;
    //margin-top: 5px;
    width: 95px;
    margin-right: 5px;
    cursor: ${props => props.onClick ? 'pointer' : 'default'};
    &.is_prorated{
      width: 30px;
      height: 30px;
    }
    &.have_more{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 30px;
      height: 30px;
      background-color: #FFFFFF;
      border-color: ${COLORS.lightgold};
      margin-left: 5px;
      .rest{
        color: ${COLORS.lightgold};
        font-weight: 700;
      }
    }
    .portion{
      font-size: 13px;
      line-height: 13px;
      font-weight: 600;
      text-align: center;
      padding-left: 3px;
      width: calc(100% - 34px);
    }
    .thumb_party{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 17px;
      overflow: hidden;
      img{
        float: left;
        width: 30px;
      }
    }
    .text{
      text-align: center;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 600;
      width: calc(100% - 30px);
    }
    :hover{
      border: 1px solid ${props => props.onClick ? COLORS.lightgold : COLORS.whiteFour};
    }
`

const PortionPill = (props) => {

    const { profile_image, gender, portion, is_prorated, onClick, tooltip_title, showTootip = true } = props;
    
    return (
      <>
        {showTootip ?
          <Tooltip title={tooltip_title} arrow placement="top" enterTouchDelay={0}>
            <Wrapper onClick={onClick}>
                <ImageProfile className="thumb_party" profile_image={profile_image} gender={gender} />  
                <div className="portion">{portion}%</div>
            </Wrapper>
          </Tooltip>
          :
          <Wrapper onClick={onClick}>
                <ImageProfile className="thumb_party" profile_image={profile_image} gender={gender} />  
                <div className="portion">{portion}%</div>
            </Wrapper>
        }
      </>
    )
}

export default PortionPill
