import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';
import { breakpoints } from '../../helpers/breakpoints';
import { ContentContext } from '../../context';
import { useTranslation } from 'react-i18next';
import Input from '../../components/Elements/Form/Input';
import Textarea from '../../components/Elements/Form/Textarea';
import Select from '../../components/Elements/Form/Select';

const Wrapper = styled.div`

    width: 100%;

    .inner_form{
        display: flex;
        width: 100%;
        flex-direction: column;
        
        //box-sizing: border-box;
        .form_top{
            display: flex;
            width: 100%;
            flex-direction: column;
            padding-bottom: 20px;
            border-bottom: 1px solid ${COLORS.iceblue};
            //background-color: pink;
            .left{
                display: flex;
                width: 320px;
            }
            .right{
                display: flex;
                width: 100%;
            }
        }
        .form_bottom{
            display: flex;
            width: 100%;
            flex-direction: column;
            //background-color: skyblue;
            .center{
                width: 100%;
            }
            .title{
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }

    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        .inner_form{
            display: flex;
            width: 100%;
            flex-direction: column;
            
            //box-sizing: border-box;
            .form_top{
                display: flex;
                width: 100%;
                flex-direction: row;
                padding-bottom: 20px;
                border-bottom: 1px solid ${COLORS.iceblue};
                //background-color: pink;
                .left{
                    display: flex;
                    width: 320px;
                }
                .right{
                    display: flex;
                    width: 100%;
                }
            }
            .form_bottom{
                display: flex;
                width: 100%;
                flex-direction: row;
                //background-color: skyblue;
                .center{
                    width: 100%;
                }
                .title{
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const Fields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    .double_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        .field{
            margin-bottom: 10px;
        }
    }
    .single_field{
        display: flex;
        width: 100%;
        flex-direction: column;
        .field{
            width: 100%;
            margin-bottom: 10px;
        }
    }

    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        .double_field{
            display: flex;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .field{
                margin-bottom: 10px;
            }
        }
        .single_field{
            display: flex;
            width: 100%;
            flex-direction: column;
            .field{
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    .description{
        textarea{
            resize: none;
            height: 180px;
        }
    }
    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        width: calc(50% - 10px);
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`

const Form = (props) => {

    const { t } = useTranslation();

    const { assetCategories } = useContext(ContentContext);

    const { formData, setFormData, inputRef, className } = props;

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
    }
      
    return(
        <Wrapper className={className}>

            <div className="inner_form">
                <div className="form_top">
                    <Fields>
                        <div className="double_field">
                            <Field className="field">
                                <Input
                                    className={`name ${formData?.name?.error ? "error" : ""}`}
                                    isRequired={"true"}
                                    style_type="default"
                                    type="text"
                                    label={`${t("labels.asset_name")}:`}
                                    inputRef={inputRef}
                                    elName={formData?.name?.ref}
                                    value={formData?.name?.value}
                                    onChange={handleFormElementChange}
                                />
                            </Field>
                            <Field className="field">
                                <Select
                                    style_type="default"
                                    label={`${t("labels.category")}:`}
                                    inputRef={inputRef}
                                    elName={formData?.asset_category_id?.ref}
                                    className={`category ${formData?.asset_category_id?.error ? "error" : ""}`}
                                    data={assetCategories}
                                    optionSelected={formData?.asset_category_id?.value}
                                    onChange={handleFormElementChange/*handleOnChangeGender*/}
                                />
                            </Field>
                        </div>
                        <div className="single_field">
                            <Field className="field">
                                <Textarea
                                    className={`description ${formData?.description?.error ? "error" : ""}`}
                                    style_type="default"
                                    label={`${t("labels.description")}:`}
                                    inputRef={inputRef}
                                    type="text"
                                    elName={formData?.description?.ref}
                                    value={formData?.description?.value}
                                    onChange={handleFormElementChange}
                                />
                            </Field>
                        </div>
                    </Fields>
                </div>
            </div>

        </Wrapper>
    )
}

export default Form;