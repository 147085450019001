import React, {useState, useEffect, useContext} from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';

import BottomLinks from '../../components/BottomLinks';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import RegisterContainer from '../../components/Home/RegisterContainer';
import LoginContainer from '../../components/Home/LoginContainer';
import ForgotContainer from '../../components/Home/ForgotContainer';
import RecoveryPasswordContainer from '../../components/Home/RecoveryPasswordContainer';
import OverlayValidateAction from '../../components/Elements/OverlayValidateAction';
import { useTranslation, Trans } from 'react-i18next';

import { COLORS,
         LogoInherit } from '../../helpers/constants';

import backvault from "../../assets/images/backvault.jpg"
import { AuthContext } from '../../context';

const Wrapper = styled.div`

    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100%;
    min-height: 100%;
    
    
    .logo{
        display: flex;
        align-self: flex-start;
        width: 230px;
        svg{
            display: flex;
            float: left;
            width: 100%;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        width: 90vw;
        align-self: center;
        margin-top: 5vw;
        margin-bottom: 7vw;
    }

    @media ${breakpoints.xs} {
        .content{
            width: 550px;
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${breakpoints.xs} {
    
  }
  @media ${breakpoints.sm} {
    
  }
  @media ${breakpoints.md} {
      width: 662px;
  }
  @media ${breakpoints.lg} {
      width: 870px;
  }
  @media ${breakpoints.xl} {
      width: 1036px;
  }
  @media ${breakpoints.xxl} {
      width: 1110px;
  }
  @media ${breakpoints.xxxl} {
      width: 1350px;
  }
  @media ${breakpoints.xxxxl} {
      width: 1590px;
  }
`
const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    
    >.inner{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.5vw;
        margin-left: 0;
        width: 95vw;
    } 

    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {
        >.inner{
            margin-top: 40px;
            //margin-left: 60px;
            width: calc(100% - 120px);
        }
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        width: 950px;
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }

`
const Right = styled.div`
    display: none;
    flex-direction: column;
    align-items: flex-end;
    width: calc(100% - 950px);
    background-image: url(${backvault});
    background-size: cover;
    background-position-y: center;
    background-position-x: left;
    .actions{
        display: flex;
        flex-direction: row;
        .bt_signup{
            margin-right: 10px;
            margin-top: 10px;
        }
        .bt_signin{
            margin-right: 10px;
            margin-top: 10px;
        }
    }
    
    
    @media ${breakpoints.xs} {
        
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        display: flex;
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }

`


const HomeContainer = (props) => {

    const { t } = useTranslation();

    const history = useHistory();
    const { search, pathname } = useLocation();
    const { sendValidateAccount } = useContext(AuthContext);
    const [ showHome, setShowHome ] = useState(null);
    const [ showValidateAccountOverlay, setShowValidateAccountOverlay ] = useState(false);
    const [ validateAccountSuccess, setValidateAccountSuccess ] = useState(null);
    const [ validateAccountMessage, setValidateAccountMessage ] = useState(null);

    useEffect(() => {
        console.log("pathname")
        console.log(pathname);
        console.log("pathname")

        switch (pathname) {
            case '/':
            case '/signin':
                setShowHome('signin');
                break;
            case '/signup':
                setShowHome('signup');
                break;
            case '/forgot':
                setShowHome('forgot');
                break;
            case '/recovery_password':
                setShowHome('recovery_password');
                break;
            default:
                setShowHome('signin');
                break;
        }
// REVISAR EL TOKEN DEL FORGOT
        if(search.length > 0) {
            const query = new URLSearchParams(search);
            if(query.get("validate_account") && query.get("validate_account").length > 0){

                setShowValidateAccountOverlay(true);
                

                const payload = { validate_account: query.get("validate_account") };
                const response = sendValidateAccount(payload);
                response.then((res) => {
                    
                    setValidateAccountSuccess(res.data.success);
                    setValidateAccountMessage(res.data.message);
                    
                })
                
            }
        }
      return () => {
        toast.dismiss();
      };
    }, [search, pathname])

    const handleGoRegister = () => {

    }

    return (
        <Wrapper>

            <OverlayValidateAction open={showValidateAccountOverlay} setOpen={setShowValidateAccountOverlay} success={validateAccountSuccess} message={validateAccountMessage} />
            

            <Left>
                <div className="inner">
                    <div className="logo">
                        <LogoInherit />
                    </div>
                    <div className="content">
                        {showHome === 'signin' &&
                            <LoginContainer />
                        }
                        {showHome === 'signup' &&
                            <RegisterContainer />
                        }
                        {showHome === 'forgot' &&
                            <ForgotContainer />
                        }
                        {showHome === 'recovery_password' &&
                            <RecoveryPasswordContainer />
                        }
                    </div>
                </div>

                <BottomLinks />
                
            </Left>

            <Right>
                <div className="actions">
                    {(showHome === 'signup' || showHome === 'forgot' || showHome === 'recovery_password') &&
                    <ButtonSolid
                        type="submit"
                        style_type="default"
                        className="bt_signin"
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.lightgold},
                                    background:{normal: '#000000', hover: '#FFFFFF'},
                                    border:{normal: '#000000', hover: COLORS.lightgold}}}
                        onClick={() => history.push('/signin')}
                    >
                        <p>{t("buttons.signin")}</p>
                    </ButtonSolid>
                    }
                    {(showHome === 'signin' || showHome === 'forgot' || showHome === 'recovery_password') &&
                    <ButtonSolid
                        type="submit"
                        style_type="default"
                        className="bt_signup"
                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.lightgold},
                                    background:{normal: '#000000', hover: '#FFFFFF'},
                                    border:{normal: '#000000', hover: COLORS.lightgold}}}
                        onClick={() => history.push('/signup')}
                    >
                        <p>{t("buttons.signup")}</p>
                    </ButtonSolid>
                    }
                </div>
            </Right>
            
        </Wrapper>
    )
}

export default HomeContainer
