import React, { useState, useRef, useContext, useEffect } from 'react';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { ContentContext } from '../../context';
import { AuthContext } from '../../context';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLORS } from '../../helpers/constants';

import Select from '../../components/Elements/Form/Select';
import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import CustomToastMessageError from '../../components/Elements/CustomToastMessageError';

import moment from 'moment';
import "moment-timezone";

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;
    border-radius: 12px;
    svg{
        color: ${COLORS.lightgold};
    }
    .form_timezone{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px;
        max-width: 600px;
        .title{
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        .text{
            margin-bottom: 20px;
        }
        .actions{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 20px;
            .bt_goto_profile{
                margin-left: 10px;
            }
        }
    }
 `  
 const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    &.field_timezone{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .aclaration{
            font-size: 14px;
            margin: 10px 0 0 0;
        }
    }
`

const OverlayWaiting = (props) => {

    const history = useHistory();
    const { setUserTimezone } = useContext(ContentContext);
    const { userData, setUserData } = useContext(AuthContext);
    
    const { setHaveTimezone } = props;

    const [ loading, setLoading ] = useState(false);
    const [ timeZoneList, setTimeZoneList ] = useState([]);
    const [ formData, setFormData ] = useState({});
    let inputRef = useRef({});

    useEffect(() => {
        
        setFormData({
            timezone: { value: (userData && userData.timezone) ? userData.timezone : moment.tz.guess(), required: false, disabled: false, ref: "timezone", error: false },
        });

        const timezone_list = [];
        if(moment.tz.names()){
            moment.tz.names().map((name, index) => {
                timezone_list.push({"description": name, "value": name});
            })
            setTimeZoneList(timezone_list);
        }
    
      return () => {
        
      }
    }, [])

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};

        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let haveError = false;

        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {

                if(formData[key].required){
                    if(!formData[key].value.length){
                        haveError = true;
                        updateFormData[key].error = true;
                    }else{
                        updateFormData[key].error = false;
                    }
                }
                
            })

            if(!haveError){

                setLoading(true);
                const response = setUserTimezone(updateFormData);
                response.then((res) => {
                    
                    let message = '';
                    let responseType = '';
                    let errors = [];

                    if(res.success){

                        message = 'The timezone as been updated.';
                        responseType = 'success';

                        const updateUserData = {...userData};
                        updateUserData.timezone = updateFormData.timezone.value;
                        setUserData(updateUserData);

                    }else{

                        responseType = 'error';
                        errors.push('An error has occured. Please try again later');

                    }

                    toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    
                    setLoading(false);
                    //setHaveTimezone(true);
                })
                //handleSendAsset(updateFormData);
            }

            setFormData(updateFormData);
        }

    }
    
    return (
        <Wrapper>
            <BoxContainer className="form_timezone">
                <div className="title">Timezone not detected</div>
                <div className="text">Para configurar una alarma es necesario que seleccione la zona horaria a la que usted pertenece, esta accion la puede realizar desde acá, o desde su perfil de usuario</div>
                <Field className="field field_timezone">
                    <Select
                        style_type="default"
                        label="Timezone:"
                        inputRef={inputRef}
                        elName={formData?.timezone?.ref}
                        className={`category ${formData?.timezone?.error ? "error" : ""}`}
                        data={timeZoneList}
                        optionSelected={formData?.timezone?.value}
                        onChange={handleFormElementChange}
                    />
                    <p className="aclaration">La opción preseleccionada corresponde a su zona horaria actuál</p>
                </Field>

                

                <div className="actions">

                    {loading ==! null && loading == true ? 

                        <div className="loader">
                            <CircularProgress size={34} />
                        </div>

                        :
                        <>
                        <ButtonSolid 
                            type="submit" 
                            style_type="default" 
                            className="bt_save" 
                            override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                            onClick={handleSubmit}
                        >
                            <p>Save</p>
                        </ButtonSolid>
                        <ButtonSolid 
                            type="submit" 
                            style_type="tab" 
                            className="bt_goto_profile" 
                            override_css={null}
                            onClick={() => history.push('/settings/profile')}
                        >
                            <p>Go to Profile</p>
                        </ButtonSolid>
                        </>
                    }

                </div>

                
            </BoxContainer>
        </Wrapper>
    )
}

export default OverlayWaiting;