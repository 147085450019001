import React from "react";
import AddContainer from "./AddContainer";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const AddBeneficiary = (props) => {

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <AddContainer props={props} location={props.location} history={props.history} />
    </MuiPickersUtilsProvider>
  );

};

export default AddBeneficiary;
