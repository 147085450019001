import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { COLORS } from '../../../helpers/constants';

import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

const materialTheme = createTheme({
  overrides: {
      Mui:{
        "root": {
            "&$focused": {
              "display": "none",
              "outline": "none"
            }
          }
        /*
        display: 'none',
          focused:{
              display: 'none',
            outline: 'none',
          }
        */
      },
      
    MuiInput:{
        underline:{
            '&:after':{
                border: '0!important',
            }
        }
    },
    MuiFormControl:{ 
        marginNormal:{
            marginTop: '0',
            marginBottom: '0',
        }
    },
    //MuiDialogActions:{
    MuiButton: {
        // Name of the rule
        
        text: {
          // Some CSS
          borderRadius: 3,
          border: 0,
          //color: 'white',
          height: 48,
          //padding: '0 30px',
          
        },
        textPrimary:{
            fontWeight: '600',
            color: 'white',
            backgroundColor: COLORS.darkgrey,
            padding: '0 20px',
            '&:hover':{
                backgroundColor: COLORS.lightgold,
            }
        }
      },
    //},

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: COLORS.darkgrey,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: COLORS.darkgrey,
        '&:hover':{
            backgroundColor: COLORS.hovergold,
        }
      },
      daySelected: {
        backgroundColor: COLORS.darkgrey,
        '&:hover':{
            backgroundColor: COLORS.hovergold,
            color: COLORS.darkgrey,
        }
      },
      dayDisabled: {
        color: '#FFFFFF',
      },
      current: {
        color: COLORS.darkgrey,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: 'pink',
      },
    },
    MuiPickersYear:{
        root:{
            color: COLORS.darkgrey,
            '&:hover':{
                color: COLORS.lightgold,
            }
        },
        yearSelected:{
            color: COLORS.lightgold,
        }
    }
  },
});


const Wrapper = styled.div`
    
    display: flex;
    width: 100%;
    flex-direction: column;

    label{
        font-size: ${props => props.st.label.fontSize};
        font-weight: ${props => props.st.label.fontWeight};
        color: ${props => props.st.label.color};
        margin-bottom: 4px;
    }

    .MuiPickersToolbar-toolbar{
        display: none!important;
        background-color: ${COLORS.darkgrey};
    }

    &.error{
        input{
            border-color: red;
        }
    }

`
const KeyboardDatePickerStyled = styled(KeyboardDatePicker)`
    .MuiInputBase-root{
        
        background-color: ${props => props.st.input.backgroundColor};
        //border-radius: 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        ::before{
            border: none !important;
        }
        .MuiIconButton-root{
            padding-left: 20px;
            padding-right: 20px;
            padding: 0;
            background: none !important;
            .MuiSvgIcon-root{
                padding-right: 5px;
                fill: ${COLORS.lightgold}!important;
            }
            :hover{
                //color: #000000;
                background: none !important;
                .MuiSvgIcon-root{
                    fill: ${COLORS.darkgrey}!important;
                }
            }
            .MuiTouchRipple-root{
                display: none;
            }
        }
        
        .MuiInputAdornment-root{
            width: 50px;
        }
        
    }
    
    

    input{
        //background-color: pink;


        display: flex;
        width: calc(100% - 26px);
        background-color: ${props => props.st.input.backgroundColor};
        padding: 16px 15px 16px 15px;
        font-size: ${props => props.st.input.fontSize};
        font-weight: ${props => props.st.input.fontWeight};
        color: ${props => props.st.input.color};
        border: 1px solid ${props => props.st.input.backgroundColor};
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-family: 'Poppins';
        :focus {
            outline: none;
        }
        *:focus {
            outline: none;
        }
    }

    .MuiFormLabel-root{
        font-family: 'Poppins';
        font-size: ${props => props.st.label.fontSize}!important;
        font-weight: ${props => props.st.label.fontWeight};
        color: ${props => props.st.label.color};
        margin-bottom: 4px;
    }


    
`

const DatePickerContainer = ( props ) => {

    const { className, override_css, style_type, label, elName, onChange, selectedDate, inputRef, format="MM/DD/yyyy", disableFuture=false } = props;
    const [ styles, setStyle ] = useState(null);
    
    useEffect(() => {

        switch (style_type) {
            case 'default':
                setStyle({

                    input: { color: override_css?.input?.color ? override_css.input.color : COLORS.bluegrey,
                             fontSize: override_css?.input?.fontSize ? override_css.input.fontSize : "16px",
                             fontWeight: override_css?.input?.fontWeight ? override_css.input.fontWeight : "500",
                             backgroundColor: override_css?.input?.backgroundColor ? override_css.input.backgroundColor : COLORS.whiteTwo },

                    label: { fontSize: override_css?.label?.fontSize ? override_css.label.fontSize : "15px",
                             fontWeight: override_css?.label?.fontWeight ? override_css.label.fontWeight : "600",
                             color: override_css?.label?.color ? override_css.label.color : COLORS.darkgrey},

                });
                break;
        
            default:
                break;
        }

    }, []);

    return (
        <>
        {styles &&
        <Wrapper override={override_css} st={styles} className={className}>
            {/*<DatePicker value={selectedDate} onChange={handleDateChange} />*/}
            <ThemeProvider theme={materialTheme}>
            {label.length > 0 &&
                <label htmlFor={elName}>{label}</label>
            }
                <KeyboardDatePickerStyled
                    st={styles}
                    margin="normal"
                    id="date-picker-dialog"
                    label=""
                    format={format}
                    disableFuture={disableFuture}
                    value={selectedDate?.length ? selectedDate : null}
                    inputRef={el => inputRef.current[elName] = el}
                    onChange={(e) => onChange(elName, moment(e).format('L'))}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </ThemeProvider>    
        </Wrapper>
        }
        </>
    )
}

export default DatePickerContainer
