import React, { useState, useEffect, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { breakpoints } from '../../helpers/breakpoints';
import { useTranslation } from 'react-i18next';
import storage from 'local-storage-fallback';

import CircularProgress from '@material-ui/core/CircularProgress';

import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import ThumbBox from '../../components/Elements/ThumbBox';
import Image from '../../components/Elements/Image';
import NoImage from '../../components/Elements/NoImage';
import PortionPill from '../../components/Elements/PortionPill';
import CustomToastMessageError from '../../components/Elements/CustomToastMessageError';
import { toast } from 'react-toastify';
import OverlayAssignAsset from '../../components/Elements/OverlayAssignAsset';
import { COLORS, SIZES } from '../../helpers/constants';

import { ContentContext,
         AuthContext } from '../../context';


const Wrapper = styled.div`
    /*
    display: flex;
    width: 100%;
    height: auto;
    //min-height: calc(100% - 100px);
    flex-direction: column;
    align-items: center;
    padding: 3vw;
    box-sizing: border-box;
    */
    display: flex;
    width: 100%;
    padding-top: 17px;
    padding-bottom: 17px;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    .inner_box{
      display: flex;
      flex-direction: column;
      width: calc(100% - 30px);
      min-height: auto;
      padding: 7vw 5vw 7vw 7vw;
      margin-bottom: 5vw;
      position: relative;
      box-sizing: border-box;
      box-shadow: unset!important;
      .top{
        .title{
          font-weight: 600;
          font-size: 6vw;
          color: ${COLORS.darkgrey};
          margin-bottom: 1vw;
        }
        .description{
          font-size: 3.1vw;
          color: ${COLORS.bluegrey};
        }
      }

      .box_assets_assigned_inner{
        margin-top: 4vw;
        ul.container_owners_asset{
          list-style: none;
          padding: 0;
          margin: 0;
          li{
            margin-bottom: 3vw;
            margin-top: 4vw;
            border-bottom: 1px solid ${COLORS.whiteThree};
            .owner_asset{
              font-size: 3.6vw;
              font-weight: 400;
              margin-bottom: 1vw;
            }
            :last-child{
              border-bottom: 0;
              margin-bottom: 0;
              margin-top: 0;
            }
            
          }
        }
      }

      .text_no_element{
        color: ${COLORS.bluegrey};
        font-weight: 600;
        font-size: 3.2vw;
      }

      .loader{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        svg{
          color: ${COLORS.lightgold};
        }
      }
    }

    @media ${breakpoints.xs} {

      width: 100%;
      //min-height: calc(100% - 100px);
      //padding: 15px;

      .inner_box{
        display: flex;
        flex-direction: column;
        width: calc(100% - 30px);
        padding: 35px 25px 35px 35px;
        margin-bottom: 15px;
        .top{
          .title{
            font-size: 30px;
            margin-bottom: 5px;
          }
          .description{
            font-size: 16px;
          }
        }

        .box_assets_assigned_inner{
          margin-top: 20px;
          ul.container_owners_asset{
            padding: 0;
            margin: 0;
            li{
              margin-bottom: 15px;
              margin-top: 20px;
              .owner_asset{
                font-size: 18px;
                margin-bottom: 5px;
              }
            }
          }
        }
        .text_no_element{
          color: ${COLORS.bluegrey};
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
    @media ${breakpoints.sm} {}
    @media ${breakpoints.md} {}
    @media ${breakpoints.lg} {}
    @media ${breakpoints.xl} {}
    @media ${breakpoints.xxl} {}
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
`
const AssignedAssetsStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .elements{
    width: 100%;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: min-content min-content min-content; 
    gap: 10px 10px; 

    margin: 0;
    padding: 0;
    .element{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #FFFFFF;
      border-radius: 16px;
      min-height: 100px;
      padding: 10px;
      border: 1px solid ${COLORS.whiteFour};
      .box_image{
        display: flex;
        flex-direction: row;
        justify-content: center;
        .image{
          //margin: 10px 10px 0 10px;
          width: 110px;
          height: 110px;
          background-color: skyblue;
        }
      }
      .box_info{
        display: flex;
        flex-direction: column;
        justify-self: flex-start;
        width: calc(100% - 240px);
        margin-left: 10px;
        //margin-top: 10px;
        //margin-bottom: 10px;
        margin-right: auto;
        //background-color: gray;
        .title{
          font-size: 17px;
          font-weight: 500;
          margin-bottom: 5px;
          color: ${COLORS.darkgrey};
        }
        .category{
          font-size: 13px;
          font-weight: 600;
          color: ${COLORS.lightgold};
        }
      }
      .box_portion{
        width: 110px;
        margin-left: 10px;
        //background-color: green;
      }
    }
  }
`

const MyAssignedAssetsContainer = (props) => {
  
  const { t } = useTranslation();

  const history = useHistory();
  let { section } = useParams();

  const { userData } = useContext(AuthContext);

  const { getAssignedAsset,
          assignAssetByPartyCode } = useContext(ContentContext);
  
  const [ showSection, setShowSection ] = useState("");
  const [ showOverlayAssignAsset, setShowOverlayAssignAsset ] = useState(false);
  const [ assetsAssigned, setAssetsAssigned ] = useState(null);
  const [ assignAssetByPartyCodeStatus, setAssignAssetByPartyCodeStatus ] = useState(null);
  const [ assignAssetByPartyCodeError, setAssignAssetByPartyCodeError ] = useState(null);

  useEffect(() => {
    
    if(storage.getItem('ie_pcode')){
      if(storage.getItem('ie_pcode').length){
        setShowOverlayAssignAsset(true);

        const payload = {party_code: storage.getItem('ie_pcode')};

        const response = assignAssetByPartyCode(payload);
        response.then((res) => {
          console.log("assignAssetByPartyCode ----------------")
          console.log(res);
          console.log("assignAssetByPartyCode ----------------")

          let message = '';
          let responseType = '';
          let errors = [];

          if(res.success){

            message = res.message;
            responseType = 'success';

            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });

            setShowOverlayAssignAsset(false);

            if(assetsAssigned){
              setAssetsAssigned(prev => [...prev, res.data]);
            }else{
              setAssetsAssigned(res.data);
            }
            //setAssetsAssigned(res.data);

          }else{
            setAssignAssetByPartyCodeStatus(res.success);
            setAssignAssetByPartyCodeError(res.errors);
          }
        })
        storage.setItem('ie_pcode', '');
        
      }
    }

    return () => {
      return () => {
        toast.dismiss();
      }
    }

  }, [storage.getItem('ie_pcode')])
  
  useEffect(() => {
    if(userData){
      const response = getAssignedAsset(userData.id);
      response.then((res) => {
        setAssetsAssigned(res.data);
      })
    }
    return () => {
      //toast.dismiss();
    }
  }, [userData])


  const OwnerAssets = useCallback(({owner}) => {

    const assets_arr = [];

    owner.map((own, index) => {
      assets_arr.push(<div className="element">
        <div className="box_image">
          
          {own.data.image ? 
            <ThumbBox className="thumb" css={null}>
              <Image filename={own.data.thumb_file} type={"image_asset"} user_type={"party"} owner_id={own.data.user_id} />
            </ThumbBox>
            :
            <ThumbBox className="thumb" css={null}>
              <NoImage type={own.data.category.description} />
            </ThumbBox>
          }

        </div>
        <div className="box_info">
          <div className="title">
            {own.data.name}
          </div>
          <div className="category">
            {t(`labels.category`)}:<br/> {own.data.category.description}
          </div>
        </div>
        {!own.hide_portion &&
        <div className="box_portion">
          <PortionPill profile_image={userData.profile_image} portion={own.portion} is_prorated={own.data.is_prorated} showTootip={false} />
        </div>
        }

      </div>);
    })
    

    return assets_arr;

  }, [])



  return (
    <Wrapper>
     
      <BoxContainer className="inner_box">

        {showOverlayAssignAsset &&
          <OverlayAssignAsset
            assignAssetByPartyCodeStatus={assignAssetByPartyCodeStatus}
            assignAssetByPartyCodeError={assignAssetByPartyCodeError}
            setShowOverlayAssignAsset={setShowOverlayAssignAsset}
          />
        }

        <div className="top">
          <div className="title">{t(`assigned_assets.title`)}</div>
          <div className="description">{t(`assigned_assets.description`)}</div>
        </div> 

        <div className="box_assets_assigned_inner">
        <ul className="container_owners_asset">
        {
        assetsAssigned ?
          assetsAssigned.length ?
            assetsAssigned.map((ownerAssets, index) => {
              return(
                <li>
                  <div className="owner_asset">{`From: ${ownerAssets.owner?.first_name} ${ownerAssets.owner?.last_name}`}</div>
                  <AssignedAssetsStyled>
                      <div className="elements">
                        <OwnerAssets owner={ownerAssets.assets} />
                      </div>
                  </AssignedAssetsStyled>
                </li>
              )})
            :
          <li className="text_no_element">{t(`alerts.not_beneficiary`)}</li>
          :
          <div className="loader">
            <CircularProgress size={40} />
          </div>
        }
        </ul> 
        </div>
      </BoxContainer>
      
    </Wrapper>
  )
}

export default MyAssignedAssetsContainer
