import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import ImageProfile from '../../../components/Elements/ImageProfile';

const Wrapper = styled.div`

    display: flex;
    background-color: #FFFFFF;
    min-width: 225px;
    border-radius: 10px;
    //margin-top: 5px;
    //margin-bottom: 5px;
    box-sizing: border-box;
    //padding: 7px 10px 7px 10px;
    border: 1px solid #FFFFFF;
    cursor: pointer;
    min-height: 66px;

    .inner_party_element{
        display: flex;
        width: 100%;
        margin: 7px 10px 7px 10px;
    }

    :hover{
        border: 1px solid ${COLORS.cloudyblue};
    }

    .thumb{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: ${COLORS.whiteTwo};
        width: 40px;
        height: 50px;
        margin-right: 8px;
        overflow: hidden;
        border-radius: 6px;
        img{
            float: left;
            height: 100%;
        }
    }
    .data{
        display: flex;
        flex-direction: column;
        width: calc(100% - 53px);
        .name{
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: ${COLORS.darkgrey};
        }
        .title{
            width: 100%;
            font-size: 13px;
            line-height: 16px;
            text-align: center;
            font-weight: 600;
            color: ${COLORS.lightgold};
            text-align: left;
            margin-top: 2px;
        }
    }
    &.selected{
        border: 1px solid ${COLORS.cloudyblue};
    }

`

const ElementAsset = (props) => {

    const { party, index, canClick, handleSelectParty } = props;
 
    return (
        <Wrapper className="element" onClick={() => { canClick && handleSelectParty(party) }}>
            
            <div className="inner_party_element">
                <div className="thumb">
                    <ImageProfile profile_image={party.profile_image} gender={party.gender} />
                </div>

                <div className="data">
                    <div className="name">{ party.first_name + " " + party.last_name }</div>
                    <div className="title">{ party.title.description }</div>
                </div>
            </div>

        </Wrapper>
    )
}

export default ElementAsset
