import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { validateEmail,
         getErrorMessage } from '../../helpers/functions';

import ButtonSolid from '../Elements/Buttons/Solid';
//import OverlayErrorMessage from '../Elements/Form/OverlayErrorMessage';
import Input from '../Elements/Form/Input';

import CircularProgress from '@material-ui/core/CircularProgress';
import { AuthContext } from '../../context';
import { COLORS } from '../../helpers/constants';
import { breakpoints } from '../../helpers/breakpoints';
import { useTranslation, Trans } from 'react-i18next';


const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    
    .title{
        letter-spacing: 0px;
        font-size: 8vw;
        line-height: 10vw;
        font-weight: 700;
        text-align: left;
        color: ${COLORS.darkgrey};
    }
    .inner{
        box-sizing: border-box;
        padding: 15px;
    }
    .form_register{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .field{
            width: 100%!important;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        .actions{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            .bt_signup{
                width: 100%;
                min-height: 65px;
                
                p{
                    font-size: 20px;
                }
            }
            .donthave{
                margin-top: 30px;
                font-size: 16px;
                color: ${COLORS.darkgrey};
                button{
                    font-size: 16px;
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    color: ${COLORS.lightgold};
                    cursor: pointer;
                }
                button:hover{
                    color: ${COLORS.darkgrey};
                }
            }
        }
        a.bt_forgot{
            display: flex;
            align-self: flex-end;
            text-decoration: none;
            margin-top: 10px;
            font-weight: 500;
            color: ${COLORS.darkgold};
            :hover{
                color: ${COLORS.darkgrey};
            }
        }
        .loader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 150px;
            min-height: 65px;
            svg{
                color: ${COLORS.lightgold};
            }
        }
    }
    @media ${breakpoints.xs} {
        .title{
            text-align: left;
            font-size: 48px;
            line-height: 55px;
        }
    }
    @media ${breakpoints.sm} {
        
    }
    @media ${breakpoints.md} {
        
    }
    @media ${breakpoints.lg} {
        
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {
        
    }
    @media ${breakpoints.xxxxl} {
        
    }
`
const OverlayCongratulation = styled.div`

    position: absolute;
    background-color: rgba(255, 255, 255, 0.90);
    border: 1px solid rgba(255, 255, 255, 0.90);
    width: 100%;
    height: 100%;
    z-index: 99;
    .inner_overlay{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px 30px 20px;
        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.05);
        background-color: #FFFFFF;
        .bt_close_errors{
            margin-top: 25px;
        }
        p{
            width: 100%;
            text-align: center;
            margin: 3px 20px 3px 20px;
            color: ${COLORS.red};
            font-weight: 500;
            span{
                font-weight: 600;
                color: ${COLORS.lightgold};
            }
        }
        .title{
            font-size: 30px;
            line-height: 30px;
            width: 100%;
            text-align: center;
            color: ${COLORS.lightgold};
        }
        .text{
            font-size: 16px;
            line-height: 20px;
            width: 100%;
            text-align: center;
            margin-top: 15px;
            color: ${COLORS.darkgrey};
        }

    }
`

const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: calc(50% - 10px);
    .description{
        textarea{
            resize: none;
            height: 180px;
        }
    }
`

const ForgotContainer = (props) => {

    const { t } = useTranslation();
    
    const { forgot,
            isLoading } = useContext(AuthContext);
    
    const history = useHistory();

    const [ formData, setFormData ] = useState({});
    const [ loading, setLoading ] = useState(null);
    const [ isWaiting, setIsWaiting ] = useState(null);
    const [ formHaveError, setFormHaveError ] = useState(false);
    const [ errorMessage, setErrorMessage ] =  useState({});
    const [ successForgot, setSuccessForgot ] = useState(false);
    
    let inputRef = useRef({});

    useEffect(() => {

        setFormData({
            email: { value: "", required: true, disabled: false, ref: "email", error: false },
        });

        return () => {
            //toast.dismiss();
        }
        
    }, [])

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;
        
    }

    const handleSubmit = (e) => {
        
        e.preventDefault();
        setLoading(true);

        let arrErrorMessage = [];
        const haveError = checkErrorForm();
        
        if(!haveError){
            const response = forgot({email: formData['email'].value});
            response.then((res) => {
                console.log(res.data);
                resetForm();
                setSuccessForgot(true);
                setLoading(false);
            })
        }else{
            setLoading(false);
        }
        
        
    }

    const checkErrorForm = () => {

        let haveError = false;
        let arrErrorMessage = [];

        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {

                if(formData[key].required){
                    if(!formData[key].value.length){
                        haveError = true;

                        updateFormData[key].error = true;
                        const msg = getErrorMessage(formData[key].ref, 'mandatory');
                        arrErrorMessage.push('- '+msg);

                    }else{
                        updateFormData[key].error = false;
                    }
                    
                    if(key == 'email'){
                        if(formData[key].value.length){
                            const validEmail = validateEmail(formData[key].value);
                            if(!validEmail){
                                haveError = true;
                                updateFormData[key].error = true;

                                const msg = getErrorMessage(formData[key].ref, 'format');
                                arrErrorMessage.push('- '+msg);
                                
                            }else{
                                updateFormData[key].error = false;
                            }
                        }

                    }

                }
                
            })

            if(haveError){

                setErrorMessage({title: t(`basics.response_title_error`),
                                 text: t(`alerts.response_error`),
                                 errors: arrErrorMessage,
                                 actions: [{type: 'close', text: 'Close', goto: null}]});

                setFormHaveError(true);
            }

            setFormData(updateFormData);
            return haveError;

        }

    }

    const resetForm = () => {
        
        Object.keys(inputRef.current).map(function(key) {
            inputRef.current[key].value = '';
        })
        Object.keys(formData).map(function(key) {
            formData[key].value = '';
        })
        /*
        setTitleSelected(null);
        setGenderSelected(null);
        setLoadingStates(null);
        setCroppedImageProfile(null);
        setProfileImage(null);
        setImageBase64(null);
        setSelectedBeneficiary(null);
        setNotifyBeneficiaryToggled(false);

        console.log(inputRef);
        console.log(formData);
        */
    }

    return (
        <Wrapper>
            
            {successForgot &&
            <OverlayCongratulation>
                <div className="inner_overlay">
                    <div className="title">{t('basics.response_title_success')}</div>
                    <div className="text">{t('forgot.mail_text')}</div>
                    <ButtonSolid
                        type="submit"
                        style_type="default"
                        className="bt_close_errors"
                        override_css={null}
                        onClick={(e) => setSuccessForgot(!successForgot)}
                    >
                        <p>Close</p>
                    </ButtonSolid>
                </div>
            </OverlayCongratulation>
            }
            {/*<OverlayErrorMessage errorMessage={errorMessage} setErrorMessage={setErrorMessage} />*/}
            
            <div className="title">
                {t("forgot.title")}
            </div>

            <div className="form_register">

                <Field className="field">
                    <Input
                        className={`email ${formData?.email?.error ? "error" : ""}`}
                        isRequired={"true"}
                        style_type="default"
                        type="text"
                        label={`${t("labels.email")}:`}
                        inputRef={inputRef}
                        elName={formData?.email?.ref}
                        value={formData?.email?.value}
                        onChange={handleFormElementChange}
                    />
                </Field>

                <div className="actions">

                    {loading ?
                        <div className="loader">
                            <CircularProgress size={50} />
                        </div>
                        :
                        <ButtonSolid
                            type="submit"
                            style_type="default"
                            className="bt_signup"
                            override_css={{color:{normal: "#FFFFFF", hover: COLORS.lightgold},
                                        background:{normal: COLORS.lightgold, hover: '#FFFFFF'},
                                        border:{normal: COLORS.lightgold, hover: COLORS.lightgold}}}
                            onClick={(e) => handleSubmit(e)}
                        >
                            <p>{t("buttons.reset_password")}</p>
                        </ButtonSolid>

                        
                    }

                    <div className="donthave">
                        {t("basics.dont_have_account")} <button onClick={() => history.push('/signup')}>{t("buttons.signup")}</button>
                    </div>
                </div>

            </div>
        </Wrapper>
    )
}

export default ForgotContainer;
