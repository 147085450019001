import React, {useState, useCallback, useContext, useEffect} from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../helpers/breakpoints';
import { useHistory } from 'react-router';
import { ContentContext,
         AuthContext } from '../../context';
import { useTranslation } from 'react-i18next';
import OverlayExtraDocuments from '../../components/Elements/OverlayExtraDocuments';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { GiHamburgerMenu } from "react-icons/gi";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IoIosCloseCircleOutline } from "react-icons/io";
import noasset from '../../assets/images/no_asset.svg';
import OverlayImage from '../../components/Elements/OverlayImage';
import BoxContainer from '../../components/Elements/BoxContainer';
import ButtonSolid from '../../components/Elements/Buttons/Solid';
import ThumbBox from '../../components/Elements/ThumbBox';
import Image from '../../components/Elements/Image';
import NoImage from '../../components/Elements/NoImage';
import CustomToastMessageError from '../../components/Elements/CustomToastMessageError';
import { toast } from 'react-toastify';

import { IcoCheck,
         IcoPlus,
         IcoClose,
         COLORS } from '../../helpers/constants';  


const Wrapper = styled.div`

    display: flex;
    //flex: 1;
    //padding-left: 30px;
    //padding-right: 30px;
    padding-top: 17px;
    padding-bottom: 17px;
    flex: 1;
    flex-direction: row;
    justify-content: center;

    .menu_assets{
      display: none!important;
      font-style: normal;
    }
    
    //background-color: pink;
    /*
    @media ${breakpoints.md} {
      width: 300px;
    }
    @media ${breakpoints.lg} {
      width: 300px;
    }
    */
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 30px);
  .box_data{
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 15px;
    box-sizing: border-box;

    .inner{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      /*padding: 20px;*/
      @media ${breakpoints.md} {
        flex-direction: row;
      }
      @media ${breakpoints.lg} {
        
      }
    }
  }
  .box_assets{
    margin-bottom: 20px;
  }
  .box_recent{
  }
  .box_header_actions{
    max-height: 60px;
    box-shadow: 0!important;
    .inner{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 10px;
      width: 100%;
      .menu_assets_mobile{
        display: flex;
        .ico_hamburger{
          color: ${COLORS.lightgold};
        }
      }
      .menu_assets_desktop{
        display: none;
        flex-direction: row;
      }
      .bt_all,
      .bt_assigned,
      .bt_not_assigned{
        display: flex;
        margin-left: 10px;
        /*margin-right: 10px;
        margin-left: auto;*/
        &.selected{
          background-color: ${COLORS.lightgold}!important;
          border-color: ${COLORS.lightgold}!important;
          p{
            color: #FFFFFF!important;
          }
        }
      }
      .bt_add_new_asset{
        margin-right: auto;
      }
      .bt_not_assigned{
        //margin-right: 0;
      }
    }
  }
  .box_assets{
    margin-top: 20px;
  }

  @media ${breakpoints.xs} {
      //min-width: 576px
  }
  @media ${breakpoints.sm} {
    .box_header_actions{
      .inner{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 10px;
        width: 100%;
        .menu_assets_mobile{
          display: none;
        }
        .menu_assets_desktop{
          display: flex;
        }
      }
    }
  }
  @media ${breakpoints.md} {
    
  }
  @media ${breakpoints.lg} {
    
  }
  @media ${breakpoints.xl} {
      //min-width: 1366px
      //width: 1036px;
  }
  @media ${breakpoints.xxl} {
      //min-width: 1440px
      //width: 1110px;
  }
  @media ${breakpoints.xxxl} {
      //min-width: 1680px
      //width: 1350px;
      //background-color: pink;
  }
  @media ${breakpoints.xxxxl} {
      //min-width: 1920px
      //width: 1590px;
      //background-color: skyblue;
  }

  
`
const MyAssets = styled.div`
  /*
  display: flex;
  width: 100%;
  flex-direction: row;
  
  flex-wrap: wrap;
  */
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`
const ButtonAddAsset = styled.div`
  display: flex;
  width: 230px;//calc(25% - 10px);
  height: 210px;
  background-color: #FFFFFF;
  border-radius: 12px;
  margin-right: 15px;

  box-shadow: 4px 0px 13px 0px rgba(0,0,0,0.05);
  -webkit-box-shadow: 4px 0px 13px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 4px 0px 13px 0px rgba(0,0,0,0.05);

  .inner{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 15px;
    .texts{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 23px;
    }
    .bt_add_new_asset{

    }
  }
  .ico{
    display: flex;
    flex: 1;
    flex-grow: 1;
    align-items: flex-end;
    .ico_plus{
      padding-bottom: 10px;
    }
  }
  p.text{
    font-size: 14px;
    color: ${COLORS.lightgold};
    text-align: center;
    display: flex;
    flex: 1;
  }
  .bt_add_asset{
    width: 100%;
    text-align: center;
  }
`
const Assets = styled.div`
  width: 100%;

  /*
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  */
  display: grid; 
  //grid-template-columns: repeat(1, 1fr);
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 15px 15px; 
  //grid-template-rows: min-content; 

  @media ${breakpoints.xs} {
    
  }
  @media ${breakpoints.sm} {
    
  }
  @media ${breakpoints.md} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    //grid-template-columns: repeat(2, 1fr);
  }
  @media ${breakpoints.lg} {
    
  }
  @media ${breakpoints.xl} {
    
  }
  @media ${breakpoints.xxl} {
    
  }
  @media ${breakpoints.xxxl} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media ${breakpoints.xxxxl} {
    
    //grid-template-columns: repeat(3, 3fr);
  }

`
const Asset = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 42vw;
  //height: 260px;
  background-color: #FFFFFF;
  border-radius: 12px;
  flex-direction: column;

  //margin-right: 15px;

  box-shadow: 4px 0px 13px 0px rgba(0,0,0,0.05);
  -webkit-box-shadow: 4px 0px 13px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 4px 0px 13px 0px rgba(0,0,0,0.05);
  &.nomargin{
    //margin-right: 0;
  }
  &.hide{
    display: none;
  }
  &.show{
    display: flex;
  }

  .delete_overlay{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0,0,0, 0.90);/*208, 178, 87*/
    border-radius: 12px;
    z-index: 999;
    .inner_overlay{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 5vw;
      padding-right: 5vw;
      .actions{
        margin-top: 3vw;
        .loader{
          display: flex;
          .MuiCircularProgress-root{
            width: 8vw!important;
            height: 8vw!important;
          }
          svg{
            color: ${COLORS.lightgold};
            width: 8vw;
            height: 8vw;
          }
        }
      }
      p{
        font-size: 3.5vw;
        margin: 0;
        text-align: center;
        color: ${COLORS.whiteTwo};
        font-weight: 600;
      }
      p.username{
        margin: 0;
        margin-top: 1vw;
        font-weight: 700;
        color: ${COLORS.lightgold};
      }
      .bt_delete_beneficiary{
        min-width: 28.4vw;
        height: 8vw;
      }
    }
  }

  .inner{
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    height: 100%;
    padding: 15px;
    .top{
      display: flex;
      flex-direction: row;
      .more_actions{
        position: absolute;
        right: 1.2vw;
        top: 1.2vw;
        padding: 1vw!important;
        color: ${COLORS.lightgold};
        :hover {
          background-color: ${COLORS.lightgold};
          color: #FFFFFF;
        }
      }
      .thumb{
        border: 2px solid ${COLORS.lightgold};
        width: 26vw!important;
        height: 26vw!important;
        margin-right: 2vw;
      }
      .info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: calc(100% - 195px);
        .files{
          font-size: 3vw;
          font-weight: 600;
          color: ${COLORS.bluegrey};
          cursor: pointer;
          .number{
            color: ${COLORS.darkgold};
          }
          :hover{
            color: ${COLORS.darkgold};
          }
        }
        .texts{
          width: 100%;
          //background-color: pink;
          .name{
            font-weight: 600;
            font-size: 3.2vw;
          }
          .address{
            font-weight: 500;
            font-size: 3vw;
            line-height: 3.9vw;
            margin-top: 0.7vw;
            color: ${COLORS.darkgold};
          }
          .category{
            font-weight: 500;
            font-size: 3vw;
            line-height: 3.9vw;
            color: ${COLORS.darkgold};
          }
          .description{
            font-weight: 500;
            font-size: 2.6vw;
            line-height: 3.2vw;
            margin-top: 1.2vw;
            margin-bottom: 3vw;
            color: ${COLORS.bluegrey};
            word-wrap: break-word;

            /*
            // These are technically the same, but use both
            overflow-wrap: break-word;
            word-wrap: break-word;

            -ms-word-break: break-all;
            //This is the dangerous one in WebKit, as it breaks things wherever
            word-break: break-all;
            //Instead use this non-standard one:
            word-break: break-word;

            //Adds a hyphen where the word breaks, if supported (No Blink)
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
            */
          }
        }
      }
    }
    .bottom{
      display: flex;
      flex-direction: column;
      margin-top: 13px;
      //background-color: skyblue;
      
    }
    .actions{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: 100%;
      .bt_assign_to{
        min-width: 28vw;
        height: 8vw;
        margin-top: 3vw;
        p{
          font-size: 3.3vw!important;
          padding-left: 4vw;
          padding-right: 4vw;
          padding-top: 1.2vw;
          padding-bottom: 1.2vw;
        }
      }
    }

    &.no-image{
      .top{
        .texts{
          width: calc(100% - 37px);
        }
      }
      .bottom{
          width: 100%;
      }
      
    }
  }

  @media ${breakpoints.xs} {
    min-height: 210px;
    .delete_overlay{
      .inner_overlay{
        padding-left: 25px;
        padding-right: 25px;
        .actions{
          margin-top: 15px;
          .loader{
            display: flex;
            .MuiCircularProgress-root{
              width: 40px!important;
              height: 40px!important;
            }
            svg{
              color: ${COLORS.lightgold};
              width: 40px;
              height: 40px;
            }
          }
        }
        p{
          font-size: 16px;
          margin: 0;
        }
        p.username{
          margin: 0;
          margin-top: 5px;
          font-weight: 700;
        }
        .bt_delete_beneficiary{
          min-width: 142px;
          height: 40px;
        }
      }
    }
    .inner{
      padding: 15px;
      .top{
        .more_actions{
          right: 7px;
          top: 7px;
          padding: 5px!important;
        }
        .thumb{
          width: 130px!important;
          height: 130px!important;
          margin-right: 10px;
        }
        .info{
          width: calc(100% - 195px);
          .files{
            font-size: 15px;
          }
          .texts{
            .name{
              font-size: 18px;
            }
            .address{
              font-size: 15px;
              line-height: 19px;
              margin-top: 3px;
            }
            .category{
              font-size: 15px;
              line-height: 19px;
            }
            .description{
              font-size: 13px;
              line-height: 19px;
              margin-top: 6px;
              margin-bottom: 15px;
            }
          }
        }
      }
      .bottom{
        margin-top: 13px;
      }
      .actions{
        .bt_assign_to{
          min-width: 142px;
          height: 40px;
          margin-top: 5px;
          p{
            font-size: 16px!important;
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 7px;
            padding-bottom: 7px;
          }
        }
      }

      &.no-image{
        .top{
          .texts{
            width: calc(100% - 37px);
          }
        }
      }
    }

  }
  @media ${breakpoints.sm} {}
  @media ${breakpoints.md} {
    //width: 100%;
  }
  @media ${breakpoints.lg} {}
  @media ${breakpoints.xl} {}
  @media ${breakpoints.xxl} {}
  @media ${breakpoints.xxxl} {}
  @media ${breakpoints.xxxxl} {
    //width: calc(100% / 3);
    width: 100%;
  }
`
const ButtonClose = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 25px;
    cursor: pointer;
    right: 13px;
    top: 13px;
    z-index: 9;
    .icon{
        display: flex;
        svg{
          width: 35px;
          height: 35px;
          fill: ${COLORS.whiteTwo};
        }
    }
    :hover{
        .icon{
            svg{
                fill: ${COLORS.lightgold};
            }
        }
    }
`
const NoAssets = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  //background-color: pink;
  .inner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - 0px);
    background-color: #FFFFFF;
    border-radius: 16px;
    .inner_container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding-top: 8vw;
      padding-bottom: 8vw;
      .image{
        width: 50vw;
        img{
          float: left;
          width: 100%;
        }
      }
      .title{
        font-size: 5.5vw;
        font-weight: 600;
        color: ${COLORS.darkgrey};
        margin-top: 3vw;
      }
      .description{
        font-size: 3.8vw;
        color: ${COLORS.cloudyblue};
        text-align: center;
        margin-top: 2vw;
      }
      .bt_add_asset{
        margin-top: 6vw;
      }
    }
  }

  @media ${breakpoints.xs} {
    height: 100%;
    .inner{
      justify-content: center;
      align-items: center;
      .inner_container{
        width: 400px;
        padding-top: 40px;
        padding-bottom: 40px;
        .image{
          width: 200px;
        }
        .title{
          font-size: 24px;
          margin-top: 15px;
        }
        .description{
          font-size: 16px;
          margin-top: 10px;
        }
        .bt_add_asset{
          margin-top: 30px;
        }
      }
    }
  }
  @media ${breakpoints.sm} {}
  @media ${breakpoints.md} {}
  @media ${breakpoints.lg} {}
  @media ${breakpoints.xl} {}
  @media ${breakpoints.xxl} {}
  @media ${breakpoints.xxxl} {}
  @media ${breakpoints.xxxxl} {}

`

const MenuAssetsStyled = styled(Menu)`
  ul{
    li{
      &.selected{
        background-color: ${COLORS.lightgold};
        color: #FFFFFF;
      }
    }
  }
`

const MyAssetsContainer = (props) => {

  const { t } = useTranslation();

  const { userData,
          isTheVaultOpen,
          setOverlayVaultOpen,
          setOverlayAssetsLimitReach } = useContext(AuthContext);

  const { setAssets, 
          assets, 
          deleteAsset, 
          getFile, 
          getFileByType } = useContext(ContentContext);

  const history = useHistory();

  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ menuAssetsEl, setMenuAssetsEl ] = useState(null);
  const [ selectedAsset, setSelectedAsset ] = useState(null);
  const [ selectedAssetToDelete, setSelectedAssetToDelete ] = useState(null);
  const [ deleteElementStatus, setDeleteElementStatus ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(null);
  const [ filter, setFilter ] = useState('all');
  const [ showOverlayExtraDocuments, setShowOverlayExtraDocuments ] = useState(false);
  const [ showImage, setShowImage ] = useState(null);
  const [ noImage, setNoImage ] = useState(null);
  const [ loading, setLoading ] = useState("");
  const [ assetsLimitReach, setAssetsLimitReach ] = useState(null);

  useEffect(() => {

    if(userData && assets){
      let limit_reach = false;
      console.log("userData.subscription.plan.assets", userData.subscription.plan.assets)
      console.log("assets", assets.length)
      if(assets.length >= userData.subscription.plan.assets){
        limit_reach = true;
      }
      setAssetsLimitReach(limit_reach);
    }
    
  
    return () => {}
  }, [userData, assets])
  
  const handleAddNewAsset = () => {
    if(!isTheVaultOpen){
      if(!assetsLimitReach){
        history.push('/my-assets/add');
      }else{
        setOverlayAssetsLimitReach(true);
      }
    }else{
      setOverlayVaultOpen(true);
    }
  }
 
  const handleMenuClick = useCallback((event, asset, i) => {
    console.log(asset);
    setSelectedAsset(asset);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAsset(null);
  };

  const handleMenuAssetsMobileClick = useCallback((event) => {
    setMenuAssetsEl(event.currentTarget);
  }, []);

  const handleCloseAssetsMenu = () => {
    setMenuAssetsEl(null);
  };

  const handleEdit = () => {
    handleClose();
    if(!isTheVaultOpen){
      goToEdit(selectedAsset.id);
    }else{
      setOverlayVaultOpen(true);
    }
  }
  const goToEdit = (asset_id) => {
    history.push('/my-assets/edit/'+asset_id);
  }

  const handleDelete = () => {

    handleClose();
    if(!isTheVaultOpen){
      setSelectedAssetToDelete(selectedAsset);
      setDeleteElementStatus(selectedAsset.id)
    }else{
      setOverlayVaultOpen(true);
    }

  }

  const handleCloseDeleteAsset = () => {
    
    setDeleteElementStatus(null);
    setSelectedAsset(null);
    setAnchorEl(null);
    setLoading("");
    
  }

  const handleDeleteAsset = () => {
    
    const filteredAssets = assets.filter(asset => asset.id != selectedAssetToDelete.id);
    const result = deleteAsset(selectedAssetToDelete.id);
    setLoading("delete_asset");
    
    result.then(res => {

      let message = '';
      let responseType = '';
      let errors = [];

      if(res.success === true){

          setAssets(filteredAssets);
          message = 'The Asset has been deleted';
          responseType = 'success';
          
      }else{

          responseType = 'error';
          errors.push('An error has occured. Please try again later');
      }

      toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
      });

      setLoading("");
      
    })

  }

  const handleFilesAttachedClick = useCallback((event, asset) => {
    setSelectedAsset(asset);
    setShowOverlayExtraDocuments(true);
  }, []);


  
  const handleShowImage = (image, noimage) => {

    setNoImage(noimage)
    setShowImage(image);

  }
  

  return (
    <Wrapper>

      {showImage &&
        <OverlayImage image={showImage} setShowImage={setShowImage} noImage={noImage} setNoImage={setNoImage} />
      }

      <MenuAssetsStyled
        id="simple-menu"
        className="menu_assets"
        anchorEl={menuAssetsEl}
        keepMounted
        open={Boolean(menuAssetsEl)}
        onClose={handleCloseAssetsMenu}
      >
        <MenuItem onClick={() => { setFilter('all'); handleCloseAssetsMenu()} } className={`${filter === "all" ? "selected" : ""}`}>All</MenuItem>
        <MenuItem onClick={() => { setFilter('assigned'); handleCloseAssetsMenu()} } className={`${filter === "assigned" ? "selected" : ""}`}>Assigned</MenuItem>
        <MenuItem onClick={() => { setFilter('not_assigned'); handleCloseAssetsMenu()} } className={`${filter === "not_assigned" ? "selected" : ""}`}>Not Assigned</MenuItem>
      </MenuAssetsStyled>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>

      {showOverlayExtraDocuments &&
        <OverlayExtraDocuments assetId={selectedAsset.id} setShowOverlayExtraDocuments={setShowOverlayExtraDocuments} type="edit" asset={selectedAsset} />
      }

      <Container>

        {isLoading != null && isLoading == true ?
          <div className="loader">
            <CircularProgress size={40} />
          </div>
          :
          assets && assets.length ?
          <>
            <BoxContainer className="box_header_actions">
              <div className="inner">
                <ButtonSolid
                  style_type="tab"
                  className="bt_add_new_asset"
                  onClick={handleAddNewAsset}
                  override_css={null}>
                  <p>{t(`buttons.add_new_asset`)}</p>
                </ButtonSolid>

                <div className="menu_assets_desktop">
                  <ButtonSolid
                    style_type="invert" 
                    className={`bt_all ${filter == 'all' ? 'selected' : ''}`}
                    onClick={() => setFilter('all')}
                    override_css={{
                                  color:{normal: "#000000", hover: COLORS.darkgold},
                                  icon:{width: "18px", height: "18px"},
                                }}
                  >
                    <p>{t(`buttons.all`)}</p>
                  </ButtonSolid>
                  <ButtonSolid 
                    style_type="invert" 
                    className={`bt_assigned ${filter == 'assigned' ? 'selected' : ''}`}
                    onClick={() => setFilter('assigned')}
                    override_css={{
                      color:{normal: "#000000", hover: COLORS.darkgold},
                      icon:{width: "18px", height: "18px"},
                    }}
                  >
                    <p>{t(`buttons.assigned`)}</p>
                  </ButtonSolid>
                  <ButtonSolid 
                    style_type="invert" 
                    className={`bt_not_assigned ${filter == 'not_assigned' ? 'selected' : ''}`}
                    onClick={() => setFilter('not_assigned')}
                    override_css={{
                      color:{normal: "#000000", hover: COLORS.darkgold},
                      icon:{width: "18px", height: "18px"},
                    }}
                  >
                    <p>{t(`buttons.not_assigned`)}</p>
                  </ButtonSolid>
                </div>

                <div className="menu_assets_mobile">
                  <IconButton className="more_actions" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleMenuAssetsMobileClick(event)}>
                    <GiHamburgerMenu className="ico_hamburger" />
                  </IconButton>
                </div>

              </div>
            </BoxContainer>

            <MyAssets>
              {/*
              <ButtonAddAsset>
                <div className="inner">
                  <div className="texts">
                    <div className="ico">
                      <IcoPlus className="ico_plus"/>
                    </div>
                    <p className="text">Create a new Asset</p>
                  </div>
                  <ButtonSolid
                    style_type="default"
                    className="bt_add_new_asset"
                    onClick={handleAddNewAsset}
                    override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}>
                    <p>Add new</p>
                  </ButtonSolid>
                </div>
              </ButtonAddAsset>
              */}
              
              <Assets>
                {
                  assets ?
                    assets.length ?
                      assets.map((asset, i) => {
                        console.log("asset", asset)
                        return(
                          <Asset key={'asset' + i} className={`${(filter == 'all' || (filter == 'assigned' && asset.is_assigned == true) || (filter == 'not_assigned' && asset.is_assigned == false)) ? 'show' : 'hide' } ${(i > 0 && (i+1) % 3 == 0) ? " nomargin" : ""}`}>
                            {deleteElementStatus == asset.id ?
                              <div className="delete_overlay">
                                <div className="inner_overlay">

                                  <ButtonClose
                                      onClick={() => handleCloseDeleteAsset()}
                                  >
                                      <div className="icon">
                                        <IoIosCloseCircleOutline />
                                      </div>
                                  </ButtonClose>

                                  <p>{t(`alerts.you_wish_delete`)}</p>
                                  <p className="username">{`${selectedAssetToDelete.name}`}</p>

                                  <div className="actions">
                                    {loading === "delete_asset" ? 
                                    <div className="loader">
                                      <CircularProgress />
                                    </div>
                                    :
                                    <ButtonSolid
                                      className="bt_delete_beneficiary"
                                      onClick={() => handleDeleteAsset()}
                                      style_type="tab"
                                      override_css={{
                                        color:{normal: "#000000", hover: COLORS.darkgold},
                                        background:{normal: COLORS.lightgold, hover: "#000000"},
                                      }}
                                    >
                                      <p>{t(`buttons.confirm`)}</p>
                                    </ButtonSolid>
                                    }
                                  </div>
                                  
                                </div>
                              </div>
                              :<></>
                            }

                            <div className={`${'inner' + (!asset.image ? ' ' : '' )}`}>

                              <div className="top">
                                
                                {asset.thumb ? 
                                  <ThumbBox className="thumb" css={null} onClick={() => handleShowImage(asset.image_file, false)}>
                                    <Image filename={asset.thumb_file} type={"image_asset"} showMagnify={true} />
                                  </ThumbBox>
                                  :
                                  <ThumbBox className="thumb" css={null} onClick={() => handleShowImage(asset.category.description, true)}>
                                    <NoImage type={asset.category.description} showMagnify={true} />
                                  </ThumbBox>
                                }

                                <IconButton className="more_actions" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleMenuClick(event, asset, i)}>
                                  <MoreVertIcon />
                                </IconButton>
                                
                                <div className="info">
                                  <div className="texts">
                                    <div className="name">
                                      {asset.name}
                                    </div>
                                    <div className="category">
                                      {t(`labels.category`)} {asset.category.description}
                                    </div>
                                    <div className="description">
                                      {asset.description}
                                    </div>
                                    {asset.data ?
                                      <div className="address">
                                        {asset.data.street + ", " + asset.data.state.description + ", " + asset.data.city + ", " + asset.data.zip}
                                      </div>
                                    :
                                      <></>
                                    }
                                  </div>
                                  <div className="files" onClick={(event) => handleFilesAttachedClick(event, asset)}>
                                    {t(`labels.files_attached`)}: <span className="number">{asset.files.length}</span>
                                  </div>
                                </div>
                              </div>

                              <div className="actions">
                                <ButtonSolid
                                  style_type="invert"
                                  className="bt_assign_to"/*notified*/
                                  onClick={() => history.push('/my-will/'+asset.id)}
                                  override_css={{
                                    color:{normal: "#000000", hover: COLORS.darkgold},
                                    icon:{width: "18px", height: "18px"},
                                  }}>
                                  <p>{`${asset.is_assigned ? t('buttons.assigned_to') : t('buttons.assign_to')}`}</p>
                                </ButtonSolid>
                              </div>
                              
                            </div>
                          </Asset>
                        )
                      })
                    :<></>
                  :<></>
                }
              </Assets>

            </MyAssets>
          </>
        :
        <NoAssets>
          <div className="inner">
            <div className="inner_container">
              <div className="image">
                <img src={noasset} />
              </div>
              <div className="title">{t(`alerts.no_asset_yet`, { name: "Asset" })}</div>
              <div className="description">{t(`basics.add_new_asset`)}</div>
              <ButtonSolid
                style_type="default"
                className="bt_add_asset"
                onClick={handleAddNewAsset}
                override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}>
                <p>{t(`buttons.add_new_asset`)}</p>
              </ButtonSolid>
            </div>
          </div>
        </NoAssets>
        }
      </Container>
    </Wrapper>
  )
}

export default MyAssetsContainer
