import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import { useHistory } from 'react-router';
import PartiesItem from '../../../components/Elements/Parties/Item';
import { breakpoints } from '../../../helpers/breakpoints';
import Tooltip from '@mui/material/Tooltip';
import Chip from '../Chip';

const Wrapper = styled.div`
    width: 100%;
    .inner_parties{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }
    .party_element{
        width: 100%!important;
        box-sizing: border-box;
        padding: 5px 5px 5px 5px;
        border-radius: 10px;
        border: 1px solid ${COLORS.whiteThree};
        display: flex;
        flex-direction: column;
        cursor: pointer;
        :hover{
        border: 1px solid ${COLORS.lightgold};
        }
    }
    .have_more_parties_container{
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        &.have_more_parties_inner{
            display: none;
            align-items: flex-start;
        }
        &.have_more_parties_outer{
            display: flex;
            align-items: center;
            margin-top: 3vw;
        }
        .have_more_parties{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-color: #FFFFFF;
            border: 2px solid ${COLORS.lightgold};
            margin-top: 2px;
            border-radius: 31px;
            width: 50px;
            height: 50px;
            cursor: pointer;
            p{
                font-size: 20px;
                line-height: 20px;
                font-weight: 600;
                padding: 0;
                margin: 0;
                color: ${COLORS.lightgold};
            }
            :hover{
                background-color: ${COLORS.lightgold};
                p{
                color: #FFFFFF;
                }
            }
        }
    }

    @media ${breakpoints.xs} {
        .inner_parties{
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 10px;
            grid-row-gap: 10px;
        }
        .have_more_parties_container{
            &.have_more_parties_inner{
                display: none;
            }
            &.have_more_parties_outer{
                display: flex;
                margin-top: 15px;
            }
        }
    }
    @media ${breakpoints.sm} {
        .inner_parties{
            grid-template-columns: repeat(2, 1fr);
        }
        .have_more_parties_container{
            &.have_more_parties_inner{
                display: flex;
            }
            &.have_more_parties_outer{
                display: none;
            }
        }
    }
    @media ${breakpoints.md} {
        .inner_parties{
            grid-template-columns: repeat(3, 1fr);
        }
        .have_more_parties_container{
            &.have_more_parties_inner{
                display: none;
            }
            &.have_more_parties_outer{
                display: flex;
            }
        }
    }
    @media ${breakpoints.lg} {
    }
    @media ${breakpoints.xl} {
        
    }
    @media ${breakpoints.xxl} {
        .have_more_parties_container{
            &.have_more_parties_inner{
                display: flex;
            }
            &.have_more_parties_outer{
                display: none;
            }
        }
        .inner_parties{
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {
        .inner_parties{
            grid-template-columns: repeat(5, 1fr);
        }
    }

`

const Parties = (props) => {
    
    const history = useHistory();

    const { parties } = props;
    const [ rest, setRest ] = useState(0);

    const limit = 9;

    useEffect(() => {
      
        if(parties.length){
            setRest(parties.length - limit);
        }
    
      return () => {}
    }, [parties])
    
    const partiesNames = (parties) => {
        let names = [];
        
        for(let i = limit; i < parties.length; i++){
            names.push(<div>{`${parties[i].first_name} ${parties[i].middle_name ? parties[i].middle_name : ''} ${parties[i].last_name}`}</div>);
        }
        
        return names;
    }

    return (
        <Wrapper>
            <div className="inner_parties">
            {parties ?
                parties.length ?
                    <>
                    {
                    parties.map((party, i) => {
                        if(i < limit){
                            return(
                                <div className="party_element"><PartiesItem key={"party"+i} onClick={() => history.push('/parties')} index={i} image={party.profile_image} gender={party.gender} name={`${party.first_name} ${party.middle_name ? party.middle_name : ''} ${party.last_name}`} title={party.title.description} /></div>
                            )
                        }
                    })
                    }

                
                    {parties.length > limit ? 
                        
                        <div className="have_more_parties_container have_more_parties_inner">
                            <Tooltip title={partiesNames(parties)} arrow placement="top" enterTouchDelay={0}>
                                <div className="have_more_parties" onClick={() => history.push('/parties')}><p>{`+${rest}`}</p></div>
                            </Tooltip>
                        </div>
                       
                    : <></>}
                    </>

                :<></>
            :<></>}
            </div>
            
            {parties.length > limit ? 
                        
                <div className="have_more_parties_container have_more_parties_outer">
                    <Tooltip title={partiesNames(parties)} arrow placement="top" enterTouchDelay={0}>
                        <div className="have_more_parties" onClick={() => history.push('/parties')}><p>{`+${rest}`}</p></div>
                    </Tooltip>
                </div>
                
            : <></>}
            
        </Wrapper>
    )
}

export default Parties