import React, { useState, useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';

import userMaleImageProfile from '../../assets/images/user_male3.svg';
import userFemaleImageProfile from '../../assets/images/user_female3.svg';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    img{
        float: left;
        height: 100%;
    }
`

export const ImageProfile = (props) => {

    const { profile_image, gender, className } = props;
    return (
        <Wrapper className={className}>
            {profile_image ? 
                <img src={profile_image ? profile_image : ''}/>
            :
                <img src={gender == 1 ? userMaleImageProfile : userFemaleImageProfile} />
            }
        </Wrapper>
    )
}
export default React.memo(ImageProfile);