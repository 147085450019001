import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { COLORS,
         IcoErrorOutline } from '../../../helpers/constants';

import { AiFillEyeInvisible,
         AiFillEye } from "react-icons/ai";

import parse from 'html-react-parser';
import Tooltip from '@mui/material/Tooltip';

const Wrapper = styled.div`
    
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    
    &.error{
        input{
            border-color: red;
        }
    }

    input{
        display: flex;
        width: 100%;
        background-color: ${props => props.st.input.backgroundColor};
        padding: 13px;
        font-size: ${props => props.st.input.fontSize};
        font-weight: ${props => props.st.input.fontWeight};
        color: ${props => props.st.input.color};
        border: 1px solid #F5F5F5;
        border-radius: 5px;
        font-family: 'Poppins';
        box-sizing: border-box;
        :focus {
            outline: none;
        }
        *:focus {
            outline: none;
        }
        :disabled{
            cursor: not-allowed;
            color: rgba(117, 132, 165, .55);
            background-color: transparent;//rgba(245, 245, 245, .5);
        }
    }
    
    label{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: ${props => props.st.label.fontSize};
        font-weight: ${props => props.st.label.fontWeight};
        color: ${props => props.st.label.color};
        margin-bottom: 4px;
    }

    .password_show{
        right: 10px;
        top: 35px;
        position: absolute;
        font-size: 30px;
        color: white;
        cursor: pointer;
        svg{
            fill: ${COLORS.darkgrey};
        }
        :hover{
            svg{
                fill: ${COLORS.lightgold};
            }
        }
    }

    .pass_help{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        width: 15px;
        height: 15px;
        border-radius: 10px;
        background-color: rgba(0,0,0,0.5);
        font-size: 11px;
        line-height: 11px;
        font-weight: 700;
        margin-right: 5px;
    }

`

const InputContainer = ( props ) => {

    const { t } = useTranslation();
    
    const { className,
            override_css,
            style_type,
            label,
            elName,
            onChange,
            inputRef,
            type,
            value,
            disabled,
            helper = null,
            placeholder = "" } = props;

    const [ styles, setStyle ] = useState(null);
    const [ passwordShow, setPasswordShow ] = useState(false);

    const handleShowPassword = () => {

    }
    
    useEffect(() => {

        switch (style_type) {
            case 'default':
                setStyle({

                    input: { color: override_css?.input?.color ? override_css.input.color : COLORS.bluegrey,
                             fontSize: override_css?.input?.fontSize ? override_css.input.fontSize : "16px",
                             fontWeight: override_css?.input?.fontWeight ? override_css.input.fontWeight : "500",
                             backgroundColor: override_css?.input?.backgroundColor ? override_css.input.backgroundColor : COLORS.whiteTwo },

                    label: { fontSize: override_css?.label?.fontSize ? override_css.label.fontSize : "15px",
                             fontWeight: override_css?.label?.fontWeight ? override_css.label.fontWeight : "600",
                             color: override_css?.label?.color ? override_css.label.color : COLORS.darkgrey},

                });
                break;
        
            default:
                break;
        }

    }, []);

    return (
        <>
        {styles &&
        <Wrapper override={override_css} st={styles} className={className}>
            {label.length > 0 &&
                <Fragment>
                    <label htmlFor={elName}>
                        { type == 'password' && helper != null &&
                            <Tooltip title={parse(helper)} arrow placement="top-end" enterTouchDelay={0}>
                                <div className="pass_help">?</div>
                            </Tooltip> 
                        }
                        {label}
                    </label>
                    
                </Fragment>
            }
            { type == 'password' && passwordShow != null ? 
                <div className="password_show" onClick={() => setPasswordShow(!passwordShow)}>
                    { passwordShow ? <AiFillEyeInvisible /> : <AiFillEye /> }
                </div>
            : '' }
            
            
            <input
                ref={el => inputRef.current[elName] = el}
                type={type == 'password' ? passwordShow ? 'text' : 'password' : type }
                id={elName}
                name={elName}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(e) => onChange(elName, e.target.value)}
            />
            {/*<IcoErrorOutline className="ico_error" />*/}
        </Wrapper>
        }
        </>
    )
}

export default InputContainer
