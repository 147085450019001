import React, { useState, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../helpers/breakpoints';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonSolid from '../Buttons/Solid';
import FormGetQuote from './../Plans/FormGetQuote';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IcoCameraSolid, 
         IcoBack,
         COLORS } from '../../../helpers/constants';

import { useHistory, useLocation } from 'react-router-dom';

import { Elements, } from '@stripe/react-stripe-js';

import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from '../../Subscription/CheckoutForm';
import OverlayAction from '../../Elements/OverlayAction';

import useMediaQuery from '@mui/material/useMediaQuery';
import { AiOutlineCheckCircle } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { TfiClose } from "react-icons/tfi";

import CustomToastMessageError from '../../Elements/CustomToastMessageError';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';


import { ContentContext,
         AuthContext } from '../../../context';


const DialogStyled = styled(Dialog)`
    &.MuiDialog-root{
        
        .inner{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            .congratulation_message{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: 100px;
                font-size: 18px;
                text-align: center;
            }
            .resume_plan_aclaration{
                margin-top: 10px;
                border: 1px dashed #FFFFFF;
                border-radius: 6px;
                box-sizing: border-box;
                padding: 10px 10px 10px 10px;
                text-align: center;
                background-color: ${COLORS.red};
                color: #FFFFFF;
                font-size: 18px;
                font-weight: 600;
            }
            .bt_close{
                position: absolute;
                right: 20px;
                top: 20px;
                font-size: 35px;
                line-height: 35px;
                cursor: pointer;
                color: ${COLORS.darkgrey};
                :hover{
                    color: ${COLORS.lightgold};
                }
            }
            .inner_quote,
            .inner_plans{
                >.title{
                    font-size: 24px;
                    margin-bottom: 20px;
                    width: calc(100% - 50px);
                    .highlight{
                        font-weight: 600;
                    }
                }
            }
            .inner_quote{
                >.title{
                    font-size: 19px;
                    .highlight{
                        font-size: 24px;
                    }
                }
            }
            
            .box_plans{
                /*
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                */
                display: ${props => props.planSelected == "" ? "grid" : "flex"};
                flex-direction: ${props => props.planSelected == "" ? "none" : "column"};
                width: 100%;
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 10px;

                >.loader{
                    width: 100%;
                    min-height: 150px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    svg{
                        color: ${COLORS.lightgold};
                    }
                }
                .box_plan{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;
                    overflow: hidden;
                    border: 1px solid ${COLORS.whiteFour};
                    border-radius: 6px;
                    width: 100%;
                    min-height: 100%;
                    box-sizing: border-box;
                    padding: 15px 20px 25px 20px;
                    &.hide{
                        display: none;
                    }
                    .top{
                        margin-bottom: 0;
                        .plan_name{
                            font-weight: 500;
                            font-size: 23px;
                            line-height: 23px;
                            margin-bottom: 15px;
                            color: ${COLORS.bluegrey};
                        }
                        .title{
                            font-size: 20px;
                            line-height: 20px;
                            font-weight: 600;
                            color: ${COLORS.darkgrey};
                            margin-bottom: 15px;
                        }

                        .description{
                            .text{
                                color: ${COLORS.bluegrey};
                            }
                            ul.items{
                                list-style: none;
                                padding: 0;
                                margin 20px 0 0 0;
                                li{
                                    display: flex;
                                    flex-direction: row;
                                    align-items: flex-start;
                                    margin: 10px 0 10px 0;
                                    .icon{
                                        display: flex;
                                        color: ${COLORS.lightgold};
                                        font-size: 25px;
                                        line-height: 25px;
                                    }
                                    .text{
                                        margin-left: 5px;
                                        color: ${COLORS.darkgrey};
                                    }
                                    :last-child{
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                        .price{
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: flex-end;
                            margin-bottom: 15px;
                            .value{
                                font-size: 50px;
                                line-height: 50px;
                                font-weight: 600;
                                color: ${COLORS.darkgrey};
                            }
                            .recurrence{
                                font-size: 15px;
                                line-height: 15px;
                                margin-bottom: 7px;
                                margin-left: 7px;
                                font-weight: 400;
                                color: ${COLORS.bluegrey};
                            }
                        }
                        .no_credit_card_needed{
                            font-size: 15px;
                            line-height: 15px;
                            margin-bottom: 7px;
                            margin-left: 7px;
                            font-weight: 400;
                            color: ${COLORS.darkgrey};
                        }
                    }

                    .bottom{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        
                        .actions{
                            margin-top: 30px;
                            .bt_subscribe,
                            .bt_changeplan,
                            .bt_delete{
                                width: 100%;
                            }
                            .actual_plan{
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 40px;
                                font-size: 16px;
                                font-weight: 600;
                                color: #FFFFFF;
                                border-radius: 6px;
                                border: 1px solid ${COLORS.lightgold};
                                background-color: ${COLORS.lightgold};
                            }
                        }
                    }
                    :hover{
                        border: 1px solid ${COLORS.lightgold};
                    }

                }
            }
            .free_plan_aclaration{
                margin-top: 20px;
                font-size: 14px;
                text-align: center;
            }
        }
        .payment_form{
            border: 1px solid ${COLORS.whiteFour};
            border-radius: 6px;
            width: 100%;
            box-sizing: border-box;
            padding: 25px 20px 25px 20px;
        }
    }

    @media ${breakpoints.xs} {
    }
    @media ${breakpoints.sm} {
        .inner{
            .box_plans{
                grid-template-columns: repeat(2, 1fr)!important;
                grid-gap: 10px;
                .box_plan{

                }
            }
        }
        
    }
    @media ${breakpoints.md} {
        .inner{
            .box_plans{
                flex-direction: ${props => props.planSelected == "" ? "none" : "row"}!important;
                .box_plan{
                    width: ${props => props.planSelected == "" ? "100%" : "400px"}!important;
                }
            }
        }
    }
    @media ${breakpoints.lg} {
        .inner{
            .box_plans{
                grid-template-columns: repeat(4, 1fr)!important;
                grid-gap: 10px;
            }
        }
    }
    @media ${breakpoints.xl} {
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}
    
`
const ButtonStyled = styled.button`

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: ${COLORS.red};
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid ${COLORS.red};

    p{

        margin: 0;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.38px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        
    }

    :hover:enabled{

        background-color: ${COLORS.darkgrey};
        border: 1px solid ${COLORS.darkgrey};

        p{
            color: #FFFFFF;
        }
        :disabled{
            p{
                //color: pink;
            }
        }
    }



    .icon{
    }

    :disabled{
        opacity: .1;
        cursor: default;
        //background-color: ${COLORS.lightgrey};
        //border-color: ${COLORS.lightgrey};
        p{
            //color: ${COLORS.bluegrey}!important;
        }
    }

    @media ${breakpoints.xs} {
    }
    @media ${breakpoints.sm} {
    }
    @media ${breakpoints.md} {
    }
    @media ${breakpoints.lg} {
    }
    @media ${breakpoints.xl} {
    }
    @media ${breakpoints.xxl} {
        
    }
    @media ${breakpoints.xxxl} {}
    @media ${breakpoints.xxxxl} {}


`

const stripePromise = loadStripe('pk_test_51JE6zZIls69beLtuDxRuEWnGDIpUzoa2Ob4sBsMwcEAGRuA6UYPrl3Frnf09E2NgsD5BUhSmQ270ykNV5KuoZY43009EsEuPpI');

const ModalPlans = (props) => {

    const { t } = useTranslation();
    const { search, pathname } = useLocation();
    
    const { open, setOpen, callback = null } = props;
    const [ planSelected, setPlanSelected ] = useState("");
    const [ plans, setPlans ] = useState(null);
    const [ isSubscribed, setIsSubscribed ] = useState(null);
    const [ isSubscriptionEnding, setIsSubscriptionEnding ] = useState(null);
    const [ changePlan, setChangePlan ] = useState(false);
    const [ loadingOverlayAction, setLoadingOverlayAction ] = useState(false);
    const [ subscribedCongrats, setSubscribedCongrats ] = useState(false);
    const [ waitSubscriptionProcess, setWaitSubscriptionProcess ] = useState(false);
    const [ showGetQuoteNow, setShowGetQuoteNow ] = useState(false);

    const fullScreen = useMediaQuery('(max-width:600px)');
    
    const { userData, 
            setUserData,
            overlayDowngradePlan,
            setOverlayDowngradePlan } = useContext(AuthContext);

    const { getPlans,
            subscriptionChange,
            assets,
            parties } = useContext(ContentContext);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const response_plans = getPlans();
        response_plans.then(res => {
            console.log("planes", res)
            setPlans(res.data);
        })

        return () => { 
            setPlanSelected("");
            toast.dismiss();
        }
    }, [])
    useEffect(() => {
        
        console.log("userData.subscription", userData.subscription)
        if(userData && userData.subscription.status){
            setIsSubscribed(userData.subscription.price_id);
            setIsSubscriptionEnding(userData.subscription.ends_at);
        }

        return () => {}
    }, [userData])

    
    
    
    const PlanPrice = (props) => {

        const { name, price } = props;

        switch (name) {
            case "Plan_free":
                return(
                    <>
                    <div className="plan_name">{ t(`plans.free_name`) }</div>
                       
                    <div className="price">
                        <span className="value">${price / 100}</span>
                        <span className="recurrence"> / { t(`plans.month`) }</span>
                    </div>

                    <div  className='no_credit_card_needed'>{ t('plans.no_credit_card_needed')}</div>
                    </>
                )
                break;
            case "Plan_1":
                return(
                    <>
                    <div className="plan_name">{ t(`plans.plan1_name`) }</div>
                    <div className="price">
                        <span className="value">${price / 100}</span>
                        <span className="recurrence"> / { t(`plans.month`) }</span>
                    </div>
                    </>
                )
                break;
            case "Plan_2":
                return(
                    <>
                    <div className="plan_name">{ t(`plans.plan2_name`) }</div>
                    <div className="price">
                        <span className="value">${price / 100}</span>
                        <span className="recurrence"> / { t(`plans.month`) }</span>
                    </div>
                    </>
                )
                break;
            case "Plan_business":
                return(
                    <>
                    <div className="plan_name">{ t(`plans.business_plan_name`) }</div>
                    
                    <div className="price">
                        <span className="value">Let’s Talk</span>
                        {/* <span className="recurrence"> / month</span> */}
                    </div>
                    
                    </>
                )
                break;
            default:
                return(
                    <div className="">nada</div>
                )
                break;
        }

    }

    const PlanDescription = (props) => {

        const { name } = props;

        switch (name) {
            case "Plan_free":
                return(
                    <div className="description">
                        {/*
                        <div className="text">
                            It is a long established fact that a reader will be distracted by the readable content
                        </div>
                        */}
                        <ul className='items'>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`plans.free_description`) }</span>
                            </li>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`plans.free_for_life`) }</span>
                            </li>
                        </ul>
                    </div>
                )
                break;
            case "Plan_1":
                return(
                    <div className="description">
                        {/*
                        <div className="text">
                            It is a long established fact that a reader will be distracted by the readable content
                        </div>
                        */}
                        <ul className='items'>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`plans.plan1_description`) }</span>
                            </li>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`buttons.cancel_anytime`) }</span>
                            </li>
                        </ul>
                    </div>
                )
                break;
            case "Plan_2":
                return(
                    <div className="description">
                        {/*
                        <div className="text">
                            It is a long established fact that a reader will be distracted by the readable content
                        </div>
                        */}
                        <ul className='items'>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`plans.plan2_description`) }</span>
                            </li>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`buttons.cancel_anytime`) }</span>
                            </li>
                        </ul>
                    </div>
                )
                break;
            case "Plan_business":
                return(
                    <div className="description">
                        {/*
                        <div className="text">
                            It is a long established fact that a reader will be distracted by the readable content
                        </div>
                        */}
                        <ul className='items'>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`plans.plan_enterprise_item1`) }</span>
                            </li>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`plans.plan_enterprise_item2`) }</span>
                            </li>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`plans.plan_enterprise_item3`) }</span>
                            </li>
                            <li>
                                <span className="icon"><AiOutlineCheckCircle /></span>
                                <span className="text">{ t(`buttons.cancel_anytime`) }</span>
                            </li>
                        </ul>
                    </div>
                )
                break;
            default:
                return(
                    <div className="">...</div>
                )
                break;
        }

    }

    const handleChangePlan = () => {
        console.log("changePlan::::::::::::::", changePlan);
        setLoadingOverlayAction(true);
        const payload = { name: changePlan.name,
                          stripe_price_month_id: changePlan.stripe_price_month_id,
                          stripe_product_id: changePlan.stripe_product_id }
        
        const response = subscriptionChange(payload);
        response.then(res => {
            console.log("ChangePlan", res);
            setLoadingOverlayAction(false);
            setIsSubscribed(changePlan.stripe_price_month_id);

            const userDataUpdate = {...userData};
            userDataUpdate.subscription.price_id = changePlan.stripe_price_month_id;
            userDataUpdate.subscription.plan.notify = changePlan.name != "Plan_free" ? true : false;
            userDataUpdate.subscription.plan.assets = changePlan.assets;
            userDataUpdate.subscription.plan.beneficiaries = changePlan.beneficiaries;
            setUserData(userDataUpdate);
            setChangePlan(null);

            if(pathname){
                if(pathname == '/settings/subscription'){
                    //callback();
                }
            }

            let message = '';
            let responseType = '';
            let errors = [];

            if(res.success === true){

                message = t(`alerts.item_changed`, { name: "subscription plan" });
                responseType = 'success';
                
            }else{

                responseType = 'error';
                errors.push(t('alerts.standar_error'));

            }

            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        })

    }

    const handleSetPlan = (plan) => {
        console.log(plan.assets +"<"+ assets.length)
        console.log(plan.beneficiaries +"<"+ parties.length)
        //return null;
        if(plan.assets < assets.length){
            setOverlayDowngradePlan({status: true, assets: plan.assets, parties: plan.beneficiaries});
            return null;
        }
        if(plan.beneficiaries < parties.length){
            setOverlayDowngradePlan({status: true, assets: plan.assets, parties: plan.beneficiaries});
            return null;
        }

        setChangePlan(plan);

    }
    

  return (
    <>
        
    
        <DialogStyled
            fullWidth={true}
            maxWidth={`${planSelected == "" ? showGetQuoteNow ? "lg" : "xl" : "lg"}`}
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            planSelected={planSelected}
        >

        
        

           <div className="inner">
            
            <>
                <div className="bt_close" onClick={handleClose}>
                    <TfiClose />
                </div>
                
                { !showGetQuoteNow ?
                    <div className='inner_plans'>
                        
                        {!subscribedCongrats &&
                        <div className="title">
                            { t(`plans.title`) }
                        </div>
                        }
                    
                        <div className={`box_plans${planSelected && planSelected.stripe_price_month_id != "" ? " plan_selected" : ""}`}>
                
                            {!subscribedCongrats ?
                            plans && plans.length > 0 ?
                                plans.map((plan, i) => {
                                    return(
                                        <div className={`box_plan${planSelected && planSelected.stripe_price_month_id != "" && planSelected.stripe_price_month_id != plan.stripe_price_month_id ? " hide" : ""}`}>
                                            {changePlan && changePlan.stripe_price_month_id == plan.stripe_price_month_id &&
                                                <OverlayAction 
                                                    message={t(`subscription.please_confirm`)}
                                                    targetChange={changePlan && changePlan.title}
                                                    onClick={handleChangePlan}
                                                    setShowOverlayAction={setChangePlan}
                                                    loading={loadingOverlayAction}
                                                    setLoading={setLoadingOverlayAction}
                                                />
                                            }
                                            <div className="top">
                                                <div className="title">{plan.title}</div>
                                                <PlanPrice name={plan.name} price={plan.price} />
                                                <PlanDescription name={plan.name} />
                                            </div>
                                            {!isSubscriptionEnding &&
                                            <div className="bottom">
                                                <div className="actions">
                                                    {isSubscribed && isSubscribed == plan.stripe_price_month_id ?
                                                        <div className="actual_plan">
                                                            { t(`plans.your_actual_plan`) }
                                                        </div>
                                                    :
                                                    plan.name == "Plan_business" ?
                                                    <ButtonSolid
                                                        //disabled={isWaiting != null && isWaiting == false ? true : false}
                                                        type="submit"
                                                        style_type="default"
                                                        className="bt_subscribe"
                                                        override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}
                                                        onClick={() => setShowGetQuoteNow(true)}
                                                    >
                                                        <p>{ t(`buttons.contact_sales`) }</p>
                                                    </ButtonSolid>
                                                    :
                                                    plan.name != "Plan_free" ?
                                                    !planSelected ? 
                                                    isSubscribed ?
                                                        <ButtonSolid
                                                            //disabled={isWaiting != null && isWaiting == false ? true : false}
                                                            type="submit"
                                                            style_type="default"
                                                            className="bt_changeplan"
                                                            override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}
                                                            onClick={() => handleSetPlan(plan)}
                                                        >
                                                            <p>{ t(`buttons.change_plan`) }</p>
                                                        </ButtonSolid>
                                                    :
                                                        <ButtonSolid
                                                            //disabled={isWaiting != null && isWaiting == false ? true : false}
                                                            type="submit"
                                                            style_type="default"
                                                            className="bt_subscribe"
                                                            override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}
                                                            onClick={() => setPlanSelected(plan)}
                                                        >
                                                            <p>{ t(`buttons.subscribe`) }</p>
                                                        </ButtonSolid>
                                                        :
                                                        <ButtonSolid
                                                            disabled={waitSubscriptionProcess}
                                                            type="submit"
                                                            style_type="default"
                                                            className="bt_subscribe"
                                                            override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}
                                                            onClick={() => setPlanSelected("")}
                                                        >
                                                            <p>{ t(`buttons.cancel`) }</p>
                                                        </ButtonSolid>
                                                        :
                                                        <></>
                                                    }
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    )
                                })
                            :
                            <div className="loader">
                                <CircularProgress size={45} />
                            </div>
                            :
                            <></>
                            }

                            

                            {!subscribedCongrats && planSelected && 

                                <div className="payment_form">
                                    <Elements stripe={stripePromise}>
                                        <CheckoutForm 
                                            callback={callback} 
                                            target={"subscribe"}
                                            planSelected={planSelected}
                                            setSubscribedCongrats={setSubscribedCongrats}
                                            setWaitSubscriptionProcess={setWaitSubscriptionProcess}
                                        />
                                    </Elements>
                                </div>  

                            }

                        </div>
                        
                        {!subscribedCongrats &&
                            <div className="free_plan_aclaration">{ t(`plans.free_plan_aclaration`) }</div>
                        }
                        {subscribedCongrats &&
                            <div className="congratulation_message">{ t(`plans.congratulation_message`) }</div>
                        }
                        {plans && plans.length > 0 && isSubscriptionEnding &&
                            <div className='resume_plan_aclaration'>Para cambiar de plan por favor primero reactiva tu actual plan y luego cambialo.</div>
                        }

                    </div>
                :
                    <div className='inner_quote'>
                        <div className="title">
                            <span className="highlight">{ t(`plans.quote_title`) }</span><br/>
                            { t(`plans.quote_information`) }
                        </div>
                        <FormGetQuote 
                            showGetQuoteNow={showGetQuoteNow} 
                            setShowGetQuoteNow={setShowGetQuoteNow} 
                        />
                    </div>
                }
                
                </>
                
                

           </div>
        </DialogStyled>
       
      </>
  );
}

export default ModalPlans;