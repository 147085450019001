import React, {useEffect} from 'react';
import styled from 'styled-components';
import { COLORS } from '../../helpers/constants';

import { BiErrorCircle,
         BiCheckCircle } from "react-icons/bi";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    .toast_notification_text{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        text-align: center;
        &.success{
            align-items: flex-start;
        }
        &.error{
            align-items: flex-start;
        }
        .icon_error,
        .icon_success{
            display: flex;
            font-size: 30px;
            color: ${COLORS.red};
        }
        .icon_success{
            color: ${COLORS.green};
        }
        .error_list{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 5px;
            p{
                margin-bottom: 5px;
            }
        }
        p{
            margin: 0;
            padding: 0;
            font-size: 16px;
            line-height: 25px;
            font-weight: 600;
            margin-left: 7px;
        }
    }
`

const CustomToastMessageError = (props) => {
    
    const { closeToast, toastProps, errors, type, message } = props;

    useEffect(() => {
        console.log(type);
        console.log(errors);
        console.log(message);
      return () => {
        
      }
    }, [errors])
    

    return (
        <Wrapper>
            
            <div className={`toast_notification_text ${type}`}>

                {type == 'error' &&
                    <>
                        <div className="icon_error">
                            <BiErrorCircle />
                        </div>
                        <div className="error_list">
                        {errors.map((error, index) => {
                            return(
                                <p>- {error}</p>
                            )
                        })}
                        </div>
                    </>
                }

                {type == 'success' &&
                    <>
                        <div className="icon_success">
                            <BiCheckCircle />
                        </div>
                        <p>- {message}</p>
                    </>
                }
                {/*
                <button>Retry</button>
                <button onClick={closeToast}>Close</button>
                */}
            </div>
        </Wrapper>
    )
}

export default CustomToastMessageError
