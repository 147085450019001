import React from 'react';
import styled from 'styled-components';
import BoxContainer from '../../components/Elements/BoxContainer';
import { COLORS,
         SIZES } from '../../helpers/constants';

const Wrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
    .inner{
        
        box-sizing: border-box;
        padding: 15px;
        width: calc(100% - 30px);
    }

`

const NotificationsContainer = (props) => {

    //const [] = props;

    return (
        <Wrapper>
            <BoxContainer className="inner">
                NotificationsContainer<br/>NotificationsContainer
            </BoxContainer>
        </Wrapper>
    )
}

export default NotificationsContainer
