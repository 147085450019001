import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';

import CountdownBeneficiaries from '../Settings/Countdown/CountdownBeneficiaries';
import ButtonSolid from '../Elements/Buttons/Solid';
import Image from '../Elements/Image';
import NoImage from '../Elements/NoImage';
import Switch from '../Elements/Form/Switch';
import ThumbBox from '../Elements/ThumbBox';
import { toast } from 'react-toastify';
import CustomToastMessageError from '../../components/Elements/CustomToastMessageError';
import { useHistory } from 'react-router';

import CircularProgress from '@material-ui/core/CircularProgress';

import { COLORS } from '../../helpers/constants';

import { ContentContext, AuthContext } from '../../context';

import parse from 'html-react-parser';
import { useTranslation, Trans } from 'react-i18next';

const Wrapper  = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(208, 178, 87, 0.55);
    background-color: rgba(0, 0, 0, 0.90);
    z-index: 2;
    //border-radius: 12px;
    svg{
        color: ${COLORS.lightgold};
    }
    .box_beneficiary_list{
        border-top: 0!important;
        margin-top: 15px;
    }
    .inner_overlay{
        
        width: 700px;
        background-color: #FFFFFF;
        border-radius: 12px;

        box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -webkit-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 4px 0px 23px 0px rgba(0,0,0,0.15);

        p{
            margin: 0;
        }
        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            .notify_setup{
                margin-top: 20px;
                background-color: ${COLORS.whiteTwo};
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 7px;
                padding-bottom: 7px;
                color: #000000;
                strong{
                    color: ${COLORS.lightgold};
                }
            }
            .actions{
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                height: 40px;
                .bt_send_notifications{
                    margin-right: 5px;
                }
                .loader{
                    margin-top: 0;
                }
            }

            .assets{
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-top: 20px;
                .asset{
                    display: flex;
                    flex-direction: row;
                    margin: 5px;
                    border-radius: 10px;
                    border: 1px solid ${COLORS.whiteFour};
                    padding: 5px 10px 5px 5px;
                    width: calc(33% - 25px);
                    .thumb{
                        width: 65px;
                        height: 65px;
                    }
                    .data{
                        //background-color: pink;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin-left: 5px;
                        width: calc(100% - 70px);
                        .title{
                            font-size: 15px;
                            line-height: 18px;
                            font-weight: 600;
                        }
                        .description{
                            font-size: 13px;
                            line-height: 15px;
                            font-weight: 500;
                        }
                        .portion{
                            font-size: 13px;
                            line-height: 15px;
                            font-weight: 600;
                            margin-top: 5px;
                            color: ${COLORS.lightgold};
                        }
                    }
                }
            }
            .loader{
                margin-top: 20px;
            }
        }
    }
    .loaderParties{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: calc(100% - 100px);
        margin-left: 100px;
        margin-top: 10px;
        min-height: 70px;
        border-radius: 10px;
        border: 1px dashed ${COLORS.bluegrey};
        .loader{
            width: 33px;
            height: 33px;
            svg{
            color: ${COLORS.bluegrey};
            }
        }
    }
    .parties{
            
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: calc(100% - 30px);
        margin-top: 15px;
        //border: 1px dashed ${COLORS.bluegrey};
        
        box-sizing: border-box;
        border-radius: 10px;
        
        &.not_edit{
            border: 0;
            .inner{
            padding: 0;
            }
        }
        .inner{
            display: flex; 
            flex-direction: row;
            //padding: 15px 15px 0 15px;
        }
        .prorated_container{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            //background-color: ${COLORS.bluegrey};
            width: 100%;
            height: 40px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            p{
            margin: 0;
            padding: 0;
            font-size: 15px;
            line-height: 19px;
            font-weight: 600;
            //color: #FFFFFF;
            color: ${COLORS.bluegrey};
            }
            .icon{
            color: ${COLORS.bluegrey};
            font-size: 25px;
            margin-right: 5px;
            }
        }

        .party{
            position: relative;
            width: 55px;
            margin-bottom: 15px;
            margin-left: 3px;
            margin-right: 3px;
            .thumb{
                display: flex;
                flex-direction: row;
                justify-content: center;
                width: 55px;
                height: 70px;
                overflow: hidden;
                border-radius: 10px;
                img{
                    float: left;
                    height: 100%;
                }
            }
        }
        }
 ` 
 const Field = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 10px;
    .hide_portion{
        width: 110px;
    }
    p{
        margin: 0;
        padding: 0;
    }
 `

const OverlayNotificateBeneficiary = (props) => {

    const { t } = useTranslation();

    const { parties,
            setParties,
            getAssetsDistributedByParty,
            sendNotificationToParty } = useContext(ContentContext);
            
    const { open, setOpen, data, loaderData, party } = props;
    const [ assets, setAssets ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoadingSender, setIsLoadingSender ] = useState(false);
    const [ hidePortion, setHidePortion ] = useState(false);

    const history = useHistory();

    let inputRef = useRef({});

    useEffect(() => {
        
        //setIsLoading(true);

    }, [])

    const handleSendNotificationToAllParties = () => {

        setIsLoadingSender(true);
        const payload = { natification_type: "all",
                          hide_portion: hidePortion }
        
        const response = sendNotificationToParty(payload);
        response.then((res) => {

            let message = '';
            let responseType = '';
            let errors = [];

            if(res.success === true){
                console.log("data", res.data)
                
                const parties_updated = parties.map((el, index) => {
                    console.log("parties_updated data", res.data)
                    for(let i = 0; i<res.data.length; i++) {
                        console.log("===> el.id == res.data[i].id", el.id +"=="+ res.data[i])
                        if(el.id == res.data[i]) {
                            el.is_notified = true;
                        }
                    }
                    console.log("---> el data", el)
                    return el;
                })

                setParties(parties_updated);
                
                message = res.message;
                responseType = 'success';
                
            }else{

                responseType = 'error';
                errors.push('An error has occured. Please try again later');

            }

            toast(<CustomToastMessageError type={responseType} errors={errors} message={message}/>, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setIsLoadingSender(false);

        })
        
    }

    if(!open){
        return null;
    }

    return (
        <Wrapper>
            <div className="inner_overlay">
                <div className="content">

                {isLoading ? 

                    <div className="loader">
                        <CircularProgress size={33} />
                    </div>

                    :
                        <>
                            <p className="">{t(`notify.title_masive`)}</p>

                            <CountdownBeneficiaries 
                                title={null}
                            />

                            <Field className="field">
                                <Switch
                                    label=""
                                    toggled={hidePortion}
                                    //setToggled={setNotifyBeneficiaryToggled}
                                    color="default"
                                    style_type="default"
                                    inputRef={inputRef}
                                    //ref={el => inputRef.current[formData?.notify_beneficiary?.ref] = el}
                                    elName="hide_portion"
                                    className="hide_portion"
                                    onChange={() => setHidePortion(!hidePortion)}
                                />
                                <p>{t(`notify.hide_portion_to_all`)}</p>
                            </Field>
                            

                            <div className="actions">
                                {isLoadingSender ? 
                                <div className="loader">
                                    <CircularProgress size={33} />
                                </div>
                                :
                                <>
                                    <ButtonSolid 
                                        type="submit" 
                                        style_type="default" 
                                        className="bt_send_notifications" 
                                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                        onClick={() => handleSendNotificationToAllParties()}
                                    >
                                        <p>{t(`buttons.send_notifications`)}</p>
                                    </ButtonSolid>
                                
                                    <ButtonSolid 
                                        type="submit" 
                                        style_type="default" 
                                        className="bt_close" 
                                        override_css={{color:{normal: "#FFFFFF", hover: COLORS.darkgrey}}}
                                        onClick={() => setOpen(!open)}
                                    >
                                        <p>{t(`buttons.close`)}</p>
                                    </ButtonSolid>
                                </>
                                }
                            </div>
                        </>
                    }
                    
                    

                </div>
            </div>
        </Wrapper>
    )
}

export default OverlayNotificateBeneficiary;