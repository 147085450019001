import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import CircularProgress from '@material-ui/core/CircularProgress';

import ButtonSolid from '../Buttons/Solid';
import Input from '../Form/Input';
import CustomToastMessageError from '../CustomToastMessageError';

import { toast } from 'react-toastify';


const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    //margin-top: 30px;
    //margin-bottom: 30px;
    .loader{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid #F5F5F5;
        height: 52px;
        border-radius: 6px;
        width: 212px;
        margin-left: 10px;
        svg{
            color: ${COLORS.lightgold};
        }
    }
    .bt_add_assignated_asset{
        height: 52px;
        margin-left: 10px;
    }
`
const Field = styled.div`
    width: 285px;
    input{
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        //background-color: pink;
        //height: 13px;
    }
`

const FindAssignedAsset = (props) => {
    /*
    const { handleDistributionByPartyCode,
            loaderAssignedAsset } = props;
    */
    
    const { assignAssetByPartyCode, setAssetsAssigned } = props;
    const [ formData, setFormData ] = useState({});
    const [ loaderAssignedAsset, setLoaderAssignedAsset ] = useState(false);

    let inputRef = useRef({});

    useEffect(() => {

        setFormData({
            assignation_code: { value: "", required: true, disabled: false, ref: "assignation_code", error: false },
        });
        
    }, [])

    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        updateFormData[name].value = value;
        setFormData(updateFormData);
        
        return null;

    }

    const handleSubmit = (e) => {

        e.preventDefault();

        let haveError = false;
        
        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};

            Object.keys(formData).map(function(key) {

                if(formData[key].required){
                    if(!formData[key].value.length){
                        haveError = true;
                        updateFormData[key].error = true;
                        
                    }else{
                        updateFormData[key].error = false;
                    }
                }

            });
            
            if(!haveError){
                handleDistributionByPartyCode(updateFormData);
            }

            setFormData(updateFormData);

        }
    }

    const handleDistributionByPartyCode = (party_code) => {
    
        setLoaderAssignedAsset(true);
        const response = assignAssetByPartyCode(party_code);
        response.then((res) => {
    
          let responseType = '';
          setLoaderAssignedAsset(false);
          if(res.success === true){
            
            resetForm();
            responseType = 'success';
            setAssetsAssigned(prev => [res.data[0], ...prev]);
              
          }else{
    
            responseType = 'error';
    
          }
    
          toast(<CustomToastMessageError type={responseType} errors={res.errors} message={res.message}/>, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
          });    
          
        })
    
    }

    const resetForm = () => {
        
        Object.keys(inputRef.current).map(function(key) {
            inputRef.current[key].value = '';
        })
        Object.keys(formData).map(function(key) {
            formData[key].value = '';
        })

    }

    return (
        <Wrapper>
            <Field className="field">
                <Input
                    className={`assignation_code ${formData?.assignation_code?.error ? "error" : ""}`}
                    isRequired={"true"}
                    style_type="default"
                    type="text"
                    label="Assignation Code:"
                    inputRef={inputRef}
                    elName={formData?.assignation_code?.ref}
                    value={formData?.assignation_code?.value}
                    onChange={handleFormElementChange}
                />
            </Field>
            {loaderAssignedAsset ?
            <div className="loader">
                <CircularProgress size={30} />
            </div>
            :
            <ButtonSolid
                style_type="default"
                className="bt_add_assignated_asset"
                onClick={handleSubmit/*handleDistributionByPartyCode('AAAA1111')*/}
                override_css={{color:{normal: COLORS.darkgold, hover: "#000000"}}}>
                <p>Find Distribution Data</p>
            </ButtonSolid>
            }
        </Wrapper>
    )
}

export default FindAssignedAsset
