import React, { useState,
                useCallback,
                useContext,
                useEffect } from 'react';

import styled from 'styled-components';
import { COLORS } from '../../../helpers/constants';
import { breakpoints } from '../../../helpers/breakpoints';
import CloseIcon from '@material-ui/icons/Close';
import { TfiClose } from "react-icons/tfi";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const DialogStyled = styled(Dialog)`
    .inner{
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 15px 15px 25px 15px;
        position: relative;

        .bt_close{
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 27px;
            line-height: 27px;
            cursor: pointer;
            color: ${COLORS.darkgrey};
            :hover{
                color: ${COLORS.lightgold};
            }
        }
        .title{
            font-size: 22px;
            line-height: 22px;
            padding-bottom: 15px;
            margin-top: 5px;
            margin-bottom: 20px;
            border-bottom: 1px solid ${COLORS.whiteFour}
        }
        .field{
            display: flex;
            flex-direction: row;
            margin-bottom: 15px;
            .label{
                width: 130px;
                font-weight: 600;
            }
            .value{
                font-size: 15px;
            }
            :last-child{
                margin-bottom: 0;
            }
        }
    }
`



const ModalBillingDetails = (props) => {


    /*
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    */

    //const [open, setOpen] = React.useState(false);

    const { open, setOpen, content } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
      
        console.log("content", content)
    
      return () => { }
    }, [content])
    


    return (

        <div>
            <DialogStyled
                fullScreen={fullScreen}
                fullWidth={true}
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                aria-labelledby="responsive-dialog-title"
            >
                <div className="inner">

                    <div className="bt_close" onClick={handleClose}>
                        <TfiClose />
                    </div>

                    <div className="title">Billing Details</div>

                    
                        <div className="field">
                            <div className="label">Name:</div>
                            <div className="value">{content.billing_details.name}</div>
                        </div>
                        <div className="field">
                            <div className="label">Address:</div>
                            <div className="value">{`${content.billing_details.address.line1} ${content.billing_details.address.line2 && " " + content.billing_details.address.line2}, ${content.billing_details.address.city}, ${content.billing_details.address.state} ${content.billing_details.address.postal_code} ${content.billing_details.address.country}`}</div>
                        </div>
                        <div className="field">
                            <div className="label">Phone:</div>
                            <div className="value">{content.billing_details.phone}</div>
                        </div>
                    
                </div>
                {/*
                <DialogTitle id="responsive-dialog-title">
                    {"Billing Details"}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque suscipit, justo vitae suscipit aliquam, nunc dolor tempus tellus, at interdum sem tellus sit amet ipsum. Nulla sollicitudin metus ac eros vehicula, in egestas urna elementum. Praesent iaculis dolor aliquam, efficitur ligula et, pretium eros. Nunc nec neque dapibus, laoreet quam eget, posuere magna. Aliquam quis leo purus. Nullam facilisis imperdiet maximus. Curabitur commodo sed turpis eget tempus. Nunc lorem tortor, fermentum a justo in, volutpat scelerisque neque. Cras felis dui, dignissim vitae tincidunt vitae, sodales sed justo. Mauris ante lacus, lacinia non sapien eu, iaculis fermentum purus. Vestibulum ultrices maximus nisi, sed rhoncus nibh egestas at. Duis sed dolor ligula. Donec et pulvinar ipsum. Nam ullamcorper felis eu placerat venenatis.</p>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
                */}

            </DialogStyled>
        </div>

    )
}

export default ModalBillingDetails
